// Packages
import React from 'react';

// Hooks
import { KiteIcon } from '@kite/react-kite';
import { useAppConfig } from '../../hooks/useAppConfig';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useAuthSession } from '../../hooks/useAuthSession';

// Components
import InternalUserInfo from '../InternalUserInfo/InternalUserInfo';

// Utils
import { IAuthUser } from '../../contexts/types/authUser';

// Styles
import './InternalUserBanner.scss';

export interface IInternalUserBannerProps {
  user: IAuthUser;
}

/**
 * InternalUserBanner that is rendered on every page through the Layout component. Future state: user management on right hand site.
 */

const InternalUserBanner = ({ user }: IInternalUserBannerProps) => {
  const {
    api: { cxdo },
  } = useAppConfig();

  const { portalAccountGUID } = useAuthUser();
  const { aimSessionTransfer } = useAuthSession();

  return (
    <header className="internal-user-banner">
      <a
        className="internal-user-banner__overview"
        onClick={aimSessionTransfer}
        href={`${cxdo}/organizations/${portalAccountGUID}`}
      >
        <KiteIcon icon="chevron-left" />
        <span className="internal-user-banner__overview-words">
          View Organizations
        </span>
      </a>
      <div className="internal-user-banner__viewing">
        <span>Viewing</span>
        <span className="internal-user-banner__viewing-company">
          {user?.organization?.name}
        </span>
      </div>
      <InternalUserInfo />
    </header>
  );
};

export default InternalUserBanner;
