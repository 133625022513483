/**
 * Error Utils for handling errors.
 * @see https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
 */

import { IPiNxtAPIResponse } from '../contexts/types/pinxt';
import { IGqlError } from '../types/types.generated';

type ErrorWithMessage = {
  message: string;
};

type ErrorWithName = {
  name: string;
};

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
};

const isErrorWithName = (error: unknown): error is ErrorWithName => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'name' in error &&
    typeof (error as Record<string, unknown>).name === 'string'
  );
};

const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) {
    return maybeError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
};

const toErrorWithName = (maybeError: unknown): ErrorWithName => {
  if (isErrorWithName(maybeError)) {
    return maybeError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
};

/**
 * Get error message from Error Object
 * @param error Unknown Error
 * @returns Error Message
 */
export const getErrorMessage = (error: unknown) => {
  return toErrorWithMessage(error).message;
};

/**
 * Get error name from Error Object
 * @param error Unknown Error
 * @returns Error Message
 */
export const getErrorName = (error: unknown) => {
  return toErrorWithName(error).name;
};

/**
 * All our GraphQL calls go through PiNxt and if for some reason the PiNxt gate fails,
 * PiNxt doesn't return back the correct format we are expected from a GraphQL request.
 * This mapper func transforms it to the correct, expected format.
 */
export const piErrToGraphQLErr = (
  piError: IPiNxtAPIResponse
): { errors: IGqlError[] } => {
  return {
    errors: [
      {
        message: piError.resultMessage || piError.error_description || '',
        extensions: {
          code: piError.resultCode || piError.error || '',
        },
      },
    ],
  };
};

/**
 * All our REST calls go through PiNxt and if for some reason the PiNxt gate fails,
 * PiNxt might not return back the correct format we are expecting from the REST request.
 * This mapper func transforms it to the correct, expected format.
 */
export const piErrToRestErr = (piError: IPiNxtAPIResponse) => {
  return {
    code: piError.resultCode || piError.error || '',
    message: piError.resultMessage || piError.error_description || '',
  };
};
