/**
 * Analytics SDR for Page State Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

export const pageStateAnalytics: IAnalyticSdr = {
  pageError: {
    siteSection: '500',
  },
  pageNotFound: {
    siteSection: '404',
  },
};
