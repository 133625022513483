/* eslint-disable no-empty-function */
/* eslint-disable react/jsx-no-constructed-context-values */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import React, { createContext, useState } from 'react';
import {
  IStoredPaymentGetData,
  IStoredPaymentCard,
  IStoredPaymentEft,
} from '../../../types/types.generated';
import { IViewBillAccount, IBillPayForm } from '../types/billpay.types';
import {
  IBillPayApiErrorData,
  IBillPayApiSuccessData,
} from '../types/billpay-api.types';

interface IProps {
  children: React.ReactNode;
}

export interface IBillPayState {
  billAccount: IViewBillAccount | null | undefined;
  isPaymentRestrictionOnCard: boolean | undefined;
  isPaymentRestrictionOnEFT: boolean | undefined;
  storedPaymentsGetData: IStoredPaymentGetData[] | null | undefined;
  storedPaymentsCard: IStoredPaymentCard[] | null | undefined;
  storedPaymentsEft: IStoredPaymentEft[] | null | undefined;
  currentBillPayFormData: IBillPayForm | null | undefined;
  billpaySubmitErrorData?: IBillPayApiErrorData | null | undefined;
  billpaySubmitSuccessData?: IBillPayApiSuccessData | null | undefined;
  totalPaymentsForCard: number;
}

export interface IBillPayFormData {
  currentBillPayFormData: IBillPayForm | null;
}

interface IBillPayContext {
  billPayState: IBillPayState | null;
  setBillPayState: (value: React.SetStateAction<IBillPayState | null>) => void;
  billStoredPayState: IBillPayState | null;
  setBillStoredPayState: (
    value: React.SetStateAction<IBillPayState | null>
  ) => void;
}

export const defaultIBillPayContextValue: IBillPayContext = {
  billPayState: null,
  setBillPayState: (value: React.SetStateAction<IBillPayState | null>) => {},
  billStoredPayState: null,
  setBillStoredPayState: (
    value: React.SetStateAction<IBillPayState | null>
  ) => {},
};

export const BillPayContext = createContext<IBillPayContext>(
  defaultIBillPayContextValue
);

export const BillPayContextProvider = ({ children }: IProps) => {
  // Provide 2 different state data for Bill Pay and Stored Payment Management
  // activities so that they don't confuse and conflict with each other.
  const [billPayState, setBillPayState] = useState<IBillPayState | null>(
    defaultIBillPayContextValue.billPayState
  );
  const [billStoredPayState, setBillStoredPayState] =
    useState<IBillPayState | null>(defaultIBillPayContextValue.billPayState);

  return (
    <BillPayContext.Provider
      value={{
        billPayState,
        setBillPayState,
        billStoredPayState,
        setBillStoredPayState,
      }}
    >
      {children}
    </BillPayContext.Provider>
  );
};
