import { IEnvConfig } from './envConfig.types';

let envConfig: IEnvConfig;

/**
 * Fetch EnvConfig.
 * The environment config (located here `<root>./configs/<env>.json`) is a autogenerated
 * json file created / inserted by Gitlab pipelines on deploy  It gives us
 * information about the pipeline and what env the app is deploying to.
 */
export const fetchEnvConfig = async (): Promise<IEnvConfig> => {
  if (envConfig) {
    return envConfig;
  }

  const path = '/config/env-vars.json';
  const response = await fetch(path, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Unable to fetch Env Config file!');
  }

  envConfig = await response.json();
  return envConfig;
};
