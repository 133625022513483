/**
 * Analytics SDR for Network Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Network';
const detailsSubSection = 'Network Details';
const cloudSubSection = 'Cloud Connect Details';

export const networkAnalytics: IAnalyticSdr = {
  networkInventory: {
    siteSection,
    siteSubSection: 'Network Inventory',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
    },
    menuSelections: {
      networkTypeMenu: {
        itemClicked: 'Network Type',
      },
    },
  },
  networkDetails: {
    siteSection,
    siteSubSection: detailsSubSection,
    buttonClicks: {
      serviceAvailTab: {
        itemClicked: 'Service Availability',
      },
      utilizationTab: {
        itemClicked: 'Utilization',
      },
      equipmentTab: {
        itemClicked: 'Equipment Status',
      },
      equipmentTabNoMetricsSupportLink: {
        itemClicked: 'support page',
      },
      createReportBtn: {
        itemClicked: 'Create Report',
      },
      createTicketBtn: {
        itemClicked: 'Create Ticket',
      },
      backBtn: {
        itemClicked: 'Back',
      },
      createEquipmentReport: {
        itemClicked: 'Create Report',
        pageRegion: 'Equipment Status',
      },
    },
    menuSelections: {
      viewMenu: {
        itemClicked: 'Views',
      },
      trafficTypeToggle: {
        pageRegion: '', // dynamic value based on the tab selected (ie: 'Utilization' or 'Service Availability')
        itemClicked: 'Traffic Type',
      },
    },
  },
  networkCreateReport: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Network Report',
  },
  networkCreateReportSuccess: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Network Report - Success',
  },
  networkEquipmentCreateReport: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Network Report',
  },
  networkEquipmentCreateReportSuccess: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Network Report - Success',
  },

  cloudDetails: {
    siteSection,
    siteSubSection: cloudSubSection,
    buttonClicks: {
      serviceAvailTab: {
        itemClicked: 'Service Availability',
      },
      utilizationTab: {
        itemClicked: 'Utilization',
      },
      createReportBtn: {
        itemClicked: 'Create Report',
      },
      createTicketBtn: {
        itemClicked: 'Create Ticket',
      },
      trafficTypeToggle: {
        pageRegion: '', // dynamic value based on the tab selected (ie: 'Utilization' or 'Service Availability')
        itemClicked: 'Traffic Type',
      },
    },
  },
  cloudCreateReport: {
    siteSection,
    siteSubSection: cloudSubSection,
    siteSubSubSection: 'Create Cloud Connect Report',
  },
  cloudCreateReportSuccess: {
    siteSection,
    siteSubSection: cloudSubSection,
    siteSubSubSection: 'Create Cloud Connect Report - Success',
  },
};
