/**
 * Analytics SDR for Secure Access Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/Adobe+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Secure Access';

export const secureAccessAnalytics: IAnalyticSdr = {
  secureAccessInventory: {
    siteSection,
    siteSubSection: 'Secure Access Inventory',
    buttonClicks: {
      duoPortalLink: {
        itemClicked: 'Duo Admin Portal',
      },
    },
  },
};
