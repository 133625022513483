import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { businessVoiceRoutePerm } from '../../components/Routes/routePermissions';
import { businessVoiceAnalytics } from './types/businessVoiceAnalytics';

const BusinessVoiceInventory = lazy(
  () =>
    import(
      /* webpackChunkName: "business-voice-inventory" */ './BusinessVoiceInventory'
    )
);

const { orgServices } = businessVoiceRoutePerm;
const { businessVoiceInventory } = businessVoiceAnalytics;

export const businessVoiceRoutes = (
  <Route
    path="services/business-voice/inventory"
    element={
      <PageRoute orgServices={orgServices} pageInfo={businessVoiceInventory}>
        <BusinessVoiceInventory />
      </PageRoute>
    }
  />
);
