import React from 'react';
import { Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import SecureAccessInventory from './SecureAccessInventory';
import { secureAccessRoutePerm } from '../../components/Routes/routePermissions';
import { secureAccessAnalytics } from './types/secureAccessTypes';

const { orgServices } = secureAccessRoutePerm;
const { secureAccessInventory } = secureAccessAnalytics;

export const secureAccessRoutes = (
  <Route
    path="services/secure-access/inventory"
    element={
      <PageRoute orgServices={orgServices} pageInfo={secureAccessInventory}>
        <SecureAccessInventory />
      </PageRoute>
    }
  />
);
