import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { wavelengthRoutePerm } from '../../components/Routes/routePermissions';
import { wavelengthAnalytics } from './types/wavelengthAnalytics';

const WavelengthInventory = lazy(
  () =>
    import(
      /* webpackChunkName: "wavelength-inventory" */ './WavelengthInventory'
    )
);

const { orgServices } = wavelengthRoutePerm;
const { wavelengthInventory } = wavelengthAnalytics;

export const wavelengthRoutes = (
  <Route
    path="services/wavelength/inventory"
    element={
      <PageRoute orgServices={orgServices} pageInfo={wavelengthInventory}>
        <WavelengthInventory />
      </PageRoute>
    }
  />
);
