import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { eneAnalytics } from './types/eneAnalytics';
import { eneRoutePerm } from '../../components/Routes/routePermissions';

const EneInventory = lazy(
  () => import(/* webpackChunkName: "ene-inventory" */ './EneInventory')
);

const EneDetails = lazy(
  () => import(/* webpackChunkName: "ene-details" */ './EneDetails')
);

const { orgServices } = eneRoutePerm;
const { eneInventory, eneDetails } = eneAnalytics;

export const eneRoutes = (
  <Route path="services/enterprise-network-edge/*">
    <Route
      path="inventory"
      element={
        <PageRoute orgServices={orgServices} pageInfo={eneInventory}>
          <EneInventory />
        </PageRoute>
      }
    />
    <Route
      path="details/:id/edge"
      element={
        <PageRoute orgServices={orgServices} pageInfo={eneDetails}>
          <EneDetails />
        </PageRoute>
      }
    />
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>
);
