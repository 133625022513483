import { useState, useCallback, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAppConfig } from '../../hooks/useAppConfig';

interface IUserIdleProps {
  /** Is the user authenticated */
  isAuthenticated: boolean;

  /** Called anytime a user is interacting with the site */
  onUserInteraction: () => void;
}

/**
 *  Monitor User idleness and Auto Log the user out of their session if goes too long
 */
export const useUserIdle = ({
  isAuthenticated,
  onUserInteraction,
}: IUserIdleProps) => {
  const [showIdlePrompt, setShowIdlePrompt] = useState(false);
  const [isUserIdleLogout, setIsUserIdleLogout] = useState(false);

  const {
    auth: {
      autoLogout: { idleWarningMins, postIdleWarnMins },
    },
  } = useAppConfig();

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { start: startIdleTimer, pause: stopIdleTimer } = useIdleTimer({
    // when time, show the Idle Warning Prompt modal
    timeout: idleWarningMins * 60 * 1000,
    onPrompt: () => setShowIdlePrompt(true),

    // after warning is shown, give them a few more mins to response then auto log them out
    promptTimeout: postIdleWarnMins * 60 * 1000,
    onIdle: () => setIsUserIdleLogout(true),

    // Anytime the user interacts with the
    eventsThrottle: 2000, // give at least 2 secs between `onAction` calls
    onAction: onUserInteraction,

    startManually: true,
  });

  /** Reset the idle timer and prompt */
  const resetIdlePrompt = useCallback(() => {
    setShowIdlePrompt(false);
    startIdleTimer();
    setIsUserIdleLogout(false);
  }, [startIdleTimer]);

  useEffect(() => {
    if (isAuthenticated) {
      // Start monitoring user inactivity
      startIdleTimer();
      return;
    }
    stopIdleTimer();
  }, [isAuthenticated, startIdleTimer, stopIdleTimer]);

  return {
    isUserIdleLogout,
    startIdleTimer,
    stopIdleTimer,
    showIdlePrompt,
    resetIdlePrompt,
  };
};
