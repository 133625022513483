/**
 * Analytics SDR for Business Internet Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */
import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Business Internet';

export const businessInternetAnalytics: IAnalyticSdr = {
  businessInternetInventory: {
    siteSection,
    siteSubSection: 'Business Internet Inventory',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      viewDetailsBtn: {
        itemClicked: 'View Details',
      },
    },
    menuSelections: {
      viewMenu: {
        itemClicked: 'Views',
      },
    },
  },
  businessInternetDetails: {
    siteSection,
    siteSubSection: 'Business Internet Details',
    buttonClicks: {
      back: {
        itemClicked: 'Back',
      },
      editEquipmentName: {
        itemClicked: 'Edit Equipment Name',
      },
      cancelEditEquipmentName: {
        itemClicked: 'Cancel Edit Equipment Name',
      },
      saveEditEquipmentName: {
        itemClicked: 'Save Edit Equipment Name',
      },
      restartEquipment: {
        itemClicked: 'Restart Equipment',
      },
      troubleshootEquipment: {
        itemClicked: 'Troubleshoot Equipment',
      },
      /** BEGIN: 'Troubleshoot Equipment' FullPage Overlay Stepper Modal Button Clicks for "Troubleshoot Equipment" Step 1 */
      troubleshootEquipModalTroubleshootBack: {
        pageRegion: 'Troubleshoot Equipment: Troubleshoot Equipment',
        itemClicked: 'Back',
      },
      troubleshootEquipModalTroubleshootIssueResolved: {
        pageRegion: 'Troubleshoot Equipment: Troubleshoot Equipment',
        itemClicked: 'Issue Resolved',
      },
      troubleshootEquipModalTroubleshootGetSupport: {
        pageRegion: 'Troubleshoot Equipment: Troubleshoot Equipment',
        itemClicked: 'Get Support',
      },
      /** END: 'Troubleshoot Equipment' FullPage Overlay Stepper Modal Button Clicks for "Troubleshoot Equipment" Step 1 */

      /** BEGIN: 'Troubleshoot Equipment' FullPage Overlay Stepper Modal Button Clicks for "Contact Us" Step 2 */
      troubleshootEquipModalContactUsBack: {
        pageRegion: 'Troubleshoot Equipment: Contact Us',
        itemClicked: 'Back',
      },
      troubleshootEquipModalContactUsClose: {
        pageRegion: 'Troubleshoot Equipment: Contact Us',
        itemClicked: 'Close',
      },
      /** END: 'Troubleshoot Equipment' FullPage Overlay Stepper Modal Button Clicks for "Contact Us" Step 2 */

      /** BEGIN: Restart Equipment Processs: 'Restart Equipment?' Overlay Modal Button Clicks */
      restartEquipProcessRestartEquipmentRestartEquipment: {
        pageRegion: 'Restart Equipment: Restart Equipment?',
        itemClicked: 'Restart Equipment',
      },
      restartEquipProcessRestartEquipmentClose: {
        pageRegion: 'Restart Equipment: Restart Equipment?',
        itemClicked: 'Close',
      },
      restartEquipProcessRestartEquipmentCancel: {
        pageRegion: 'Restart Equipment: Restart Equipment?',
        itemClicked: 'Cancel',
      },
      /** END: Restart Equipment Processs: 'Restart Equipment?' Overlay Modal Button Clicks */

      /** BEGIN: Restart Equipment Processs: 'Restarting Equipment' (spinner) Overlay Modal Button Clicks */
      restartEquipProcessRestartingEquipmentClose: {
        pageRegion: 'Restart Equipment: Restarting Equipment',
        itemClicked: 'Close',
      },
      /** END: Restart Equipment Processs: 'Restarting Equipment' (spinner) Overlay Modal Button Clicks */

      /** BEGIN: Restart Equipment Processs: 'Equipment Restart Successful' Overlay Modal Button Clicks */
      restartEquipProcessRestartSuccessfulClose: {
        pageRegion: 'Restart Equipment: Equipment Restart Successful',
        itemClicked: 'Close',
      },
      restartEquipProcessRestartSuccessfulContinueTroubleshooting: {
        pageRegion: 'Restart Equipment: Equipment Restart Successful',
        itemClicked: 'Continue Troubleshooting',
      },
      /** END: Restart Equipment Processs: 'Equipment Restart Successful' Overlay Modal Button Clicks */

      /** BEGIN: Restart Equipment Processs: 'Unable to Restart Equipment' Overlay Modal Button Clicks */
      restartEquipProcessRestartUnSuccessfulClose: {
        pageRegion: 'Restart Equipment: Unable to Restart Equipment',
        itemClicked: 'Close',
      },
      restartEquipProcessRestartUnSuccessfulRestartManually: {
        pageRegion: 'Restart Equipment: Unable to Restart Equipment',
        itemClicked: 'Restart Manually',
      },
      restartEquipProcessRestartUnSuccessfulTryAgain: {
        pageRegion: 'Restart Equipment: Unable to Restart Equipment',
        itemClicked: 'Try Again',
      },
      /** END: Restart Equipment Processs: 'Unable to Restart Equipment' Overlay Modal Button Clicks */
    },
  },
};
