import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { locationAnalytics } from './types/locationsAnalytics';
import { locationRoutePerm } from '../../components/Routes/routePermissions';

const Locations = lazy(
  () => import(/* webpackChunkName: "locations" */ './Locations')
);

const LocationSummary = lazy(
  () => import(/* webpackChunkName: "locations-summary" */ './LocationSummary')
);

const { orgServices } = locationRoutePerm;
const { location, locationSummary } = locationAnalytics;

export const locationRoutes = (
  <Route path="locations/*">
    <Route
      index
      element={
        <PageRoute orgServices={orgServices} pageInfo={location}>
          <Locations />
        </PageRoute>
      }
    />
    <Route
      path="summary/:id"
      element={
        <PageRoute orgServices={orgServices} pageInfo={locationSummary}>
          <LocationSummary />
        </PageRoute>
      }
    />
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>
);
