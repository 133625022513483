import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { voiceTrunkingAnalytics } from './types/voiceAnalytics';
import { voiceRoutePerm } from '../../components/Routes/routePermissions';
import { UserCapability } from '../../types/userCapability';

const VoiceInventory = lazy(
  () => import(/* webpackChunkName: "voice-inventory" */ './VoiceInventory')
);

const VoiceDetails = lazy(
  () => import(/* webpackChunkName: "voice-details" */ './VoiceDetails')
);

const VoiceCallGuardEnterprise = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-call-guard-enterprise" */ './VoiceCallGuardEnterprise'
    )
);

const VoiceCallGuardSingleLocation = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-call-guard-single-location" */ './VoiceCallGuardSingleLocation'
    )
);

const VoiceCreateMetricReport = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-create-metric-report" */ './VoiceCreateMetricReport'
    )
);

const VoiceCallDetailRecords = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-call-detail-records" */ './VoiceCallDetailRecords'
    )
);

const VoiceCreateCdrReport = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-create-cdr-report" */ './VoiceCreateCdrReport'
    )
);

const VoiceDetailsManagedFeatures = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-details-managed-features" */ './VoiceDetailsManagedFeatures'
    )
);

const VoiceDetailsPlan = lazy(
  () =>
    import(/* webpackChunkName: "voice-details-plan" */ './VoiceDetailsPlan')
);

const VoiceManageDids = lazy(
  () => import(/* webpackChunkName: "voice-manage-dids" */ './VoiceManageDids')
);

const VoiceMOUPackageDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-mou-pkg-details" */ './VoiceMOUPackageDetails'
    )
);

const VoiceSelectReport = lazy(
  () =>
    import(/* webpackChunkName: "voice-select-report" */ './VoiceSelectReport')
);

const ReportConfirm = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-report-confirm" */ '../../components/ReportConfirm'
    )
);

const VoiceCallGuardSettingsEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-call-guard-settings-edit" */ './VoiceCallGuardSettingsEdit'
    )
);

const VoiceCallGuardSettingsPreSave = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-call-guard-settings-pre-save" */ './VoiceCallGuardSettingsPreSave'
    )
);

const VoiceCallGuardSettingsSuccess = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-call-guard-settings-success" */ './VoiceCallGuardSettingsSuccess'
    )
);

const VoiceCreateCallBlockingReport = lazy(
  () =>
    import(
      /* webpackChunkName: "voice-create-call-blocking-report" */ './VoiceCreateCallBlockingReport'
    )
);

const { orgServices } = voiceRoutePerm;
const {
  voiceInventory,
  voiceDetails,
  voicePlanDetails,
  voiceCallRecords,
  voiceManageFeatures,
  voiceManageDids,
  voiceCallGuardByLocation,
  voiceSharedMou,
  voiceCallGuardEnterprise,
  voiceCallGuardSettingsEdit,
  voiceCallGuardSettingsConfirm,
  voiceCallGuardSettingsSuccess,
  voiceCallGuardReport,
  voiceCallGuardReportSuccess,
  voiceCreateReportType,
  voiceCallRecordsReport,
  voiceMetricsReport,
  voiceCallReportSuccess,
} = voiceTrunkingAnalytics;

export const voiceRoutes = (
  <>
    <Route path="services/voice-trunking/*">
      <Route
        path="inventory"
        element={
          <PageRoute orgServices={orgServices} pageInfo={voiceInventory}>
            <VoiceInventory />
          </PageRoute>
        }
      />
      <Route path="details/:accountNumber/*">
        <Route
          index
          element={
            <PageRoute orgServices={orgServices} pageInfo={voiceDetails}>
              <VoiceDetails />
            </PageRoute>
          }
        />
        <Route
          path="plan"
          element={
            <PageRoute orgServices={orgServices} pageInfo={voicePlanDetails}>
              <VoiceDetailsPlan />
            </PageRoute>
          }
        />
        <Route
          path="records"
          element={
            <PageRoute orgServices={orgServices} pageInfo={voiceCallRecords}>
              <VoiceCallDetailRecords />
            </PageRoute>
          }
        />
        <Route
          path="records/:serviceType"
          element={
            <PageRoute orgServices={orgServices} pageInfo={voiceCallRecords}>
              <VoiceCallDetailRecords />
            </PageRoute>
          }
        />
        <Route
          path="edit/:type"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={{
                ...voiceManageFeatures,
                skipPageView: true,
              }}
            >
              <VoiceDetailsManagedFeatures />
            </PageRoute>
          }
        />
        <Route
          path="did-overflow"
          element={
            <PageRoute orgServices={orgServices} pageInfo={voiceManageDids}>
              <VoiceManageDids />
            </PageRoute>
          }
        />

        <Route
          path=":locationId/call-guard"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={voiceCallGuardByLocation}
            >
              <VoiceCallGuardSingleLocation />
            </PageRoute>
          }
        />

        <Route
          path="sharedMOU/:packageParentId"
          element={
            <PageRoute orgServices={orgServices} pageInfo={voiceSharedMou}>
              <VoiceMOUPackageDetails />
            </PageRoute>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route
        path="call-guard-enterprise"
        element={
          <PageRoute
            orgServices={orgServices}
            pageInfo={voiceCallGuardEnterprise}
          >
            <VoiceCallGuardEnterprise />
          </PageRoute>
        }
      />

      <Route
        path="call-guard-settings-edit"
        element={
          <PageRoute pageInfo={voiceCallGuardSettingsEdit}>
            <VoiceCallGuardSettingsEdit />
          </PageRoute>
        }
      />

      <Route
        path="call-guard-settings-edit/pre-save"
        element={
          <PageRoute pageInfo={voiceCallGuardSettingsConfirm}>
            <VoiceCallGuardSettingsPreSave />
          </PageRoute>
        }
      />

      <Route
        path="call-guard-settings-edit/success"
        element={
          <PageRoute pageInfo={voiceCallGuardSettingsSuccess}>
            <VoiceCallGuardSettingsSuccess />
          </PageRoute>
        }
      />

      <Route
        path=":accountNumber/call-blocking-report"
        element={
          <PageRoute pageInfo={voiceCallGuardReport}>
            <VoiceCreateCallBlockingReport />
          </PageRoute>
        }
      />

      <Route
        path=":accountNumber/call-blocking-report/success"
        element={
          <PageRoute pageInfo={voiceCallGuardReportSuccess}>
            <ReportConfirm />
          </PageRoute>
        }
      />

      <Route path="*" element={<Navigate to="/404" />} />
    </Route>

    <Route path="reports/build/voice-trunking/:accountNumber/*">
      <Route
        index
        element={
          <PageRoute orgServices={orgServices} pageInfo={voiceCreateReportType}>
            <VoiceSelectReport />
          </PageRoute>
        }
      />
      <Route
        path="select"
        element={
          <PageRoute orgServices={orgServices} pageInfo={voiceCreateReportType}>
            <VoiceSelectReport />
          </PageRoute>
        }
      />

      <Route
        path="call-detail-records"
        element={
          <PageRoute
            userCapabilities={UserCapability.ViewCallDetailRecords}
            orgServices={orgServices}
            pageInfo={voiceCallRecordsReport}
          >
            <VoiceCreateCdrReport />
          </PageRoute>
        }
      />
      <Route
        path="call-metrics"
        element={
          <PageRoute orgServices={orgServices} pageInfo={voiceMetricsReport}>
            <VoiceCreateMetricReport />
          </PageRoute>
        }
      />
      <Route
        path="success"
        element={
          <PageRoute
            orgServices={orgServices}
            pageInfo={voiceCallReportSuccess}
          >
            <ReportConfirm />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </>
);
