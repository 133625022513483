/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable default-case */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import { KiteIcon } from '@kite/react-kite';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { NavLink } from 'react-router-dom';
import differenceBy from 'lodash/differenceBy';
import { useAuthUser } from '../hooks/useAuthUser';
import { useGetReportListQuery } from '../hooks/useRequest.generated';
import { OrganizationFeature, ReportStatus } from '../types/types.generated';
import { invalidateAssociatedQueries } from '../utils/createReportUtils';
import { ReportType } from '../domains/Reports/types/report';

export interface IReportsPollingContext {
  isReportAvailable: boolean;
  reportsNotificationMessage: string;
  startPolling: (reportId: string) => void;
  resetReportsNotifications: () => void;
  goToReportsIsClicked: boolean;
  setGoToReportsIsClicked: Dispatch<SetStateAction<boolean>>;
  updateInitialReportsList: (reportId: ReportType[]) => void;
  completedReportsToNotify: number;
}

// set the defaults
const defaultValue: IReportsPollingContext = {
  isReportAvailable: false,
  startPolling: () => {},
  reportsNotificationMessage: '',
  resetReportsNotifications: () => {},
  goToReportsIsClicked: false,
  setGoToReportsIsClicked: () => {},
  updateInitialReportsList: () => {},
  completedReportsToNotify: 0,
};

export const ReportsPollingContext =
  createContext<IReportsPollingContext>(defaultValue);

/**
 * Make Reports Polling state available to all components.
 */
export const ReportsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isReportAvailable, setIsReportAvailable] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const { portalAccountGUID, authUser, hasOrgFeature } = useAuthUser();
  const [completedReportsToNotify, setCompletedReportsToNotify] = useState(0);
  const [completedReportsCount, setCompletedReportsCount] = useState(0);
  const [reportsNotificationMessage, setReportsNotificationMessage] = useState(
    defaultValue.reportsNotificationMessage
  );
  const [pendingReports, setPendingReports] = useState<string[]>([]);
  const [goToReportsIsClicked, setGoToReportsIsClicked] = useState(false);
  const [prevReportsList, setPrevReportsList] = useState<ReportType[]>([]);
  const [unknownReportIdsCount, setUnknownReportIdsCount] = useState(0);

  const pollingInterval = 20000;

  const { data, refetch } = useGetReportListQuery(
    {
      portalAccountGUID,
      // We need to send these to BE so they can filter the data, in the future they'll integrate AIM and do this.
      orgFeatures: {
        hasNetwork: hasOrgFeature(OrganizationFeature.Network),
        hasFiber: hasOrgFeature(OrganizationFeature.Fiber),
        hasWavelength: hasOrgFeature(OrganizationFeature.Wavelength),
        hasMss: hasOrgFeature(OrganizationFeature.MSS),
      },
    },
    {
      // Refetch the data every 20 seconds
      refetchInterval: isPolling ? pollingInterval : 0,
      enabled: !!authUser,
    }
  );

  const queryClient = useQueryClient();

  const reportList = data?.user.organization?.services?.reports?.list?.reports;

  useEffect(() => {
    reportList?.forEach((report) => {
      if (pendingReports.includes(report.id)) {
        switch (report.status) {
          case ReportStatus.Completed:
            setCompletedReportsCount((prevCompletedReportCount) => {
              return prevCompletedReportCount + 1;
            });
            setPendingReports((prev) => prev.filter((id) => id !== report.id));
            invalidateAssociatedQueries({
              queryClient,
              queryKeys: ['getLocationSummary', 'getLocationList'],
            });
            break;
          case ReportStatus.Failed:
            setPendingReports((prev) => prev.filter((id) => id !== report.id));
            break;
        }
      } else if (
        report.status === ReportStatus.Queued ||
        report.status === ReportStatus.Running
      ) {
        setPendingReports((prev) =>
          !prev.includes(report.id) ? [...prev, report.id] : prev
        );
      }
    });

    if (completedReportsCount > 0) {
      setCompletedReportsToNotify(completedReportsCount);
    }

    if (pendingReports.length === 0 && unknownReportIdsCount === 0) {
      setIsPolling(false);
    }
  }, [
    reportList,
    pollingInterval,
    completedReportsCount,
    pendingReports,
    unknownReportIdsCount,
  ]);

  useEffect(() => {
    if (completedReportsToNotify > 0) {
      // Set content to display for notification badge assistive text and page alert content.
      const assistiveLabelText = `${completedReportsToNotify} report${
        completedReportsToNotify > 1 ? 's are' : ' is'
      } ready for review/download.`;

      setReportsNotificationMessage(assistiveLabelText);
      setIsReportAvailable(true);
    }
  }, [completedReportsToNotify, isPolling]);

  useEffect(() => {
    // Only invoked when there are unknown report IDs
    // Old report list will be used to determine if new reports are available
    if (unknownReportIdsCount && reportList?.length) {
      const newReports = differenceBy(reportList, prevReportsList, (x) => x.id);

      if (newReports.length) {
        // add the new report id's to pendingReports
        newReports.forEach((report) => {
          setPendingReports((prev) =>
            !prev.includes(report.id) ? [...prev, report.id] : prev
          );
        });

        setUnknownReportIdsCount((prev) =>
          prev - newReports.length ? prev - newReports.length : 0
        );
      }
      setPrevReportsList(reportList);
    }
  }, [unknownReportIdsCount, reportList, prevReportsList]);

  const startPolling = (reportId: string) => {
    setPendingReports((prev) =>
      !prev.includes(reportId) ? [...prev, reportId] : prev
    );

    setIsPolling(true);
    refetch().catch(() => {});
  };

  // Use this method when reportId is not available. Pass the reportList available before running a report
  // Old report list will be used to find any new reports that are available
  const updateInitialReportsList = (reportsList: ReportType[]) => {
    setUnknownReportIdsCount((prev) => prev + 1);
    setPrevReportsList(reportsList);

    setIsPolling(true);
    refetch().catch(() => {});
  };

  const resetReportsNotifications = () => {
    setIsReportAvailable(false);
    // TODO: Is it possible to just combine these two into one? Or is it better to keep them separate.
    setCompletedReportsCount(() => 0);
    setCompletedReportsToNotify(() => 0);
  };

  return (
    <ReportsPollingContext.Provider
      value={{
        isReportAvailable,
        startPolling,
        reportsNotificationMessage,
        resetReportsNotifications,
        goToReportsIsClicked,
        setGoToReportsIsClicked,
        updateInitialReportsList,
        completedReportsToNotify,
      }}
    >
      {children}
    </ReportsPollingContext.Provider>
  );
};
