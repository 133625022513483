import React, { lazy } from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { billsRoutePerm } from '../../components/Routes/routePermissions';
import {
  billPayCreditLimitReachedRestrictionsSection,
  billPayRestrictionsSection,
  billPaySection,
  billsAnalytics,
  siteSection,
} from './utils/billsAnalytics';
import { BillPayContextProvider } from './contexts/billPayContext';
import { PaperlessBillingContextProvider } from './contexts/paperlessBillingContext';
import { FeatureToggleName } from '../../config/featureToggles/featureToggles.types';

const Bills = lazy(() => import(/* webpackChunkName: "bills" */ './Bills'));

const BillPayModal = lazy(
  () => import(/* webpackChunkName: "bill-pay-modal" */ './BillPayModal')
);

const BillAccountSummary = lazy(
  () =>
    import(
      /* webpackChunkName: "bill-acc-summary" */ './views/BillAccountSummary/BillAccountSummary'
    )
);

const BillAddPaymentMethod = lazy(
  () =>
    import(
      /* webpackChunkName: "bill-add-payment-meth" */ './components/BillAddPaymentMethod/BillAddPaymentMethod'
    )
);

const BillEditPaymentMethod = lazy(
  () =>
    import(
      /* webpackChunkName: "bill-edit-pay-meth" */ './components/BillEditPaymentMethod/BillEditPaymentMethod'
    )
);

const BillStoredPayAddUpdateConfirmView = lazy(
  () =>
    import(
      /* webpackChunkName: "bill-store-pay-add-confirm" */ './views/BillStoredPayAddUpdateConfirmView/BillStoredPayAddUpdateConfirmView'
    )
);

const BillPayRestrictedModal = lazy(
  () =>
    import(
      /* webpackChunkName: "bill-pay-restricted-modal" */ './pages/BillPayRestrictedModal/BillPayRestrictedModal'
    )
);

const BillPayCreditLimitReachedModal = lazy(
  () =>
    import(
      /* webpackChunkName: "bill-pay-credit-limit-reached-modal" */ './pages/BillPayCreditLimitReachedModal/BillPayCreditLimitReachedModal'
    )
);

const AutopayEnrollModal = lazy(
  () =>
    import(
      /* webpackChunkName: "bill-autopay-enroll" */ './views/AutopayEnrollModal/AutopayEnrollModal'
    )
);

const TermsAndConditionsForBilling = lazy(
  () =>
    import(
      /* webpackChunkName: "terms-and-conditions-for-billing" */ './pages/TermsAndConditionsForBilling/TermsAndConditionsForBilling'
    )
);

const InactiveAccountModal = lazy(
  () =>
    import(
      /* webpackChunkName: "bill-inactive-account-modal" */ './InactiveAccountModal'
    )
);

const PaperlessBillingModal = lazy(
  () =>
    import(
      /* webpackChunkName: "paperless-billing-modal" */ './pages/PaperlessBillingModal/PaperlessBillingModal'
    )
);

// Analytic info
const {
  billsMultiAccDetails,
  billsAddSavedPayment,
  billsEditSavedPayment,
  billsAddSavedPaymentSuccess,
  billAutoPayChangeMethod,
  billsAccountInactiveModal,
  paperlessBillingAnalytics,
} = billsAnalytics;

const { orgServices } = billsRoutePerm;

const BillPayContextLayout = () => {
  return (
    <BillPayContextProvider>
      <PaperlessBillingContextProvider>
        <Outlet />
      </PaperlessBillingContextProvider>
    </BillPayContextProvider>
  );
};

export const billsRoutes = (
  <Route element={<BillPayContextLayout />}>
    <Route path="bills/*">
      <Route path="listings/*">
        <Route
          index
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={{
                title: siteSection,
                // PageView Analytics will be handled from inside the components bc of multiple tabs
                // and different use cases that change the rendered view and its sub-sections when
                // loading this page or going back to this page from the various Stored Payments pages.
                skipPageView: true,
              }}
            >
              <Bills />
            </PageRoute>
          }
        />
        <Route
          path=":activateTab"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={{
                title: siteSection,
                // PageView Analytics will be handled from inside the components bc of multiple tabs
                // and different use cases that change the rendered view and its sub-sections when
                // loading this page or going back to this page from the various Stored Payments pages.
                skipPageView: true,
              }}
            >
              <Bills />
            </PageRoute>
          }
        />
        <Route
          path="account-details"
          element={<Navigate to="/bills/listings" />}
        />
        <Route
          path="account-details/:accountNumber/:divisionId/:statementCode"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={billsMultiAccDetails}
            >
              <BillAccountSummary />
            </PageRoute>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="billpay/*">
        <Route
          path=":sourcePage"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={{
                title: billPaySection,
                // PageView Analytics will be handled from inside the components of the Bill Pay steps/pages.
                skipPageView: true,
              }}
            >
              {/* FIXME: This should be a page not a modal. See https://chalk.charter.com/display/ERSPM/FE+Project+Structure  */}
              <BillPayModal />
            </PageRoute>
          }
        />
        <Route
          path="add/payment-method/:sourcePage/:accountNumber/:divisionId/:statementCode"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={billsAddSavedPayment}
            >
              <BillAddPaymentMethod />
            </PageRoute>
          }
        />
        <Route
          path="edit/payment-method/:sourcePage/:accountNumber/:divisionId/:statementCode/:storedPaymentMethodId"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={billsEditSavedPayment}
            >
              <BillEditPaymentMethod />
            </PageRoute>
          }
        />
        <Route
          path="addupdateconfirm/payment-method/:sourcePage/:accountNumber/:divisionId/:statementCode/:storedPaymentMethodId/:isAdd"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={{
                title: billsAddSavedPaymentSuccess.siteSubSection,
                skipPageView: true,
              }}
            >
              {/* FIXME: This should be a page not a view. See https://chalk.charter.com/display/ERSPM/FE+Project+Structure  */}
              <BillStoredPayAddUpdateConfirmView />
            </PageRoute>
          }
        />
        <Route
          path="restricted/:accountNumber/:divisionId/:statementCode/:sourcePage/:isForAutopay"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={{
                title: billPayRestrictionsSection,
                // PageView Analytics will be handled from inside the component
                skipPageView: true,
              }}
            >
              <BillPayRestrictedModal />
            </PageRoute>
          }
        />
        <Route
          path="restrictedCreditCardLimit/:accountNumber/:divisionId/:statementCode/:sourcePage"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={{
                title: billPayCreditLimitReachedRestrictionsSection,
                // PageView Analytics will be handled from inside the component
                skipPageView: true,
              }}
            >
              <BillPayCreditLimitReachedModal />
            </PageRoute>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="autopayenroll/*">
        <Route
          path=":sourcePage/:isEnrollInAutopay"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={{
                title: billAutoPayChangeMethod.siteSubSubSection,
                // PageView Analytics will be handled from inside the components of the Autopay Enroll steps/pages.
                skipPageView: true,
              }}
            >
              <AutopayEnrollModal />
            </PageRoute>
          }
        />
      </Route>

      <Route path="paperless/*">
        <Route
          path=":sourcePage/:optInOrOutRoute"
          element={
            <PageRoute isProtected pageInfo={paperlessBillingAnalytics}>
              <PaperlessBillingModal />
            </PageRoute>
          }
        />
      </Route>

      <Route path="accountinactive/*">
        <Route
          path=":accountNumber/:divisionId/:statementCode/:enrolling"
          element={
            <PageRoute
              orgServices={orgServices}
              pageInfo={billsAccountInactiveModal}
            >
              <InactiveAccountModal />
            </PageRoute>
          }
        />
      </Route>

      <Route path="spectrumLegalPaymentTerms">
        <Route
          index
          element={
            <PageRoute
              isProtected
              pageInfo={{
                title:
                  'Spectrum Enterprise Electronic Payment and Paperless Billing Terms',
                skipPageView: false,
              }}
            >
              <TermsAndConditionsForBilling />
            </PageRoute>
          }
        />
      </Route>

      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </Route>
);
