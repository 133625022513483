// Packages
import React, { useCallback } from 'react';

import { KiteGrid, KiteGridCell, KiteIcon, KiteLink } from '@kite/react-kite';

// Hooks
import { useGetSecureAccessInventoryQuery } from '../../hooks/useRequest.generated';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useAnalytics } from '../../hooks/useAnalytics';

// Components
import Layout from '../../components/Layout/Layout';
import SecureAccessInventoryCard from './components/SecureAccessInventoryCard';
import NoDataMessage from '../../components/NoDataMessage';

// Utils
import { renderApiContent } from '../../utils/componentUtils';
import { noDataMessageMessage, noDataMessageTitle } from '../../utils/copyText';
import { secureAccessAnalytics } from './types/secureAccessTypes';
import { duoManualLink } from './types/commonTypes';

// Styles
import './SecureAccessInventory.scss';

const SecureAccessInventory = () => {
  const { portalAccountGUID } = useAuthUser();
  const { trackButtonEvent } = useAnalytics();
  const { secureAccessInventory } = secureAccessAnalytics;
  const { duoPortalLink } = secureAccessInventory.buttonClicks!;

  const handleDuoPortalClick = () => {
    trackButtonEvent(duoPortalLink, secureAccessInventory);
    window.open(duoManualLink);
  };

  // Data Fetching
  const { isSuccess, data, isError, isLoading } =
    useGetSecureAccessInventoryQuery({
      portalAccountGUID,
    });

  const secureAccessData =
    data?.user?.organization?.services?.secureAccess?.inventory?.services;

  const hasData = !!secureAccessData?.products?.length;
  const pageContent = renderApiContent({
    isSuccess,
    isError,
    hasData,
    onSuccessContent: () =>
      hasData && (
        <SecureAccessInventoryCard secureAccessData={secureAccessData} />
      ),
    onNoDataContent: useCallback(
      () => (
        <KiteGrid>
          <KiteGridCell col={12}>
            <NoDataMessage
              title={noDataMessageTitle}
              message={noDataMessageMessage}
            />
          </KiteGridCell>
        </KiteGrid>
      ),
      []
    ),
  });

  return (
    <Layout loading={isLoading}>
      <KiteGrid className="secure-access">
        <KiteGridCell col={10}>
          <h1>Secure Access Inventory</h1>
          <p className="secure-access__subtitle">
            A list of Secure Access subscriptions.
          </p>
        </KiteGridCell>
        <KiteGridCell col={12}>
          <div className="secure-access__header">
            <KiteGrid>
              <KiteGridCell col={12}>
                <dl>
                  <dt className="secure-access__header-label">Secure Access</dt>
                  <dd>
                    <KiteLink
                      href="#"
                      aria-label="Secure access duo admin portal"
                      id="secure-access-portal-link"
                      onClick={handleDuoPortalClick}
                    >
                      Duo Admin Portal
                      <KiteIcon icon="ki-linkout" />
                    </KiteLink>
                  </dd>
                </dl>
              </KiteGridCell>
            </KiteGrid>
          </div>
        </KiteGridCell>
        <KiteGridCell col={12}>{pageContent}</KiteGridCell>
      </KiteGrid>
    </Layout>
  );
};

export default SecureAccessInventory;
