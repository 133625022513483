import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';

import { fiberRoutePerm } from '../../components/Routes/routePermissions';
import { fiberAnalytics } from './types/fiberAnalytics';

const FiberInventory = lazy(
  () => import(/* webpackChunkName: "fiber-inventory" */ './FiberInventory')
);

const FiberDetails = lazy(
  () => import(/* webpackChunkName: "fiber-details" */ './FiberDetails')
);

const FiberCreateReport = lazy(
  () =>
    import(/* webpackChunkName: "fiber-create-report" */ './FiberCreateReport')
);

const FiberEquipmentCreateReport = lazy(
  () =>
    import(
      /* webpackChunkName: "fiber-equipment-create-report" */ './FiberEquipmentCreateReport'
    )
);

const ReportConfirm = lazy(
  () =>
    import(
      /* webpackChunkName: "fiber-report-confirm" */ '../../components/ReportConfirm'
    )
);

const { orgServices } = fiberRoutePerm;
const {
  fiberInventory,
  fiberDetails,
  fiberCreateReport,
  fiberEquipmentCreateReport,
  fiberEquipmentCreateReportSuccess,
  fiberCreateReportSuccess,
} = fiberAnalytics;

export const fiberRoutes = (
  <>
    <Route path="services/fiber/*">
      <Route
        path="inventory"
        element={
          <PageRoute orgServices={orgServices} pageInfo={fiberInventory}>
            <FiberInventory />
          </PageRoute>
        }
      />
      <Route
        path="details/:circuitId"
        element={
          <PageRoute orgServices={orgServices} pageInfo={fiberDetails}>
            <FiberDetails />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>

    <Route path="reports/build/*">
      <Route
        path="fiber/:circuitId"
        element={
          <PageRoute orgServices={orgServices} pageInfo={fiberCreateReport}>
            <FiberCreateReport />
          </PageRoute>
        }
      />
      <Route
        path="equipment/fiber/:circuitId"
        element={
          <PageRoute
            orgServices={orgServices}
            pageInfo={fiberEquipmentCreateReport}
          >
            <FiberEquipmentCreateReport />
          </PageRoute>
        }
      />
      <Route
        path="fiber/:circuitId/success"
        element={
          <PageRoute
            orgServices={orgServices}
            pageInfo={fiberCreateReportSuccess}
          >
            <ReportConfirm />
          </PageRoute>
        }
      />
      <Route
        path="equipment/fiber/:circuitId/success"
        element={
          <PageRoute
            orgServices={orgServices}
            pageInfo={fiberEquipmentCreateReportSuccess}
          >
            <ReportConfirm analytics={fiberEquipmentCreateReportSuccess} />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </>
);
