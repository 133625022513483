import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const today = new Date(new Date().setHours(0, 0, 0, 0));
export const weekAgo = new Date(dayjs(today).subtract(6, 'day').format());
export const monthAgo = new Date(dayjs(today).subtract(1, 'month').format());

export const generateMonthData = (currentMonth: string) => {
  const monthData = [];
  const startMonth = dayjs(currentMonth);
  const daysInMonth = startMonth.daysInMonth();
  let weekOfMonth = 1;
  for (let i = 1; i <= daysInMonth; i++) {
    const dateValue = new Date(startMonth.year(), startMonth.month(), i);
    const dayIndex = dayjs(dateValue).day();
    const dayOfMonth = i;
    if (dayIndex === 0 && i !== 0) {
      weekOfMonth++;
    }
    monthData.push({
      dayIndex,
      dayOfMonth,
      weekOfMonth,
      dateValue,
      isMonth: true,
    });
  }
  const lastDayIndex = monthData[monthData.length - 1].dayIndex;
  if (lastDayIndex !== 6) {
    const nextMonth = startMonth.add(1, 'month');
    let dayOfMonth = 1;
    for (let i = lastDayIndex; i < 6; i++) {
      const dateValue = new Date(
        nextMonth.year(),
        nextMonth.month(),
        dayOfMonth
      );
      const nextDayIndex = dayjs(dateValue).day();
      monthData.push({
        dayIndex: nextDayIndex,
        dayOfMonth,
        weekOfMonth,
        dateValue,
        isMonth: false,
      });
      dayOfMonth++;
    }
  }
  const firstDayIndex = monthData[0].dayIndex;
  if (firstDayIndex > 0) {
    weekOfMonth = 1;
    const prevMonth = startMonth.subtract(1, 'month');
    const prevDaysInMonth = prevMonth.daysInMonth();
    for (let i = 0; i < firstDayIndex; i++) {
      const dayOfMonth = prevDaysInMonth - i;
      const dateValue = new Date(
        prevMonth.year(),
        prevMonth.month(),
        dayOfMonth
      );
      const prevDayIndex = dayjs(dateValue).day();
      monthData.unshift({
        dayIndex: prevDayIndex,
        dayOfMonth,
        weekOfMonth,
        dateValue,
        isMonth: false,
      });
    }
  }
  return monthData;
};

export const datePickerClassNames = (
  dateValue: Date,
  isMonth: boolean,
  hoverRange: Date[],
  selectedStart: Date,
  selectedEnd: Date,
  minDate: Date,
  maxDate: Date
) => {
  const isSelected = (date: Date) => {
    return (
      dayjs(date).isSame(dayjs(selectedStart), 'day') ||
      dayjs(date).isSame(dayjs(selectedEnd), 'day')
    );
  };

  const isStart = (date: Date) => {
    return (
      dayjs(date).isSame(dayjs(selectedStart), 'day') &&
      !dayjs(date).isSame(dayjs(selectedEnd), 'day')
    );
  };

  const isEnd = (date: Date) => {
    return (
      dayjs(date).isSame(dayjs(selectedEnd), 'day') &&
      !dayjs(date).isSame(dayjs(selectedStart), 'day')
    );
  };

  const isBetween = (date: Date) => {
    return !!(
      dayjs(date).isBetween(dayjs(selectedStart), dayjs(selectedEnd)) &&
      !isEnd(date) &&
      !isStart(date)
    );
  };

  const isDisabled = (date: Date) => {
    return (
      dayjs(date).isBefore(dayjs(minDate)) ||
      dayjs(date).isAfter(dayjs(maxDate))
    );
  };

  const isHoverBetween = (date: Date) => {
    const [start, end] = hoverRange;
    return !!(start && end && dayjs(date).isBetween(start, end));
  };

  const isHoverStart = (date: Date) => {
    const start = hoverRange[0];
    return !!(start && dayjs(date).isSame(start, 'day'));
  };

  const isHoverEnd = (date: Date) => {
    const end = hoverRange[1];
    return !!(end && dayjs(date).isSame(end, 'day'));
  };

  return {
    'rkp-date-range__calendar-grid-item': true,
    'rkp-date-range__calendar-grid-item--between': isBetween(dateValue),
    'rkp-date-range__calendar-grid-item--hover-range':
      isHoverBetween(dateValue),
    'rkp-date-range__calendar-grid-item--hover-range--between':
      isHoverBetween(dateValue) && isBetween(dateValue),
    'rkp-date-range__calendar-grid-item--hoverbegin': isHoverStart(dateValue),
    'rkp-date-range__calendar-grid-item--hoverbegin--selected':
      isHoverStart(dateValue) && isSelected(dateValue),
    'rkp-date-range__calendar-grid-item--hoverbegin--outside':
      isHoverStart(dateValue) && !isMonth,
    'rkp-date-range__calendar-grid-item--hoverbegin--outside--selected':
      isHoverStart(dateValue) && !isMonth && isSelected(dateValue),
    'rkp-date-range__calendar-grid-item--hoverend': isHoverEnd(dateValue),
    'rkp-date-range__calendar-grid-item--hoverend--selected':
      isHoverEnd(dateValue) && isSelected(dateValue),
    'rkp-date-range__calendar-grid-item--hoverend--outside':
      isHoverEnd(dateValue) && !isMonth,
    'rkp-date-range__calendar-grid-item--hoverend--outside--selected':
      isHoverEnd(dateValue) && !isMonth && isSelected(dateValue),
    'rkp-date-range__calendar-grid-item--selected': isSelected(dateValue),
    'rkp-date-range__calendar-grid-item--selected--outside':
      isSelected(dateValue) && !isMonth,
    'rkp-date-range__calendar-grid-item--selected-start':
      isSelected(dateValue) && isStart(dateValue),
    'rkp-date-range__calendar-grid-item--selected-start--outside':
      isSelected(dateValue) && isStart(dateValue) && !isMonth,
    'rkp-date-range__calendar-grid-item--selected-start--hoverbegin':
      isSelected(dateValue) && isStart(dateValue) && isHoverStart(dateValue),
    'rkp-date-range__calendar-grid-item--selected-start--hoverbegin--outside':
      isSelected(dateValue) &&
      isStart(dateValue) &&
      isHoverStart(dateValue) &&
      !isMonth,
    'rkp-date-range__calendar-grid-item--selected-start--hoverend':
      isSelected(dateValue) && isStart(dateValue) && isHoverEnd(dateValue),
    'rkp-date-range__calendar-grid-item--selected-start--hoverend--outside':
      isSelected(dateValue) &&
      isStart(dateValue) &&
      isHoverEnd(dateValue) &&
      !isMonth,
    'rkp-date-range__calendar-grid-item--selected-end':
      isSelected(dateValue) && isEnd(dateValue),
    'rkp-date-range__calendar-grid-item--selected-end--outside':
      isSelected(dateValue) && isEnd(dateValue) && !isMonth,
    'rkp-date-range__calendar-grid-item--selected-end--hoverbegin':
      isSelected(dateValue) && isEnd(dateValue) && isHoverStart(dateValue),
    'rkp-date-range__calendar-grid-item--selected-end--hoverbegin--outside':
      isSelected(dateValue) &&
      isEnd(dateValue) &&
      isHoverStart(dateValue) &&
      !isMonth,
    'rkp-date-range__calendar-grid-item--selected-end--hoverend':
      isSelected(dateValue) && isEnd(dateValue) && isHoverEnd(dateValue),
    'rkp-date-range__calendar-grid-item--selected-end--hoverend--outside':
      isSelected(dateValue) &&
      isEnd(dateValue) &&
      isHoverEnd(dateValue) &&
      !isMonth,
    'rkp-date-range__calendar-grid-item--outside': !isMonth,
    'rkp-date-range__calendar-grid-item--disabled': isDisabled(dateValue),
    'rkp-date-range__calendar-grid-item--current-day': dayjs(dateValue).isSame(
      dayjs(new Date()),
      'day'
    ),
  };
};
