export enum UserCapability {
  SuperAdmin = 'Super Admin',
  CreateAccessPolicy = 'Create Access Policy',
  CreateCapability = 'Create Capability',
  CreateInternalAccessPolicy = 'Create Internal Access Policy',
  CreateOrganization = 'Create Organization',
  CreateService = 'Create Service',
  CreateInternalUser = 'Create Internal User',
  CreateUser = 'Create User',
  DeleteAccessPolicy = 'Delete Access Policy',
  DeleteCapability = 'Delete Capability',
  DeleteInternalAccessPolicy = 'Delete Internal Access Policy',
  DeleteOrganization = 'Delete Organization',
  DeleteService = 'Delete Service',
  DeleteInternalUser = 'Delete Internal User',
  DeleteUser = 'Delete User',
  EditAccessPolicy = 'Edit Access Policy',
  EditCapability = 'Edit Capability',
  EditInternalAccessPolicy = 'Edit Internal Access Policy',
  EditOrganization = 'Edit Organization',
  EditService = 'Edit Service',
  EditInternalUser = 'Edit Internal User',
  EditUser = 'Edit User',
  EndUserAllAdmin = 'End User All Admin',
  RingCentralAdmin = 'RC Admin',
  RingCentralCharterAdmin = 'Charter RC Admin',
  ViewAccessPolicies = 'View Access Policies',
  ViewCapabilities = 'View Capabilities',
  ViewInternalAccessPolicies = 'View Internal Access Policies',
  ViewOrganizations = 'View Organizations',
  ViewServices = 'View Services',
  ViewEventLogs = 'View Event Logs',
  ViewInternalUsers = 'View Internal Users',
  ViewUsers = 'View Users',
  InternalEdit = 'Internal Edit',
  InternalView = 'Internal View',
  ManageNetworkEdgeAdministrator = 'Manage Network Edge Administrator',
  ManageNetworkEdgeSiteManager = 'SSO Service - MNE SiteManager',
  ManageTickets = 'Manage Tickets',
  ManageVoiceFeatures = 'Manage Voice Features',
  StorageCreateFiles = 'Storage Create Files',
  StorageDeleteFiles = 'Storage Delete Files',
  StorageEditFiles = 'Storage Edit Files',
  StorageReadFiles = 'Storage Read Files',
  PayBill = 'Pay Bill',
  ViewBills = 'View Bills',
  ViewCallDetailRecords = 'View Call Detail Records',
  ViewLocationsAndDetails = 'View Locations and Details',
  OrganizationsAllView = 'Organizations All View',
  LoginAccess = 'Login Access',
  TechnicalSupportTickets = 'Technical Support Tickets',
  UserManagement = 'User Management',
  SiteAdmin = 'Site Admin',
  SiteUser = 'Site User',
}

export const userCapabilityValues = Object.values(UserCapability);
export const toUserCapabilityEnum = (val: string): UserCapability => {
  return UserCapability[val as keyof typeof UserCapability];
};
