import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { passwordAnalytics } from './utils/passwordAnalytics';

const PasswordForgot = lazy(
  () =>
    import(
      /* webpackChunkName: "password-forgot" */ './PasswordForgot/PasswordForgot'
    )
);

const PasswordResetLink = lazy(
  () =>
    import(
      /* webpackChunkName: "password-reset-link" */ './PasswordResetLink/PasswordResetLink'
    )
);

const PasswordResetLinkExpired = lazy(
  () =>
    import(
      /* webpackChunkName: "password-reset-link-expired" */ './PasswordResetLinkExpired/PasswordResetLinkExpired'
    )
);

const PasswordResetSessionExpired = lazy(
  () =>
    import(
      /* webpackChunkName: "password-reset-session-expired" */ './PasswordResetSessionExpired/PasswordResetSessionExpired'
    )
);

const PasswordReset = lazy(
  () =>
    import(
      /* webpackChunkName: "reset-password" */ './PasswordReset/PasswordReset'
    )
);

const {
  loginResetPwdLink,
  loginResetPwdLinkExpired,
  loginResetPwdUsername,
  loginResetPwd,
  loginResetPwdSessionExpired,
} = passwordAnalytics;

export const loginPasswordRoutes = (
  <Route path="login/*">
    <Route path="forgot/*">
      <Route
        path="username"
        element={
          <PageRoute isProtected={false} pageInfo={loginResetPwdUsername}>
            <PasswordForgot />
          </PageRoute>
        }
      />
      <Route
        path="reset/sent/link"
        element={
          <PageRoute isProtected={false} pageInfo={loginResetPwdLink}>
            <PasswordResetLink />
          </PageRoute>
        }
      />
      <Route
        path=":token"
        element={
          <PageRoute isProtected={false} pageInfo={loginResetPwd}>
            <PasswordReset />
          </PageRoute>
        }
      />
      <Route
        path=":token/expired"
        element={
          <PageRoute isProtected={false} pageInfo={loginResetPwdLinkExpired}>
            <PasswordResetLinkExpired />
          </PageRoute>
        }
      />
      <Route
        path=":token/session-expired"
        element={
          <PageRoute isProtected={false} pageInfo={loginResetPwdSessionExpired}>
            <PasswordResetSessionExpired />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </Route>
);
