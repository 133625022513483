/**
 * Analytics SDR for Cloud Security Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/Adobe+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Cloud Security';

export const cloudSecurityAnalytics: IAnalyticSdr = {
  cloudSecurityInventory: {
    siteSection,
    siteSubSection: 'Cloud Security Inventory',
    buttonClicks: {
      edgePortalLink: {
        itemClicked: 'Managed Network Edge Portal',
      },
    },
  },
};
