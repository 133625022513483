/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import isArray from 'lodash/isArray';
import { OrganizationFeature } from '../../types/organizationFeature';
import { OrgServiceType } from '../../types/orgServiceType';
import {
  IAimCapabilitiesForUser,
  IAimOrganization,
  IAimOrganizationFeature,
  IAimSelf,
  IOrganization,
  Maybe,
} from '../../types/types.generated';
import { UserCapability } from '../../types/userCapability';
import { IAimViewAsClientParams, IAuthUser } from '../types/authUser';

/**
 * Extract the info from the url sent over from AIM when the Internal User selects "View Org as Client"
 * Example: ?viewingAsPAG=${ORG_ID_GUID}&code=${PINXT_TRANSFER_CODE}&aimHostOverride=${AIM_HOST_URL}
 */
export const parseAimViewOrgAsClientQuery = (): IAimViewAsClientParams => {
  const urlParams = new URLSearchParams(window.location.search);
  const { code, viewingAsPAG, aimHostOverride } = Object.fromEntries(
    urlParams
  ) as unknown as IAimViewAsClientParams;

  return {
    code,
    viewingAsPAG,
    // eslint-disable-next-line no-extra-boolean-cast
    aimHostOverride: !!aimHostOverride
      ? new URL(aimHostOverride).origin // clean the url as sometimes query params get in here
      : undefined,
  };
};

export const hasCapability = (
  neededCapabilities: UserCapability | UserCapability[],
  currentCapabilities: UserCapability[]
): boolean => {
  if (!isArray(neededCapabilities)) {
    neededCapabilities = [neededCapabilities];
  }

  if (neededCapabilities.length === 0) {
    return true;
  }

  return neededCapabilities.some((cap) => currentCapabilities.includes(cap));
};

export const hasOrgFeature = (
  neededFeatures: OrganizationFeature | OrganizationFeature[],
  currentFeatures: IAimOrganizationFeature[]
): boolean => {
  if (!isArray(neededFeatures)) {
    neededFeatures = [neededFeatures];
  }

  if (neededFeatures.length === 0) {
    return true;
  }

  return neededFeatures.some((feature) =>
    currentFeatures.some((x) => x.name === feature && x.isEnabled)
  );
};

export const hasOrgService = (
  neededServices: OrgServiceType | OrgServiceType[],
  currentServices: OrgServiceType[]
): boolean => {
  if (!isArray(neededServices)) {
    neededServices = [neededServices];
  }

  if (neededServices.length === 0) {
    return true;
  }

  return neededServices.some((service) =>
    currentServices.some((x) => x === service)
  );
};

/**
 * Builds the auth user onces all the data sources are finished returning data about the user.
 */
export const buildAuthUser = (
  selfData: IAimSelf,
  capabilitiesData: IAimCapabilitiesForUser,
  orgData: Maybe<IAimOrganization>,
  orgServices: IOrganization,
  selectedOrgId: string
): IAuthUser => {
  const {
    id,
    firstName,
    lastName,
    phone,
    email,
    pid,
    isInternalUser,
    parentOrganization,
    organizations,
  } = selfData;

  const capabilities =
    capabilitiesData.capabilities
      ?.map((item) => item?.name || '')
      .filter((name) => !!name)
      .map((name) => name as UserCapability) || [];

  let organization: IAimOrganization =
    organizations?.find((org) => org?.id === selectedOrgId) ||
    parentOrganization!;
  if (isInternalUser && orgData) {
    organization = orgData;
  }

  return {
    id,
    firstName,
    lastName,
    phone,
    email,
    pid,
    isInternalUser,
    capabilities,
    organization,
    services: getServices(orgServices, organization, capabilities),
  };
};

/**
 * Get the services the user should have.
 */
export const getServices = (
  orgServices: IOrganization,
  organization: IAimOrganization,
  capabilities: UserCapability[]
) => {
  // Current Org services
  // const hasBills = !!orgServices.billing?.hasBills; // BE hasn't integrated with AIM, check capabilities on this side.
  const hasLocationsService = !!orgServices.locations?.hasLocations;
  const hasManageLocationNames = !!orgServices.settings?.hasManageLocationNames;
  const hasFiberService = !!orgServices.services?.fiber?.hasFiber;
  const hasEne = !!orgServices.services?.enterpriseNetworkEdge?.hasEne;
  const hasMne = !!orgServices.services?.managedNetworkEdge?.hasMne;
  const hasWavelengthService =
    !!orgServices.services?.wavelength?.hasWavelength;
  const hasUnifiedCommsService =
    !!orgServices.services?.unifiedComms?.hasUnifiedComms;
  const hasNetworkService = !!orgServices.services?.network?.hasNetwork;
  const hasBusinessInternet =
    !!orgServices.services?.businessInternet?.hasBusinessInternet;
  const hasBusinessVoice =
    !!orgServices.services?.businessVoice?.hasBusinessVoice;
  const hasVideoService = !!orgServices.services?.video?.hasVideo;
  const hasTvService = !!orgServices.services?.tv?.hasTv;
  const hasWireless = !!orgServices.services?.wireless?.hasWireless;
  const hasManagedSecurityService =
    !!orgServices.services?.managedSecurity?.hasManagedSecurity;
  const hasTickets = !!orgServices.services?.tickets?.hasTickets;
  const hasVoiceService = !!orgServices.services?.voice?.hasVoice;
  const hasReporting = !!orgServices.services?.reports?.hasReporting;
  const hasStorage = !!orgServices.services?.storage?.hasStorage;
  const hasMrs = !!orgServices.services?.managedRouterService?.hasMrs;
  const hasCloudSecurityService =
    !!orgServices.services?.cloudSecurity?.hasCloudSecurity;
  const hasSecureAccessService =
    !!orgServices.services?.secureAccess?.hasSecureAccess;
  const hasSecureInternetService =
    !!orgServices.services?.secureInternet?.hasSecureInternet;

  // Get all the services toggle on in Aim via feature switches.
  // The following are the only ones in use, others soon to follow.
  const hasLocationsFeature = hasOrgFeature(
    OrganizationFeature.Locations,
    organization.features || []
  );
  const hasNetworkFeature = hasOrgFeature(
    OrganizationFeature.Network,
    organization.features || []
  );
  const hasWavelengthFeature = hasOrgFeature(
    OrganizationFeature.Wavelength,
    organization.features || []
  );
  const hasMssFeature = hasOrgFeature(
    OrganizationFeature.MSS,
    organization.features || []
  );
  const hasFiberFeature = hasOrgFeature(
    OrganizationFeature.Fiber,
    organization.features || []
  );
  const hasVoiceFeature = hasOrgFeature(
    OrganizationFeature.VoiceTrunking,
    organization.features || []
  );
  const hasUCFeature = hasOrgFeature(
    OrganizationFeature.UnifiedComm,
    organization.features || []
  );

  // Capabilities
  const hasBills = capabilities.includes(UserCapability.ViewBills);

  // All Together
  // Video:  Only enforce the services level feature toggle
  const hasLocations = hasLocationsService && hasLocationsFeature;
  const hasNetwork = hasNetworkService && hasNetworkFeature;
  const hasFiber = hasFiberService && hasFiberFeature;
  const hasVideo = hasVideoService;
  const hasTv = hasTvService;
  const hasWavelength = hasWavelengthService && hasWavelengthFeature;
  const hasManagedSecurity = hasManagedSecurityService && hasMssFeature;
  const hasVoice = hasVoiceService && hasVoiceFeature;
  const hasUnifiedComms = hasUnifiedCommsService && hasUCFeature;
  const hasCloudSecurity = hasCloudSecurityService;
  const hasSecureAccess = hasSecureAccessService;
  const hasSecureInternet = hasSecureInternetService;

  return [
    hasBills ? OrgServiceType.Billing : null,
    hasBusinessInternet ? OrgServiceType.BusinessInternet : null,
    hasBusinessVoice ? OrgServiceType.BusinessVoice : null,
    hasEne ? OrgServiceType.EnterpriseNetworkEdge : null,
    hasFiber ? OrgServiceType.Fiber : null,
    hasLocations ? OrgServiceType.Locations : null,
    hasManagedSecurity ? OrgServiceType.ManagedSecurity : null,
    hasManageLocationNames ? OrgServiceType.ManageLocationNames : null,
    hasMne ? OrgServiceType.ManageNetworkEdge : null,
    hasNetwork ? OrgServiceType.Network : null,
    hasReporting ? OrgServiceType.Reporting : null,
    hasStorage ? OrgServiceType.Storage : null,
    hasTickets ? OrgServiceType.Tickets : null,
    hasUnifiedComms ? OrgServiceType.UnifiedComms : null,
    hasVideo ? OrgServiceType.Video : null,
    hasTv ? OrgServiceType.TV : null,
    hasVoice ? OrgServiceType.VoiceTrunking : null,
    hasWavelength ? OrgServiceType.Wavelength : null,
    hasWireless ? OrgServiceType.Wireless : null,
    hasMrs ? OrgServiceType.ManagedRouterService : null,
    hasCloudSecurity ? OrgServiceType.CloudSecurity : null,
    hasSecureAccess ? OrgServiceType.SecureAccess : null,
    hasSecureInternet ? OrgServiceType.SecureInternet : null,
  ].filter((x) => !!x) as OrgServiceType[];
};
