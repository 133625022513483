export enum OrganizationFeature {
  Network = 'Network',
  FeedbackForm = 'Feedback Form',
  EquipmentStatus = 'Equipment Status',
  Locations = 'Locations',
  Wavelength = 'Wavelength',
  MNE = 'MNE',
  MSS = 'MSS',
  MRS = 'MRS',
  ENE = 'ENE',
  Fiber = 'Fiber',
  BusinessInternet = 'BusinessInternet',
  BusinessVoice = 'BusinessVoice',
  Wireless = 'Wireless',
  VoiceTrunking = 'Voice Trunking',
  UnifiedComm = 'United Comm',
  Video = 'Video',
  TV = 'TV',
  Reports = 'Reports',
  Storage = 'Storage',
  Tickets = 'Tickets',
  Bills = 'Bills',
  SecureAccess = 'Secure Access',
  CloudSecurity = 'Cloud Security',
  // -------------------------------------------------------------------------------------------
  // IMPORTANT
  // Originally, the FE needed to send these org feature strings to the BE so that the BE
  // could correctly filter for and get the desired service data.  The BE was not integrated
  // with AIM to look up these org feature service keys itself.  However, as of mid 2023, with
  // the ULTIMA release, the BE implemented BE ENTDEL-4085, where the BE is now able to lookup
  // the AIM org features itself.  Thus, with this fix, the FE is no longer required
  // to pass these org feature flags over the BE and act as the middleman between AIM and the
  // BE GraphQL services. This is why, for example, there is no "Secure Internet" org feature that
  // the FE passes to the BE to get locations data with the March 2024 Secure Internet project.
  // -------------------------------------------------------------------------------------------
}
