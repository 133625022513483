/* eslint-disable react/jsx-no-constructed-context-values */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

// Packages
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { createClient, Client, SubscribePayload } from 'graphql-ws';
import { v4 as uuidv4 } from 'uuid';
import { faker } from '@faker-js/faker';

// Hooks
import { useAuthUser } from '../../../hooks/useAuthUser';
import { useAppConfig } from '../../../hooks/useAppConfig';
import { useAuthSession } from '../../../hooks/useAuthSession';
import { useFeatureToggles } from '../../../hooks/useFeatureToggles';
import { GetBusinessInternetEquipmentRestartStatusSubscriptionDocument } from '../../../hooks/useRequest.generated';
import { useBusinessInternetEquipmentRestartMockSubscriptionQueueing } from '../hooks/useBusinessInternetEquipmentRestartMockSubscriptionQueueingContext';

// Types
import { FeatureToggleName } from '../../../config/featureToggles/featureToggles.types';

export interface IBusinessInternetEquipmentRestartStatusState {
  isSuccessRestart: boolean; // true if restart was successful. false if restart failed.
  equipmentIdentifier: string | null; // The MAC Address of the device that was restarted.
}

export interface IBusinessInternetEquipmentRestartSubscriptionContext {
  isSubscribed: boolean;
  // ----------------------------------------------------------------------------------------------
  // TODO:  Q1 2024 Work
  // This is the unique client ID used when this instance connects to the BE GQL Subscription Server
  // When submitting restart requests to the BE mutation restartBusinessInternetEquipment, we need
  // to pass this same unique client ID to the BE, so that when restart statuses are posted
  // by the BE GQL Subscription Server, the server will know to which specific client to deliver
  // the message to. This will prevent it from delivering to just any client subscriber.  It will only
  // deliver the message to this specific client that will only be valid when the user's logged in session is
  // still valid.  So user A who is logged in with user login 'abc' from his laptop won't ever see
  // success or fail subscription messages of user B who logged in separately with the same user login
  // from his own laptop.  Once logged out, and logged back in, a new unique client ID will then be
  // generated and used. Thus, any restart subscriptions from previous logins will never get
  // delivered to newer logins.  So restart messages from 9 days ago, for example, won't 'suddenly'
  // show up when the user comes back, logs back into the Portal, and hits the BI Details page
  // 9 days later.
  // ----------------------------------------------------------------------------------------------
  uniqueSubscriberClientId: string;
  turnOnSubscriptionListener: () => void;
  turnOffSubscriptionListener: () => void;
  subscriptionMessageReceived: IBusinessInternetEquipmentRestartStatusState | null;
  clearOutSubscriptionMessageReceived: () => void;
}

const defaultContextValue: IBusinessInternetEquipmentRestartSubscriptionContext =
  {
    isSubscribed: false,
    uniqueSubscriberClientId: '',
    turnOnSubscriptionListener: () => {},
    turnOffSubscriptionListener: () => {},
    subscriptionMessageReceived: null,
    clearOutSubscriptionMessageReceived: () => {},
  };

export const BusinessInternetEquipmentRestartSubscriptionContext =
  createContext<IBusinessInternetEquipmentRestartSubscriptionContext>(
    defaultContextValue
  );

export const BusinessInternetEquipmentRestartSubscriptionContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // GraphQL Client variable to open web socket connections, send messages,
  // and receive messages to and from the subscription server.  The
  // GraphQL Client class it NOT itself a web socket, but is a wrapper
  // to the web browser web socket itself.
  // const gqlWebSocketClient = useRef<Client | null>(null);
  let gqlWebSocketClient: Client;

  // Hooks
  const { portalAccountGUID, authUser } = useAuthUser();
  const { authToken } = useAuthSession();

  // Hooks: Get the connection configs of the websocket connection to the
  // Business Internet equipment restart subscription server.
  const {
    isMock,
    isDemo,
    api: { wsBusinessInternetRestartUpdatesHost: webSocketEndpoint },
  } = useAppConfig();

  // Hooks: Feature Toggle to enable this subcription handler to be activated or not.
  const { isFeatureToggledOn } = useFeatureToggles();
  const isBusinessInternetRestartEquipmentEnabled = isFeatureToggledOn(
    FeatureToggleName.BusinessInternetEquipmentRestart
  );

  // TODO: Hooks: Mock Queueing until we figure out way to mock web sockets
  const {
    mockRestartEquipmentQueueChanged,
    mockRemoveItemFromRestartEquipmentQueue,
    mockResetRestartEquipmentQueue,
  } = useBusinessInternetEquipmentRestartMockSubscriptionQueueing();

  // State data used by this context
  const [turnOnListenerAndConnect, setTurnOnListenerAndConnect] =
    useState(false);
  const [uniqueSubscriberClientId, setUniqueSubscriberClientId] =
    useState<string>('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionMessageReceived, setSubscriptionMessageReceived] =
    useState<IBusinessInternetEquipmentRestartStatusState | null>(null);

  // When this client connects to the subscription server, it needs to
  // give it a unique client id.  This unique client ID is sent to the subscriber server
  // to allow the subscriber server to connect published messages to the GraphQL operation
  // that was requested by the client, and send back the message to the specific client.
  // This id is passed to the subscriber server to allow the server to manage 1 and only 1
  // web socket connection on its side for each unique client ID.  All future communication
  // between the client and the server is linked through this unique ID.
  //
  // IMPORTANT:
  // Since we are subscribing to the BE GraphQL subscription server, where we
  // pass it the BE GraphQL subscription query, and give it the:
  // 1. PAG
  // 2. PUG
  // 3. Access Token (JWT)
  //
  // We need to generate a unique UUID.  Else, if we re-use the same client ID,
  // and 30 different people with different PAG, PUG, and Access Tokens used,
  // the subscription server will post messages to all clients with that same
  // client ID, and thus Company A will see the messages for Company B.
  const uniqueSubscriberClientIdOnWebSocketConnect = uuidv4();
  useEffect(() => {
    setUniqueSubscriberClientId(uniqueSubscriberClientIdOnWebSocketConnect);
  }, []);

  const clearOutSubscriptionMessageReceived = useCallback(() => {
    setSubscriptionMessageReceived(null);
  }, []);

  const turnOnSubscriptionListener = useCallback(() => {
    if (isBusinessInternetRestartEquipmentEnabled) {
      setSubscriptionMessageReceived(null);
      setTurnOnListenerAndConnect(true);

      // TODO: Mock remove when we have found way to mock web socket
      mockResetRestartEquipmentQueue();
    }
  }, [isBusinessInternetRestartEquipmentEnabled]);

  const turnOffSubscriptionListener = useCallback(() => {
    if (isBusinessInternetRestartEquipmentEnabled) {
      // Each time this web socket is turned off, clear out
      // the state of any previous subscription messages.
      // Then close out and release the websocket connection.
      setSubscriptionMessageReceived(null);
      setTurnOnListenerAndConnect(false);
    }
  }, [isBusinessInternetRestartEquipmentEnabled]);

  useEffect(() => {
    // Only create a new websocket connection to the subscriber server
    // if we are directed to do so via the turnOnListenerAndConnect() method,
    // and then, only if the feature toggle is enabled, and only if there
    // is not yet an existing web socket object as determined by the isSubscribed
    // value. We don't want to make multiple web socket connections un-necessarily
    // since Google Chrome, for example, only allows a max of 6 socket connections.
    // So we need to be super stingy and careful with opening and closing connections.
    // We want to make 1 and only 1 open web socket connection to the subscriber
    // server. We will then listen on that single connection alone.
    // The browser will also automatically close the web socket connection
    // after 300 seconds (or 5 minutes). So when the web browser does close out
    // the web socket, we will re-open it up each time.
    if (!turnOnListenerAndConnect) {
      // Dispose of the GraphQL client instance, close the underlying
      // web socket, and clear up resources.
      (async () => gqlWebSocketClient?.dispose())();
      setIsSubscribed(false);
      setSubscriptionMessageReceived(null);
    }

    if (
      turnOnListenerAndConnect &&
      isBusinessInternetRestartEquipmentEnabled &&
      !isSubscribed &&
      webSocketEndpoint?.trim().length > 0
    ) {
      // ----------------------------------------------------------------------------
      // Create a brand-new GraphQL client.  The GraphQL Client interface
      // is a wrapper that then creates a web socket connection to the GraphQL
      // subscription server.
      // NOTE:  When the GraphQL client class fails to connect to the server,
      // it will not throw an exception.  Instead, the class will catch the exception
      // and then dispatch the 'error' event.  So a try/catch here is not used.
      // ----------------------------------------------------------------------------
      gqlWebSocketClient = createClient({
        // Refer to: https://the-guild.dev/graphql/ws/docs/interfaces/client.ClientOptions
        url: webSocketEndpoint,
        // Have the GraphQL client attempt 3 total connection tries, then stop trying if those fail.
        // Both the retryAttempts and shouldRetry options are needed.  Normally, the GraphQL
        // client will not attempt reconnects on fatal close events. However, this default
        // behavior can be altered by implementing shouldRetry.  We have shouldRetry always
        // return true so that the GraphQL client will keep retrying until the retryAttempts
        // have been exceeded. Once the connection is fully closed after the initial failed
        // attempt, plus the extra retryAttempts, the current instance of the client will not attempt
        // to reconnect.  Thus, a new instance of createClient is needed anew each time to ask it to
        // attempt again.
        retryAttempts: 2,
        shouldRetry: (errOrCloseEvent) => {
          return true;
        },
      });

      // ----------------------------------------------------------------------------
      // NOTE:  The GraphQL client will itself dispatch other events
      // that can be monitored if desired:
      // 1. gqlWebSocketClient.on('connecting', (event: any) => {}
      //    'connecting' occurs when this GraphQL client is attempting to connect to the
      //    BE GraphQL Server via a web socket and using a given endpoint URL
      //    It may not succeed if the given endpoint URL is wrong.  Or if the PINXT
      //    firewall is blocking the connection attempt.
      //
      // 2. gqlWebSocketClient.on('connected', (event: any) => {}
      //    'connected' occurs when this GraphQL client has successfully connected
      //     a web socket to the BE GraphQL Server itself over the network
      //    and with the given endpoint URL.  'connected' does not mean that
      //    this GraphQL client has successfully subscribed to the subscription service
      //    though.
      //
      // 3. gqlWebSocketClient.on('opened', (event: any) => {}
      //    'opened' occurs when this GraphQL client has sent the subscription service a
      //    'connection_init' message and the subscription service sends back a
      //    'connection_ack' message. Thus, once acknowledge, this GraphQL client considers
      //    that the subscription service is ready and listening, and is thus now
      //    'opened'.  Upon 'opened', this GraphQL client then submits back to the
      //    subscription service the payload (see below).
      // ----------------------------------------------------------------------------

      // Handle web socket being closed
      gqlWebSocketClient.on('closed', (event: any) => {
        // When the web socket connection is closed, the following is a list of closure codes
        // that are possible and their implied reasons:
        //
        /*
        var CloseCode;
        (function(CloseCode2) {
          CloseCode2[CloseCode2["InternalServerError"] = 4500] = "InternalServerError";
          CloseCode2[CloseCode2["InternalClientError"] = 4005] = "InternalClientError";
          CloseCode2[CloseCode2["BadRequest"] = 4400] = "BadRequest";
          CloseCode2[CloseCode2["BadResponse"] = 4004] = "BadResponse";
          CloseCode2[CloseCode2["Unauthorized"] = 4401] = "Unauthorized";
          CloseCode2[CloseCode2["Forbidden"] = 4403] = "Forbidden";
          CloseCode2[CloseCode2["SubprotocolNotAcceptable"] = 4406] = "SubprotocolNotAcceptable";
          CloseCode2[CloseCode2["ConnectionInitialisationTimeout"] = 4408] = "ConnectionInitialisationTimeout";
          CloseCode2[CloseCode2["ConnectionAcknowledgementTimeout"] = 4504] = "ConnectionAcknowledgementTimeout";
          CloseCode2[CloseCode2["SubscriberAlreadyExists"] = 4409] = "SubscriberAlreadyExists";
          CloseCode2[CloseCode2["TooManyInitialisationRequests"] = 4429] = "TooManyInitialisationRequests";
        })(CloseCode || (CloseCode = {}));
        */

        // When this GraphQL client has closed the connection due one of the issues
        // listed above, be sure to mark that this context has been turned off, and
        // that we are no longer subscribed.
        setTurnOnListenerAndConnect(false);
        setIsSubscribed(false);
      });

      // Have this GraphQL client listen for messages that the BE GraphQL subscription server sends
      // us after we have successfully connected to it and opened the connection to the subscription
      // service. Some messages will simply be connection acknowledgementa, or pong messages, in order
      // to keep the web socket connection open and alive.  So we only will process the specific
      // messages that give us back the desired GraphQL subscription query response object.
      gqlWebSocketClient.on('message', (event: any) => {
        if (event?.payload?.data?.getBusinessInternetEquipmentRestartStatus) {
          const newMessageReceived: IBusinessInternetEquipmentRestartStatusState =
            {
              isSuccessRestart:
                event?.payload?.data?.getBusinessInternetEquipmentRestartStatus
                  .restart,
              equipmentIdentifier:
                event?.payload?.data?.getBusinessInternetEquipmentRestartStatus
                  .equipmentIdentifier,
            };
          setSubscriptionMessageReceived(newMessageReceived);
        }
      });

      // Have this GraphQL client listen for and handle errors
      // (Such as initial connection failures or the failure to successfully
      // subscribe to the subscription service itself.)
      gqlWebSocketClient.on('error', (event: any) => {
        // Terminate the GraphQL client.
        // Terminating is not considered fatal and a connection retry back to the
        // BE GraphQL subscription server will occur again automatically
        // by this GraphQL client, up to the number of retryAttempts we specified above.
        gqlWebSocketClient.terminate();
      });

      // Have this GraphQL client actually subscribe to the BE GraphQL subscription
      // service after this GraphQL client has successfully:
      // 1. 'connected' to the BE GraphQL subscription server itself using the web socket and URL endpoint.
      // 2. 'opened' the connection to the BE GraphQL subscription service itself.
      //
      // Do this by using the GraphQL client's iterate() method. The iterate() method performs
      // both of these actions:
      //
      // 1. The actual subscribe action, where we give the BE subscription service
      //    the desired payload and are allowed to subscribe to it
      // 2. The subsequent listening on the subscription service for messages
      //    that it emits.   Each time a message is emitted, this GraphQL client
      //    will process the messsage, then dispatch the 'message' event, which
      //    are then listened to via this GraphQL client class' .on('message', ...) method.
      const subscribePayload: SubscribePayload = {
        operationName: 'getBusinessInternetEquipmentRestartStatusSubscription',
        query: GetBusinessInternetEquipmentRestartStatusSubscriptionDocument,
        variables: {
          portalAccountGUID,
          portalUserGUID: authUser?.id || '',
          accessToken: authToken,
          // -----------------------------------------------------------
          // The additional unique client Id will be added as
          // part of the payload given to the BE Subscription Server
          // when this client subscribes. This will then be used
          // by the BE subscription server to only deliver messages
          // back to this specific client and its clientId that this
          // client is meant to see, and not to deliver it to any
          // other client. So, even though 2 users can log into the
          // Portal with the same username and password, in 2 different
          // browsers, and thus are associated to the same PAG, both
          // users will not be delivered the same messages.  Messages
          // meant for user 1 in his browser instance will only be
          // delivered to him. And messages meant for user 2 in his
          // browser instance will only be delivered to him.
          // -----------------------------------------------------------
          clientId: uniqueSubscriberClientIdOnWebSocketConnect,
        },
      };
      gqlWebSocketClient.iterate(subscribePayload);

      // At this point, we set setIsSubscribed to true.
      // We have 'successfully' subscribed, unless an error occurs.
      // For exaample, the BE GraphQL subscription service may still error out,
      // such as when it does not like the given subscription payload,
      // such as us trying to invoke the wrong GraphQL query, or operation name,
      // or we giving it a payload that is missing an expected property.
      // When any such subscription errors occur, this will cause this
      // GraphQL client to detect the errors that the subscription service
      // sends back, whereby this client fires off the 'closed' event,
      // at which time, setIsSubscribed will be reset back to false.
      setIsSubscribed(true);
    }
  }, [turnOnListenerAndConnect, isSubscribed]);

  // ===============================================================================
  // BEGIN: Mock Subscription Server Responses until we find a way to mock websockets
  // TODO:  Remove this when we are able to replace w mock websockets.
  // ===============================================================================
  const mockSimulateReceivingBackendSubscriptionRestartMessages = (
    equipmentIdentifier: string
  ) => {
    // Set the new subscription response message on the hook to simulate the
    // BE GQL subscription server having given us a message to consume.
    const randomSuccessFail = Math.random() >= 0.5;
    setSubscriptionMessageReceived({
      isSuccessRestart: !randomSuccessFail,
      equipmentIdentifier,
    });

    // Remove the id from the restarting queue now that we've consumed it.
    mockRemoveItemFromRestartEquipmentQueue(equipmentIdentifier!);
  };

  useEffect(() => {
    // Only take action when a new restart equipment id was added to
    // the mock queue, and not when there are removals off the queue.
    if (
      (isMock || isDemo) &&
      mockRestartEquipmentQueueChanged.isChangeDueToAddition &&
      mockRestartEquipmentQueueChanged.restartEquipmentQueue.length > 0
    ) {
      // Generate random delay times to simulate different response times
      // that it takes for different devices to fully restart.
      let randomResponseDelayInMs = faker.number.int({
        min: 10,
        max: 20,
      });
      randomResponseDelayInMs *= 1000;
      setTimeout(() => {
        mockSimulateReceivingBackendSubscriptionRestartMessages(
          mockRestartEquipmentQueueChanged.equipmentIdentifierJustAdded!
        );
      }, randomResponseDelayInMs);
    }
  }, [isMock, isDemo, mockRestartEquipmentQueueChanged]);
  // ===============================================================================
  // END: Mock Subscription Server Responses until we find a way to mock websockets
  // ===============================================================================

  return (
    <BusinessInternetEquipmentRestartSubscriptionContext.Provider
      value={{
        isSubscribed,
        uniqueSubscriberClientId,
        turnOnSubscriptionListener,
        turnOffSubscriptionListener,
        subscriptionMessageReceived,
        clearOutSubscriptionMessageReceived,
      }}
    >
      {children}
    </BusinessInternetEquipmentRestartSubscriptionContext.Provider>
  );
};
