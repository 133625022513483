import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { loginAnalytics } from './utils/loginAnalytics';

const Login = lazy(() => import(/* webpackChunkName: "login" */ './Login'));

const ContactSupport = lazy(
  () => import(/* webpackChunkName: "contact-support" */ './ContactSupport')
);

const { login, contactSupport } = loginAnalytics;

export const loginRoutes = (
  <Route path="login/*">
    <Route
      index
      element={
        <PageRoute isProtected={false} pageInfo={login}>
          <Login />
        </PageRoute>
      }
    />
    <Route
      path="contact-support"
      element={
        <PageRoute isProtected={false} pageInfo={contactSupport}>
          <ContactSupport />
        </PageRoute>
      }
    />
  </Route>
);
