import {
  AccountType,
  AimQueryKeyOrgName,
  OrganizationFeature,
  UserCapability,
} from '../../types/types.generated';

export enum EnterpriseContactOption {
  TechBillingSupport = 'Technical and Billing Support',
  TechBillingSupportHawaii = 'Technical and Billing Support - Hawaii',
  ENOC = 'Enterprise Network Operations Center (ENOC)',
}

export interface IOrgUserSettingsOption {
  isInternalUser: boolean;
  capabilities: UserCapability[];
}

export interface IMockOrgPreset {
  id: string;
  name: string;
  accountType: AccountType;
  enterpriseContacts: EnterpriseContactOption[];
  queryKeys: AimQueryKeyOrgName[];
  features: OrganizationFeature[];
  userSettings: IOrgUserSettingsOption;
}
