import { useContext } from 'react';
import { AuthUserContext } from '../contexts/authUserContext';

/**
 * Interact with the Auth User Context
 */
export const useAuthUser = () => {
  const {
    authUser,
    isError,
    organizations,
    isInternalView,
    hasCapability,
    hasOrgFeature,
    hasOrgService,
    viewingAsOrg,
    portalAccountGUID,
    setUserOrgId,
  } = useContext(AuthUserContext);
  return {
    authUser,
    isError,
    organizations,
    isInternalView,
    hasCapability,
    hasOrgFeature,
    viewingAsOrg,
    hasOrgService,
    portalAccountGUID,
    setUserOrgId,
  };
};
