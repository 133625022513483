import { useCallback } from 'react';
import { IRoutePermissions } from '../types/common';
import { Maybe } from '../types/types.generated';
import { useAuthUser } from './useAuthUser';
import { useFeatureToggles } from './useFeatureToggles';

/**
 * Hook to help with route operations
 */
export const useRouteUtils = () => {
  const { authUser, hasCapability, hasOrgFeature, hasOrgService } =
    useAuthUser();

  const { isFeatureToggledOn } = useFeatureToggles();

  /**
   * Check if user has permissions to view route
   */
  const checkRoutePermissions = useCallback(
    (routePermissions: Maybe<IRoutePermissions> | undefined) => {
      if (!authUser) {
        return false;
      }

      if (!routePermissions) {
        return true;
      }

      const { orgFeatures, orgServices, userCapabilities, featureToggle } =
        routePermissions;

      if (orgFeatures && !hasOrgFeature(orgFeatures)) {
        return false;
      }

      if (orgServices && !hasOrgService(orgServices)) {
        return false;
      }

      if (userCapabilities && !hasCapability(userCapabilities)) {
        return false;
      }

      return !(featureToggle && !isFeatureToggledOn(featureToggle));
    },
    [authUser, hasOrgFeature, hasOrgService, hasCapability, isFeatureToggledOn]
  );

  return { checkRoutePermissions };
};
