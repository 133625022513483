import React, { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { wirelessRoutes } from '../../domains/Wireless/routes';
import { wavelengthRoutes } from '../../domains/Wavelength/routes';
import { voiceRoutes } from '../../domains/Voice/routes';
import { virtualSecurityRoutes } from '../../domains/VirtualSecurity/routes';
import { videoRoutes, tvRoutes } from '../../domains/Video/routes';
import { unifiedCommsRoutes } from '../../domains/UnifiedComms/routes';
import { ticketRoutes } from '../../domains/Tickets/routes';
import { supportRoutes } from '../../domains/Support/routes';
import { storageRoutes } from '../../domains/Storage/routes';
import { settingsRoutes } from '../../domains/Settings/routes';
import { reportsRoutes } from '../../domains/Reports/routes';
import { networkRoutes } from '../../domains/Network/routes';
import { managedSecurityRoutes } from '../../domains/ManagedSecurity/routes';
import { managedNetworkEdgeRoutes } from '../../domains/ManagedNetworkEdge/routes';
import { managedRouterServiceRoutes } from '../../domains/ManagedRouterService/routes';
import { loginRoutes } from '../../domains/Login/routes';
import { loginPasswordRoutes } from '../../domains/Password/routes';
import {
  loginRegistrationRoutes,
  selfRegistrationRoutes,
} from '../../domains/Registration/routes';
import { locationRoutes } from '../../domains/Locations/routes';
import { fiberRoutes } from '../../domains/Fiber/routes';
import { eneRoutes } from '../../domains/EnterpriseNetworkEdge/routes';
import { businessInternetRoutes } from '../../domains/BusinessInternet/routes';
import { businessVoiceRoutes } from '../../domains/BusinessVoice/routes';
import { billsRoutes } from '../../domains/Bills/routes';
import { pageStatesRoutes } from '../../domains/PageStates/routes';
import { cloudSecurityRoutes } from '../../domains/CloudSecurity/routes';
import { secureAccessRoutes } from '../../domains/SecureAccess/routes';
import { secureInternetRoutes } from '../../domains/SecureInternet/routes';
import { FeatureToggleName } from '../../config/featureToggles/featureToggles.types';
import { useFeatureToggles } from '../../hooks/useFeatureToggles';

const PageRoutes = () => {
  const { search } = useLocation();
  const { getMultiToggleSettings } = useFeatureToggles();
  const { AuthSelfRegistrationCircuitId: showSelfRegCircuitStep } =
    getMultiToggleSettings([FeatureToggleName.AuthSelfRegistrationCircuitId]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`locations${search}`} replace />}
        />
        {loginRoutes}
        {loginPasswordRoutes}
        {loginRegistrationRoutes}
        {selfRegistrationRoutes(showSelfRegCircuitStep)}
        {locationRoutes}
        {cloudSecurityRoutes}
        {networkRoutes}
        {wavelengthRoutes}
        {eneRoutes}
        {managedNetworkEdgeRoutes}
        {managedRouterServiceRoutes}
        {secureAccessRoutes}
        {secureInternetRoutes}
        {fiberRoutes}
        {businessInternetRoutes}
        {businessVoiceRoutes}
        {wirelessRoutes}
        {voiceRoutes}
        {unifiedCommsRoutes}
        {managedSecurityRoutes}
        {virtualSecurityRoutes}
        {videoRoutes}
        {tvRoutes}
        {reportsRoutes}
        {ticketRoutes}
        {storageRoutes}
        {billsRoutes}
        {supportRoutes}
        {settingsRoutes}
        {pageStatesRoutes}
        <Route path="*" element={<Navigate to="404" />} />
      </Routes>
    </Suspense>
  );
};

export default PageRoutes;
