import { AppEnv } from '../../appConfig/appConfig.types';
import { FeatureToggleName, IFeatureToggles } from '../featureToggles.types';
import { featureTogglesDefault } from './default';

/**
 * Specific hard-coded Mock overrides to ./default.ts that requires a code push to change.
 * Alternatively, if you wanted to change these at runtime you would put them in the corresponding TDCS config instead but
 * Mock doesn't have a remote TDCS env thus why we hard code them here in the code at: src/config/tdcs/configs/demo.json
 *
 * For more info, see https://chalk.charter.com/display/ERSPM/FE+Configs
 */
export const featureTogglesMock: IFeatureToggles = {
  environment: AppEnv.Mock,
  toggles: [
    ...featureTogglesDefault.toggles,
    {
      name: FeatureToggleName.ArchitectureDataDogRumIntegration,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.DesignKiteDropDown, // TODO: Is this supposed to be off in Demo??
      isEnabled: false,
    },
    {
      name: FeatureToggleName.TicketDetailsSurfaceThreeContacts,
      isEnabled: false,
    },
  ],
};
