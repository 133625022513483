import { ILoginResetPwdAnalyticsSdr } from '../types/passwordAnalytics.types';

/**
 * Analytics SDR for Login Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

const loginSection = 'Login';
const resetPassword = 'Reset Password';
export const passwordAnalytics: ILoginResetPwdAnalyticsSdr = {
  loginResetPwdUsername: {
    siteSection: loginSection,
    siteSubSection: resetPassword,
    siteSubSubSection: `${resetPassword} - Username`,
    buttonClicks: {
      continueBtn: {
        itemClicked: 'Continue',
      },
    },
  },

  loginResetPwdLink: {
    siteSection: loginSection,
    siteSubSection: resetPassword,
    siteSubSubSection: `${resetPassword} - Password Reset Link`,
    buttonClicks: {
      backToSignIn: {
        itemClicked: 'Back to Sign In',
      },
      resendEmailBtn: {
        itemClicked: 'Resend Email',
      },
      supportLnk: {
        itemClicked: 'Get Support',
      },
    },
  },

  loginResetPwdLinkExpired: {
    siteSection: loginSection,
    siteSubSection: resetPassword,
    siteSubSubSection: `${resetPassword} - Password Reset Link Expired`,
    buttonClicks: {
      resendEmailBtn: {
        itemClicked: 'Resend Email',
      },
    },
  },

  loginResetPwdSessionExpired: {
    siteSection: loginSection,
    siteSubSection: resetPassword,
    siteSubSubSection: `${resetPassword} - Password Reset Session Expired`,
    buttonClicks: {
      resetPassword: {
        itemClicked: resetPassword,
      },
    },
  },

  loginResetPwd: {
    siteSection: loginSection,
    siteSubSection: resetPassword,
    buttonClicks: {
      contactSupportLnk: {
        itemClicked: 'Contact Support',
      },
      sendPasswordResetLink: {
        itemClicked: 'Send Password Reset Link',
      },
      saveSignInBtn: {
        itemClicked: 'Save & Sign In',
      },
      cancelBtn: {
        itemClicked: 'Cancel',
      },
    },
  },
};
