import { IEnvConfig } from '../envConfig/envConfig.types';
import { FeatureToggleName } from '../featureToggles/featureToggles.types';
import { getLocalFeatureTogglesByEnv } from '../featureToggles/featureToggles.utils';
import { IAppConfig } from './appConfig.types';
import {
  getApiHost,
  getAppEnv,
  getAppPackageVersion,
  getAppVersionName,
  getAuthEndpoint,
  getGraphQlEndpoints,
  getPiEssoInfo,
  getQuantumEnvName,
  getRestEndpoint,
  getRumEnv,
  getRumKeys,
  getTargetBg,
  getTdcsDeviceType,
  isDemo,
  isLocalhost,
  isMock,
  isProd,
  isTrueProd,
} from './appConfig.utils';

/**
 * Create Application Config Object
 * @param envConfig the pipeline generated env config file
 */
export const createAppConfig = (envConfig: IEnvConfig): IAppConfig => {
  const appEnv = getAppEnv(envConfig);
  console.debug(`App running as '${appEnv}' env.`);

  const appShortName = 'senet';
  const piClientId = 'enterprise-portal';

  const appVersionName = getAppVersionName(envConfig);
  const appFullVersion = getAppPackageVersion(envConfig);
  const {
    piHost,
    authAnonymousHost,
    directHost,
    directLegacyHost,
    cxdoHost,
    directSSOHost,
    wsVoiceCallGuardUpdatesHost,
    wsBusinessInternetRestartUpdatesHost,
  } = getApiHost(appEnv);
  const auth = getAuthEndpoint(piHost, appEnv);
  const authAnonymous = `${authAnonymousHost}/graphql`;

  const useDirectHost =
    envConfig.use_direct_host === 'true' ||
    import.meta.env.VITE_APP_USE_DIRECT_HOST === 'true';

  const useAimDirectHost =
    envConfig.use_aim_direct_host === 'true' ||
    import.meta.env.VITE_APP_USE_AIM_DIRECT_HOST === 'true';

  const { graphql, anonymous } = getGraphQlEndpoints(
    useDirectHost ? directHost : piHost,
    useDirectHost
  );

  const restEndpoint = getRestEndpoint(
    useDirectHost ? directLegacyHost : piHost,
    useDirectHost
  );

  // DataDog FeatureToggle can only be changed by local toggles since appConfigContext is above the FeatureTogglesContext
  const localToggles = getLocalFeatureTogglesByEnv(appEnv);
  const togglesMap: Map<FeatureToggleName, boolean> = new Map(
    localToggles.toggles.map((toggle) => [toggle.name, toggle.isEnabled])
  );
  const isDataDogRumToggledOn =
    togglesMap.get(FeatureToggleName.ArchitectureDataDogRumIntegration) ??
    false;

  const isDevBranch =
    appVersionName.includes('feature-') ||
    appVersionName.includes('local') ||
    appVersionName.includes('bugfix-');

  /**
   * App config stores anything static that's not expected to change at runtime or per environment.
   * If you're wanting to change settings at runtime (or per environment), use TDCS Config service.
   *
   * WARNING: Do not store any secrets (such as private API keys)!
   * For more info, see https://chalk.charter.com/display/ERSPM/FE+Configs.
   */
  return {
    isProd: isProd(appEnv),
    isTrueProd: isTrueProd(),
    isMock: isMock(appEnv),
    isDemo: isDemo(appEnv),
    isLocal: isLocalhost(),

    app: {
      shortName: appShortName,
      displayName: 'Spectrum Enterprise',
      env: appEnv,
      version: appFullVersion,
      versionName: appVersionName,
    },

    api: {
      useDirectHost,
      useAimDirectHost,
      auth,
      authAnonymous,
      anonymous,
      graphql,
      restEndpoint,
      cxdo: cxdoHost,
      sso: directSSOHost,
      wsVoiceCallGuardUpdatesHost,
      wsBusinessInternetRestartUpdatesHost,
      video: `${piHost}/entservices/video/v1/elemental/{org}/{channel}`,
    },

    auth: {
      targetBg: getTargetBg(),
      loginUrl: '/login',
      piClientId,
      piEsso: getPiEssoInfo(cxdoHost, appEnv, piClientId),
      gqlServicesClientId: 'senet',
      autoLogout: {
        idleWarningMins: 25, // after 25 mins, show the Idle Prompt Warning modal in AutoLogout
        postIdleWarnMins: 5, // after another 5 mins of the above modal displayed and still no response, auto log them out
      },
    },

    dataDog: {
      enabled: isDataDogRumToggledOn && !isDevBranch,
      site: 'datadoghq.com',
      service: `${appShortName}-ui`.toLowerCase(),
      env: getRumEnv(appEnv),
      rum: getRumKeys(appEnv),
    },

    mapbox: {
      // Token setup to only work on spectrumenterprise.net domains
      prodToken:
        'pk.eyJ1IjoiY2hhcnRlci1lbnQiLCJhIjoiY2t1ZjBwMW1mMDdwbjJydDQzdjFmc21wMyJ9.8C6u0pY33E2NoP3_RJa6PQ',

      // Token setup to only work on enterprise.spectrumtoolbox.com and localhost
      devToken:
        'pk.eyJ1IjoiY2hhcnRlci1lbnQiLCJhIjoiY2t1ZXphZ3F4MXBlODJvcXBsOGNvcHo1bCJ9.YbNKvX7W3V0LP50SlPDdmw',
      streetStyle: 'mapbox://styles/charter-ent/ckla1v4ni1ert18m4v6x7wt59',
      heatmapStyle: 'mapbox://styles/charter-ent/ckla328111is418k76tqysqah',
    },

    amcharts: {
      license: 'CH207031187',
    },

    // Access Google API library
    google: {
      siteKey: '6LdBxjsUAAAAAKmr7DTZn8fp_6ZhQ0n_bKI5iXnf',
    },

    analytics: {
      // Quantum Installation Docs: https://prism.spectruminternal.com/documentation/helix/helixJS/installation
      quantum: {
        enabled: true,
        // https://prism.spectruminternal.com/documentation/helix/endpoints
        devEndpoint: '//collector.pi-charter.net/topics/sspp?bulk=true', // Staging Endpoint
        prodEndpoint: '//collector.pi.spectrum.net/topics/sspp?bulk=true',

        // See MetaData Tab under https://prism.spectruminternal.com/SpectrumEnterpriseNet@Web/quantum-resources/helix/requirements/decoder/2.40
        config: {
          domain: 'portals',
          appName: 'SpectrumEnterpriseNet',
          appVersion: getAppVersionName(envConfig),
          packageVersion: appFullVersion,
          appEnvName: getQuantumEnvName(appEnv),
          startSession: 'SpectrumEnterpriseNet_startSession_generic',
          requirementsVersion: '2.448', // ensure requirementsVersion up-to-date by checking https://prism.spectruminternal.com/SpectrumEnterpriseNet@Web/quantum-resources/helix/requirements/versions
        },
      },

      adobe: {
        enabled: true,
        devEndpoint:
          '//assets.adobedtm.com/d5e6a435fd0f/61d188005020/launch-c755e44844e3-development.min.js',
        prodEndpoint:
          '//assets.adobedtm.com/d5e6a435fd0f/61d188005020/launch-f898116ba08a.min.js',
      },
    },

    /**
     * Access our TDCS Config Server
     * see https://chalk.charter.com/display/SPECENT/FE+Configs#FEConfigs-TDCSConfigs
     */
    tdcs: {
      enabled: true,
      apiKey: 'EPRIZE',
      host: piHost,
      config: {
        appVersion: '1.0.0',
        clientType: 'EPRIZE-PORTAL',
        deviceId: '123456789',
        osVersion: '1.0.0',
        deviceType: getTdcsDeviceType(appShortName, appEnv),
      },
      // Allows for local config files for local development, see repo readme.
      localConfig: import.meta.env.VITE_APP_TDCS_CONFIG || '',
    },
  };
};
