import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { storageRoutePerm } from '../../components/Routes/routePermissions';
import { storageAnalytics } from './types/storageAnalytics';

const StorageInventory = lazy(
  () => import(/* webpackChunkName: "storage-inventory" */ './StorageInventory')
);

const { orgServices } = storageRoutePerm;
const { storage } = storageAnalytics;

export const storageRoutes = (
  <Route path="storage/*">
    <Route index element={<Navigate to="all" />} />
    <Route
      path=":tab"
      element={
        <PageRoute orgServices={orgServices} pageInfo={storage}>
          <StorageInventory />
        </PageRoute>
      }
    />
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>
);
