/**
 * Analytics SDR for Billing Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import {
  IBillAutoPayChangeMethodAnalytics,
  IBillAutoPayChangeMethodConfirmAnalytics,
  IBillAutoPayChangeMethodSuccessAnalytics,
  IBillAutoPayEnrollAnalytics,
  IBillAutoPayEnrollMethodConfirmAnalytics,
  IBillAutoPayEnrollSuccessAnalytics,
  IBillPayConfirmPaymentAnalytics,
  IBillPayMakePaymentAnalytics,
  IBillPayPaymentSuccessAnalytics,
  IBillingAccountCardEvents,
  IBillsAccountInactiveModalAnalytics,
  IBillsAddSavedPaymentAnalytics,
  IBillsAddSavedPaymentSuccessAnalytics,
  IBillsAnalyticsSdr,
  IBillsEditSavedPaymentAnalytics,
  IBillsEditSavedPaymentSuccessAnalytics,
  IBillsMultiAccAnalytics,
  IBillsMultiAccDetailsAnalytics,
  IBillsMultiAccEnrollAutoPaymentAlertAnalytics,
  IBillsSingleAccDetailsAnalytics,
  IBillsSingleAccEnrollAutoPaymentAlertAnalytics,
  IBillsMultiAccBillPayCreditLimitReachedAlertAnalytics,
  IBillsSingleAccBillPayCreditLimitReachedAlertAnalytics,
  IPaymentDeleteConfirmDialogEvents,
  ISavedPaymentMethodCardEvents,
  IUnenrollConfirmDialogEvents,
  IPaperlessBillingAnalytics,
} from '../types/billsAnalytics';

export const siteSection = 'Bills';
const multiAccsSection = 'Billing Accounts';
const singleAccSection = 'Billing Account Details';
export const billPaySection = 'Bill Pay';
const changeAutoPaySection = 'Change Auto Pay';
const enrollInAutoPaySection = 'Enroll in Auto Pay';
export const accountInactive = 'Account Inactive';

const savedPaymentsSubSection = 'Bills Saved Payments ';
export const enrollAutoPayRestrictionsSection =
  'Enroll in Auto Pay or Change Auto Pay Payment Method - Restrictions Alert';

export const billPayRestrictionsSection = 'Bill Pay - Restrictions Alert';

export const billPayCreditLimitReachedRestrictionsSection =
  'Bill Pay Credit Card Limit Reached Restrictions Alert';

const savedPaymentMethodCardEvents: ISavedPaymentMethodCardEvents = {
  editSavedPaymentBtn: {
    pageRegion: 'Saved Payment Method Card',
    itemClicked: 'Edit',
  },
  deleteSavedPaymentBtn: {
    pageRegion: 'Saved Payment Method Card',
    itemClicked: 'Delete',
  },
  unenrollSavedPaymentBtn: {
    pageRegion: 'Saved Payment Method Card',
    itemClicked: 'Unenroll from Auto Pay',
  },
};

const unenrollConfirmDialogEvents: IUnenrollConfirmDialogEvents = {
  cancelUnenrollModalBtn: {
    pageRegion: 'Saved Payment Unenroll from Auto Pay Confirm Dialog',
    itemClicked: 'Cancel',
  },
  confirmUnenrollModalBtn: {
    pageRegion: 'Saved Payment Unenroll from Auto Pay Confirm Dialog',
    itemClicked: 'Unenroll from Auto Pay',
  },
};

const paymentDeleteConfirmDialogEvents: IPaymentDeleteConfirmDialogEvents = {
  cancelPaymentDeleteModalBtn: {
    pageRegion: 'Saved Payment Delete Confirm Dialog',
    itemClicked: 'Cancel',
  },
  deletePaymentDeleteModalBtn: {
    pageRegion: 'Saved Payment Delete Confirm Dialog',
    itemClicked: 'Delete',
  },
};

const billAccountCard = 'Bill Account Card';
const billingAccountCardEvents: IBillingAccountCardEvents = {
  makePaymentBtn: {
    pageRegion: billAccountCard,
    itemClicked: 'Make a Payment',
  },
  changeAutoPayBtn: {
    pageRegion: billAccountCard,
    itemClicked: 'Change Auto Pay Payment Method',
  },
  enrollAutoPayBtn: {
    pageRegion: billAccountCard,
    itemClicked: 'Enroll in Auto Pay',
  },
  paperlessBillingOptOutBtn: {
    pageRegion: billAccountCard,
    itemClicked: 'Paperless Billing On',
  },
  paperlessBillingOptInBtn: {
    pageRegion: billAccountCard,
    itemClicked: 'Paperless Billing Opt In',
  },
};

const accountsTxt = 'Accounts';
const billsMultiAcc: IBillsMultiAccAnalytics = {
  siteSection,
  siteSubSection: multiAccsSection,
  buttonClicks: {
    accountsTabClick: {
      itemClicked: 'Accounts',
    },
    statementsTabClick: {
      itemClicked: 'Statements',
    },
    paymentHistoryTabClick: {
      itemClicked: 'Payment History',
    },
    downloadStmtsBtn: {
      pageRegion: 'Statements',
      itemClicked: 'Download',
    },
    makePaymentBtn: {
      pageRegion: accountsTxt,
      itemClicked: 'Make a Payment',
    },
    mngAccDetailsBtn: {
      pageRegion: accountsTxt,
      itemClicked: 'Manage Account Details',
    },
    paperlessBillingOptOutBtn: {
      pageRegion: accountsTxt,
      itemClicked: 'Paperless Billing On',
    },
    paperlessBillingOptInBtn: {
      pageRegion: accountsTxt,
      itemClicked: 'Paperless Billing Opt In',
    },
    statementsViewFilterBtn: {
      pageRegion: 'Statements',
      itemClicked: 'View & Filter',
    },
    statementsFilterViewResultsBtn: {
      pageRegion: 'Statements',
      itemClicked: 'View Results',
    },
    statementsFilterCancelBtn: {
      pageRegion: 'Statements',
      itemClicked: 'Cancel',
    },
    multiDownloadPaymentReceiptBtn: {
      pageRegion: 'Payment History',
      itemClicked: 'Download',
    },
    multiPaymentHistoryViewFilterBtn: {
      pageRegion: 'Payment History',
      itemClicked: 'View & Filter',
    },
    multiPaymentHistoryFilterViewResultsBtn: {
      pageRegion: 'Payment History',
      itemClicked: 'View Results',
    },
    multiPaymentHistoryFilterCancelBtn: {
      pageRegion: 'Payment History',
      itemClicked: 'Cancel',
    },
    multiPaymentHistoryDownloadReceiptErrorCancelBtn: {
      pageRegion: 'Payment History - Unable to Download',
      itemClicked: 'Cancel',
    },
    multiPaymentHistoryDownloadReceiptErrorTryAgainBtn: {
      pageRegion: 'Payment History - Unable to Download',
      itemClicked: 'Try Again',
    },
    multiPaymentHistoryDownloadReceiptErrorCloseBtn: {
      pageRegion: 'Payment History - Unable to Download',
      itemClicked: 'Close',
    },
  },
  menuSelections: {
    accountNumberMenu: {
      itemClicked: 'Select Account Number',
    },
    displayMenu: {
      pageRegion: 'Statements',
      itemClicked: 'Display',
    },
    multiPaymentHistoryPaymentMonthMenu: {
      pageRegion: 'Payment History',
      itemClicked: 'Payment Month',
    },
    multiPaymentHistoryPaymentCategoryMenu: {
      pageRegion: 'Payment History',
      itemClicked: 'Payment Category',
    },
  },
};

const billsSingleAccDetails: IBillsSingleAccDetailsAnalytics = {
  siteSection,
  siteSubSection: singleAccSection,
  buttonClicks: {
    downloadBtn: {
      pageRegion: 'Statements',
      itemClicked: 'Download',
    },
    statementsTabClick: {
      itemClicked: 'Statements',
    },
    paymentHistoryTabClick: {
      itemClicked: 'Payment History',
    },
    savedPaymentsTabClick: {
      itemClicked: 'Saved Payment Methods',
    },
    addPaymentBtn: {
      itemClicked: 'Add Payment Method',
    },
    statementsViewFilterBtn: {
      pageRegion: 'Statements',
      itemClicked: 'View & Filter',
    },
    statementsFilterViewResultsBtn: {
      pageRegion: 'Statements',
      itemClicked: 'View Results',
    },
    statementsFilterCancelBtn: {
      pageRegion: 'Statements',
      itemClicked: 'Cancel',
    },
    singleDownloadPaymentReceiptBtn: {
      pageRegion: 'Payment History',
      itemClicked: 'Download',
    },
    singlePaymentHistoryViewFilterBtn: {
      pageRegion: 'Payment History',
      itemClicked: 'View & Filter',
    },
    singlePaymentHistoryFilterViewResultsBtn: {
      pageRegion: 'Payment History',
      itemClicked: 'View Results',
    },
    singlePaymentHistoryFilterCancelBtn: {
      pageRegion: 'Payment History',
      itemClicked: 'Cancel',
    },
    singlePaymentHistoryDownloadReceiptErrorCancelBtn: {
      pageRegion: 'Payment History - Unable to Download',
      itemClicked: 'Cancel',
    },
    singlePaymentHistoryDownloadReceiptErrorTryAgainBtn: {
      pageRegion: 'Payment History - Unable to Download',
      itemClicked: 'Try Again',
    },
    singlePaymentHistoryDownloadReceiptErrorCloseBtn: {
      pageRegion: 'Payment History - Unable to Download',
      itemClicked: 'Cancel',
    },

    ...billingAccountCardEvents,
    ...savedPaymentMethodCardEvents,
    ...unenrollConfirmDialogEvents,
    ...paymentDeleteConfirmDialogEvents,
  },
  menuSelections: {
    displayMenu: {
      pageRegion: 'Statements',
      itemClicked: 'Display',
    },
    singlePaymentHistoryPaymentMonthMenu: {
      pageRegion: 'Payment History',
      itemClicked: 'Payment Month',
    },
    singlePaymentHistoryPaymentCategoryMenu: {
      pageRegion: 'Payment History',
      itemClicked: 'Payment Category',
    },
  },
};

const billsMultiAccDetails: IBillsMultiAccDetailsAnalytics = {
  siteSection,
  siteSubSection: multiAccsSection,
  siteSubSubSection: 'Billing Account Details',
  buttonClicks: {
    addPaymentBtn: {
      itemClicked: 'Add Payment Method',
    },
    backBtn: {
      itemClicked: 'Back',
    },
    ...billingAccountCardEvents,
    ...savedPaymentMethodCardEvents,
    ...unenrollConfirmDialogEvents,
    ...paymentDeleteConfirmDialogEvents,
  },
  menuSelections: {
    displayMenu: {
      itemClicked: 'Display',
    },
  },
};

const billPayMakePayment: IBillPayMakePaymentAnalytics = {
  siteSection,
  siteSubSection: billPaySection,
  siteSubSubSection: 'Make a Payment',
  buttonClicks: {
    cancelBtn: {
      itemClicked: 'Cancel',
    },
    continueBtn: {
      itemClicked: 'Continue',
    },
    backBtn: {
      itemClicked: 'Back',
    },
  },
  menuSelections: {
    paymentAmountRadio: {
      itemClicked: 'Payment Amount',
    },
    paymentTypeRadio: {
      pageRegion: 'Payment Method',
      itemClicked: 'Payment Type',
    },
    paymentTypeMethodRadio: {
      pageRegion: 'Payment Method',
      itemClicked: 'Payment Method',
    },
    saveForFutureCheckbox: {
      pageRegion: 'Payment Method',
      itemClicked: 'Save for Future',
    },
  },
};

const billPayConfirmPayment: IBillPayConfirmPaymentAnalytics = {
  siteSection,
  siteSubSection: billPaySection,
  siteSubSubSection: 'Confirm Payment',
  buttonClicks: {
    editBtn: {
      itemClicked: 'Edit',
    },
    cancelBtn: {
      itemClicked: 'Cancel',
    },
    submitBtn: {
      itemClicked: 'Submit',
    },
    paymentTermsLink: {
      itemClicked: 'Spectrum Enterprise Electronic Payment Terms',
    },
    backBtn: {
      itemClicked: 'Back',
    },
  },
};

const billPayPaymentSuccess: IBillPayPaymentSuccessAnalytics = {
  siteSection,
  siteSubSection: billPaySection,
  siteSubSubSection: 'Payment Successful',
  buttonClicks: {
    printBtn: {
      itemClicked: 'Print',
    },
    closeBtn: {
      itemClicked: 'Close',
    },
  },
};

const billAutoPayChangeMethod: IBillAutoPayChangeMethodAnalytics = {
  siteSection,
  siteSubSection: changeAutoPaySection,
  siteSubSubSection: 'Change Auto Pay Payment Method',
  buttonClicks: {
    continueBtn: {
      itemClicked: 'Continue',
    },
    cancelBtn: {
      itemClicked: 'Cancel',
    },
    backBtn: {
      itemClicked: 'Back',
    },
  },
  menuSelections: {
    paymentMethodRadio: {
      pageRegion: 'Auto Pay Details',
      itemClicked: 'Select Payment Method',
    },
  },
};

const billAutoPayChangeMethodConfirm: IBillAutoPayChangeMethodConfirmAnalytics =
  {
    siteSection,
    siteSubSection: changeAutoPaySection,
    siteSubSubSection: 'Confirm Change of Auto Pay Payment Method',
    buttonClicks: {
      editClick: {
        itemClicked: 'Edit',
      },
      submitBtn: {
        itemClicked: 'Submit',
      },
      cancelBtn: {
        itemClicked: 'Cancel',
      },
      backBtn: {
        itemClicked: 'Back',
      },
      paymentTermsLink: {
        itemClicked: 'Spectrum Enterprise Electronic Payment Terms',
      },
    },
  };

const billAutoPayChangeMethodSuccess: IBillAutoPayChangeMethodSuccessAnalytics =
  {
    siteSection,
    siteSubSection: changeAutoPaySection,
    siteSubSubSection: 'You Changed Your Auto Pay Payment Method',
    buttonClicks: {
      closeClick: {
        itemClicked: 'Close',
      },
    },
  };

const billAutoPayEnroll: IBillAutoPayEnrollAnalytics = {
  siteSection,
  siteSubSection: enrollInAutoPaySection,
  siteSubSubSection: 'Select Payment Method',
  buttonClicks: {
    continueBtn: {
      itemClicked: 'Continue',
    },
    cancelBtn: {
      itemClicked: 'Cancel',
    },
    backBtn: {
      itemClicked: 'Back',
    },
  },
  menuSelections: {
    paymentTypeMenu: {
      pageRegion: 'Auto Pay Details',
      itemClicked: 'Payment Type',
    },
  },
};

const billAutoPayEnrollMethodConfirm: IBillAutoPayEnrollMethodConfirmAnalytics =
  {
    siteSection,
    siteSubSection: enrollInAutoPaySection,
    siteSubSubSection: 'Confirm Auto Pay Payment Method',
    buttonClicks: {
      editClick: {
        itemClicked: 'Edit',
      },
      submitBtn: {
        itemClicked: 'Submit',
      },
      cancelBtn: {
        itemClicked: 'Cancel',
      },
      backBtn: {
        itemClicked: 'Back',
      },
      paymentTermsLink: {
        itemClicked: 'Spectrum Enterprise Electronic Payment Terms',
      },
    },
  };

const billAutoPayEnrollSuccess: IBillAutoPayEnrollSuccessAnalytics = {
  siteSection,
  siteSubSection: enrollInAutoPaySection,
  siteSubSubSection: 'You Enrolled in Auto Pay',
  buttonClicks: {
    closeClick: {
      itemClicked: 'Close',
    },
    supportLink: {
      itemClicked: 'Spectrum Enterprise Support',
    },
  },
};

const billsEditSavedPayment: IBillsEditSavedPaymentAnalytics = {
  siteSection,
  siteSubSection: savedPaymentsSubSection,
  siteSubSubSection: 'Edit Payment Method',
  buttonClicks: {
    cancelClick: {
      itemClicked: 'Cancel',
    },
    saveClick: {
      itemClicked: 'Save',
    },
    backClick: {
      itemClicked: 'Back',
    },
  },
};

const billsEditSavedPaymentSuccess: IBillsEditSavedPaymentSuccessAnalytics = {
  siteSection,
  siteSubSection: savedPaymentsSubSection,
  siteSubSubSection: 'Edit Payment Method Success',
  buttonClicks: {
    closeClick: {
      itemClicked: 'Close',
    },
  },
};

const billsAddSavedPayment: IBillsAddSavedPaymentAnalytics = {
  siteSection,
  siteSubSection: savedPaymentsSubSection,
  siteSubSubSection: 'Add Payment Method',
  buttonClicks: {
    cancelClick: {
      itemClicked: 'Cancel',
    },
    saveClick: {
      itemClicked: 'Save',
    },
    backClick: {
      itemClicked: 'Back',
    },
  },
  menuSelections: {
    paymentTypeMenu: {
      itemClicked: 'Payment Type',
    },
  },
};

const billsAddSavedPaymentSuccess: IBillsAddSavedPaymentSuccessAnalytics = {
  siteSection,
  siteSubSection: savedPaymentsSubSection,
  siteSubSubSection: 'Add Payment Method Success',
  buttonClicks: {
    closeClick: {
      itemClicked: 'Close',
    },
  },
};

const billsMultiAccEnrollAutoPaymentAlert: IBillsMultiAccEnrollAutoPaymentAlertAnalytics =
  {
    siteSection,
    siteSubSection: 'Billing Account Details',
    siteSubSubSection: enrollAutoPayRestrictionsSection,
    buttonClicks: {
      closeClick: {
        itemClicked: 'Close',
      },
    },
  };

const billsMultiAccBillPayCreditLimitReachedAlert: IBillsMultiAccBillPayCreditLimitReachedAlertAnalytics =
  {
    siteSection,
    siteSubSection: 'Bills Account Details',
    siteSubSubSection: billPayCreditLimitReachedRestrictionsSection,
    buttonClicks: {
      closeClick: {
        itemClicked: 'Close',
      },
    },
  };

const billsSingleAccBillPayCreditLimitReachedAlert: IBillsSingleAccBillPayCreditLimitReachedAlertAnalytics =
  {
    siteSection,
    siteSubSection: billPayCreditLimitReachedRestrictionsSection,
    buttonClicks: {
      closeClick: {
        itemClicked: 'Close',
      },
    },
  };

const billsSingleAccEnrollAutoPaymentAlert: IBillsSingleAccEnrollAutoPaymentAlertAnalytics =
  {
    siteSection,
    siteSubSection: enrollAutoPayRestrictionsSection,
    buttonClicks: {
      closeClick: {
        itemClicked: 'Close',
      },
    },
  };

const billsAccountInactiveModal: IBillsAccountInactiveModalAnalytics = {
  siteSection,
  siteSubSection: changeAutoPaySection,
  siteSubSubSection: accountInactive,
  buttonClicks: {
    closeClick: {
      itemClicked: 'Close',
    },
  },
};

const paperlessBillingAnalytics: IPaperlessBillingAnalytics = {
  siteSection: '',
  siteSubSection: '',
  siteSubSubSection: '',
  pages: {
    siteSection: {
      billingAccountDetails: singleAccSection,
    },
    siteSubSection: {
      optIn: 'Paperless Billing Opt In',
      optOut: 'Paperless Billing Opt Out',
    },
    siteSubSubSection: {
      success: 'Success',
      error: 'Error',
      successWithError:
        'Opt In Success! However, when trying to update Billing Email Notifications to "ON" we encountered an error.',
    },
  },
  buttonClicks: {
    back: {
      itemClicked: 'Back',
    },
    confirmAndOptIn: {
      itemClicked: 'Confirm & Opt In',
    },
    confirmAndOptOut: {
      itemClicked: 'Confirm & Opt Out',
    },
    cancel: {
      itemClicked: 'Cancel',
    },
    close: {
      itemClicked: 'Close',
    },
    tryAgain: {
      itemClicked: 'Try Again',
    },
    viewUserSettings: {
      itemClicked: 'View User Settings',
    },
    termsAndConditions: {
      itemClicked: 'terms and conditions',
    },
  },
};

export const billsAnalytics: IBillsAnalyticsSdr = {
  billsMultiAcc,
  billsSingleAccDetails,
  billsMultiAccDetails,
  billPayMakePayment,
  billPayConfirmPayment,
  billPayPaymentSuccess,

  billAutoPayChangeMethod,
  billAutoPayChangeMethodConfirm,
  billAutoPayChangeMethodSuccess,

  billAutoPayEnroll,
  billAutoPayEnrollMethodConfirm,
  billAutoPayEnrollSuccess,

  billsEditSavedPayment,
  billsEditSavedPaymentSuccess,
  billsAddSavedPayment,
  billsAddSavedPaymentSuccess,
  billsMultiAccEnrollAutoPaymentAlert,
  billsSingleAccEnrollAutoPaymentAlert,
  billsMultiAccBillPayCreditLimitReachedAlert,
  billsSingleAccBillPayCreditLimitReachedAlert,

  billsAccountInactiveModal,
  paperlessBillingAnalytics,
};
