import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { supportAnalytics } from './types/supportAnalytics';

const Support = lazy(
  () => import(/* webpackChunkName: "support" */ './Support')
);

const SupportForms = lazy(
  () => import(/* webpackChunkName: "support-forms" */ './SupportForms')
);
const { support } = supportAnalytics;

export const supportRoutes = (
  <>
    <Route
      path="support"
      element={
        <PageRoute pageInfo={support}>
          <Support />
        </PageRoute>
      }
    />
    <Route
      path="support/forms"
      element={
        <PageRoute>
          <SupportForms />
        </PageRoute>
      }
    />
  </>
);
