import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { registrationAnalytics } from './utils/registrationAnalytics';

const Registration = lazy(
  () =>
    import(/* webpackChunkName: "registration" */ './Registration/Registration')
);

const RegistrationError = lazy(
  () =>
    import(
      /* webpackChunkName: "registration-error" */ './RegistrationError/RegistrationError'
    )
);

const RegistrationPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "registration-password" */ './RegistrationPassword/RegistrationPassword'
    )
);

const SelfRegistrationBANandCode = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-ban-and-code" */ './SelfRegistrationBANandCode/SelfRegistrationBANandCode'
    )
);

const SelfRegistrationVerificationMethod = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-verification-method" */ './SelfRegistrationVerificationMethod/SelfRegistrationVerificationMethod'
    )
);

const SelfRegistrationVerificationMethodDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-verification-method-details" */ './SelfRegistrationVerificationMethodDetails/SelfRegistrationVerificationMethodDetails'
    )
);

// SELF REGISTRATION COMPONENTS
const SelfRegistrationUserInfo = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-user-info" */ './SelfRegistrationUserInfo/SelfRegistrationUserInfo'
    )
);

const SelfRegistrationAlreadyRegistered = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-already-registered" */ './SelfRegistrationAlreadyRegistered/SelfRegistrationAlreadyRegistered'
    )
);

const SelfRegistrationPendingActivation = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-pending-activation" */ './SelfRegistrationPendingActivation/SelfRegistrationPendingActivation'
    )
);

const SelfRegistrationPendingSuccess = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-pending-success" */ './SelfRegistrationPendingSuccess/SelfRegistrationPendingSuccess'
    )
);

const SelfRegistrationAccountSecurityInfo = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-account-security-info" */ './SelfRegistrationAccountSecurityInfo/SelfRegistrationAccountSecurityInfo'
    )
);

const SelfRegistrationSupport = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-support" */ './SelfRegistrationSupport/SelfRegistrationSupport'
    )
);

const SelfRegistrationStep2NoMatch = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-step-2-no-match" */ './SelfRegistrationStep2NoMatch/SelfRegistrationStep2NoMatch'
    )
);

const SelfRegistrationStep2UnableToComplete = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-step-2-unable-to-complete" */ './SelfRegistrationStep2UnableToComplete/SelfRegistrationStep2UnableToComplete'
    )
);

const SelfRegistrationSessionExpired = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-session-expired" */ './SelfRegistrationSessionExpired/SelfRegistrationSessionExpired'
    )
);

const SelfRegistrationLinkExpired = lazy(
  () =>
    import(
      /* webpackChunkName: "self-registration-link-expired" */ './SelfRegistrationLinkExpired/SelfRegistrationLinkExpired'
    )
);

const {
  registerError,
  registerLinkExpired,
  registerStep1,
  registerStep1AlertPending,
  registerStep1AlertRegistered,
  registerStep2,
  registerStep2AlertNoMatch,
  registerStep2UnableToComplete,
  registerSuccess,
  registerUserCreds,
  registerUserValidation,
  registerContactSupport,
  registerSessionExpired,
  registerAccountSecurityInfo,
  registerPendingSuccess,
} = registrationAnalytics;

export const loginRegistrationRoutes = (
  <Route path="login/*">
    <Route path="registration">
      <Route
        path=":token"
        element={
          <PageRoute isProtected={false} pageInfo={registerUserValidation}>
            <Registration />
          </PageRoute>
        }
      />
      <Route
        path="password/validation/:identityToken"
        element={
          <PageRoute isProtected={false} pageInfo={registerUserCreds}>
            <RegistrationPassword />
          </PageRoute>
        }
      />
    </Route>
    <Route path="registration/pending">
      <Route
        path=":token"
        element={
          <PageRoute isProtected={false} pageInfo={registerUserValidation}>
            <Registration />
          </PageRoute>
        }
      />
      <Route
        path="password/validation/:identityToken"
        element={
          <PageRoute isProtected={false} pageInfo={registerUserCreds}>
            <RegistrationPassword />
          </PageRoute>
        }
      />
    </Route>
  </Route>
);

/**
 * Self-Registration Flow
 * Chalk: https://chalk.charter.com/display/SPECENT/SE.NET+Registration+and+SE.NET+Self-Registration
 * Figma: https://www.figma.com/file/o1K5QTch6dDjIngcRyFzAZ/Sign-In-%2B-Registration?type=design&node-id=4126-40297&mode=design&t=elKHuWK8TRHzMiKy-0
 */
export const selfRegistrationRoutes = (showSelfRegCircuitStep: boolean) => {
  return (
    <Route path="registration/*">
      <Route
        index
        element={
          <PageRoute isProtected={false} pageInfo={registerStep1}>
            <SelfRegistrationUserInfo />
          </PageRoute>
        }
      />
      <Route
        path="already-registered"
        element={
          <PageRoute
            isProtected={false}
            pageInfo={registerStep1AlertRegistered}
          >
            <SelfRegistrationAlreadyRegistered />
          </PageRoute>
        }
      />
      <Route
        path="pending-activation/:userId"
        element={
          <PageRoute isProtected={false} pageInfo={registerStep1AlertPending}>
            <SelfRegistrationPendingActivation />
          </PageRoute>
        }
      />
      <Route
        path="pending-activation/:userId/success"
        element={
          <PageRoute isProtected={false} pageInfo={registerPendingSuccess}>
            <SelfRegistrationPendingSuccess />
          </PageRoute>
        }
      />
      <Route
        path="step-2/:token"
        element={
          <PageRoute isProtected={false} pageInfo={registerStep2}>
            {showSelfRegCircuitStep ? (
              <SelfRegistrationVerificationMethod />
            ) : (
              <SelfRegistrationBANandCode />
            )}
          </PageRoute>
        }
      />
      <Route
        path="step-2/:token/verification-methods"
        element={
          <PageRoute isProtected={false} pageInfo={registerStep2}>
            {showSelfRegCircuitStep ? (
              <SelfRegistrationVerificationMethodDetails />
            ) : (
              <SelfRegistrationAccountSecurityInfo />
            )}
          </PageRoute>
        }
      />
      <Route
        path="step-2/:token/no-match"
        element={
          <PageRoute isProtected={false} pageInfo={registerStep2AlertNoMatch}>
            <SelfRegistrationStep2NoMatch />
          </PageRoute>
        }
      />
      <Route
        path="step-2/:token/unable-to-complete"
        element={
          <PageRoute
            isProtected={false}
            pageInfo={registerStep2UnableToComplete}
          >
            <SelfRegistrationStep2UnableToComplete />
          </PageRoute>
        }
      />
      <Route
        path="step-2/:token/session-expired"
        element={
          <PageRoute isProtected={false} pageInfo={registerSessionExpired}>
            <SelfRegistrationSessionExpired />
          </PageRoute>
        }
      />
      <Route
        path="session-expired"
        element={
          <PageRoute isProtected={false} pageInfo={registerSessionExpired}>
            <SelfRegistrationSessionExpired />
          </PageRoute>
        }
      />
      <Route
        path="link-expired/:userId"
        element={
          <PageRoute isProtected={false} pageInfo={registerLinkExpired}>
            <SelfRegistrationLinkExpired />
          </PageRoute>
        }
      />
      <Route
        path="contact-support"
        element={
          <PageRoute isProtected={false} pageInfo={registerContactSupport}>
            <SelfRegistrationSupport />
          </PageRoute>
        }
      />
      <Route
        path="success/:userId"
        element={
          <PageRoute isProtected={false} pageInfo={registerSuccess}>
            <SelfRegistrationPendingSuccess />
          </PageRoute>
        }
      />
      <Route
        path="error"
        element={
          <PageRoute isProtected={false} pageInfo={registerError}>
            <RegistrationError />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  );
};
