/**
 * Analytics SDR for MNE Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Manage Network Edge';

export const mneAnalytics: IAnalyticSdr = {
  mneInventory: {
    siteSection,
    siteSubSection: 'Manage Network Edge Inventory',
    buttonClicks: {
      edgePortalLink: {
        itemClicked: 'Managed Network Edge Portal',
      },
      managerPortalLink: {
        itemClicked: 'Site and Conference Manager Portal',
      },
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },

      viewFilterBtn: {
        itemClicked: 'View & Filter',
      },
      cardViewBtn: {
        itemClicked: 'Card View',
      },
      listViewBtn: {
        itemClicked: 'List View',
      },
      filterViewResultsBtn: {
        itemClicked: 'View Results',
      },
      filterCancelBtn: {
        itemClicked: 'Cancel',
      },
      viewDetailsBtn: {
        itemClicked: 'View Details',
      },
    },
    menuSelections: {
      servicesMenu: {
        itemClicked: 'Services',
      },
    },
  },
  mneDetails: {
    siteSection,
    siteSubSection: 'Manage Network Edge Details',
    buttonClicks: {
      edgePortalLink: {
        itemClicked: 'Managed Network Edge Portal',
      },
      managerPortalLink: {
        itemClicked: 'Site and Conference Manager Portal',
      },
      backBtn: {
        itemClicked: 'Back',
      },
      circuitIDBtn: {
        itemClicked: 'MNE Circuit',
      },
    },
  },
};
