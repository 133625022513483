import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { KiteProgressIndicator } from '@kite/react-kite';
import { useAuthUser } from '../hooks/useAuthUser';
import { Maybe } from '../types/types.generated';
import {
  IAnalyticApiCallEventInfo,
  IAnalyticButtonEvent,
  IAnalyticButtonEventInfo,
  IAnalyticJsErrorEventInfo,
  IAnalyticJsLogEventInfo,
  IAnalyticMenuSelectionEvent,
  IAnalyticMenuSelectionEventInfo,
  IAnalyticPageInfo,
  IAnalyticPageViewEvent,
  IAnalyticsContext,
  IAnalyticUserInfo,
} from './types/analytics';
import { useAdobeAnalytics } from './hooks/useAdobeAnalytics';
import QuantumService from './services/quantumService';

// Analytics

const defaultValue: IAnalyticsContext = {
  pageView: () => {},
  buttonEvent: () => {},
  menuSelectionEvent: () => {},
  jsError: () => {},
  jsLog: () => {},
};

export const AnalyticsContext = createContext<IAnalyticsContext>(defaultValue);

export const AnalyticsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { authUser } = useAuthUser();
  const adobeAnalytics = useAdobeAnalytics();

  const userInfo: Maybe<IAnalyticUserInfo> = useMemo(() => {
    if (!authUser) {
      return null;
    }

    // User logged in
    return {
      organizationId: authUser.organization.id || '',
      organizationName: authUser.organization.name || '',
      isInternal: authUser.isInternalUser ?? false,
      isPID: !!authUser.pid,
      isPremier: !!authUser.organization.supportPin,
      userId: authUser.id || '',
    };
  }, [authUser]);

  /**
   * Push a page view event
   * @param siteSection Top level page name (ie: 'Voice Trunking')
   * @param siteSubSection Secondary level page name (ie: 'Voice Trunking Account Details') if any
   * @param siteSubSubSection  Tertiary level page name (ie: 'Voice Call Records') if any
   */
  const pageView = useCallback(
    (
      siteSection: string,
      siteSubSection?: string,
      siteSubSubSection?: string
    ): void => {
      const event: IAnalyticPageViewEvent = {
        pageInfo: {
          siteSection,
          siteSubSection,
          siteSubSubSection,
        },
        userInfo,
      };

      adobeAnalytics.pageView(event);
      QuantumService.trackPageView(event);
    },
    [userInfo, adobeAnalytics]
  );

  /**
   * Push a button event
   * @param eventInfo The button event info
   * @param pageInfo The page info the event occurred on.
   */
  const buttonEvent = useCallback(
    (
      eventInfo: IAnalyticButtonEventInfo,
      pageInfo: IAnalyticPageInfo
    ): void => {
      const event: IAnalyticButtonEvent = {
        eventInfo,
        pageInfo,
        userInfo,
      };

      adobeAnalytics.buttonEvent(event);
      QuantumService.buttonEvent(event);
    },
    [userInfo, adobeAnalytics]
  );

  /**
   * Push a menu selection event
   * @param eventInfo The menu selection event info
   * @param pageInfo The page info the event occurred on.
   */
  const menuSelectionEvent = useCallback(
    (
      eventInfo: IAnalyticMenuSelectionEventInfo,
      pageInfo: IAnalyticPageInfo
    ): void => {
      const event: IAnalyticMenuSelectionEvent = {
        eventInfo,
        pageInfo,
        userInfo,
      };

      adobeAnalytics.menuSelectionEvent(event);
      QuantumService.menuSelectionEvent(event);
    },
    [userInfo, adobeAnalytics]
  );

  /**
   * Track a Javascript error, for API errors track under API Call Events.
   * Should only be used to track "hard" errors that we won't to show up under the errors dashboard in Prism.
   * For "soft" errors and all other logging events use jsLog.
   * See flow: https://prism.spectruminternal.com/documentation/quantum/error-events
   */
  const jsError = useCallback((eventInfo: IAnalyticJsErrorEventInfo): void => {
    QuantumService.jsError(eventInfo);
  }, []);

  /**
   * Track custom logging and debugging.
   * Don't use to track "hard" errors (see jsError function for more info).
   * But you can use this for "soft" errors.
   */
  const jsLog = useCallback((eventInfo: IAnalyticJsLogEventInfo): void => {
    QuantumService.jsLog(eventInfo);
  }, []);

  /**
   * Track a API calls and responses (including failures), for Javascript errors track under Error Events.
   * See flow: https://prism.spectrumtoolbox.com/documentation/quantum/api-call-events
   */
  const apiCall = useCallback((eventInfo: IAnalyticApiCallEventInfo): void => {
    QuantumService.apiCall(eventInfo);
  }, []);

  useEffect(() => {
    if (adobeAnalytics.ready) {
      setIsLoading(false);
    }
  }, [adobeAnalytics.ready]);

  useEffect(() => {
    if (!userInfo) {
      return;
    }

    const hasUserData = QuantumService.hasUserData();
    if (userInfo && !hasUserData) {
      // Don't record login success until auth user is populated
      if (userInfo.isInternal) {
        QuantumService.loginStop({
          eventInfo: { loginType: 'ssoAuth' },
          userInfo,
        });
      } else {
        QuantumService.loginStop({
          eventInfo: { loginType: 'manualAuth' },
          userInfo,
        });
      }
    }
  }, [userInfo]);

  const contextValueObj = useMemo(
    () => ({
      pageView,
      buttonEvent,
      menuSelectionEvent,
      jsError,
      jsLog,
      apiCall,
    }),
    [pageView, buttonEvent, menuSelectionEvent, jsError, jsLog, apiCall]
  );

  return (
    <AnalyticsContext.Provider value={contextValueObj}>
      {!isLoading && children}
      {isLoading && (
        <div className="display-loader">
          <KiteProgressIndicator id="analytics-loader" />
        </div>
      )}
    </AnalyticsContext.Provider>
  );
};
