import { useContext } from 'react';
import { ReportsPollingContext } from '../contexts/reportsContext';

export const useReportListUpdates = () => {
  const {
    reportsNotificationMessage,
    isReportAvailable,
    startPolling,
    resetReportsNotifications,
    goToReportsIsClicked,
    setGoToReportsIsClicked,
    updateInitialReportsList,
    completedReportsToNotify,
  } = useContext(ReportsPollingContext);

  return {
    reportsNotificationMessage,
    isReportAvailable,
    startPolling,
    resetReportsNotifications,
    goToReportsIsClicked,
    setGoToReportsIsClicked,
    updateInitialReportsList,
    completedReportsToNotify,
  };
};
