import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { mneAnalytics } from './types/mneAnalytics';
import { mneRoutePerm } from '../../components/Routes/routePermissions';

const MneInventory = lazy(
  () => import(/* webpackChunkName: "mne-inventory" */ './MneInventory')
);

const MneDetails = lazy(
  () => import(/* webpackChunkName: "mne-details" */ './MneDetails')
);

const { orgServices } = mneRoutePerm;
const { mneInventory, mneDetails } = mneAnalytics;

export const managedNetworkEdgeRoutes = (
  <Route path="services/managed-network-edge/*">
    <Route
      path="inventory"
      element={
        <PageRoute orgServices={orgServices} pageInfo={mneInventory}>
          <MneInventory />
        </PageRoute>
      }
    />
    <Route
      path="details/:id/edge"
      element={
        <PageRoute orgServices={orgServices} pageInfo={mneDetails}>
          <MneDetails />
        </PageRoute>
      }
    />
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>
);
