/**
 * Analytics SDR for Reports Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

export const reportsAnalytics: IAnalyticSdr = {
  reports: {
    siteSection: 'Reports',
    buttonClicks: {
      downloadReportBtn: {
        itemClicked: 'Download Report',
      },
      viewAndFilterBtn: {
        itemClicked: 'View & Filter',
      },
      viewResultBtn: {
        itemClicked: 'View Results',
      },
    },
    menuSelections: {
      createdByMenu: {
        itemClicked: 'Created By',
      },
    },
  },
};
