import React, { lazy } from 'react';
import { Navigate, Route, Outlet } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { businessInternetAnalytics } from './types/businessInternetAnalytics';
import { businessInternetRoutePerm } from '../../components/Routes/routePermissions';
import { BusinessInternetEquipmentRestartSubscriptionContextProvider } from './contexts/businessInternetEquipmentRestartSubscriptionContext';
import { BusinessInternetEquipmentRestartMockQueueingContextProvider } from './contexts/businessInternetEquipmentRestartMockQueueingContext';

const BusinessInternetInventory = lazy(
  () =>
    import(
      /* webpackChunkName: "business-internet-inventory" */ './features/BusinessInternetInventory/BusinessInternetInventory'
    )
);

const BusinessInternetDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "business-internet-details" */ './features/BusinessInternetDetails/BusinessInternetDetails'
    )
);

const { orgServices } = businessInternetRoutePerm;
const { businessInternetInventory, businessInternetDetails } =
  businessInternetAnalytics;

const BusinessInternetContextLayout = () => {
  return (
    <BusinessInternetEquipmentRestartMockQueueingContextProvider>
      <BusinessInternetEquipmentRestartSubscriptionContextProvider>
        <Outlet />
      </BusinessInternetEquipmentRestartSubscriptionContextProvider>
    </BusinessInternetEquipmentRestartMockQueueingContextProvider>
  );
};

export const businessInternetRoutes = (
  <Route element={<BusinessInternetContextLayout />}>
    <Route path="services/business-internet/*">
      <Route
        path="inventory"
        element={
          <PageRoute
            orgServices={orgServices}
            pageInfo={businessInternetInventory}
          >
            <BusinessInternetInventory />
          </PageRoute>
        }
      />
      <Route
        path="details/:accountNumber/:divisionId/:locationId"
        element={
          <PageRoute
            orgServices={orgServices}
            pageInfo={businessInternetDetails}
          >
            <BusinessInternetDetails />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </Route>
);
