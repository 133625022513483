import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { KiteProgressIndicator } from '@kite/react-kite';
import { useQueryClient } from '@tanstack/react-query';
import { useAppConfig } from '../hooks/useAppConfig';
import {
  IAuthSessionContext,
  IAuthSessionSignOutProps,
} from './types/authSession';
import * as storage from './services/authSessionStorage';
import authContextDefault from './utils/authSessionDefault';
import { useUserIdle } from './hooks/useUserIdle';
import { useAccessToken } from './hooks/useAccessToken';
import { useAnonymousToken } from './hooks/useAnonymousToken';

export const AuthSessionContext =
  createContext<IAuthSessionContext>(authContextDefault);

/**
 * Handles the management of a Auth Session
 */
export const AuthSessionContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isMock } = useAppConfig();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    isLoading: isAccessTokenLoading,
    accessToken,
    idToken,
    idTokenObj,
    legacyToken,
    isAuthenticating,
    isAuthenticated,
    isAuthError,
    signOut: accessTokenSignOut,
    authenticateWithCode,
    authenticateViaCreds,
    authenticateViaEsso,
    refreshTokenIfExpired,
  } = useAccessToken();

  const { getAnonymousToken } = useAnonymousToken();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Show Full Page Loading until everything is done loading
    setIsLoading(isAccessTokenLoading);
  }, [isAccessTokenLoading]);

  /**  Prepares the Auth Session for transferring the user back to AIM. */
  const aimSessionTransfer = useCallback(() => {
    storage.clearSessionStorageAndAuthTokens(isMock);

    // clear cache from Query data
    queryClient.removeQueries();
  }, [isMock, queryClient]);

  /** Called anytime a user is interacting with the site  */
  const handleOnUserInteraction = useCallback(() => {
    // if the access token hasn't been updated in awhile and user is still active, refresh token.
    refreshTokenIfExpired().catch(() => {});
  }, [refreshTokenIfExpired]);

  /**
   * Monitors user inactivity
   */
  const { showIdlePrompt, resetIdlePrompt, isUserIdleLogout } = useUserIdle({
    isAuthenticated,
    onUserInteraction: handleOnUserInteraction,
  });

  /**
   * Sign the user out and clear auth session
   * @param showError whether or not to show the Error Message banner on logout
   */
  const signOut = useCallback(
    async (options?: IAuthSessionSignOutProps) => {
      setIsLoading(true); // Show a full screen loading indicator

      await accessTokenSignOut(options);
    },
    [accessTokenSignOut]
  );

  useEffect(() => {
    if (isUserIdleLogout) {
      // The user has been inactive for too long, its time to automatically sign them out.
      setTimeout(() => {
        // give child components a couple seconds to tie up any loose ends before sign out.
        signOut().catch(console.error);
      }, 2000);
    }
  }, [isUserIdleLogout, signOut]);

  return (
    <AuthSessionContext.Provider
      value={useMemo(
        () => ({
          isAuthenticating,
          isAuthenticated,
          isAuthError,
          authToken: accessToken,
          idToken,
          idTokenObj,
          legacyToken,
          isUserIdleLogout,
          showIdlePrompt,
          resetIdlePrompt,
          signOut,
          aimSessionTransfer,
          getAnonymousToken,
          authenticateViaCreds,
          authenticateWithCode,
          authenticateViaEsso,
        }),
        [
          isAuthenticating,
          isAuthenticated,
          isAuthError,
          accessToken,
          idToken,
          idTokenObj,
          legacyToken,
          isUserIdleLogout,
          showIdlePrompt,
          resetIdlePrompt,
          signOut,
          aimSessionTransfer,
          getAnonymousToken,
          authenticateViaCreds,
          authenticateWithCode,
          authenticateViaEsso,
        ]
      )}
    >
      {!isLoading && children}
      {isLoading && (
        <div className="display-loader">
          <KiteProgressIndicator id="auth-session-loader" />
        </div>
      )}
    </AuthSessionContext.Provider>
  );
};
