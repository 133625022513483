import { INavItem } from '../../types/common';
import {
  billsRoutePerm,
  networkRoutePerm,
  wavelengthRoutePerm,
  eneRoutePerm,
  mneRoutePerm,
  fiberRoutePerm,
  businessInternetRoutePerm,
  businessVoiceRoutePerm,
  wirelessRoutePerm,
  voiceRoutePerm,
  unifiedCommRoutePerm,
  manageSecurityRoutePerm,
  virtualSecurityRoutePerm,
  videoRoutePerm,
  reportsRoutePerm,
  ticketsRoutePerm,
  storageRoutePerm,
  locationRoutePerm,
  mrsRoutePerm,
  cloudSecurityRoutePerm,
  secureAccessRoutePerm,
  secureInternetRoutePerm,
  tvRoutePerm,
} from '../Routes/routePermissions';

export const navItems: INavItem[] = [
  {
    label: 'Locations',
    path: '/locations',
    icon: 'ki-map-point-f',
    permissions: locationRoutePerm,
    group: 'locations',
  },
  {
    label: 'Network',
    path: '/services/network/inventory',
    icon: 'ki-share-f',
    permissions: networkRoutePerm,
    group: 'product',
  },
  {
    label: 'Wavelength',
    path: '/services/wavelength/inventory',
    icon: 'se-network',
    permissions: wavelengthRoutePerm,
    group: 'product',
  },
  {
    label: 'Enterprise Network Edge',
    path: '/services/enterprise-network-edge/inventory',
    icon: 'ki-settings-f',
    permissions: eneRoutePerm,
    group: 'product',
  },
  {
    label: 'Managed Network Edge',
    path: '/services/managed-network-edge/inventory',
    icon: 'ki-settings',
    permissions: mneRoutePerm,
    group: 'product',
  },
  {
    label: 'Managed Router Service',
    path: '/services/managed-router-service/inventory',
    icon: 'ki-streaming-box-f',
    permissions: mrsRoutePerm,
    group: 'product',
  },
  {
    label: 'Fiber Internet',
    path: '/services/fiber/inventory',
    icon: 'ki-fiber-f',
    permissions: fiberRoutePerm,
    group: 'product',
  },
  {
    label: 'Secure Internet',
    path: '/services/secure-internet/inventory',
    icon: 'se-secure-internet',
    permissions: secureInternetRoutePerm,
    group: 'product',
  },
  {
    label: 'Business Internet',
    path: '/services/business-internet/inventory',
    icon: 'ki-modem-f',
    permissions: businessInternetRoutePerm,
    group: 'product',
  },
  {
    label: 'Business Voice',
    path: '/services/business-voice/inventory',
    icon: 'ki-phone-f',
    permissions: businessVoiceRoutePerm,
    group: 'product',
  },
  {
    label: 'Wireless Internet',
    path: '/services/wireless/inventory',
    icon: 'ki-wifi-f',
    permissions: wirelessRoutePerm,
    group: 'product',
  },
  {
    label: 'Voice Trunking',
    path: '/services/voice-trunking/inventory',
    icon: 'se-voice-trunking',
    permissions: voiceRoutePerm,
    group: 'product',
  },
  {
    label: 'Unified Communications',
    path: '/services/unified-communications/inventory',
    icon: 'ki-phone-office-f',
    permissions: unifiedCommRoutePerm,
    group: 'product',
  },
  {
    label: 'Cloud Security',
    path: '/services/cloud-security/inventory',
    icon: 'se-cloud-security',
    permissions: cloudSecurityRoutePerm,
    group: 'product',
  },
  {
    label: 'Managed Security',
    path: '/services/managed-security/inventory',
    icon: 'ki-shield-f',
    permissions: manageSecurityRoutePerm,
    group: 'product',
  },
  {
    label: 'Secure Access',
    path: '/services/secure-access/inventory',
    icon: 'se-secure-access',
    permissions: secureAccessRoutePerm,
    group: 'product',
  },
  {
    label: 'Virtual Security',
    path: '/services/virtual-security/sites',
    icon: 'ki-shield-f',
    permissions: virtualSecurityRoutePerm,
    group: 'product',
  },
  {
    label: 'Video',
    path: '/services/video/inventory',
    icon: 'ki-live-tv-f',
    permissions: videoRoutePerm,
    group: 'product',
  },
  {
    label: 'TV',
    path: '/services/tv/inventory',
    icon: 'ki-live-tv-f',
    permissions: tvRoutePerm,
    group: 'product',
  },
  {
    label: 'Reports',
    path: '/reports',
    icon: 'ki-signal-f',
    permissions: reportsRoutePerm,
    group: 'service',
    notifications: {
      hasNotifications: true,
    },
  },
  {
    label: 'Tickets',
    path: '/tickets',
    icon: 'ki-ticket-f',
    permissions: ticketsRoutePerm,
    group: 'service',
    notifications: {
      hasNotifications: true,
    },
  },
  {
    label: 'Bills',
    path: '/bills/listings',
    permissions: billsRoutePerm,
    icon: 'ki-payment-f',
    group: 'service',
  },
  {
    label: 'Storage',
    path: '/storage',
    icon: 'ki-folder-f',
    permissions: storageRoutePerm,
    group: 'service',
  },
  {
    label: 'Support',
    path: '/support',
    icon: 'ki-headphones',
    group: 'support',
  },
];
