import { useContext } from 'react';
import { FeatureTogglesContext } from '../contexts/featureTogglesContext';

/**
 * Hook to get feature toggles
 */
export const useFeatureToggles = () => {
  const { envToggles, isFeatureToggledOn, getMultiToggleSettings } = useContext(
    FeatureTogglesContext
  );
  return {
    envToggles,
    isFeatureToggledOn,
    getMultiToggleSettings,
  };
};
