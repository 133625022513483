import React, { useState } from 'react';
import { EntAlertView } from '@enterprise/EntAlertView';
import { FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { genericErrorMessage } from '../../utils/copyText';
import ModalWrapper from '../ModalWrapper';

const GlobalErrorModal = ({ error, resetErrorBoundary }: FallbackProps) => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    resetErrorBoundary();
    setShow(false);
  };

  return (
    <ModalWrapper
      canShow={show}
      onHide={handleClose}
      ctaCopy="Close"
      ctaAction={handleClose}
    >
      <EntAlertView type="error" title={genericErrorMessage} />
    </ModalWrapper>
  );
};

export default GlobalErrorModal;
