import { useContext } from 'react';
import { BusinessInternetEquipmentRestartMockQueueingContext } from '../contexts/businessInternetEquipmentRestartMockQueueingContext';

export const useBusinessInternetEquipmentRestartMockSubscriptionQueueing =
  () => {
    const {
      mockRestartEquipmentQueueChanged,
      mockAddItemToRestartEquipmentQueue,
      mockRemoveItemFromRestartEquipmentQueue,
      mockResetRestartEquipmentQueue,
    } = useContext(BusinessInternetEquipmentRestartMockQueueingContext);

    return {
      mockRestartEquipmentQueueChanged,
      mockAddItemToRestartEquipmentQueue,
      mockRemoveItemFromRestartEquipmentQueue,
      mockResetRestartEquipmentQueue,
    };
  };
