import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { ticketsRoutePerm } from '../../components/Routes/routePermissions';
import { ticketAnalytics } from './types/ticketsAnalytics';

const Tickets = lazy(
  () => import(/* webpackChunkName: "tickets" */ './Tickets')
);

const TicketDetails = lazy(
  () => import(/* webpackChunkName: "ticket-details" */ './TicketDetails')
);

const CreateTicket = lazy(
  () => import(/* webpackChunkName: "create-ticket" */ './CreateTicket')
);

const { orgServices } = ticketsRoutePerm;

const { ticketInventory, ticketDetails, createTicket } = ticketAnalytics;

export const ticketRoutes = (
  <Route path="tickets/*">
    <Route
      index
      element={
        <PageRoute orgServices={orgServices} pageInfo={ticketInventory}>
          <Tickets />
        </PageRoute>
      }
    />
    <Route
      path="create"
      element={
        <PageRoute
          orgServices={orgServices}
          pageInfo={{
            title: createTicket.siteSubSection,
            ...createTicket,
          }}
        >
          <CreateTicket />
        </PageRoute>
      }
    />
    <Route
      path=":ticketId"
      element={
        <PageRoute orgServices={orgServices} pageInfo={ticketDetails}>
          <TicketDetails />
        </PageRoute>
      }
    />
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>
);
