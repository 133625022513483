import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { unifiedCommRoutePerm } from '../../components/Routes/routePermissions';
import { UserCapability } from '../../types/userCapability';
import { unifiedCommsAnalytics } from './types/unifiedCommsAnalytics';

const UnifiedCommsInventory = lazy(
  () =>
    import(
      /* webpackChunkName: "unified-comms-inventory" */ './UnifiedCommsInventory'
    )
);

const UcCallDetailRecords = lazy(
  () =>
    import(
      /* webpackChunkName: "uc-call-detail-records" */ './UcCallDetailRecords'
    )
);

const UcCreateCdrReport = lazy(
  () =>
    import(/* webpackChunkName: "uc-create-cdr-report" */ './UcCreateCdrReport')
);

const ReportConfirm = lazy(
  () =>
    import(
      /* webpackChunkName: "uc-report-confirm" */ '../../components/ReportConfirm'
    )
);

const { orgServices } = unifiedCommRoutePerm;
const { ucInventory, ucCallRecords, ucCreateReport, ucReportSuccess } =
  unifiedCommsAnalytics;

export const unifiedCommsRoutes = (
  <>
    <Route path="services/unified-communications/*">
      <Route
        path="inventory"
        element={
          <PageRoute orgServices={orgServices} pageInfo={ucInventory}>
            <UnifiedCommsInventory />
          </PageRoute>
        }
      />
      <Route path="details/:accountNumber/records">
        <Route
          index
          element={
            <PageRoute orgServices={orgServices} pageInfo={ucCallRecords}>
              <UcCallDetailRecords />
            </PageRoute>
          }
        />
        <Route
          path=":serviceType"
          element={
            <PageRoute orgServices={orgServices} pageInfo={ucCallRecords}>
              <UcCallDetailRecords />
            </PageRoute>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="*" element={<Navigate to="/404" />} />
    </Route>

    <Route path="reports/build/unified-communications/:accountNumber/*">
      <Route
        path="call-detail-records"
        element={
          <PageRoute
            userCapabilities={UserCapability.ViewCallDetailRecords}
            orgServices={orgServices}
            pageInfo={ucCreateReport}
          >
            <UcCreateCdrReport />
          </PageRoute>
        }
      />
      <Route
        path="success"
        element={
          <PageRoute orgServices={orgServices} pageInfo={ucReportSuccess}>
            <ReportConfirm />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </>
);
