/* eslint-disable no-empty-function */
/* eslint-disable react/jsx-no-useless-fragment */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import React from 'react';
import { KiteIcon, KiteLink } from '@kite/react-kite';
import { IGetSsoUrlInput } from '../types/types.generated';
import { useGetSsoUrlQuery } from './useRequest.generated';
import { useAuthUser } from './useAuthUser';
import { useAnalytics } from './useAnalytics';
import { IAnalyticPageInfo } from '../contexts/types/analytics';
import { SsoFallBackUrl, SSOLink } from '../types/sso';

const useSsoUrl = (
  ssoLink: SSOLink,
  ssoHost: string,
  pageInfo: IAnalyticPageInfo
) => {
  const { portalAccountGUID } = useAuthUser();
  const { trackButtonEvent } = useAnalytics();
  const trackAnalytics = () => {
    trackButtonEvent(
      {
        itemClicked: ssoLink.name,
      },
      pageInfo
    );
  };

  // Note: For Cloud Security SSO we will always want a fallback url even if user doesn't have the capabilities
  // when implementing for other sso links this might need to change back to null and add a boolean property
  let ssoUrl: SsoFallBackUrl | string = ssoLink.fallBackUrl;
  const ssoUrlInput: IGetSsoUrlInput = {
    organizationId: portalAccountGUID,
    serviceId: ssoLink.id,
    fallbackUrl: ssoLink.fallBackUrl,
  };
  const {
    isSuccess: isSuccessSsoUrl,
    data: dataSsoUrl,
    isError: isErrorSsoUrl,
    isLoading: isLoadingSsoUrl,
  } = useGetSsoUrlQuery(
    { input: ssoUrlInput },
    {
      // adding 'retry: 0' because a valid scenario where the user or org doesn't have SSO returns error.
      retry: 0,
      onError: (error) => {},
      onSuccess: (res) => {},
    }
  );

  if (
    ssoHost &&
    dataSsoUrl?.getSsoUrl?.url &&
    dataSsoUrl?.getSsoUrl.message !== 'Query Key not validated'
  ) {
    ssoUrl = ssoHost.concat(dataSsoUrl.getSsoUrl.url);
  }

  let ssoLinkComponent = <></>;

  if (ssoUrl) {
    ssoLinkComponent = (
      <dl>
        <dt className="sso-url__header-label">{ssoLink.label}</dt>
        <dd>
          <KiteLink
            href={ssoUrl}
            aria-label={ssoLink.name}
            id={ssoLink.id}
            onClick={trackAnalytics}
            target="_blank"
          >
            {ssoLink.name}
            <KiteIcon icon="ki-linkout" />
          </KiteLink>
        </dd>
      </dl>
    );
  }

  return { hasSsoLink: !!ssoUrl, ssoLinkComponent, ssoUrl };
};

export default useSsoUrl;
