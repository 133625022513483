import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { networkRoutePerm } from '../../components/Routes/routePermissions';
import { networkAnalytics } from './types/networkAnalytics';

const NetworkInventory = lazy(
  () => import(/* webpackChunkName: "network-inventory" */ './NetworkInventory')
);

const NetworkDetails = lazy(
  () => import(/* webpackChunkName: "network-details" */ './NetworkDetails')
);

const NetworkCreateReport = lazy(
  () =>
    import(
      /* webpackChunkName: "network-create-report" */ './NetworkCreateReport'
    )
);

const NetworkEquipmentCreateReport = lazy(
  () =>
    import(
      /* webpackChunkName: "network-equipment-create-report" */ './NetworkEquipmentCreateReport'
    )
);

const NetworkCloudConnect = lazy(
  () =>
    import(
      /* webpackChunkName: "network-cloud-connect" */ './NetworkCloudConnect'
    )
);

const NetworkCreateCloudReport = lazy(
  () =>
    import(
      /* webpackChunkName: "network-create-cloud-report" */ './NetworkCreateCloudReport'
    )
);

const ReportConfirm = lazy(
  () =>
    import(
      /* webpackChunkName: "network-report-confirm" */ '../../components/ReportConfirm/ReportConfirm'
    )
);

const { orgServices, orgFeatures } = networkRoutePerm;
const {
  networkInventory,
  networkDetails,
  networkCreateReport,
  networkCreateReportSuccess,
  cloudDetails,
  cloudCreateReport,
  cloudCreateReportSuccess,
  networkEquipmentCreateReport,
  networkEquipmentCreateReportSuccess,
} = networkAnalytics;

export const networkRoutes = (
  <>
    <Route path="services/network/*">
      <Route
        path="inventory"
        element={
          <PageRoute
            orgServices={orgServices}
            orgFeatures={orgFeatures}
            pageInfo={networkInventory}
          >
            <NetworkInventory />
          </PageRoute>
        }
      />

      <Route path="details/:networkId/*">
        <Route
          path="location/:circuitId"
          element={
            <PageRoute
              orgServices={orgServices}
              orgFeatures={orgFeatures}
              pageInfo={networkDetails}
            >
              <NetworkDetails />
            </PageRoute>
          }
        />
        <Route
          path="cloud-connect/:gatewayId/:circuitId"
          element={
            <PageRoute
              orgServices={orgServices}
              orgFeatures={orgFeatures}
              pageInfo={cloudDetails}
            >
              <NetworkCloudConnect />
            </PageRoute>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
    <Route path="reports/build/*">
      <Route path="network/:networkId/:circuitId/*">
        <Route
          index
          element={
            <PageRoute
              orgServices={orgServices}
              orgFeatures={orgFeatures}
              pageInfo={networkCreateReport}
            >
              <NetworkCreateReport />
            </PageRoute>
          }
        />
        <Route
          path="success"
          element={
            <PageRoute
              orgServices={orgServices}
              orgFeatures={orgFeatures}
              pageInfo={networkCreateReportSuccess}
            >
              <ReportConfirm />
            </PageRoute>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="equipment/network/:networkId/:circuitId/*">
        <Route
          index
          element={
            <PageRoute
              orgServices={orgServices}
              orgFeatures={orgFeatures}
              pageInfo={networkEquipmentCreateReport}
            >
              <NetworkEquipmentCreateReport />
            </PageRoute>
          }
        />
        <Route
          path="success"
          element={
            <PageRoute
              orgServices={orgServices}
              orgFeatures={orgFeatures}
              pageInfo={networkEquipmentCreateReportSuccess}
            >
              <ReportConfirm analytics={networkEquipmentCreateReportSuccess} />
            </PageRoute>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="cloud/:networkId/:gatewayId/:circuitId/*">
        <Route
          index
          element={
            <PageRoute
              orgServices={orgServices}
              orgFeatures={orgFeatures}
              pageInfo={cloudCreateReport}
            >
              <NetworkCreateCloudReport />
            </PageRoute>
          }
        />
        <Route
          path="success"
          element={
            <PageRoute
              orgServices={orgServices}
              orgFeatures={orgFeatures}
              pageInfo={cloudCreateReportSuccess}
            >
              <ReportConfirm />
            </PageRoute>
          }
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </>
);
