/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-constructed-context-values */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import React, { createContext, useEffect, useState } from 'react';
import { ITnsStatus } from '../types/types.generated';
import { useAuthUser } from '../hooks/useAuthUser';
import { useAppConfig } from '../hooks/useAppConfig';
import { useAuthSession } from '../hooks/useAuthSession';
import { OnVoiceTnsNotificationDocument } from '../hooks/useRequest.generated';

export interface ISubscriptionContext {
  tnsStatus: ITnsStatus;
  toastClosed: () => void;
  isUpdated: boolean;
  footerToast: JSX.Element;
  isError: boolean;
  isUpdateInProgress: boolean;
  resetError: () => void;
  isUpdateInitiated: () => void;
}

// set the defaults
const defaultValue: ISubscriptionContext = {
  tnsStatus: {},
  toastClosed: () => {},
  isUpdated: false,
  footerToast: <></>,
  isError: false,
  isUpdateInProgress: false,
  resetError: () => {},
  isUpdateInitiated: () => {},
};

export const SubscriptionContext =
  createContext<ISubscriptionContext>(defaultValue);

/**
 * Make Subscription state available to all components.
 */
export const SubscriptionContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tnsStatus, setTnsStatus] = useState({});
  const [isError, setIsError] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [footerToast, setFooterToast] = useState(<></>);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [reSubscribeOnErrorAttempts, setReSubscribeOnErrorAttempts] =
    useState<number>(0);
  const { portalAccountGUID, authUser } = useAuthUser();
  const { authToken, isAuthenticated } = useAuthSession();
  const {
    isMock,
    api: { wsVoiceCallGuardUpdatesHost: webSocketEndpoint },
  } = useAppConfig();

  const toastClosed = () => {
    setIsUpdated(false);
  };

  const resetError = () => {
    setIsError(false);
  };

  const isUpdateInitiated = () => {
    setIsUpdateInProgress(true);
  };

  const callGuardFooterMessage = (
    <>Call Guard updates completed successfully.</>
  );

  useEffect(() => {
    if (
      !isAuthenticated ||
      isSubscribed ||
      isMock ||
      reSubscribeOnErrorAttempts > 1 // Max 2 extra connects on error. Total of 3 error attempts.
    ) {
      return;
    }
    // Don't attempt to open a websocket however no websocket URL is gotten from the configs.
    // Attempting to open an empty websocket causes a full app runtime exception.
    if (!webSocketEndpoint || webSocketEndpoint?.trim().length === 0) {
      return;
    }

    const webSocket = new WebSocket(webSocketEndpoint, 'graphql-ws');
    setIsSubscribed(true);

    webSocket.onopen = () => {
      webSocket.send(JSON.stringify({ type: 'connection_init', payload: {} }));
      webSocket.send(
        JSON.stringify({
          id: '4',
          type: 'start',
          payload: {
            variables: {
              portalUserGUID: authUser?.id || '',
              portalAccountGUID,
              accessToken: authToken,
            },
            extensions: {},
            operationName: 'onVoiceTnsNotification',
            query: OnVoiceTnsNotificationDocument,
          },
        })
      );
    };

    webSocket.onmessage = (event) => {
      const msg = JSON.parse(event.data);

      if (msg.type == 'data' && msg?.payload?.data?.getTNSObjectNotifications) {
        // TODO: Need to get this hooked up with Quantum
        const tnsStatus =
          msg.payload.data.getTNSObjectNotifications?.status.toLowerCase();
        if (
          tnsStatus === 'failed' ||
          tnsStatus === 'error' ||
          tnsStatus === 'failure'
        ) {
          setIsError(true);
          setIsUpdateInProgress(false);
        }
        if (tnsStatus === 'success') {
          setIsError(false);
          setIsUpdated(true);
          setIsUpdateInProgress(false);
        }

        setTnsStatus(msg.payload.data.getTNSObjectNotifications);
        setFooterToast(callGuardFooterMessage);
      }
    };

    webSocket.onclose = (event) => {
      // When connection is lost due to the web socket from our client side
      // prematurely closing and this onclose() method is fired off with nothing
      // to do with any 'complete' message from the subscription server itself,
      // when the connection longer exists because the web socket subscription server
      // itself breaks off the socket connection or closes down the socket connection
      // and tells the client so with a 'complete' message, we need to always
      // re-establish the connection by setting isSubscribed back to false, and thus
      // cause this useEffect to re-fire and the web socket connection is created anew above.
      setTimeout(() => {
        setIsSubscribed(false);
      }, 5000);
    };

    webSocket.onerror = (event) => {
      // When connection is lost due to the web socket from our client side
      // prematurely closing and this onerror() method is fired off (which can also happen
      // merely when a connection is attempted and there is no URL given or the URL can't be reached
      // bc of firewall blocks or you are running this code not on Charter VPN for lower envs),
      // we need to always re-establish the connection by setting isSubscribed back to false, and thus
      // cause this useEffect to re-fire and the web socket connection is created anew above.
      // We will only try this a max of 3 times, then we will stop retrying to connect.
      if (reSubscribeOnErrorAttempts < 3) {
        const newCount = reSubscribeOnErrorAttempts + 1;
        setReSubscribeOnErrorAttempts(newCount);
        setTimeout(() => {
          setIsSubscribed(false);
        }, 5000);
      }
    };
  }, [isAuthenticated, isSubscribed]);

  return (
    <SubscriptionContext.Provider
      value={{
        tnsStatus,
        toastClosed,
        isUpdated,
        footerToast,
        isError,
        resetError,
        isUpdateInitiated,
        isUpdateInProgress,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
