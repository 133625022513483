import { AccountType } from 'types/accountType';
import { AimQueryKeyOrgName } from 'types/aimQueryKeyOrgName';
import { OrganizationFeature } from 'types/organizationFeature';
import { UserCapability } from 'types/userCapability';
import {
  IAimAccountOrganizationContact,
  IAimEnterpriseSupportContact,
  IAimOrganization,
  IAimOrganizationFeature,
  Maybe,
} from 'types/types.generated';
import { faker } from '@faker-js/faker';
import { genPhone } from 'mocks/utils/common';
import { IMockOrgPreset, EnterpriseContactOption } from './organizations.types';

const allUserCapabilities = Object.values(UserCapability);
const allFeatures = Object.values(OrganizationFeature);
const allQueryKeys = Object.values(AimQueryKeyOrgName);

/**
 * A list of all the pre-defined Organizations
 * Set what features / queryKeys needs to display per entry
 */
export const getAllMockOrgPresets: IMockOrgPreset[] = [
  {
    // The original Demo configured one
    id: 'demo',
    name: 'Demo Corp',
    accountType: AccountType.Standard,
    enterpriseContacts: [EnterpriseContactOption.TechBillingSupport],
    features: allFeatures.filter(
      (feature) =>
        feature !== OrganizationFeature.Bills &&
        feature !== OrganizationFeature.MNE &&
        feature !== OrganizationFeature.Storage &&
        feature !== OrganizationFeature.CloudSecurity
    ),
    queryKeys: allQueryKeys.filter(
      (key) =>
        key !== AimQueryKeyOrgName.MNE &&
        key !== AimQueryKeyOrgName.Storage &&
        key !== AimQueryKeyOrgName.CloudSecurity &&
        key !== AimQueryKeyOrgName.MSS
    ),
    userSettings: {
      isInternalUser: false,
      capabilities: allUserCapabilities,
    },
  },
  // Product doesn't want to see the Multi Org Modal for mock environment for now we will comment out the extra org choices
  // {
  //   id: 'initech', // ExternalUsability
  //   name: 'Initech',
  //   accountType: AccountType.Standard,
  //   enterpriseContacts: Object.values(EnterpriseContactOption),
  //   features: allFeatures.filter(
  //     (feature) => feature !== OrganizationFeature.SecureAccess
  //   ),
  //   queryKeys: allQueryKeys.filter(
  //     (key) => key !== AimQueryKeyOrgName.SecureAccess
  //   ),
  //   userSettings: {
  //     isInternalUser: false,
  //     capabilities: allUserCapabilities,
  //   },
  // },
  // {
  //   id: 'umbrella-corporation',
  //   name: 'Umbrella Corporation',
  //   accountType: AccountType.Standard,
  //   enterpriseContacts: Object.values(EnterpriseContactOption),
  //   features: allFeatures,
  //   queryKeys: allQueryKeys,
  //   userSettings: {
  //     isInternalUser: false,
  //     capabilities: allUserCapabilities,
  //   },
  // },
  // {
  //   id: 'wonka-industries',
  //   name: 'Wonka Industries',
  //   accountType: AccountType.Standard,
  //   enterpriseContacts: Object.values(EnterpriseContactOption),
  //   features: allFeatures,
  //   queryKeys: allQueryKeys,
  //   userSettings: {
  //     isInternalUser: false,
  //     capabilities: allUserCapabilities,
  //   },
  // },
  // {
  //   id: 'stark-industries',
  //   name: 'Stark Industries',
  //   accountType: AccountType.CalNet,
  //   enterpriseContacts: Object.values(EnterpriseContactOption),
  //   features: allFeatures,
  //   queryKeys: allQueryKeys,
  //   userSettings: {
  //     isInternalUser: false,
  //     capabilities: allUserCapabilities,
  //   },
  // },
];

export const genAccountOrganizationContacts =
  (): IAimAccountOrganizationContact[] => {
    const types = ['Client Services Manager', 'Account Executive'];

    return types.map((contactType) => ({
      id: faker.string.uuid(),
      contactType,
      email: faker.internet.email(),
      firstName: faker.person.firstName(),
      lastName: faker.person.lastName(),
      phoneNumber: genPhone(),
    }));
  };

export const genOrganization = (
  orgPreset?: IMockOrgPreset
): IAimOrganization => {
  const features: IAimOrganizationFeature[] =
    orgPreset?.features.map((feature) => ({
      id: faker.string.uuid(),
      name: feature,
      isSpecialFeature: feature === OrganizationFeature.FeedbackForm,
      isEnabled: true,
    })) ?? [];

  const enterpriseSupportContacts: IAimEnterpriseSupportContact[] = [];
  if (
    orgPreset?.enterpriseContacts.includes(
      EnterpriseContactOption.TechBillingSupport
    )
  ) {
    enterpriseSupportContacts.push({
      id: faker.string.uuid(),
      name: 'Technical and Billing Support',
      phoneNumber: '8888122591',
      isPremierSupport: false,
    });
  }

  if (
    orgPreset?.enterpriseContacts.includes(
      EnterpriseContactOption.TechBillingSupportHawaii
    )
  ) {
    enterpriseSupportContacts.push({
      id: faker.string.uuid(),
      name: 'Technical and Billing Support - Hawaii',
      phoneNumber: '8086438510',
      isPremierSupport: false,
    });
  }

  if (orgPreset?.enterpriseContacts.includes(EnterpriseContactOption.ENOC)) {
    enterpriseSupportContacts.push({
      id: faker.string.uuid(),
      name: 'Enterprise Network Operations Center (ENOC)',
      phoneNumber: '8667855681',
      isPremierSupport: false,
    });
  }

  return {
    id: orgPreset?.id ?? faker.string.uuid(),
    name: orgPreset?.name,
    supportPin: faker.datatype.boolean()
      ? faker.number.int({ min: 1000, max: 9999 }).toString()
      : '',
    accountType: {
      id: faker.string.uuid(),
      name: orgPreset?.accountType,
    },
    queryKeys: orgPreset?.queryKeys.map((val) => ({
      id: faker.string.uuid(),
      name: val,
      keyData: faker.finance.iban(),
    })),
    enterpriseSupportContacts,
    accountOrganizationContacts: genAccountOrganizationContacts(),
    features,
  };
};
