/**
 * Analytics SDR for Video Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

export const videoAnalytics: IAnalyticSdr = {
  videoInventory: {
    siteSection: 'Video',
    siteSubSection: 'Video Inventory',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
    },
  },
};
