import { IAppConfig } from '../../config/appConfig/appConfig.types';

/**
 * Mocking Service Integration
 */
export default class MockService {
  /** Initialize mock service worker */
  static async init(appConfig: IAppConfig): Promise<void> {
    if (!appConfig.isMock) {
      return;
    }

    const { worker } = await import('../../mocks/browser');
    await worker.start({
      onUnhandledRequest: ({ method, url }) => {
        // only allow the mock service to intercept calls to entservices-graphql
        if (url.endsWith('/entservices-graphql-p2')) {
          throw new Error(`Unhandled ${method} request to ${url}`);
        }
      },
    });
  }
}
