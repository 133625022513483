import { useState, useCallback } from 'react';
import * as storage from '../services/authSessionStorage';
import { useAppConfig } from '../../hooks/useAppConfig';
import { Maybe } from '../../types/types.generated';
import { useAuthService } from './useAuthService';
import { hasTokenExpired } from '../utils/authSessionUtils';

export interface ITokenState {
  token: Maybe<string>;
  tokenExp: Maybe<string>;
}

/**
 * Manages the users Anonymous Token with PiNxt.
 * Don't use directly from within page components, instead use useAuthSession hook
 */
export const useAnonymousToken = () => {
  const [tokenState, setTokenState] = useState<ITokenState>({
    token: storage.getAnonymousToken(),
    tokenExp: storage.getAnonymousTokenExp(),
  });

  const { isMock } = useAppConfig();
  const { token, tokenExp } = tokenState;

  const { fetchAnonymousToken } = useAuthService();

  /**
   * Request a PiNxt Anonymous Token to be used for API calls with unauthenticated user.
   */
  const requestAnonymousToken = useCallback(async (): Promise<string> => {
    try {
      const accessToken = await fetchAnonymousToken();

      // Save state to local storage
      storage.setAnonymousToken(accessToken);

      // update context state
      setTokenState({
        token: storage.getAnonymousToken(),
        tokenExp: storage.getAnonymousTokenExp(),
      });

      return accessToken;
    } catch (error) {
      return Promise.reject(error);
    }
  }, [fetchAnonymousToken]);

  /**
   * Request Anonymous Token onDemand when its needed.
   * Anonymous Tokens last for 1 week so onDemand is small impact
   */
  const getAnonymousToken = useCallback(() => {
    if (!token || hasTokenExpired(tokenExp!)) {
      return requestAnonymousToken();
    }

    return Promise.resolve(token);
  }, [requestAnonymousToken, token, tokenExp]);

  return {
    getAnonymousToken,
  };
};
