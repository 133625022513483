import Helix from '@charter/helix/build/portals/index.min';
import { parse } from 'graphql';
import {
  IAppConfigQuantumConfig,
  IAppConfig,
} from '../../config/appConfig/appConfig.types';
import { IGqlError, Maybe } from '../../types/types.generated';
import {
  getErrorName,
  getErrorMessage,
  piErrToGraphQLErr,
} from '../../utils/errorUtils';
import {
  IAnalyticPageViewEvent,
  IAnalyticButtonEvent,
  IAnalyticMenuSelectionEvent,
  IAnalyticLoginStartEvent,
  IAnalyticLoginSuccessEvent,
  IAnalyticLoginFailedEvent,
  IAnalyticUserInfo,
  IAnalyticAuthRefreshEvent,
} from '../types/analytics';
import { IPiNxtAPIResponse, IPiNxtIdToken } from '../types/pinxt';
import {
  IQuantumPageViewOptions,
  IQuantumErrorOptions,
  IQuantumSelectActionOptions,
  IQuantumLoginStartOptions,
  IQuantumLoginStopOptions,
  IQuantumLoginRefreshAuthOptions,
  IQuantumLogOptions,
  IQuantumApiCallOptions,
  IQuantumGqlApiCall,
  IQuantumRestApiCall,
} from '../types/quantum';
import { softErrorCodes } from '../utils/analyticsUtils';

interface IHelixState {
  analytics: Maybe<QuantumService>;
  defaultData: null;
  config: Maybe<IAppConfigQuantumConfig>;
  appConfig: Maybe<IAppConfig>;
}

const state: IHelixState = {
  analytics: null,
  defaultData: null,
  config: null,
  appConfig: null,
};

const isBrowser = typeof window !== `undefined`;
let isLoaded = false;

/**
 * Quantum Service for sending up Quantum events, try not to use directly in your components but instead use `useAnalytics` hook.
 * Implementation as based on:
 * https://gitlab.spectrumflow.net/product-intelligence/helix-demo-app/-/blob/master/src/analytics/index.js
 *
 * Also see https://prism.spectruminternal.com/documentation/helix/helixJS/installation
 */
export default class QuantumService {
  static init(appConfig: IAppConfig): Promise<void> {
    if (!isBrowser) {
      return Promise.resolve();
    }

    try {
      if (!appConfig) {
        console.error(
          'Quantum Analytics Service | Error - No Config or Version provided.'
        );
        return Promise.resolve();
      }

      if (!appConfig.analytics.quantum.enabled) {
        console.info(`Quantum Analytics is disabled.`);
        return Promise.resolve();
      }

      if (appConfig.isLocal) {
        // Quantum's Helix library doesn't run on localhost.
        console.info(
          `Localhost detected, Quantum Analytics won't push up tracking events.`
        );
        return Promise.resolve();
      }

      state.appConfig = appConfig;
      state.config = appConfig.analytics.quantum.config;
    } catch (e) {
      console.error(`Quantum Analytics Service | Error on Constructor : ${e}`);
      return Promise.resolve();
    }

    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      Helix.useDefaultStorage();

      const {
        isTrueProd,
        analytics: {
          quantum: { prodEndpoint, devEndpoint },
        },
      } = appConfig;

      let endpoint;
      if (isTrueProd) {
        /**
         * Only the true prod app should be logging to Quantum Prod,
         * all else (demo, Prod InActive, Lower Envs, etc.) should be using the Staging or Dev
         */
        endpoint = prodEndpoint;
      } else {
        endpoint = devEndpoint; // Rolls up under Staging in Prism
      }

      Helix.set('endpoint', endpoint);

      if (window !== undefined) {
        (window as any).Helix = Helix;
      }

      return Helix.init(state.config).then(
        () => {
          if (Helix.get('currentState') === 'blank') {
            Helix.trackStartSession(state.config!.startSession);
            isLoaded = true;
          }
        },
        (e: Error) => {
          console.error(e, 'INIT ERROR');
        }
      );
    } catch (err) {
      console.error(err);
      return Promise.resolve();
    }
  }

  static isLoaded(): boolean {
    return isLoaded;
  }

  /**
   * @doc method
   * @name analytics#getVisitId
   * @methodOf analytics
   * @returns {}
   * @description Gets the Current VisitId from the LocalStorage
   */
  static getVisitId(): Maybe<string> {
    if (!isBrowser) {
      return null;
    }
    let visitId = null;
    const libState = JSON.parse(
      window.localStorage.getItem('library-state') || ''
    );
    // eslint-disable-next-line no-prototype-builtins
    if (libState && libState?.hasOwnProperty('model')) {
      visitId = libState.model.visit.visitId;
    }
    return visitId;
  }

  /**
   * @doc method
   * @name analytics#track
   * @methodOf analytics
   * @returns {}
   * @description Calls Helix Track
   */
  private static track(
    id: string,
    adHocData: Record<string, any>,
    options?: Record<string, any>
  ): void {
    try {
      if (adHocData.msgEventType !== 'pageViewInit') {
        console.debug(`quantum(${id})`, adHocData);
      }

      if (QuantumService.isLoaded() && isBrowser) {
        Helix.track(id, adHocData, options);
      } else if (state.appConfig?.analytics.quantum.enabled) {
        console.error(
          "Unable to push the event to quantum, quantum service hasn't been loaded yet!"
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Checks whether or not auth user state info has been set in
   * Quantum yet.
   */
  static hasUserData(): boolean {
    const helixStateStr = sessionStorage.getItem('library-state');
    if (helixStateStr) {
      try {
        const helixState = JSON.parse(helixStateStr);
        return !!helixState?.model?.visit?.account?.accountGUID;
      } catch (error) {
        QuantumService.jsError({
          appErrorType: 'application',
          msgFeatureName: 'analytics',
          appErrorCode: getErrorName(error),
          appErrorMessage: getErrorMessage(error),
        });
      }
    }

    return false;
  }

  /**
   * Send page view tracking event to Quantum
   * @param pageViewEvent event object
   */
  static trackPageView(pageViewEvent: IAnalyticPageViewEvent): void {
    const { pageInfo } = pageViewEvent;
    const { siteSection, siteSubSection, siteSubSubSection } = pageInfo;

    const pageViewOptions: IQuantumPageViewOptions = {
      currPageName: siteSubSubSection ?? siteSubSection ?? siteSection,
      currPageAppSection: siteSection || '',
      msgTriggeredBy: 'user',
    };

    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const msgEventType of ['pageViewInit', 'pageViewCompleted']) {
        const adHocData = {
          msgEventType,
          currPageUrl: window.location.href,
          currPageTitle: document.title,
          ...pageViewOptions,
        };

        QuantumService.track('pageView', adHocData);
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Track a Javascript error, for API errors track under API Call Events.
   * Should only be used to track "hard" errors that we won't to show up under the errors dashboard in Prism.
   * For "soft" errors and all other logging events use jsLog.
   * See flow: https://prism.spectruminternal.com/documentation/quantum/error-events
   */
  static jsError(event: IQuantumErrorOptions): void {
    QuantumService.track('generic_error_portals', event);
  }

  /**
   * Send user interaction events (apart from drop down) to Quantum.
   * @param buttonEvent event object
   */
  static buttonEvent(event: IAnalyticButtonEvent): void {
    try {
      const { eventInfo } = event;
      const { itemClicked, opResultSummary, pageRegion } = eventInfo;

      const selectActionOptions: IQuantumSelectActionOptions = {
        opType: 'buttonClick',
        currPageElemStdName: itemClicked,
        currPageElemUiName: itemClicked,
        msgCategory: 'contentDiscovery',
        msgTriggeredBy: 'user',
        linkAdditionalInfo: opResultSummary || '',
        currPageSecName: pageRegion ?? '',
      };

      QuantumService.track('selectAction', selectActionOptions);
    } catch (error) {
      // Catch mapping errors
      QuantumService.jsError({
        appErrorType: 'application',
        msgFeatureName: 'analytics',
        appErrorCode: getErrorName(error),
        appErrorMessage: getErrorMessage(error),
      });
    }
  }

  /**
   * Send user menu selection events specifically to Quantum.
   * @param menuSelectionEvent event object
   */
  static menuSelectionEvent(event: IAnalyticMenuSelectionEvent): void {
    try {
      const { eventInfo } = event;

      const {
        itemClicked,
        opResult,
        opResultSummary,
        menuSelection,
        pageRegion,
      } = eventInfo;

      const selectActionOptions: IQuantumSelectActionOptions = {
        opType: 'dropDown',
        currPageElemStdName: itemClicked,
        currPageElemUiName: itemClicked,
        msgCategory: 'contentDiscovery',
        msgTriggeredBy: 'user',
        currPageElemSelOptions: [menuSelection],
        linkAdditionalInfo: opResultSummary || '',
        currPageSecName: pageRegion ?? '',
      };

      QuantumService.track('selectAction', selectActionOptions);
    } catch (error) {
      // Catch mapping errors
      QuantumService.jsError({
        appErrorType: 'application',
        msgFeatureName: 'analytics',
        appErrorCode: getErrorName(error),
        appErrorMessage: getErrorMessage(error),
      });
    }
  }

  /**
   * Track the start of the login flow
   * See flow: https://prism.spectruminternal.com/documentation/quantum/login
   */
  static loginStart(event: IAnalyticLoginStartEvent): void {
    try {
      const { loginType, username } = event.eventInfo;

      const loginStartOptions: IQuantumLoginStartOptions = {
        // Required by Quantum (https://prism.spectruminternal.com/documentation/quantum/login)
        opType: loginType,
        opUserText: username,
      };

      QuantumService.track('generic_loginStart_portals', loginStartOptions);
    } catch (error) {
      // Catch mapping errors
      QuantumService.jsError({
        appErrorType: 'application',
        msgFeatureName: 'analytics',
        appErrorCode: getErrorName(error),
        appErrorMessage: getErrorMessage(error),
      });
    }
  }

  /**
   * Track result of a login
   * See flow: https://prism.spectruminternal.com/documentation/quantum/login
   */
  static loginStop(
    event: IAnalyticLoginSuccessEvent | IAnalyticLoginFailedEvent,
    idToken?: Maybe<string>,
    idTokenObj?: Maybe<IPiNxtIdToken>
  ): void {
    try {
      const { appErrorCode, appErrorMessage, loginType } = (
        event as IAnalyticLoginFailedEvent
      ).eventInfo;

      const {
        organizationId,
        organizationName,
        isInternal,
        isPID,
        isPremier,
        userId,
      } =
        (event as IAnalyticLoginSuccessEvent).userInfo ||
        ({} as IAnalyticUserInfo);

      const loginIsSuccess = !appErrorCode;

      // Don't track bad / invalid login creds as hard errors
      if (appErrorCode === '3104') {
        QuantumService.jsLog({
          customName: 'Invalid Login Creds',
          customDomain: 'login',
          customLevel: 'error',
          customMessage: `Code ${appErrorCode}: ${appErrorMessage}`,
        });
        return;
      }

      // Indicate whether this account is a QA Testing Account
      const isTestAccount = organizationName
        ? !!organizationName.toLowerCase().startsWith('z_sit_')
        : false;

      const loginStartOptions: IQuantumLoginStopOptions = {
        // Required by Quantum (https://prism.spectruminternal.com/documentation/quantum/login)
        opSuccess: loginIsSuccess,
        opType: loginType,

        // Required by Quantum (if success)
        accountOauthToken: idToken || '',
        loginCompletedTs: idTokenObj ? idTokenObj.exp.toString() : '',

        // Required by Quantum (if error)
        appErrorType: !loginIsSuccess ? 'authentication' : '',
        appErrorCode: appErrorCode ?? '',
        appErrorMessage: appErrorMessage ?? '',

        // Additional Ad-Hoc data required
        accountNumber: organizationId,
        accountGUID: organizationId,
        accountCompany: organizationName,
        identityGUID: userId ? userId.toString() : '',
        accountClassification: isPremier ? 'premier' : '',
        accountSubClassification: isTestAccount ? 'test' : '',
        userRole: isInternal ? 'internal' : 'external',
      };

      QuantumService.track('generic_loginStop_portals', loginStartOptions);
    } catch (error) {
      // Catch mapping errors
      QuantumService.jsError({
        appErrorType: 'application',
        msgFeatureName: 'analytics',
        appErrorCode: getErrorName(error),
        appErrorMessage: getErrorMessage(error),
      });
    }
  }

  static logout(): void {
    QuantumService.track('logout', {
      eventCaseId: 'generic_logout_portals',
    });
  }

  /**
   * Track result of a refresh auth
   * See flow: https://prism.spectruminternal.com/documentation/quantum/login
   */
  static refreshAuth(
    event: IAnalyticAuthRefreshEvent,
    idToken?: Maybe<string>,
    idTokenObj?: Maybe<IPiNxtIdToken>
  ): void {
    try {
      const { eventInfo } = event;
      const { appErrorCode } = eventInfo;
      const loginIsSuccess = !appErrorCode;

      const authOptions: IQuantumLoginRefreshAuthOptions = {
        // Required by Quantum (https://prism.spectruminternal.com/documentation/quantum/login)
        opType: 'refreshAuth',
        opSuccess: loginIsSuccess,
        msgTriggeredBy: 'application',

        // Required by Quantum (if success)
        accountOauthToken: idToken || '',
        loginCompletedTs: idTokenObj ? idTokenObj.exp.toString() : '',

        // Required by Quantum (if error)
        appErrorType: !loginIsSuccess ? 'authentication' : '',
        appErrorCode: appErrorCode ?? '',
      };

      QuantumService.track('generic_inVisitOauthRefresh_portals', authOptions);
    } catch (error) {
      // Catch mapping errors
      QuantumService.jsError({
        appErrorType: 'application',
        msgFeatureName: 'analytics',
        appErrorCode: getErrorName(error),
        appErrorMessage: getErrorMessage(error),
      });
    }
  }

  /**
   * Track custom logging and debugging.
   * Don't use to track "hard" errors (see jsError function for more info).
   * But you can use this for "soft" errors.
   */
  static jsLog(event: IQuantumLogOptions): void {
    // Make sure to log to console.
    switch (event.customLevel) {
      case 'error':
      case 'critical':
        console.error(event);
        break;
      case 'warn':
        console.warn(event);
        break;
      case 'info':
        console.info(event);
        break;
      default:
        console.debug(event);
    }

    QuantumService.track('generic_customEvent_portals', {
      ...event,
      /**
       * Convert to a key / value map
       * Quantum Requirements: This is a set of key-value pairs associated with the event.
       * Any information relevant to the custom event or log can be included in this dictionary
       */
      customMessage: {
        message: event.customMessage,
      },
    });
  }

  /**
   * Track a API calls and responses (including failures), for Javascript errors track under Error Events.
   * See flow: https://prism.spectrumtoolbox.com/documentation/quantum/api-call-events
   */
  static apiCall(event: IQuantumApiCallOptions): void {
    QuantumService.track('apiCall', event);
  }

  /**
   * Track the results of an GraphQL call to Quantum.
   */
  static apiGqlCall({
    endpoint,
    fetchStartMs,
    query,
    response,
    errorCode,
    errorMessage,
  }: IQuantumGqlApiCall): void {
    const { host, pathname } = new URL(endpoint);

    const appApiResponseTimeMs = (
      new Date().valueOf() - fetchStartMs
    ).toString();

    // see https://astexplorer.net to explore AST object
    const ast = parse(query);
    const isQueryCall = (ast.definitions[0] as any).operation === 'query';
    const gqlOperationName = (ast.definitions[0] as any).name.value;

    /**
     * There is a (small) chance response could be null.
     * Use-cases for this is if an fetch error occurred, for example a CORS error.
     * The error messages for these are not quite intuitive though,
     * most often appearing as "Failed to fetch"
     */
    if (!response) {
      QuantumService.apiCall({
        opSuccess: false,
        appApiResponseCode: errorCode || '',
        appApiResponseTimeMs,
        appApiHost: host,
        appApiPath: pathname,
        appApiHttpVerb: 'POST',
        appApiResponseText: errorMessage || '',
        appApiResponseSize: '',
        appApiCached: false,
        appApiTraceId: '',
        apiArchitecture: 'GQL',
        gqlOperationType: isQueryCall ? 'query' : 'mutation',
        gqlOperationName,
        gqlErrorCode: '',
      });
      return;
    }

    const res = response.clone();
    const { headers, statusText, status } = res;

    const appApiCached = !headers.get('cache-control')?.includes('no-cache');
    const appApiTraceId = headers.get('x-trace-id') || '';

    // TODO: Currently Backend isn't allowing this header to be exposed so will need to tell BE to add `Access-Control-Expose-Headers: ..., Content-Length`
    // See https://stackoverflow.com/questions/48266678/how-to-get-the-content-length-of-the-response-from-a-request-with-fetch
    const appApiResponseSize = headers.get('Content-Length') || '';

    res
      .json()
      .then((data) => {
        let opSuccess = true;
        let gqlErrorCode = '';
        let appApiResponseText = statusText;

        if (data && (!!data.errors || data.resultCode)) {
          const errors: IGqlError[] = data.resultCode
            ? piErrToGraphQLErr(data as IPiNxtAPIResponse).errors
            : data.errors;
          const errorDetails = errors[0];
          gqlErrorCode = errorDetails.extensions?.code || 'unknown';
          appApiResponseText = errorDetails.message;

          // this value determines whether this should count as a API error or not.
          opSuccess = softErrorCodes.includes(gqlErrorCode) || false;
        }

        QuantumService.apiCall({
          opSuccess,
          appApiResponseCode: status.toString(),
          appApiResponseTimeMs,
          appApiHost: host,
          appApiPath: pathname,
          appApiHttpVerb: 'POST',
          appApiResponseText,
          appApiResponseSize,
          appApiCached,
          appApiTraceId,
          apiArchitecture: 'GQL',
          gqlOperationType: isQueryCall ? 'query' : 'mutation',
          gqlOperationName,
          gqlErrorCode,
        });
      })
      .catch(console.error);
  }

  static apiRestCall({
    endpoint,
    fetchStartMs,
    method,
    response,
    responseCode,
    responseMessage,
  }: IQuantumRestApiCall): void {
    const { host, pathname, search } = new URL(endpoint);

    const appApiResponseTimeMs = (
      new Date().valueOf() - fetchStartMs
    ).toString();

    /**
     * There is a (small) chance response could be null.
     * Use-cases for this is if an fetch error occurred, for example a CORS error.
     * The error messages for these are not quite intuitive though,
     * most often appearing as "Failed to fetch"
     */
    if (!response) {
      QuantumService.apiCall({
        opSuccess: false,
        appApiHttpVerb: method ?? 'GET',
        apiArchitecture: 'REST',
        appApiHost: host,
        appApiPath: pathname,
        appApiQueryParameters: search,
        appApiResponseCode: responseCode || '',
        appApiResponseText: responseMessage || '',
        appApiResponseTimeMs,
        appApiResponseSize: '',
        appApiCached: false,
        appApiTraceId: '',
      });
      return;
    }
    const { headers, statusText, status } = response;

    let opSuccess = response.ok;
    const appApiCached = !headers.get('cache-control')?.includes('no-cache');
    const appApiTraceId = headers.get('x-trace-id') || '';

    // TODO: Currently Backend isn't allowing this header to be exposed so will need to tell BE to add `Access-Control-Expose-Headers: ..., Content-Length`
    // See https://stackoverflow.com/questions/48266678/how-to-get-the-content-length-of-the-response-from-a-request-with-fetch
    const appApiResponseSize = headers.get('Content-Length') || '';

    if (!response.ok && responseCode) {
      // this value determines whether this should count as a API error or not.
      opSuccess = softErrorCodes.includes(responseCode) || false;
    }

    QuantumService.apiCall({
      opSuccess,
      appApiHttpVerb: method ?? 'GET',
      apiArchitecture: 'REST',
      appApiHost: host,
      appApiPath: pathname,
      appApiQueryParameters: search,
      appApiResponseCode: responseCode ?? status.toString(),
      appApiResponseText: responseMessage ?? statusText,
      appApiResponseTimeMs,
      appApiResponseSize,
      appApiCached,
      appApiTraceId,
    });
  }
}
