export enum AimQueryKeyOrgName {
  Billing = 'SPECTRUM_CORE_BILLING_ACCOUNT',
  BusinessIntelligence = 'BUSINESS_INTELLIGENCE',
  BusinessVoice = 'BUSINESS_VOICE',
  DSB = 'DSB',
  ENE = 'ENTERPRISE_NETWORK_EDGE',
  HiCapBAN = 'HiCapBAN',
  MNE = 'MANAGED_NETWORK_EDGE',
  MNEWIFI = 'MNE_HOSPITALITY_WIFI',
  MRS = 'MANAGED_ROUTER_SERVICE',
  MSS = 'MANAGED_SECURITY_SERVICE',
  Network = 'NETWORK', // Granite
  SDWAN = 'SDWAN_NAME',
  StandardCap = 'StandardCap',
  Storage = 'STORAGE',
  Tickets = 'COMPANY_NAME', // Tickets
  UniComm = 'UniComm',
  VirtualFirewall = 'VFW_NAME',
  Wireless = 'WIRELESS_BAN',
  CloudSecurity = 'SASE_MERAKI_CLOUD_SECURITY',
  SecureAccess = 'SASE_CISCO_DUO',
}
