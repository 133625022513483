/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppConfig } from '../../hooks/useAppConfig';
import { useTdcsConfig } from '../../hooks/useTdcsConfig';
import {
  IAnalyticButtonEvent,
  IAnalyticMenuSelectionEvent,
  IAnalyticPageInfo,
  IAnalyticPageViewEvent,
  IAnalyticUserInfo,
} from '../types/analytics';
import { IAppConfig } from '../../config/appConfig/appConfig.types';
import { Maybe } from '../../types/types.generated';
import {
  AdobeAnalyticEvent,
  IAdobeAnalyticEventInfo,
  IAdobeAnalyticDigitalData,
  AdobeAnalyticEventOpResult,
} from '../types/adobe';
import QuantumService from '../services/quantumService';
import { getErrorMessage } from '../../utils/errorUtils';

const pushEvent = (
  event: AdobeAnalyticEvent,
  pageInfo: IAnalyticPageInfo,
  eventInfo: Maybe<IAdobeAnalyticEventInfo>,
  userInfo: Maybe<IAnalyticUserInfo>,
  appConfig: IAppConfig
): void => {
  const { app } = appConfig;

  // Set the digitalData object
  // Should not have undefined props
  const digitalData: IAdobeAnalyticDigitalData = {
    page: {
      pageInfo: {
        siteSection: pageInfo?.siteSection ?? '',
        siteSubSection: pageInfo?.siteSubSection ?? '',
        siteSubSubSection: pageInfo?.siteSubSubSection ?? '',
        sysEnv: app.env ?? '',
        appName: app.shortName ?? '',
      },
    },

    event: {
      eventInfo: {
        pageRegion: eventInfo?.pageRegion ?? '',
        itemClicked: eventInfo?.itemClicked ?? '',
        menuSelection: eventInfo?.menuSelection ?? '',
        searchPhrase: eventInfo?.searchPhrase ?? '',
        opResult: eventInfo?.opResult ?? AdobeAnalyticEventOpResult.None,
        opResultSummary: eventInfo?.opResultSummary ?? '',
      },
    },

    user: {
      userInfo: {
        organizationId: userInfo?.organizationId ?? '',
        organizationName: userInfo?.organizationName ?? '',
        isInternal: `${userInfo?.isInternal ?? ''}`,
        isPID: `${userInfo?.isPID ?? ''}`,
        isPremier: `${userInfo?.isPremier ?? ''}`,
        userId: userInfo?.userId ?? '',
      },
    },
  };

  console.debug(`adobe(${event})`, digitalData);

  (window as any).digitalData = digitalData;

  if (
    (window as any)._satellite === undefined ||
    (window as any)._satellite.track === undefined
  ) {
    return;
  }

  (window as any)._satellite.track(event);
};

/**
 * Send tracking data to Adobe Analytics
 * Note: Shouldn't use directly but go through the useAnalytics() hook instead.
 */
export const useAdobeAnalytics = () => {
  const [ready, setReady] = useState(false);

  const appConfig = useAppConfig();
  const tdcsConfig = useTdcsConfig();

  useEffect(() => {
    if (appConfig && tdcsConfig) {
      if (
        !appConfig.analytics.adobe.enabled ||
        !tdcsConfig.analytics.adobeEnabled
      ) {
        console.info('Adobe analytics is disabled');
        setReady(true);
        return;
      }

      const script = document.createElement('script');

      const {
        analytics: { adobe },
      } = appConfig;

      script.id = 'launch';
      script.src = appConfig.isTrueProd
        ? adobe.prodEndpoint
        : adobe.devEndpoint;
      script.async = true;

      script.addEventListener('load', () => {
        console.info('Adobe script tag loaded');
        setReady(true);
      });

      script.addEventListener('error', (err) => {
        QuantumService.jsError({
          appErrorType: 'application',
          msgFeatureName: 'analytics',
          appErrorMessage: `Adobe script tag failed to load: ${getErrorMessage(
            err
          )}`,
          appErrorCode: 'Failed',
        });
        setReady(true);
      });

      document.body.appendChild(script);
    }
  }, [appConfig, tdcsConfig]);

  const pageView = useCallback(
    (pageViewEvent: IAnalyticPageViewEvent): void => {
      const { pageInfo, userInfo } = pageViewEvent;

      if (tdcsConfig?.analytics.adobeEnabled && appConfig && ready) {
        pushEvent(
          AdobeAnalyticEvent.PageView,
          pageInfo,
          null,
          userInfo,
          appConfig
        );
      }
    },
    [appConfig, ready, tdcsConfig]
  );

  const buttonEvent = useCallback(
    (event: IAnalyticButtonEvent): void => {
      const { pageInfo, eventInfo, userInfo } = event;

      const eventObject: IAdobeAnalyticEventInfo = {
        pageRegion: eventInfo.pageRegion || '',
        itemClicked: eventInfo.itemClicked,
        menuSelection: '',
        searchPhrase: '',
        opResult: eventInfo.opResult || AdobeAnalyticEventOpResult.None,
        opResultSummary: eventInfo.opResultSummary || '',
      };

      if (tdcsConfig?.analytics.adobeEnabled && appConfig && ready) {
        pushEvent(
          AdobeAnalyticEvent.CTAClick,
          pageInfo,
          eventObject,
          userInfo,
          appConfig
        );
      }
    },
    [appConfig, ready, tdcsConfig]
  );

  const menuSelectionEvent = useCallback(
    (event: IAnalyticMenuSelectionEvent): void => {
      const { pageInfo, eventInfo, userInfo } = event;

      const eventObject: IAdobeAnalyticEventInfo = {
        pageRegion: eventInfo.pageRegion || '',
        itemClicked: eventInfo.itemClicked,
        searchPhrase: '',
        menuSelection: eventInfo.menuSelection,
        opResult: eventInfo.opResult || AdobeAnalyticEventOpResult.None,
        opResultSummary: eventInfo.opResultSummary || '',
      };

      if (tdcsConfig?.analytics.adobeEnabled && appConfig && ready) {
        pushEvent(
          AdobeAnalyticEvent.MenuSelection,
          pageInfo,
          eventObject,
          userInfo,
          appConfig
        );
      }
    },
    [appConfig, ready, tdcsConfig]
  );

  return useMemo(
    () => ({
      pageView,
      buttonEvent,
      menuSelectionEvent,
      ready,
    }),
    [buttonEvent, menuSelectionEvent, pageView, ready]
  );
};
