import { faker } from '@faker-js/faker';
import { mockConfig } from '../mockConfig';

const { isDemo } = mockConfig;

export const genBandwidth = () => {
  const options = ['10 Mbps', '25 Mbps', '100 Mbps', '500 Mbps', '1 Gbps'];
  return options[faker.number.int(options.length - 1)];
};

export enum ServicesEnum {
  FIBER_INTERNET = 'Fiber Internet',
  FIBER_INTERNET_PEG = 'Fiber Internet - PEG',
  FIBER_INTERNET_BV = 'Fiber Internet - Broadcaster Video',
  MANAGED_SECURITY = 'Managed Security',
}

export const genService = () => {
  const options = [
    ServicesEnum.FIBER_INTERNET.toString(),
    ServicesEnum.FIBER_INTERNET_PEG.toString(),
    ServicesEnum.FIBER_INTERNET_BV.toString(),
  ];

  // Hide 'Managed Security' from mock demo data for all users if
  // current env is demo or demo-stage (ENTDEL-7633)
  if (!isDemo) {
    options.push(ServicesEnum.MANAGED_SECURITY.toString());
  }

  return options[faker.number.int(options.length - 1)];
};

export const genPhone = (): string => {
  return faker.phone.number('!##!######');
};

export const genAccountNumber = () => {
  return faker.number.int({
    min: 10000000000000,
    max: 99999999999999,
  });
};

export const genDivisionId = () => {
  const nycPrefix = 'NYC';
  return (
    nycPrefix +
    faker.number.int({
      min: 5000,
      max: 6000,
    })
  );
};

export const shuffleArray = (array: any[]) => {
  const copyArray = [...array];
  for (let i = copyArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [copyArray[i], copyArray[j]] = [copyArray[j], copyArray[i]];
  }

  return copyArray;
};

export const applyPagination = <T>(items: T[], limit = 0, offset = 0): T[] => {
  if (!limit && !offset) {
    return items; // return everything
  }

  if (!items || items.length === 0) {
    return [];
  }

  return items.slice(offset, offset + limit);
};

export const genPhoneNumbers = (min: number, max: number): string[] => {
  const phoneNumbers: string[] = [];

  const count = faker.number.int({ min, max });
  for (let index = 0; index < count; index++) {
    phoneNumbers.push(genPhone());
  }

  return phoneNumbers;
};
