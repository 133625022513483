import { v4 as uuidv4 } from 'uuid';
import { PiTargetBg } from '../config/appConfig/appConfig.types';
import QuantumService from '../contexts/services/quantumService';
import { Maybe } from '../types/types.generated';
import { getErrorMessage, piErrToRestErr } from '../utils/errorUtils';
import { IPiNxtAPIResponse } from '../contexts/types/pinxt';

interface IRestFetcherResponseInfo {
  code: string;
  message: string;
}

interface IRestFetcherRequest<T> {
  /** PiNxt graphql endpoint   */
  endpoint: string;

  /** HTTP verb associated with the API call (ie: POST, GET, etc). */
  method?: 'POST' | 'GET' | 'DELETE';

  requestBody?: BodyInit;

  onResponse: (responseBody: T, isError: boolean) => IRestFetcherResponseInfo;

  /** The token to access the PiNxt graphql endpoint with */
  accessToken?: Maybe<string>;

  /** If on a Blue / Green env, designates which env to use */
  targetBg?: PiTargetBg;

  /** Additional Headers to pass with the request */
  additionalHeaders?: Record<string, string>;
}

/**
 * Make a REST Request, try not to call this directly in the components
 * but instead attempt to use useRestFetcher hook first.
 */
export const restFetcher = async <T>({
  endpoint,
  method,
  requestBody,
  onResponse,
  accessToken,
  targetBg,
  additionalHeaders,
}: IRestFetcherRequest<T>): Promise<T> => {
  const headers = new Map<string, string>([['X_TXN_ID', uuidv4()]]);

  // Quick fix for ENTDEL-10238 (the infamous Demo user idle bug).  See ticket comments for long term fix
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage('MOCK_ACTIVATE');

    // Small wait for changes to propagate
    await new Promise((resolve) => {
      setTimeout(resolve, 10);
    });
  }

  if (accessToken) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  if (targetBg) {
    headers.set('target_bg', targetBg);
  }

  const fetchStartMs = new Date().valueOf();

  let response: Maybe<Response> = null;
  try {
    response = await fetch(endpoint, {
      method,
      headers: {
        ...Object.fromEntries(headers),
        ...(additionalHeaders || {}),
      },

      body: requestBody,
    });
  } catch (error) {
    QuantumService.apiRestCall({
      fetchStartMs,
      response: null,
      method: method ?? 'GET',
      endpoint,
      responseCode: 'unknown', // TODO: Need FE Error Code (Verbatim Integration)
      responseMessage: getErrorMessage(error),
    });
    console.error(error);
    return Promise.reject(error);
  }

  const responseBody: T = await response.json();

  const { code, message } = (responseBody as any).resultCode
    ? piErrToRestErr(responseBody as IPiNxtAPIResponse)
    : onResponse(responseBody, !response.ok);

  QuantumService.apiRestCall({
    fetchStartMs,
    response,
    method: method ?? 'GET',
    endpoint,
    responseCode: code,
    responseMessage: message,
  });

  if (!response.ok) {
    return Promise.reject(responseBody);
  }

  return responseBody;
};
