import { FeatureToggleName } from '../../config/featureToggles/featureToggles.types';
import { IRoutePermissions } from '../../types/common';
import { OrgServiceType } from '../../types/orgServiceType';
import { UserCapability } from '../../types/userCapability';

export const billsRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Billing],
};

export const cloudSecurityRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.CloudSecurity],
  // hide cloud security from Demo until Product approves to add it back (It has SSO)
  featureToggle: FeatureToggleName.CloudSecurity,
};

export const secureAccessRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.SecureAccess],
  // hide secure access from Prod in Xenon turn on in Yoshi
  featureToggle: FeatureToggleName.SecureAccess,
};

export const secureInternetRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.SecureInternet],
  featureToggle: FeatureToggleName.SecureInternet,
};

export const networkRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Network],
};

export const wavelengthRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Wavelength],
};

export const eneRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.EnterpriseNetworkEdge],
};

export const mneRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.ManageNetworkEdge],
};

export const mrsRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.ManagedRouterService],
};

export const fiberRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Fiber],
};

export const businessInternetRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.BusinessInternet],
  // Temp hide Business Internet via env feature toggling from Demo until Product approves to add it back
  featureToggle: FeatureToggleName.BusinessInternet,
};

export const businessVoiceRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.BusinessVoice],
  // Temp hide Business Voice via env feature toggling from Demo until Product approves to add it back
  featureToggle: FeatureToggleName.BusinessVoice,
};

export const wirelessRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Wireless],
};

export const voiceRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.VoiceTrunking],
};

export const unifiedCommRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.UnifiedComms],
};

export const manageSecurityRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.ManagedSecurity],
};

export const virtualSecurityRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.VirtualSecurity],
  featureToggle: FeatureToggleName.VirtualSecurity,
};
// TODO Remove Video later
export const videoRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Video],
  featureToggle: FeatureToggleName.VideoService,
};

export const tvRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.TV],
  featureToggle: FeatureToggleName.TvService,
};

export const reportsRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Reporting],
};

export const ticketsRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Tickets],
};

export const storageRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Storage],
};

export const locationNamesRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.ManageLocationNames],
};

export const locationRoutePerm: IRoutePermissions = {
  orgServices: [OrgServiceType.Locations],
};

export const userRoutePerm: IRoutePermissions = {
  userCapabilities: [
    UserCapability.ViewUsers,
    UserCapability.SuperAdmin,
    UserCapability.EndUserAllAdmin,
  ],
};
