/**
 * Analytics SDR for Login Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import {
  ILoginAnalyticsSdr,
  ILoginAutoLogoutAnalytics,
  ILoginLayoutAnalytics,
  ILoginMultiOrgsModalAnalytics,
} from '../types/loginAnalytics.types';

const loginSection = 'Login';

const loginLayout: ILoginLayoutAnalytics = {
  buttonClicks: {
    supportLink: {
      pageRegion: 'Login Layout',
      itemClicked: 'Support',
    },
    spectrumLogoClick: {
      pageRegion: 'Login Layout',
      itemClicked: 'Spectrum Logo',
    },
  },
};

const autoLogoutModal: ILoginAutoLogoutAnalytics = {
  buttonClicks: {
    stillHereBtn: {
      pageRegion: 'Auto Logout Modal',
      itemClicked: 'Still Here',
    },
    signOutBtn: {
      pageRegion: 'Auto Logout Modal',
      itemClicked: 'Sign Out',
    },
  },
};

const multiOrgsModal: ILoginMultiOrgsModalAnalytics = {
  buttonClicks: {
    continueBtn: {
      pageRegion: 'Select An Organization Dialog',
      itemClicked: 'Continue',
    },
    signOutBtn: {
      pageRegion: 'Select An Organization Dialog',
      itemClicked: 'Sign Out',
    },
  },

  menuSelections: {
    organizationsMenu: {
      pageRegion: 'Select An Organization Dialog',
      itemClicked: 'Organizations',
    },
  },
};

export const loginAnalytics: ILoginAnalyticsSdr = {
  login: {
    siteSection: loginSection,
    buttonClicks: {
      signInBtn: {
        itemClicked: 'Sign In',
      },
      forgotPwdLink: {
        itemClicked: 'Forgot Password?',
      },
      registerLink: {
        itemClicked: 'Register',
      },
      contactSupportBtn: {
        itemClicked: 'Contact Support',
      },
    },
  },

  contactSupport: {
    siteSection: loginSection,
    siteSubSection: 'Troubles Signing In',
    buttonClicks: {
      forgotPwdLink: {
        itemClicked: 'Forgot Password',
      },
      registerLink: {
        itemClicked: 'Register for an Account',
      },
      contactSupportLink: {
        itemClicked: 'Get Support',
      },
    },
  },

  autoLogoutModal,
  loginLayout,
  multiOrgsModal,
};
