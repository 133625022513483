import { useContext } from 'react';
import { SubscriptionContext } from '../../../contexts/subscriptionContext';

export const useCallGuardSubscription = () => {
  const {
    tnsStatus,
    toastClosed,
    isUpdated,
    footerToast,
    isError,
    isUpdateInProgress,
    resetError,
    isUpdateInitiated,
  } = useContext(SubscriptionContext);

  return {
    tnsStatus,
    toastClosed,
    isUpdated,
    footerToast,
    isError,
    isUpdateInProgress,
    resetError,
    isUpdateInitiated,
  };
};
