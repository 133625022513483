import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { reportsRoutePerm } from '../../components/Routes/routePermissions';
import { reportsAnalytics } from './types/reportAnalytics';

const Reports = lazy(
  () => import(/* webpackChunkName: "reports" */ './Reports')
);

const { orgServices } = reportsRoutePerm;
const { reports } = reportsAnalytics;

export const reportsRoutes = (
  <Route
    path="reports"
    element={
      <PageRoute orgServices={orgServices} pageInfo={reports}>
        <Reports />
      </PageRoute>
    }
  />
);
