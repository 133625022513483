import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { virtualSecurityRoutePerm } from '../../components/Routes/routePermissions';

const VirtualSecurity = lazy(
  () => import(/* webpackChunkName: "virtual-security" */ './VirtualSecurity')
);

const VirtualSecurityDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "VirtualSecurityDetails" */ './VirtualSecurityDetails'
    )
);

const VirtualSecurityFilters = lazy(
  () =>
    import(
      /* webpackChunkName: "VirtualSecurityFilters" */ './VirtualSecurityFilters'
    )
);

const { orgServices, featureToggle } = virtualSecurityRoutePerm;

export const virtualSecurityRoutes = (
  <Route path="services/virtual-security/*">
    <Route
      path="sites"
      element={
        <PageRoute orgServices={orgServices} featureToggle={featureToggle}>
          <VirtualSecurity />
        </PageRoute>
      }
    />
    <Route
      path="site/:siteName/policy/:policyId"
      element={
        <PageRoute orgServices={orgServices} featureToggle={featureToggle}>
          <VirtualSecurityDetails />
        </PageRoute>
      }
    />
    <Route
      path="site/:siteName/policy/:policyId/:typeFilter"
      element={
        <PageRoute orgServices={orgServices} featureToggle={featureToggle}>
          <VirtualSecurityFilters />
        </PageRoute>
      }
    />
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>
);
