import { KiteProgressIndicator } from '@kite/react-kite';
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useAuthSession } from '../../hooks/useAuthSession';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useRouteUtils } from '../../hooks/useRouteUtils';
import { IRoutePageInfo } from '../../types/common';
import { OrganizationFeature } from '../../types/organizationFeature';
import { OrgServiceType } from '../../types/orgServiceType';
import { UserCapability } from '../../types/userCapability';
import { getPageName } from './PageRouteUtils';
import { navItems } from '../Layout/navItems';
import { FeatureToggleName } from '../../config/featureToggles/featureToggles.types';

/**
 * List of all the Base routes on the Left Hand Nav
 * This is the list we walk the user through if they don't have permissions.
 */

const baseRoutePaths = navItems.map((item) => item.path);

/**
 * By default, we send a user to the locations page (top most page in the baseRoutePaths list).
 * But if they don't have permissions or don't have the services we redirect them to the next
 * page on the list and try that one.
 * @param currentRoute the current path they are on
 */
const getNextRoute = (currentRoute: string): string => {
  let index = baseRoutePaths.findIndex((basePath) =>
    currentRoute.startsWith(basePath)
  );
  if (index === -1) {
    index = 0;
  }

  // try next page
  index += 1;

  return baseRoutePaths[index];
};

interface IProtectedRouteProps {
  userCapabilities?: UserCapability | UserCapability[];
  orgServices?: OrgServiceType | OrgServiceType[];
  orgFeatures?: OrganizationFeature | OrganizationFeature[];
  featureToggle?: FeatureToggleName;
  pageInfo?: IRoutePageInfo;
  children: any;
}

const ProtectedRoute = ({
  userCapabilities,
  orgServices,
  orgFeatures,
  featureToggle,
  pageInfo,
  children,
}: IProtectedRouteProps) => {
  const { isAuthenticating, isAuthenticated, isAuthError } = useAuthSession();
  const { authUser, isError: loadUserError } = useAuthUser();
  const { pathname, search } = useLocation();
  const { checkRoutePermissions } = useRouteUtils();
  const { trackPageView } = useAnalytics();

  const hasPermission = checkRoutePermissions({
    userCapabilities,
    orgServices,
    orgFeatures,
    featureToggle,
  });

  useEffect(() => {
    if (!pageInfo || !authUser || !hasPermission) {
      return;
    }

    let { siteSection } = pageInfo;
    const { siteSubSection, siteSubSubSection, skipPageView } = pageInfo;

    const pageName = getPageName(pageInfo);
    siteSection = siteSection ?? pageName;

    if (!skipPageView && siteSection) {
      trackPageView(siteSection, siteSubSection, siteSubSubSection);
    }

    // omit trackPageView as deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo, authUser]);

  if (isAuthError || loadUserError) {
    return <Navigate to="/500" />;
  }

  if (isAuthenticating) {
    return (
      <div className="display-loader">
        <KiteProgressIndicator id="kp1" />
      </div>
    );
  }

  // user needs a value auth code.
  // FIXME: Bills only for now.
  if (!isAuthenticated && pathname.includes('bills/listings/account-details')) {
    return <Navigate to={`/login?redirect=${pathname + search}`} replace />;
  }

  if (!isAuthenticated && pathname.includes('bills/listings')) {
    return (
      <Navigate
        to={`/login?redirect=/bills/listings/account-details${search}`}
        replace
      />
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // user has an auth code but the auth usg er object isn't built yet.
  if (isAuthenticated && !authUser) {
    return (
      <div className="display-loader">
        <KiteProgressIndicator id="kp1" />
      </div>
    );
  }

  if (!hasPermission) {
    return <Navigate to={getNextRoute(pathname) + search} replace />;
  }

  return children;
};

export default ProtectedRoute;
