import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { mrsAnalytics } from './types/mrsAnalytics';
import { mrsRoutePerm } from '../../components/Routes/routePermissions';

const { orgServices } = mrsRoutePerm;
const { mrsInventory, mrsDetails } = mrsAnalytics;

const MrsInventory = lazy(
  () => import(/* webpackChunkName: "mrs-inventory" */ './MrsInventory')
);

const MrsDetails = lazy(
  () => import(/* webpackChunkName: "mrs-details" */ './MrsDetails')
);

export const managedRouterServiceRoutes = (
  <Route path="services/managed-router-service/*">
    <Route
      path="inventory"
      element={
        <PageRoute orgServices={orgServices} pageInfo={mrsInventory}>
          <MrsInventory />
        </PageRoute>
      }
    />
    <Route
      path="details/:id/service"
      element={
        <PageRoute orgServices={orgServices} pageInfo={mrsDetails}>
          <MrsDetails />
        </PageRoute>
      }
    />
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>
);
