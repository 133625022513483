// Packages
import { KiteToast } from '@kite/react-kite';
import React, { ErrorInfo, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

// Components
import Routes from './components/Routes';
import { useCallGuardSubscription } from './domains/Voice/hooks/useCallGuardSubscription';
import AutoLogout from './components/AutoLogout';

// Hooks
import { useAuthSession } from './hooks/useAuthSession';
import { useAnalytics } from './hooks/useAnalytics';
import { getErrorName } from './utils/errorUtils';
import GlobalErrorModal from './components/GlobalErrorModal/GlobalErrorModal';

const App = () => {
  const [isFooterToastVisible, setIsFooterToastVisible] = useState(false);

  const { isAuthenticated } = useAuthSession();
  const { trackJsError } = useAnalytics();
  const navigate = useNavigate();

  const { isUpdated, toastClosed, footerToast } = useCallGuardSubscription();

  useEffect(() => {
    if (isUpdated) {
      setIsFooterToastVisible(true);
    }
  }, [isUpdated]);

  // Catch All app errors
  const handleBoundaryError = (error: Error, info: ErrorInfo) => {
    trackJsError({
      appErrorType: 'application',
      msgFeatureName: 'unknown',
      appErrorCode: getErrorName(error) || 'unexpected',
      appErrorMessage: error.message,
    });
    navigate('/500');
  };

  return (
    <div className="App">
      <ErrorBoundary
        // eslint-disable-next-line react/no-unstable-nested-components
        FallbackComponent={GlobalErrorModal}
        onError={handleBoundaryError}
      >
        {isAuthenticated && <AutoLogout />}
        <Routes />
        <KiteToast
          id="senet-footer-toast"
          bottomOffset={0}
          duration="long"
          position="bottom-left"
          visible={isFooterToastVisible}
          onExpire={() => {
            setIsFooterToastVisible(false);
            toastClosed();
          }}
          icon="ki-checkmark-circle-f"
        >
          {footerToast}
        </KiteToast>
      </ErrorBoundary>
    </div>
  );
};

export default App;
