import dayjs from 'dayjs';
import { Maybe } from '../../types/types.generated';

/**
 * Gets the stored PiNxt (unencrypted) user JWT token used to pass via header
 * for SOME API requests.
 */
export const getIdToken = (): Maybe<string> => {
  return localStorage.getItem('id_token');
};

/**
 * Sets the stored PiNxt (unencrypted) user JWT token used to pass via header
 * for SOME API requests.
 * @param idToken User's idToken (unencrypted JWT)
 */
export const setIdToken = (idToken: string): void => {
  localStorage.setItem('id_token', idToken);
};

/**
 * Gets the exp time of the Anonymous token and when it must be refreshed IF the user
 * is still active on the site.
 * @returns time in Milliseconds
 */
export const getAnonymousTokenExp = (): Maybe<string> => {
  return localStorage.getItem('anonymous_token_exp');
};

/**
 * Sets the exp time of the Anonymous token and when it must be refreshed IF the user
 * is still active on the site.
 */
export const setAnonymousTokenExp = (): void => {
  localStorage.setItem(
    'anonymous_token_exp',
    // anonymous tokens have a shelf life of 1 week, setting 6 days to stay ahead of it
    dayjs().add(6, 'day').valueOf().toString()
  );
};

/**
 * Gets the stored PiNxt Anonymous token used to pass via header
 * in API requests for NON Pi auth requests
 */
export const getAnonymousToken = (): Maybe<string> => {
  return localStorage.getItem('anonymous_token') || '';
};

/**
 * Sets the stored PiNxt Anonymous token used to pass via header
 * in API requests for NON Pi auth requests
 * @param anonymousToken PiNxt issued Anonymous Token
 */
export const setAnonymousToken = (anonymousToken: string): void => {
  localStorage.setItem('anonymous_token', anonymousToken);
  setAnonymousTokenExp();
};

/**
 * Gets the exp time of the token and when it must be refreshed IF the user
 * is still active on the site.
 * @returns time in Milliseconds
 */
export const getAccessTokenExp = (): Maybe<string> => {
  return localStorage.getItem('access_token_exp');
};

/**
 * Sets the exp time of the token and when it must be refreshed IF the user
 * is still active on the site.
 */
export const setAccessTokenExp = (): void => {
  localStorage.setItem(
    'access_token_exp',
    // access tokens have a shelf life of 1 hour, setting 20 mins to stay ahead of it
    dayjs().add(20, 'minutes').valueOf().toString()
  );
};

/**
 * Gets the stored PiNxt access user (JWE) token used to pass via header
 * in API requests.
 */
export const getAccessToken = (): Maybe<string> => {
  return localStorage.getItem('access_token') || '';
};

/**
 * Sets the stored PiNxt access user (JWE) token used to pass via header
 * in API requests.
 * @param accessToken User's Access Token (JWE)
 */
export const setAccessToken = (accessToken: string): void => {
  localStorage.setItem('access_token', accessToken);
  setAccessTokenExp();
};

/**
 * Gets the stored PiNxt refresh user (JWE) token used to pass via header
 * in API requests.
 */
export const getRefreshToken = (): Maybe<string> => {
  return localStorage.getItem('refresh_token') || '';
};

/**
 * Sets the stored PiNxt refresh user (JWE) token used to pass via header
 * in API requests.
 * @param refreshToken User's Auth Token (JWE)
 */
export const setRefreshToken = (refreshToken: string): void => {
  localStorage.setItem('refresh_token', refreshToken);
};

/**
 * Clears sessionStorage and Auth Tokens in localStorage
 */
export const clearSessionStorageAndAuthTokens = (isMock: boolean): void => {
  if (isMock) {
    // Protect some mock data in browser storage
    const keepKeys = [
      'new-user-email-token',
      'aim-multi-org-count',
      'validate-captcha-success',
    ];

    Object.keys(sessionStorage).forEach((key) => {
      if (keepKeys.includes(key) || key.startsWith('TOGGLE_')) {
        return;
      }

      sessionStorage.removeItem(key);
    });
  } else {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith('TOGGLE_')) {
        return;
      }
      sessionStorage.removeItem(key);
    });
    // For some reason this isn't being cleared.. so doing it manually.
    sessionStorage.removeItem('login_is_tracked');
  }

  /**
   * Its important to clear out known localStorage items instead of clearing ALL as there
   * might be other non-senet related items in there such as Analytics Visit session info.
   */
  localStorage.removeItem('access_token');
  localStorage.removeItem('access_token_exp');
  localStorage.removeItem('id_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('anonymous_token');
  localStorage.removeItem('anonymous_token_exp');
  localStorage.removeItem('viewing_as_pag');
  localStorage.removeItem('portal_account_guid');
  localStorage.removeItem('aim_host_override');
};

/**
 * Sets the stored View Account as Client PAG (if any)
 * Only Internal authenticated users can view other accounts.
 * @param pag The account guid to view
 */
export const setViewingAsPAG = (pag: string): void => {
  // Setting in localStorage so that this value can persist across multiple tabs.
  return localStorage.setItem('viewing_as_pag', pag);
};

/**
 * Gets the stored View Account as Client PAG (if any)
 * Only Internal authenticated users can view other accounts.
 */
export const getViewingAsPAG = (): Maybe<string> => {
  // Setting in localStorage so that this value can persist across multiple tabs.
  return localStorage.getItem('viewing_as_pag');
};

/**
 * Sets the feature branch for internal users to nav back to
 * in lower environments
 * @param branchName branch name string
 */
export const setAimHostOverride = (branchName: string): void => {
  return sessionStorage.setItem('aim_host_override', branchName);
};

/**
 * Gets the stored redirect feature branch name
 */
export const getAimHostOverride = (): Maybe<string> => {
  return sessionStorage.getItem('aim_host_override');
};

/**
 * Sets the Portal Account GUID for the current session
 * @param portalAccountGuid portalAccountGuid string
 */
export const setPortalAccountGuid = (portalAccountGuid: string): void => {
  // Setting in localStorage so that this value can persist across multiple tabs.
  // TODO: Possibly 'viewing_as_pag' and 'portal_account_guid' can be combined.
  //  Need to understand why MultiOrg login would need a separate value.
  return localStorage.setItem('portal_account_guid', portalAccountGuid);
};

/**
 * Get the Portal Account GUID for the current session
 */
export const getPortalAccountGuid = (): Maybe<string> => {
  // Setting in localStorage so that this value can persist across multiple tabs.
  // TODO: Possibly 'viewing_as_pag' and 'portal_account_guid' can be combined.
  //  Need to understand why MultiOrg login would need a separate value.
  return localStorage.getItem('portal_account_guid');
};
