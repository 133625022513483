/* eslint-disable no-param-reassign */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

// Packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

// Components
import { Drawer } from '@react-kite-plus/drawer';
import { KiteProgressIndicator } from '@kite/react-kite';
import { useLocation, useNavigate } from 'react-router-dom';
import { EntAlertView } from '@enterprise/EntAlertView';
import { EntFooter } from '@enterprise/EntFooter';
import Header from '../Header/Header';
import SideNav from '../SideNav/SideNav';
import ModalWrapper from '../ModalWrapper';

// Hooks
import { useScreenWidth } from '../../hooks/useScreenWidth';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useRouteUtils } from '../../hooks/useRouteUtils';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useReportListUpdates } from '../../hooks/useReportListUpdates';
import { useTicketsUpdates } from '../../hooks/useTicketsUpdates';

// Types
import { IAuthUser } from '../../contexts/types/authUser';
import { INavItem, ISiteNotification } from '../../types/common';

// Utils
import { navItems } from './navItems';
import { currentPageTitle } from '../../contexts/utils/analyticsUtils';
import { registrationAnalytics } from '../../domains/Registration/utils/registrationAnalytics';
import { getClientInfo } from '../../utils/generalUtils';

// Styles
import './Layout.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
}

const Layout = ({ children, className = '', loading }: IProps) => {
  const screenWidth = useScreenWidth();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);
  const [showPwdResetModal, setShowPwdResetModal] = useState<boolean>(false);

  const { authUser } = useAuthUser();
  const { checkRoutePermissions } = useRouteUtils();
  const location = useLocation();
  const { trackButtonEvent } = useAnalytics();
  const navigate = useNavigate();

  const { closeBtn } = registrationAnalytics.registerSuccessModal.buttonClicks;

  const {
    resetReportsNotifications,
    completedReportsToNotify,
    reportsNotificationMessage,
  } = useReportListUpdates();

  const {
    ticketsNotifications,
    ticketsNotificationsMessage,
    resetTicketsNotifications,
  } = useTicketsUpdates();

  // Hooks: Feature Toggling Flags

  useEffect(() => {
    const { search, pathname } = location;
    const searchParams = new URLSearchParams(search);
    const clientInfo = getClientInfo();

    if (searchParams.get('registration') === 'success') {
      setShowWelcomeModal(true);
    }

    if (searchParams.get('passwordReset') === 'success') {
      setShowPwdResetModal(true);
    }

    if (clientInfo.os === 'Windows') {
      // Add windows class to body alongisde mountaineer to fix MS Windows specific UI bugs (like very wide scrollbars).
      document.getElementsByTagName('body')[0].classList.add('windows');
    }

    // Additional logic for resetting current session count to 0 when visiting the page.
    if (pathname === '/reports' && completedReportsToNotify > 0) {
      resetReportsNotifications();
    }
    // NOTE: I don't think we need to add the notifications variables and methods here for dependencies since we're resetting the counts.
  }, [location]);

  const getNotificationsDataCallback = useCallback(
    (navItem: INavItem): ISiteNotification => {
      const notificationData: ISiteNotification = navItem.notifications!;
      if (navItem.label.toLowerCase() === 'tickets') {
        notificationData.notificationCount = ticketsNotifications;
        notificationData.notificationMessage = ticketsNotificationsMessage;
      }
      if (navItem.label.toLocaleLowerCase() === 'reports') {
        notificationData.notificationCount = completedReportsToNotify;
        notificationData.notificationMessage = reportsNotificationMessage;
      }
      // Example for adding new notifications in the future:
      // Add bills data from a bills data hook and mapping for bills notifications here here.
      // if (navItem.label.toLocaleLowerCase() === 'bills') {
      //   notificationData.notificationCount = billsNotificationCountExample;
      //   notificationData.notificationMessage = 'example bills message for aria labels';
      // }
      return notificationData;
    },
    [
      ticketsNotifications,
      ticketsNotificationsMessage,
      completedReportsToNotify,
      reportsNotificationMessage,
    ]
  );

  const filteredNavItems = useMemo(
    () =>
      navItems.filter((item) => {
        if (item.notifications?.hasNotifications) {
          const notificationData: ISiteNotification =
            getNotificationsDataCallback(item);
          item.notifications = notificationData;
        }
        if (!item.permissions) {
          return true;
        }

        return checkRoutePermissions(item.permissions);
      }) as INavItem[],
    [checkRoutePermissions, getNotificationsDataCallback]
  );

  const handleFooterLinkClick = useCallback(
    (label: string): void => {
      trackButtonEvent(
        {
          itemClicked: label,
          pageRegion: 'Footer Navigation',
        },
        {
          siteSection: currentPageTitle(),
        }
      );
    },
    [trackButtonEvent]
  );

  const handleFooterLogoClick = useCallback((): void => {
    navigate('/');
  }, [navigate]);

  return (
    <>
      <Header
        user={authUser as IAuthUser}
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
      />

      <ModalWrapper
        canShow={showWelcomeModal}
        onHide={() => setShowWelcomeModal(false)}
        ctaCopy="Close"
        ctaAction={() => {
          setShowWelcomeModal(false);
          trackButtonEvent(closeBtn, {
            siteSection: currentPageTitle(),
          });
        }}
      >
        <EntAlertView
          type="success"
          title="Registration Complete"
          message="You're all set. You can now explore your network anytime, anywhere."
        />
      </ModalWrapper>

      <ModalWrapper
        canShow={showPwdResetModal}
        onHide={() => setShowPwdResetModal(false)}
        ctaCopy="Close"
        ctaAction={() => setShowPwdResetModal(false)}
      >
        <EntAlertView
          type="success"
          title="You Have Reset Your Password"
          message={`We've sent a confirmation to ${authUser?.email}`}
        />
      </ModalWrapper>

      <div
        className={classNames({
          'layout__main-wrapper': true,
          'layout__main-wrapper-internal': authUser?.isInternalUser,
        })}
      >
        {screenWidth > 960 && <SideNav navItems={filteredNavItems} />}
        {screenWidth <= 960 && (
          <Drawer
            className={`nav-drawer${drawerIsOpen ? '--open' : ''}`}
            isOpen={drawerIsOpen}
            disableBackgroundClose={false}
            showCloseButton={false}
            onClose={() => {
              setDrawerIsOpen(false);
            }}
          >
            <SideNav
              navItems={filteredNavItems}
              fill
              drawerIsOpen={drawerIsOpen}
              setDrawerIsOpen={setDrawerIsOpen}
              tabIndex={drawerIsOpen ? 0 : -1}
            />
          </Drawer>
        )}
        <div
          className={classNames({
            'layout__main-content': true,
            'layout__main-content-internal': authUser?.isInternalUser,
          })}
        >
          <main className={`layout__main ${className}`}>
            {loading ? (
              <KiteProgressIndicator id="kp1" className="display-loader" />
            ) : (
              children
            )}
          </main>
          <div className="print-hide">
            <EntFooter
              theme="light"
              onLinkClick={handleFooterLinkClick}
              onLogoClick={handleFooterLogoClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
