/**
 * Analytics SDR for UC Voice Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Unified Communications';
const createReport = 'Create Report';

export const unifiedCommsAnalytics: IAnalyticSdr = {
  ucInventory: {
    siteSection,
    siteSubSection: 'Unified Communications Inventory',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      productFamilyWebexLink: {
        itemClicked: 'UC with Webex',
      },
      productFamilyRingLink: {
        itemClicked: 'UC with RingCentral',
      },
      closePortalDialogBtn: {
        pageRegion: 'Unified Comm Portal Modal',
        itemClicked: 'Close',
      },
      continuePortalDialogBtn: {
        pageRegion: 'Unified Comm Portal Modal',
        itemClicked: 'Continue',
      },
    },
    menuSelections: {
      viewMenu: {
        itemClicked: 'Views',
      },
    },
  },

  ucCallRecords: {
    siteSection,
    siteSubSection: 'Unified Communications Call Detail Records',
    buttonClicks: {
      inOutCallsTabClick: {
        itemClicked: 'Inbound & Outbound Calls',
      },
      tollFreeTabClick: {
        itemClicked: 'Inbound Toll-Free Calls',
      },
      createReportBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: createReport,
      },
      viewFilterBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: 'View & Filter',
      },
      filterCancelBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: 'Cancel',
      },
      filterClearBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: 'Clear Filters',
      },
      filterViewResultsBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: 'View Results',
      },
    },
    menuSelections: {
      leadMenu: {
        pageRegion: 'Inbound & Outbound Calls',
        itemClicked: 'Lead Phone Number',
      },
      tollFreeMenu: {
        pageRegion: 'Toll-Free Calls',
        itemClicked: 'Inbound Toll-Free Number',
      },
      callDirectionMenu: {
        pageRegion: '', // Dynamic based on the Tab selected or No Tabs at all
        itemClicked: 'Call Origin',
      },
      timePeriodMenu: {
        pageRegion: '', // Dynamic based on the Tab selected or No Tabs at all
        itemClicked: 'Time Period',
      },
      addChargesMenu: {
        pageRegion: '', // Dynamic based on the Tab selected or No Tabs at all
        itemClicked: 'Charges',
      },
      callTypeMenu: {
        pageRegion: '', // Dynamic based on the Tab selected or No Tabs at all
        itemClicked: 'Call Type',
      },
    },
  },

  ucCreateReport: {
    siteSection,
    siteSubSection: 'Create Unified Communications Report',
    menuSelections: {
      leadMenu: {
        itemClicked: 'Lead Phone Number',
      },
      tollFreeMenu: {
        itemClicked: 'Toll-Free Number',
      },
    },
  },

  ucReportSuccess: {
    siteSection,
    siteSubSection: 'Create Unified Communications Report - Success',
  },
};
