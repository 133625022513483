/**
 * Analytics SDR for Business Voice Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */
import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Business Voice';

export const businessVoiceAnalytics: IAnalyticSdr = {
  businessVoiceInventory: {
    siteSection,
    siteSubSection: 'Business Voice Inventory',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
    },
    menuSelections: {
      viewMenu: {
        itemClicked: 'Views',
      },
    },
  },
};
