import { AppEnv } from '../../appConfig/appConfig.types';
import { FeatureToggleName, IFeatureToggles } from '../featureToggles.types';
import { featureTogglesDefault } from './default';

/**
 * Demo and Demo-Stage can have features turned OFF that are otherwise turned ON in default, so if the product requirement
 * is to turn a feature ON id Prod, but OFF in Demo, you can override the Prod default in this file.
 *
 * For more info, see https://chalk.charter.com/display/ERSPM/FE+Configs
 */
export const featureTogglesDemo: IFeatureToggles = {
  environment: AppEnv.Demo,
  toggles: [
    ...featureTogglesDefault.toggles,
    {
      name: FeatureToggleName.BusinessInternet,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.BusinessInternetDetails,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.BusinessInternetEquipmentStatus,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.BusinessInternetEquipmentRestart,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.BusinessVoice,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.CloudSecurity,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.DesignKiteDropDown, // TODO: Is this supposed to be off in Demo??
      isEnabled: false,
    },
    {
      name: FeatureToggleName.MNEShowSSOSiteAndConferenceManagerPortal,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.MiscOrganizationPageLayoutUpdate,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.SecureInternet,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.VoiceDidOverflow,
      isEnabled: false,
    },
    {
      name: FeatureToggleName.TicketDetailsSurfaceThreeContacts,
      isEnabled: false,
    },
  ],
};
