import { useContext } from 'react';
import { AppConfigContext } from '../contexts/appConfigContext';

/**
 * Hook to get application config
 */
export const useAppConfig = () => {
  const { appConfig } = useContext(AppConfigContext);
  return appConfig;
};
