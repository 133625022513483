import { AppEnv } from '../../appConfig/appConfig.types';
import { FeatureToggleName, IFeatureToggles } from '../featureToggles.types';
import { featureTogglesDefault } from './default';

/**
 * Specific hard-coded QA overrides to ./default.ts that requires a code push to change.
 * Alternatively, if you want to be able to change these are runtime, put them in QA's TDCS config instead.
 *
 * For more info, see https://chalk.charter.com/display/ERSPM/FE+Configs
 */
export const featureTogglesQA: IFeatureToggles = {
  environment: AppEnv.QA,
  toggles: [
    ...featureTogglesDefault.toggles,
    {
      name: FeatureToggleName.ArchitectureDisableReCaptcha,
      isEnabled: true,
    },
  ],
};
