import { featureTogglesDefault } from '../../config/featureToggles/envs/default';
import { ITdcsConfig } from '../../config/tdcs/tdcs.types';

// Default data for init of context
const tdcsDefault: ITdcsConfig = {
  type: 'client',
  version: 'failsafe-backup',
  loginBanners: [],
  analytics: {
    adobeEnabled: true,
  },
  logger: {
    level: 'info',
  },
  supportNumbers: {
    selfReg: '(844) 308-0013',
  },
  // Use what's baked into the app for Prod (defaults)
  featureToggles: featureTogglesDefault.toggles,
};

export default tdcsDefault;
