import { IAuthUserContext } from '../types/authUser';

// Default data for init of context
const authUserContextDefault: IAuthUserContext = {
  authUser: null,
  isError: false,
  portalAccountGUID: '',
  organizations: null,
  isInternalView: false,
  viewingAsOrg: null,
  hasCapability: () => true,
  hasOrgFeature: () => true,
  hasOrgService: () => true,
  setUserOrgId: (value: string) => {},
};

export default authUserContextDefault;
