/**
 * Analytics SDR for ENE Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Enterprise Network Edge';

export const eneAnalytics: IAnalyticSdr = {
  eneInventory: {
    siteSection,
    siteSubSection: 'Enterprise Network Edge Inventory',
    buttonClicks: {
      edgePortalLink: {
        itemClicked: 'Enterprise Network Edge Portal', // was 'Enterprise Network Edge Portal Link'
      },
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      viewFilterBtn: {
        itemClicked: 'View & Filter',
      },
      cardViewBtn: {
        itemClicked: 'Card View',
      },
      listViewBtn: {
        itemClicked: 'List View',
      },
      filterViewResultsBtn: {
        itemClicked: 'View Results',
      },
      filterCancelBtn: {
        itemClicked: 'Cancel',
      },
      viewDetailsBtn: {
        itemClicked: 'View Details',
      },
    },
    menuSelections: {
      servicesMenu: {
        itemClicked: 'Services',
      },
    },
  },
  eneDetails: {
    siteSection,
    siteSubSection: 'Enterprise Network Edge Details',
    buttonClicks: {
      edgePortalLink: {
        itemClicked: 'Enterprise Network Edge Portal', // was 'Enterprise Network Edge Portal Link'
      },
      circuitIDBtn: {
        itemClicked: 'ENE Circuit',
      },
    },
  },
};
