/* eslint-disable no-empty-function */
/* eslint-disable react/jsx-no-constructed-context-values */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import React, { createContext, useState } from 'react';
import { IViewBillAccount } from '../types/billpay.types';

interface IProps {
  children: React.ReactNode;
}

export interface IPaperlessBillingState {
  billAccount: IViewBillAccount | null | undefined;
}

interface IPaperlessBillingContext {
  paperlessBillingState: IPaperlessBillingState | null;
  setPaperlessBillingState: (
    value: React.SetStateAction<IPaperlessBillingState | null>
  ) => void;
}

export const defaultIPaperlessBillingContextValue: IPaperlessBillingContext = {
  paperlessBillingState: null,
  setPaperlessBillingState: (
    value: React.SetStateAction<IPaperlessBillingState | null>
  ) => {},
};

export const PaperlessBillingContext = createContext<IPaperlessBillingContext>(
  defaultIPaperlessBillingContextValue
);

export const PaperlessBillingContextProvider = ({ children }: IProps) => {
  const [paperlessBillingState, setPaperlessBillingState] =
    useState<IPaperlessBillingState | null>(
      defaultIPaperlessBillingContextValue.paperlessBillingState
    );

  return (
    <PaperlessBillingContext.Provider
      value={{
        paperlessBillingState,
        setPaperlessBillingState,
      }}
    >
      {children}
    </PaperlessBillingContext.Provider>
  );
};
