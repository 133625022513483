/* eslint-disable react/no-array-index-key */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

// Packages
import { KiteCard, KiteGrid, KiteGridCell } from '@kite/react-kite';
import React from 'react';
import isArray from 'lodash/isArray';

// Types
import { ICloudSecurityInvService } from '../../../../types/types.generated';

// Styles
import './CloudSecurityInventoryCard.scss';

export interface ICloudSecurityInventoryCardProps {
  /** Data for cloud security locations */
  cloudSecurityData: ICloudSecurityInvService;
}

const CloudSecurityInventoryCard = ({
  cloudSecurityData,
}: ICloudSecurityInventoryCardProps) => {
  if (!isArray(cloudSecurityData.products)) {
    return null;
  }
  return (
    <KiteCard className="cloud-security-inventory-card">
      <h2 className="cloud-security-inventory-card__title">
        {cloudSecurityData.title}
      </h2>
      <KiteGrid>
        {cloudSecurityData.products.map((product, index) => (
          <KiteGridCell col={4} key={`${product.name}${index}`}>
            <KiteGrid>
              <KiteGridCell
                col={4}
                className="cloud-security-inventory-card__cell"
              >
                {product.name}
              </KiteGridCell>
              <KiteGridCell
                col={4}
                className="cloud-security-inventory-card__cell"
              >
                {' '}
                <dl className="cloud-security-inventory-card__service-id">
                  <dt className="cloud-security-inventory-card__service-id-label">
                    Service ID:
                  </dt>
                  <dd className="cloud-security-inventory-card__service-id-value">
                    {product.serviceId}
                  </dd>
                </dl>
              </KiteGridCell>
            </KiteGrid>
          </KiteGridCell>
        ))}
      </KiteGrid>
    </KiteCard>
  );
};

export default CloudSecurityInventoryCard;
