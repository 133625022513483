// Packages
import React, { useCallback } from 'react';

// Hooks
import { KiteGrid, KiteGridCell } from '@kite/react-kite';
import { useGetCloudSecurityInventoryQuery } from '../../hooks/useRequest.generated';
import { useAuthUser } from '../../hooks/useAuthUser';

// Components
import Layout from '../../components/Layout/Layout';
import CloudSecurityInventoryCard from './components/CloudSecurityInventoryCard/CloudSecurityInventoryCard';
import NoDataMessage from '../../components/NoDataMessage';

// types
import { noDataMessageTitle, noDataMessageMessage } from '../../utils/copyText';

// Utils
import { renderApiContent } from '../../utils/componentUtils';
import { cloudSecurityAnalytics } from './types/cloudSecurityTypes';

// Types
import { SSOLinksObject } from '../../types/sso';

// Styles
import './CloudSecurityInventory.scss';
import useSsoUrl from '../../hooks/useSsoUrl';
import { useAppConfig } from '../../hooks/useAppConfig';

const CloudSecurityInventory = () => {
  const { portalAccountGUID } = useAuthUser();
  const { cloudSecurityInventory } = cloudSecurityAnalytics;
  const {
    api: { sso: ssoHost },
  } = useAppConfig();
  const { hasSsoLink, ssoLinkComponent: cloudSecuritySsoLink } = useSsoUrl(
    SSOLinksObject.CloudSecurity.Meraki,
    ssoHost,
    cloudSecurityInventory
  );

  // Data Fetching
  const { isSuccess, data, isError, isLoading } =
    useGetCloudSecurityInventoryQuery({
      portalAccountGUID,
    });

  const cloudSecurityData =
    data?.user?.organization?.services?.cloudSecurity?.inventory?.services;

  const hasData = !!cloudSecurityData?.products?.length;
  const pageContent = renderApiContent({
    isSuccess,
    isError,
    hasData,
    onSuccessContent: () =>
      hasData && (
        <CloudSecurityInventoryCard cloudSecurityData={cloudSecurityData} />
      ),
    onNoDataContent: useCallback(
      () => (
        <KiteGrid>
          <KiteGridCell col={12}>
            <NoDataMessage
              title={noDataMessageTitle}
              message={noDataMessageMessage}
            />
          </KiteGridCell>
        </KiteGrid>
      ),
      []
    ),
  });

  return (
    <Layout loading={isLoading}>
      <KiteGrid className="cloud-security">
        <KiteGridCell col={10}>
          <h1>Cloud Security Inventory</h1>
          <p className="cloud-security__subtitle">
            A list of Cloud Security subscriptions.
          </p>
        </KiteGridCell>
        {hasSsoLink && (
          <KiteGridCell col={12}>
            <div className="cloud-security__header">{cloudSecuritySsoLink}</div>
          </KiteGridCell>
        )}
        <KiteGridCell col={12}>{pageContent}</KiteGridCell>
      </KiteGrid>
    </Layout>
  );
};

export default CloudSecurityInventory;
