import { graphqlFetcher } from '../services/graphqlFetcher';
import { useAppConfig } from './useAppConfig';
import { useAuthSession } from './useAuthSession';

/**
 * Add Queries and mutations here that need to use the anonymous auth token
 */
const anonymousQueries = [
  'aimForgotPasswordVerifyUsername',
  'aimForgotPasswordPasswordReset',
  'aimForgotPasswordValidatePasswordResetToken',
  'aimForgotPasswordUpdatePassword',
  'aimSelfRegistrationValidateUserProfileByBan',
  'SelfRegistrationValidateUserProfileByCircuitInput',
  'aimSelfRegistrationUserProfileInformation',
  'aimSendRegistrationEmail',
  'aimValidateActivationToken',
  'aimVerifyUserActivationInfo',
  'aimCreatePasswordAndActivateUser',
  'aimSelfRegistrationCreateUser',
  'validateRecaptcha',
];

/**
 * GraphQL Fetcher hooks that's injected into all the graphql Generated Request hooks
 * via `src/graphql/codegen.yml` upon `yarn generate`
 */
export function useGraphqlFetcher<TData, TVariables>(query: string) {
  const authSession = useAuthSession();
  const { legacyToken, getAnonymousToken } = authSession;
  let { authToken } = authSession;

  const {
    api,
    auth: { targetBg },
  } = useAppConfig();

  return async (variables?: TVariables): Promise<TData> => {
    // Some requests require an Anonymous Token, check if this is such one
    const requiresAnonymousToken = anonymousQueries.some((x) =>
      query.includes(x)
    );

    if (requiresAnonymousToken) {
      authToken = await getAnonymousToken();
    }

    const isAIMEndpoint =
      query.includes('query aim') || query.includes('mutation aim');

    const endpoint = requiresAnonymousToken ? api.anonymous : api.graphql;

    const additionalHeaders = new Map<string, string>();
    if (legacyToken && (api.useDirectHost || api.useAimDirectHost)) {
      if (isAIMEndpoint && api.useAimDirectHost) {
        additionalHeaders.set('X-DOMAIN-SESSION-IDENTITY', legacyToken);
      } else if (!isAIMEndpoint && api.useDirectHost) {
        additionalHeaders.set('Authorization', legacyToken);
      }
    }

    return graphqlFetcher({
      endpoint,
      accessToken: authToken ?? '',
      targetBg,
      query,
      variables,
      additionalHeaders: Object.fromEntries(additionalHeaders),
    });
  };
}
