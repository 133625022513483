// Packages
import React, { useState } from 'react';

// Components
import { EntAlertView } from '@enterprise/EntAlertView';
import ModalWrapper from '../ModalWrapper';

// Utils
import { genericErrorMessage } from '../../utils/copyText';

/** Display a generic error modal */
const ModalGenericError = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <ModalWrapper
      canShow={show}
      onHide={handleClose}
      ctaCopy="Close"
      ctaAction={handleClose}
    >
      <EntAlertView
        type="error"
        title={genericErrorMessage}
        message="Please try again"
      />
    </ModalWrapper>
  );
};

export default ModalGenericError;
