/**
 * Analytics SDR for Voice Trunking Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Voice Trunking';
const voiceDetailsSection = 'Voice Trunking Account Details';
const callGuardSettingsEdit = 'Call Guard Settings Edit';
const createReport = 'Create Report';

export const voiceTrunkingAnalytics: IAnalyticSdr = {
  voiceInventory: {
    siteSection,
    siteSubSection: 'Voice Trunking Inventory',
    buttonClicks: {
      downloadCsvByAccBtn: {
        itemClicked: 'Download CSV by Account Number',
      },
      downloadCsvByCirBtn: {
        itemClicked: 'Download CSV by Circuit ID',
      },
    },
  },

  voiceDetails: {
    siteSection,
    siteSubSection: voiceDetailsSection,
    buttonClicks: {
      createReportBtn: {
        itemClicked: createReport,
      },
      trunkEditBtn: {
        itemClicked: 'Trunk Overflow Edit',
      },
      alternateEditBtn: {
        itemClicked: 'Alternate Routing Edit',
      },
      downloadDidBtn: {
        itemClicked: 'Download DID Toll Free Report',
      },
      backBtn: {
        itemClicked: 'Back',
      },
      callDetailsRecordsBtn: {
        itemClicked: 'Call Detail Records',
      },
      planDetailsBtn: {
        itemClicked: 'Plan Details',
      },
      viewChargesBtn: {
        itemClicked: 'View Charges',
      },
      viewPackageDetailsBtn: {
        itemClicked: 'View Package Details',
      },
      manageCallGuardBtn: {
        itemClicked: 'Manage Call Guard',
      },
      manageDidsBtn: {
        itemClicked: ' Manage DIDs',
      },
    },
  },

  voiceCallRecords: {
    siteSection,
    siteSubSection: voiceDetailsSection,
    siteSubSubSection: 'Voice Trunking Call Detail Records',
    buttonClicks: {
      trunkTabClick: {
        itemClicked: 'Trunk',
      },
      tollFreeTabClick: {
        itemClicked: 'Toll-Free',
      },
      createReportBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: createReport,
      },
      viewFilterBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: 'View & Filter',
      },
      filterCancelBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: 'Cancel',
      },
      filterClearBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: 'Clear Filters',
      },
      filterViewResultsBtn: {
        pageRegion: '', // Dynamic based on the Tab selected
        itemClicked: 'View Results',
      },
    },
    menuSelections: {
      trunkMenu: {
        pageRegion: 'Trunk',
        itemClicked: 'Trunk Group Number',
      },
      tollFreeMenu: {
        pageRegion: 'Toll-Free',
        itemClicked: 'Toll-Free Number',
      },
      callDirectionMenu: {
        pageRegion: '', // Dynamic based on the Tab selected or No Tabs at all
        itemClicked: 'Call Origin',
      },
      timePeriodMenu: {
        pageRegion: '', // Dynamic based on the Tab selected or No Tabs at all
        itemClicked: 'Time Period',
      },
      addChargesMenu: {
        pageRegion: '', // Dynamic based on the Tab selected or No Tabs at all
        itemClicked: 'Charges',
      },
      callTypeMenu: {
        pageRegion: '', // Dynamic based on the Tab selected or No Tabs at all
        itemClicked: 'Call Type',
      },
    },
  },

  voicePlanDetails: {
    siteSection,
    siteSubSection: voiceDetailsSection,
    siteSubSubSection: 'Voice Plan Details',
  },

  voiceManageDids: {
    siteSection,
    siteSubSection: voiceDetailsSection,
    siteSubSubSection: 'Voice DID Overflow',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
    },
    menuSelections: {
      statusMenu: {
        itemClicked: 'Status',
      },
    },
  },

  voiceEditAltRouting: {
    siteSection,
    siteSubSection: voiceDetailsSection,
    siteSubSubSection: 'Edit Alternate Routing Service',
    buttonClicks: {
      cancelBtn: {
        itemClicked: 'Cancel',
      },
      saveBtn: {
        itemClicked: 'Save',
      },
      backBtn: {
        itemClicked: 'Back',
      },
    },
  },

  voiceEditTrunkOverflow: {
    siteSection,
    siteSubSection: voiceDetailsSection,
    siteSubSubSection: 'Edit Trunk Overflow Service',
    buttonClicks: {
      cancelBtn: {
        itemClicked: 'Cancel',
      },
      saveBtn: {
        itemClicked: 'Save',
      },
      backBtn: {
        itemClicked: 'Back',
      },
    },
  },

  voiceSharedMou: {
    siteSection,
    siteSubSection: voiceDetailsSection,
    siteSubSubSection: 'Shared Minutes of Use Package Details',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
    },
  },

  voiceCallGuardByLocation: {
    siteSection,
    siteSubSection: voiceDetailsSection,
    siteSubSubSection: 'Voice Call Guard by Location',
    buttonClicks: {
      learnAboutBtn: {
        itemClicked: 'Learn About Robocalling',
      },
      downloadCsvBtn: {
        pageRegion: 'DID Numbers',
        itemClicked: 'Download CSV',
      },
      confirmChangesBtn: {
        pageRegion: 'Call Guard Settings',
        itemClicked: 'Confirm Changes',
      },
      cancelBtn: {
        pageRegion: 'Call Guard Settings',
        itemClicked: 'Cancel',
      },
      createReportBtn: {
        pageRegion: 'Call Guard Settings',
        itemClicked: 'Create Report',
      },
      editCallGuardSettingsBtn: {
        pageRegion: 'Call Guard Settings',
        itemClicked: 'Edit Call Guard Settings',
      },
      viewFilterBtn: {
        pageRegion: 'Call Guard Settings',
        itemClicked: 'View & Filter',
      },
      filterViewResultsBtn: {
        pageRegion: 'Call Guard Settings',
        itemClicked: 'View Results',
      },
    },
    menuSelections: {
      trunkGroupNumMenu: {
        pageRegion: 'DID Numbers',
        itemClicked: 'Trunk Group Number',
      },
    },
  },

  voiceCallGuardSettingsEdit: {
    siteSection,
    siteSubSection: callGuardSettingsEdit,
    buttonClicks: {
      cancelBtn: {
        itemClicked: 'Cancel',
      },
    },
  },

  voiceCallGuardSettingsConfirm: {
    siteSection,
    siteSubSection: callGuardSettingsEdit,
    siteSubSubSection: `${callGuardSettingsEdit} - Confirm`,
    buttonClicks: {
      cancelBtn: {
        itemClicked: 'Cancel',
      },
    },
  },

  voiceCallGuardSettingsSuccess: {
    siteSection,
    siteSubSection: callGuardSettingsEdit,
    siteSubSubSection: `${callGuardSettingsEdit} - Success`,
  },

  voiceCallGuardEnterprise: {
    siteSection,
    siteSubSection: 'Voice Call Guard Enterprise',
    buttonClicks: {
      learnAboutBtn: {
        itemClicked: 'Learn About Robocalling',
      },
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      confirmChangesBtn: {
        pageRegion: 'Call Guard Settings',
        itemClicked: 'Confirm Changes',
      },
      cancelBtn: {
        pageRegion: 'Call Guard Settings',
        itemClicked: 'Cancel',
      },
    },
  },

  voiceCreateReport: {
    siteSection,
    siteSubSection: 'Create Voice CDR Report',
    menuSelections: {
      leadMenu: {
        itemClicked: 'Trunk Group Number',
      },
      tollFreeMenu: {
        itemClicked: 'Toll-Free Number',
      },
    },
  },

  voiceCreateReportType: {
    siteSection,
    siteSubSection: createReport,
    siteSubSubSection: 'Type of Voice Report',
  },

  voiceCallRecordsReport: {
    siteSection,
    siteSubSection: createReport,
    siteSubSubSection: 'Voice Call Detail Records Report',
  },

  voiceMetricsReport: {
    siteSection,
    siteSubSection: createReport,
    siteSubSubSection: 'Voice Call Metrics Report',
  },

  voiceCallReportSuccess: {
    siteSection,
    siteSubSection: createReport,
    siteSubSubSection: 'Voice Call Report - Success',
  },

  voiceCallGuardReport: {
    siteSection,
    siteSubSection: createReport,
    siteSubSubSection: 'Call Guard Call Blocking Report',
  },

  voiceCallGuardReportSuccess: {
    siteSection,
    siteSubSection: createReport,
    siteSubSubSection: 'Voice Blocked Call Report - Success',
  },
};
