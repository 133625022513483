// Packages
import { KiteCard, KiteGrid, KiteGridCell } from '@kite/react-kite';
import React from 'react';
import isArray from 'lodash/isArray';

// Types
import { ISecureAccessInvService } from '../../../../types/types.generated';

// Styles
import './SecureAccessInventoryCard.scss';

export interface ISecureAccessInventoryCardProps {
  /** Data for Secure Access locations */
  secureAccessData: ISecureAccessInvService;
}

const SecureAccessInventoryCard = ({
  secureAccessData,
}: ISecureAccessInventoryCardProps) => {
  if (!isArray(secureAccessData.products)) {
    return null;
  }
  return (
    <KiteCard className="secure-access-inventory-card">
      <h2 className="secure-access-inventory-card__title">
        {secureAccessData.title}
      </h2>
      <KiteGrid>
        {secureAccessData.products.map((product) => (
          <KiteGridCell col={4} key={product.serviceId}>
            <KiteGridCell
              col={4}
              className="secure-access-inventory-card__cell"
            >
              {product.name}
            </KiteGridCell>
            <KiteGridCell
              col={4}
              className="secure-access-inventory-card__cell"
            >
              <dl className="secure-access-inventory-card__service-id">
                <dt className="secure-access-inventory-card__service-id-label">
                  Service ID:
                </dt>
                <dd className="secure-access-inventory-card__service-id-value">
                  {product.serviceId}
                </dd>
              </dl>
            </KiteGridCell>
          </KiteGridCell>
        ))}
      </KiteGrid>
    </KiteCard>
  );
};

export default SecureAccessInventoryCard;
