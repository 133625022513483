// Packages
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import SimpleBar from 'simplebar-react'; // don't remove, required in docs to be importing like this
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import minMax from 'dayjs/plugin/minMax';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// Components
import App from './App';

// Contexts
import { AnalyticsContextProvider } from './contexts/analyticsContext';
import { AuthUserContextProvider } from './contexts/authUserContext';
import { AuthSessionContextProvider } from './contexts/authSessionContext';
import { TdcsConfigContextProvider } from './contexts/tdcsConfigContext';
import { FeatureTogglesContextProvider } from './contexts/featureTogglesContext';
import { AppConfigContextProvider } from './contexts/appConfigContext';
import { ReportsContextProvider } from './contexts/reportsContext';
import { TicketsContextProvider } from './contexts/ticketsContext';
import { SubscriptionContextProvider } from './contexts/subscriptionContext';

// Styles
import '@kite/react-kite/dist/react-kite.css';
import '@kite/theme-mountaineer/dist/css/mountaineer.css';
import './styles/index.scss';
import 'simplebar-react/dist/simplebar.min.css';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(minMax);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppConfigContextProvider>
          <TdcsConfigContextProvider>
            <FeatureTogglesContextProvider>
              <AuthSessionContextProvider>
                <AuthUserContextProvider>
                  <AnalyticsContextProvider>
                    <ReportsContextProvider>
                      <TicketsContextProvider>
                        <SubscriptionContextProvider>
                          <App />
                          <ReactQueryDevtools
                            initialIsOpen={false}
                            position="bottom-right"
                          />
                        </SubscriptionContextProvider>
                      </TicketsContextProvider>
                    </ReportsContextProvider>
                  </AnalyticsContextProvider>
                </AuthUserContextProvider>
              </AuthSessionContextProvider>
            </FeatureTogglesContextProvider>
          </TdcsConfigContextProvider>
        </AppConfigContextProvider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
