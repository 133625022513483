/**
 * Analytics SDR for Wavelength Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

export const wavelengthAnalytics: IAnalyticSdr = {
  wavelengthInventory: {
    siteSection: 'Wavelength',
    siteSubSection: 'Wavelength Inventory',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
    },
  },
};
