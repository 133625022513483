/**
 * The events available for tracking
 */
export enum AdobeAnalyticEvent {
  PageView = 'pageView',
  InternalSearch = 'internalSearch',
  CTAClick = 'ctaClick',
  MenuSelection = 'menuSelection',
}

/**
 * Result of a event operation (optional).
 * Accepted values: success, failed, failed-val, failed-api
 */
export enum AdobeAnalyticEventOpResult {
  None = '', // provide no event result info
  Success = 'success', // event was a success
  Failed = 'failed', // event failed (general)
  FailedValidation = 'failed-validation', // event failed validation
  FailedServer = 'failed-server', // event failed on the Http request to server
}

export interface IAdobeAnalyticEventInfo {
  pageRegion: string; // evar6
  itemClicked: string; // evar7
  menuSelection: string; // evar8
  searchPhrase: string; // evar2
  opResult: AdobeAnalyticEventOpResult; // (evar18) Result of a event operation (optional).
  opResultSummary: string; // (evar19) Result summary of a event operation (optional, 250 char max).
}

/**
 * The Digital Data object that will be passed to
 * Adobe Launch.
 * All things must be strings.
 */
export interface IAdobeAnalyticDigitalData {
  page: {
    pageInfo: {
      siteSection: string; // evar3
      siteSubSection: string; // evar4
      siteSubSubSection: string; // evar5
      sysEnv: string; // evar9
      appName: string; // evar16
    };
  };

  event: {
    eventInfo: IAdobeAnalyticEventInfo;
  };

  user: {
    userInfo: {
      organizationId: string; // evar10
      organizationName: string; // evar11
      isInternal: string; // evar13
      isPID: string; // evar12
      isPremier: string; // evar17
      userId: number | string; // evar14
    };
  };
}
