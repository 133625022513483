// Packages
import React, { useState, useRef, useLayoutEffect } from 'react';

// Hooks

// Components
import { NavLink, useLocation } from 'react-router-dom';
import { KiteBadge, KiteIcon, KiteTooltip } from '@kite/react-kite';
import { EntSeIcon } from '@enterprise/EntSeIcon';

// Hooks
import { useAnalytics } from '../../hooks/useAnalytics';

// Utils
import { Maybe } from '../../types/types.generated';
import { currentPageTitle } from '../../contexts/utils/analyticsUtils';

// Styles
import './NavItem.scss';
import { ISiteNotification } from '../../types/common';
import classNames from 'classnames';

export interface INavItemProps {
  /** Refers to the name of the NavItem & text displayed */
  label: string;
  /** Refers to the URL each NavItem is linked to */
  path: string;
  /** Refers to the icon that will be displayed on the NavItem */
  icon: string;
  /** Renders a border above a NavItem to help separate items by groups */
  topBorder?: boolean | undefined;
  /** Renders the label text depending on whether the parent component `SideNav` is expanded or not. Will also determine whether to render tooltips when the item is hovered over. */
  isOpen?: boolean | null;
  tabIndex?: number;
  notificationBadgeData?: ISiteNotification;
}

/** Navigation links that can be collapsed w/ tooltips */

const NavItem = ({
  label,
  path,
  icon,
  topBorder,
  isOpen,
  tabIndex,
  notificationBadgeData,
}: INavItemProps) => {
  const navLinkRef = useRef<Maybe<HTMLAnchorElement>>(null);

  const { pathname } = useLocation();
  const { trackButtonEvent } = useAnalytics();

  // Not the best performance due to multiple renders, but simple solution for now. Please advice or use componentDidMount
  useLayoutEffect(() => {
    if (navLinkRef.current?.className.includes('nav-item--active')) {
      navLinkRef.current.scrollIntoView({ block: 'center' });
    }
  }, []);

  const isActiveRoute = pathname.includes(path.replace('inventory', ''));

  const addBadge = (
    renderedIcon: React.ReactElement,
    notificationAriaLabel?: string
  ) => {
    return (
      <KiteBadge
        small
        assistiveText={notificationAriaLabel}
        xPos="right"
        yPos="top"
      >
        {renderedIcon}
      </KiteBadge>
    );
  };

  const renderIcon = (
    iconName: string,
    notificationData?: ISiteNotification
  ) => {
    let renderedIcon = <KiteIcon icon={iconName} size="24px" />;

    if (!iconName.startsWith('ki')) {
      renderedIcon = <EntSeIcon icon={iconName} />;
    }

    // NOTE: notificationData?.notificationCount is implicit. If the number is 0 (or is undefined, null) it will not render a badge.
    if (
      notificationData &&
      notificationData?.hasNotifications &&
      notificationData?.notificationCount
    ) {
      renderedIcon = addBadge(
        renderedIcon,
        notificationData.notificationMessage
      );
    }

    return renderedIcon;
  };

  const navLink = (
    <NavLink
      ref={navLinkRef}
      to={path}
      className={() =>
        isActiveRoute ? 'nav-item__link nav-item--active' : 'nav-item__link'
      }
      onClick={() =>
        trackButtonEvent(
          {
            itemClicked: label,
          },
          {
            siteSection: currentPageTitle(),
          }
        )
      }
      tabIndex={tabIndex}
    >
      <span className="nav-item-content">
        {renderIcon(icon, notificationBadgeData!)}
        {isOpen && <span className="nav-item-label">{label}</span>}
      </span>
    </NavLink>
  );

  return (
    <li
      key={path}
      className={classNames({
        'nav-item': true,
        'nav-item__hr': topBorder,
      })}
    >
      {!isOpen && (
        <KiteTooltip direction="right" id={path} text={label} type="icon">
          {navLink}
        </KiteTooltip>
      )}
      {isOpen && navLink}
    </li>
  );
};

export default NavItem;
