/**
 * Use this file to store text re-used quite often across all domains.
 */

export const genericErrorMessage = `We're sorry, something didn't work quite right.`;

export const noSearchResults = 'No results match this search.';
export const noDataPresent = 'No data present for this account.';
export const noDataAvailable = "We're sorry, data is unavailable.";

export const noMetricsExists =
  'Metrics for this service do not exist, as this service type doesn’t support them.';
export const metricsNotEnoughData =
  'Not enough data to generate utilization metrics.';

export const type3CircuitsNoDataMessage =
  "This location contains a third-party circuit outside of Spectrum's network, so metrics are not available.";
export const type2CircuitMessage =
  'Service to this location relies on third-party circuits provided by a company outside of Spectrum Enterprise. Third-party circuits are connected to existing Spectrum Enterprise circuits to complete your network.';
export const incompleteRequest =
  "We're sorry, we couldn't complete your request.";
export const serviceAvailabilityUnavailable =
  "We're sorry, service availability is temporarily unavailable.  Please try again later.";
export const loadingDataMessage =
  "We're collecting and sorting your data, it will appear momentarily.";
export const trafficByPortDataRateMessage =
  'Avg is the average traffic over the past 90 days, not including days with 0 Mb.';
export const trafficByPortDataVolumeMessage =
  'Min is the lowest daily throughput over the past 90 days, not including days with 0 Mb. Max is the highest daily throughput over the past 90 days. Total is all traffic over the past 90 days.';
export const noDataMessageTitle = `Data Isn't Available at the Moment`;
export const noDataMessageMessage = `We're sorry. Please check back again soon.`;

export const formPleaseCorrectTheFollowingErrors =
  'Please correct the following errors.';
