/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

// Packages
import React, { useState, useRef } from 'react';
import classNames from 'classnames';

// Hooks
import { KiteIcon, KiteButton } from '@kite/react-kite';
import { NavLink } from 'react-router-dom';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useRouteUtils } from '../../hooks/useRouteUtils';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useAuthSession } from '../../hooks/useAuthSession';

// Components

// Utils
import { parseInitials } from '../../utils/generalUtils';
import {
  locationNamesRoutePerm,
  userRoutePerm,
} from '../Routes/routePermissions';
import { currentPageTitle } from '../../contexts/utils/analyticsUtils';
import { settingsAnalytics } from '../../domains/Settings/types/settingsAnalytics';

// Styles
import './UserInfo.scss';

export interface IUserInfoProps {
  /** Changes styling depending on if the component is being used within the SideNav */
  isSideNav?: boolean;
  /** Closes the navigation bar when an option is selected on mobile view */
  setDrawerIsOpen?: (boolean: boolean) => void;
}

/** A button containing information about the user that also displays a dropdown menu with links when clicked */

const UserInfo = ({ isSideNav, setDrawerIsOpen }: IUserInfoProps) => {
  const { authUser } = useAuthUser();
  const { signOut } = useAuthSession();
  const { checkRoutePermissions } = useRouteUtils();
  const userInfoRef = useRef(null);
  const [displayUserOptions, setDisplayUserOptions] = useState(false);
  const username = `${authUser?.firstName || ''} ${authUser?.lastName || ''}`;
  const company = authUser?.organization?.name;

  const { trackButtonEvent } = useAnalytics();
  const { signOutBtn } = settingsAnalytics.settingsLogout.buttonClicks!;

  const {
    viewAccountBtn,
    viewUsersBtn,
    viewEventLogsBtn,
    manageLocationNamesBtn,
  } = settingsAnalytics.settingsCompany.buttonClicks!;

  const { viewProfileBtn } = settingsAnalytics.settingsUser.buttonClicks!;

  useOutsideClick(userInfoRef, () => {
    if (displayUserOptions) {
      setDisplayUserOptions(false);
    }
  });

  const handleClick = () => {
    if (setDrawerIsOpen) {
      setDrawerIsOpen(false);
    }
    setDisplayUserOptions(true);
  };

  const companyNavItems = [
    {
      label: 'View Account',
      path: '/settings/account',
      analyticsLabel: viewAccountBtn,
    },
    {
      label: 'View Users',
      permissions: userRoutePerm,
      path: '/settings/users',
      analyticsLabel: viewUsersBtn,
    },
    {
      label: 'View Event Logs',
      path: '/event-logs',
      analyticsLabel: viewEventLogsBtn,
    },
    {
      label: 'Manage Location Names',
      permissions: locationNamesRoutePerm,
      path: '/settings/location-names',
      analyticsLabel: manageLocationNamesBtn,
    },
  ];

  const userNavItems = [
    {
      label: 'View Profile',
      path: '/settings/profile',
      permissions: null,
      analyticsLabel: viewProfileBtn,
    },
  ];

  const renderCompanyNavItems = companyNavItems
    .filter((navItem) => checkRoutePermissions(navItem.permissions))
    .map((navItem) => {
      const { label, path, analyticsLabel } = navItem;
      return (
        <li key={path} onClick={handleClick}>
          <NavLink
            to={path}
            tabIndex={displayUserOptions ? 0 : -1}
            onClick={() =>
              trackButtonEvent(analyticsLabel, {
                siteSection: currentPageTitle(),
              })
            }
          >
            <span tabIndex={-1}>{label}</span>
          </NavLink>
        </li>
      );
    });

  const renderUserNavItems = userNavItems
    .filter((navItem) => checkRoutePermissions(navItem.permissions))
    .map((navItem) => {
      const { label, path, analyticsLabel } = navItem;
      return (
        <li key={path} onClick={handleClick}>
          <NavLink
            to={path}
            tabIndex={displayUserOptions ? 0 : -1}
            onClick={() =>
              trackButtonEvent(analyticsLabel, {
                siteSection: currentPageTitle(),
              })
            }
          >
            <span tabIndex={-1}>{label}</span>
          </NavLink>
        </li>
      );
    });

  return (
    <div ref={userInfoRef}>
      <button
        className={classNames({
          'user-info': true,
          'user-info--sidenav': isSideNav,
        })}
        onClick={() => setDisplayUserOptions(!displayUserOptions)}
        aria-expanded={displayUserOptions}
      >
        <span className="user-info__initials">{parseInitials(username)}</span>
        <span className="user-info__text">
          <span className="user-info__text-name">
            {username}
            {!isSideNav && (
              <KiteIcon
                size="sm"
                icon={`ki-chevron-${displayUserOptions ? 'up' : 'down'}`}
                fill="#9ba9bd"
              />
            )}
          </span>
          <span className="user-info__text-company">{company}</span>
        </span>
        {isSideNav && (
          <KiteIcon
            icon={`ki-chevron-${displayUserOptions ? 'up' : 'down'}`}
            fill="#9ba9bd"
            size="sm"
          />
        )}
      </button>
      <div
        className={classNames({
          'user-info__options': true,
          'user-info__options--open': displayUserOptions,
          'user-info__options--sidenav': isSideNav,
          'user-info__options--sidenav--open': isSideNav && displayUserOptions,
        })}
      >
        {renderCompanyNavItems.length > 0 && (
          <>
            <h2>Company Settings</h2>
            <ul>{renderCompanyNavItems}</ul>
          </>
        )}

        {!authUser?.isInternalUser && (
          <>
            {renderUserNavItems.length > 0 && (
              <>
                <h2>User Settings</h2>
                <ul>{renderUserNavItems}</ul>
              </>
            )}

            <KiteButton
              className={classNames({
                'user-info__options-signout-btn': true,
                'user-info__options-signout-btn--open': isSideNav,
              })}
              id="sign-out-button"
              onClick={async () => {
                trackButtonEvent(signOutBtn, {
                  siteSection: currentPageTitle(),
                });
                await signOut();
              }}
            >
              Sign Out
            </KiteButton>
          </>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
