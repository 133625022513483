import { useContext } from 'react';
import { TicketsContext } from '../contexts/ticketsContext';

export const useTicketsUpdates = () => {
  const {
    ticketsNotifications,
    ticketsNotificationsMessage,
    resetTicketsNotifications,
  } = useContext(TicketsContext);

  return {
    ticketsNotifications,
    ticketsNotificationsMessage,
    resetTicketsNotifications,
  };
};
