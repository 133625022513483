import { PiTargetBg } from '../config/appConfig/appConfig.types';
import QuantumService from '../contexts/services/quantumService';
import { Maybe } from '../types/types.generated';
import { getErrorMessage, piErrToGraphQLErr } from '../utils/errorUtils';
import { IPiNxtAPIResponse } from '../contexts/types/pinxt';

interface IGraphqlFetcherRequest {
  /** PiNxt graphql endpoint   */
  endpoint: string;

  /** The token to access the PiNxt graphql endpoint with */
  accessToken: string;

  /** If on a Blue / Green env, designates which env to use */
  targetBg: PiTargetBg;

  /** The graphql query / mutation that's being requested */
  query: string;

  /** Any variables that go along with the GraphQL request */
  variables?: unknown;

  /** Additional Headers to pass with the request */
  additionalHeaders?: Record<string, string>;
}

/**
 * Make a GraphQL Request, try not to call this directly in the components
 * but instead attempt to use useGraphqlFetcher hook first.
 */
export const graphqlFetcher = async <T>({
  endpoint,
  accessToken,
  targetBg,
  query,
  additionalHeaders,
  variables,
}: IGraphqlFetcherRequest): Promise<T> => {
  const fetchStartMs = new Date().valueOf();

  // Quick fix for ENTDEL-10238 (the infamous Demo user idle bug).  See ticket comments for long term fix
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage('MOCK_ACTIVATE');

    // Small wait for changes to propagate
    await new Promise((resolve) => {
      setTimeout(resolve, 10);
    });
  }

  let headers: HeadersInit = {
    'Content-Type': 'application/json',
    target_bg: targetBg,
  };

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  let response: Maybe<Response> = null;
  try {
    response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        ...headers,
        ...(additionalHeaders || {}),
      },

      body: JSON.stringify({ query, variables }),
    });
  } catch (error) {
    QuantumService.apiGqlCall({
      fetchStartMs,
      response: null,
      query,
      endpoint,
      errorCode: 'unknown', // TODO: Need FE Error Code (Verbatim Integration)
      errorMessage: getErrorMessage(error),
    });

    console.error(error);
    return Promise.reject(error);
  }

  QuantumService.apiGqlCall({
    fetchStartMs,
    response,
    query,
    endpoint,
  });

  const json = await response.json();
  const { errors, resultCode } = json;

  // todo: tech debt: https://jira.charter.com/browse/ENTDEL-11209
  // const objectName = json.data ? Object.keys(json.data)[0] : null;
  // const object = objectName ? json.data[objectName] : null;

  const promiseRejectReason = () => {
    if (resultCode) {
      return piErrToGraphQLErr(json as IPiNxtAPIResponse);
    }
    // This is the only API where the error has a data object
    // that we need to drill into to extract the userId.
    // Preferably, we would like BE to update their API error response
    // to pass the userid field under extensions instead of extracting
    // it through the data object or the error message string.
    if (json?.data?.selfRegistrationUserProfileInformation) {
      return json;
    }
    return errors;
  };

  if (errors || resultCode) {
    return Promise.reject(promiseRejectReason());
  }

  return json.data;
};
