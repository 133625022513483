import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { FeatureToggleName } from 'config/featureToggles/featureToggles.types';
import PageRoute from '../../components/Routes/PageRoute';
import {
  videoRoutePerm,
  tvRoutePerm,
} from '../../components/Routes/routePermissions';
import { videoAnalytics } from './types/videoAnalytics';
import { tvAnalytics } from './types/tvAnalytics';
// TODO Remove Video later
const VideoInventory = lazy(
  () => import(/* webpackChunkName: "video-inventory" */ './VideoInventory')
);
const TvInventory = lazy(
  () => import(/* webpackChunkName: "tv-inventory" */ './TvInventory')
);
const Dashboard = lazy(
  () => import(/* webpackChunkName: "vcp-dashboard" */ './TvpDashboard')
);
export const videoRoutes = (
  <Route
    path="services/video/inventory"
    element={
      <PageRoute
        orgServices={videoRoutePerm.orgServices}
        pageInfo={videoAnalytics.videoInventory}
      >
        <VideoInventory />
      </PageRoute>
    }
  />
);
export const tvRoutes = (
  <Route path="services/tv/*">
    <Route
      path="inventory"
      element={
        <PageRoute
          orgServices={tvRoutePerm.orgServices}
          pageInfo={tvAnalytics.tvInventory}
          featureToggle={FeatureToggleName.TvService}
        >
          <TvInventory />
        </PageRoute>
      }
    />
    <Route
      path=":locationId/dashboard"
      element={
        <PageRoute
          orgServices={tvRoutePerm.orgServices}
          pageInfo={tvAnalytics.tvContent}
          featureToggle={FeatureToggleName.TvService}
        >
          <Dashboard />
        </PageRoute>
      }
    />
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>
);
