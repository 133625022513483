import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { wirelessRoutePerm } from '../../components/Routes/routePermissions';
import { wirelessAnalytics } from './types/wirelessAnalytics';

const WirelessInventory = lazy(
  () =>
    import(/* webpackChunkName: "wireless-inventory" */ './WirelessInventory')
);

const WirelessDetails = lazy(
  () => import(/* webpackChunkName: "wireless-details" */ './WirelessDetails')
);

const WirelessDeviceDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "wireless-device-details" */ './WirelessDeviceDetails'
    )
);

const { orgServices } = wirelessRoutePerm;
const { wirelessAccounts, wirelessAccountDetails, wirelessDeviceDetails } =
  wirelessAnalytics;

export const wirelessRoutes = (
  <Route path="services/wireless/*">
    <Route
      path="inventory"
      element={
        <PageRoute orgServices={orgServices} pageInfo={wirelessAccounts}>
          <WirelessInventory />
        </PageRoute>
      }
    />
    <Route
      path="details/:accountNumber/:servicePlan"
      element={
        <PageRoute orgServices={orgServices} pageInfo={wirelessAccountDetails}>
          <WirelessDetails />
        </PageRoute>
      }
    />
    <Route
      path="device/:accountNumber/:deviceId"
      element={
        <PageRoute orgServices={orgServices} pageInfo={wirelessDeviceDetails}>
          <WirelessDeviceDetails />
        </PageRoute>
      }
    />
    <Route path="*" element={<Navigate to="/404" />} />
  </Route>
);
