import React from 'react';
import { Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import CloudSecurityInventory from './CloudSecurityInventory';
import { cloudSecurityRoutePerm } from '../../components/Routes/routePermissions';
import { cloudSecurityAnalytics } from './types/cloudSecurityTypes';

const { orgServices } = cloudSecurityRoutePerm;
const { cloudSecurityInventory } = cloudSecurityAnalytics;

export const cloudSecurityRoutes = (
  <Route
    path="services/cloud-security/inventory"
    element={
      <PageRoute orgServices={orgServices} pageInfo={cloudSecurityInventory}>
        <CloudSecurityInventory />
      </PageRoute>
    }
  />
);
