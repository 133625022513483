import { IAnalyticSdr } from '../../../types/analyticsSdr';
import { StorageTabs } from '../utils/common';

/**
 * Analytics SDR for Storage Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

export const storageAnalytics: IAnalyticSdr = {
  storage: {
    siteSection: 'Storage',
    siteSubSection: 'Storage',
    buttonClicks: {
      archiveTabClick: {
        itemClicked: StorageTabs.ARCHIVED_FILES_TAB, // Was "Archived"
      },
      currentTabClick: {
        itemClicked: StorageTabs.CURRENT_FILES_TAB, // Was "All Files"
      },

      createFolderBtn: {
        pageRegion: StorageTabs.CURRENT_FILES_TAB,
        itemClicked: 'Create Folder',
      },

      uploadFileBtn: {
        pageRegion: StorageTabs.CURRENT_FILES_TAB,
        itemClicked: 'Upload File',
      },
    },
    menuSelections: {
      accountArchMenu: {
        pageRegion: StorageTabs.ARCHIVED_FILES_TAB,
        itemClicked: 'Account',
      },
      accountCurFilesMenu: {
        pageRegion: StorageTabs.CURRENT_FILES_TAB,
        itemClicked: 'Account',
      },
      viewArchMenu: {
        pageRegion: StorageTabs.ARCHIVED_FILES_TAB,
        itemClicked: 'Views',
      },
      viewCurFilesMenu: {
        pageRegion: StorageTabs.CURRENT_FILES_TAB,
        itemClicked: 'Views',
      },
    },
  },
};
