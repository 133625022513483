/**
 * Analytics SDR for Support Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Support';
export const supportAnalytics: IAnalyticSdr = {
  support: {
    siteSection,
    buttonClicks: {
      callGuardGuideLink: {
        itemClicked: 'Call Guard User Guide',
      },
      formsLink: {
        itemClicked: 'Forms',
      },
      supportUserManualsLink: {
        itemClicked: 'Support User Manuals',
      },
      supportFAQLink: {
        itemClicked: 'Support FAQ',
      },
      ticketEscalationProcessLink: {
        itemClicked: 'Ticket Escalation Process',
      },
    },
  },
  supportForm: {
    siteSection,
    siteSubSection: 'Support - Forms',
    buttonClicks: {
      disconnectServiceLink: {
        itemClicked: 'Disconnect Service',
      },
      equipmentReturnsLink: {
        itemClicked: 'Equipment Returns',
      },
      taxperLink: {
        itemClicked: 'Taxper ID and Certification W-9',
      },
    },
  },
};
