import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { KiteProgressIndicator } from '@kite/react-kite';
import sortBy from 'lodash/sortBy';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AccountType,
  IAimOrganization,
  IAimSelf,
  Maybe,
  OrganizationFeature,
  UserCapability,
} from '../types/types.generated';
import {
  orgNameParam,
  smbUrlParam,
} from '../components/SMBRedirectModal/types/smb-redirect-modal.types';
import { IAuthUserContext, IViewingAsOrg } from './types/authUser';
import {
  hasCapability,
  hasOrgFeature,
  hasOrgService,
  parseAimViewOrgAsClientQuery,
} from './utils/authUserUtils';
import { useAuthUserFetcher } from './hooks/useAuthUserFetcher';
import { OrgServiceType } from '../types/orgServiceType';
import { useAuthSession } from '../hooks/useAuthSession';
import { useAimSelfQuery } from '../hooks/useRequest.generated';
import {
  getPortalAccountGuid,
  getViewingAsPAG,
  setAimHostOverride,
  setPortalAccountGuid,
  setViewingAsPAG,
} from './services/authSessionStorage';
import authUserContextDefault from './utils/authUserDefault';
import { useFeatureToggles } from '../hooks/useFeatureToggles';
import { FeatureToggleName } from '../config/featureToggles/featureToggles.types';
import { getAllMockOrgPresets } from '../mocks/organization/organizations.utils';

export const AuthUserContext = createContext<IAuthUserContext>(
  authUserContextDefault
);

export const AuthUserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [viewingAsOrg, setViewingAsOrg] = useState<Maybe<IViewingAsOrg>>(null);

  const { isFeatureToggledOn } = useFeatureToggles();
  const showSMBRedirectMessage = isFeatureToggledOn(
    FeatureToggleName.AuthSMBRedirectMessage
  );

  const navigate = useNavigate();
  const location = useLocation();

  // Is it an internal user "Viewing Org as Client" session?
  const { viewingAsPAG, aimHostOverride } = parseAimViewOrgAsClientQuery();
  if (viewingAsPAG) {
    // set in Local Storage
    setViewingAsPAG(viewingAsPAG);
  }
  if (aimHostOverride) {
    setAimHostOverride(aimHostOverride);
  }
  const [selectedOrgId, setSelectedOrgId] = useState(
    getViewingAsPAG() || getPortalAccountGuid()
  );

  const { isAuthenticated, isAuthenticating, signOut } = useAuthSession();

  const { data: selfRes } = useAimSelfQuery(
    {},
    {
      onError: () => {
        setIsError(true);
      },
      enabled: isAuthenticated,
    }
  );

  const { loadAuthUser, clearAuthUser, authUser } = useAuthUserFetcher({
    loadUserOnSuccess: useCallback(() => {
      setIsLoading(false);
    }, []),
    loadUserOnFailure: useCallback(() => {
      clearAuthUser();
      setIsError(true);
    }, []),
  });

  const self: Maybe<IAimSelf> = selfRes?.self || null;

  const organizations: Maybe<IAimOrganization[]> = useMemo(
    () =>
      selfRes?.self?.organizations
        ? sortBy(selfRes.self.organizations, ['name'])
        : null,
    [selfRes]
  );

  useEffect(() => {
    // Wait for Auth Session to finish loading as authUSer depends on a valid Auth Session
    if (isAuthenticating) {
      setIsError(false);
      return;
    }

    if (isAuthenticated && selectedOrgId && !!self && !isError) {
      if (showSMBRedirectMessage) {
        const org = self.parentOrganization;
        const isSMBAccount = org?.accountType?.name === AccountType.SMB;
        if (isSMBAccount && !self.isInternalUser) {
          // example = '?smb-account=true&org-name=ENTDEL13096TestOrgAccountTypeSMBHybridWithBills'
          const smbRoute = `${smbUrlParam}${orgNameParam}${org.name}`;
          signOut({ redirectRoute: smbRoute }).catch(() => {});
          return;
        }
      }
      // Load the auth user object when we have everything
      loadAuthUser(self, selectedOrgId);
      return;
    }

    // Handle Auth errors on Browser Refresh
    if (isLoading && isAuthenticated) {
      if (isError) {
        signOut({ showError: true }).then(
          () => {},
          () => {}
        );
      }

      if (organizations && organizations.length && !selectedOrgId) {
        /**
         * If everything is loaded and no selectedOrgId is set yet,
         * then set the orgId
         */
        setUserOrgId(getAllMockOrgPresets[0].id);
      }

      return;
    }
    // No auth user to load, make sure everything is cleared out.
    clearAuthUser();
    setIsLoading(false);
  }, [
    clearAuthUser,
    isAuthenticated,
    isAuthenticating,
    isError,
    isLoading,
    loadAuthUser,
    location.pathname,
    navigate,
    organizations,
    selectedOrgId,
    self,
    showSMBRedirectMessage,
    signOut,
  ]);

  /**
   * Check whether the auth user has a capability
   * @param capabilities Single or array of capabilities to check
   */
  const userHasCapability = useCallback(
    (capabilities: UserCapability | UserCapability[]) =>
      hasCapability(capabilities, authUser?.capabilities || []),
    [authUser]
  );

  /**
   * Check whether the auth user has a organization feature
   * @param orgFeatures Single or array of org features to check
   */
  const orgHasFeature = useCallback(
    (orgFeatures: OrganizationFeature | OrganizationFeature[]) =>
      hasOrgFeature(orgFeatures, authUser?.organization?.features || []),
    [authUser]
  );

  /**
   * Check whether the auth user has a organization service
   * @param orgServices Single or array of org services to check
   */
  const orgHasService = useCallback(
    (orgServices: OrgServiceType | OrgServiceType[]) =>
      hasOrgService(orgServices, authUser?.services || []),
    [authUser]
  );

  /** Set the Organization Id (aka: PAG) to use for this session */
  const setUserOrgId = useCallback((value: string) => {
    setPortalAccountGuid(value);
    setSelectedOrgId(value);
  }, []);

  return (
    <AuthUserContext.Provider
      value={useMemo(
        () => ({
          authUser,
          isError,
          isAuthenticated,
          portalAccountGUID: selectedOrgId || '',
          organizations,
          isInternalView: !!viewingAsPAG,
          hasCapability: userHasCapability,
          hasOrgFeature: orgHasFeature,
          hasOrgService: orgHasService,
          setUserOrgId,
          viewingAsOrg,
        }),
        [
          authUser,
          isError,
          isAuthenticated,
          selectedOrgId,
          organizations,
          viewingAsPAG,
          userHasCapability,
          orgHasFeature,
          orgHasService,
          setUserOrgId,
          viewingAsOrg,
        ]
      )}
    >
      {!isLoading && children}
      {isLoading && (
        <div className="display-loader">
          <KiteProgressIndicator id="auth-user-loader" />
        </div>
      )}
    </AuthUserContext.Provider>
  );
};
