/* eslint-disable no-empty-function */
/* eslint-disable react/jsx-no-constructed-context-values */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import React, { createContext, useEffect, useState, useRef } from 'react';
import { useAuthUser } from '../hooks/useAuthUser';
import { useGetTicketInventoryQuery } from '../hooks/useRequest.generated';
import { TicketCondition, TicketStatus } from '../types/types.generated';
import { OrgServiceType } from '../types/orgServiceType';

export interface ITicketsContext {
  ticketsNotifications: number;
  ticketsNotificationsMessage: string;
  resetTicketsNotifications: () => void;
}

// set the defaults
const defaultValue: ITicketsContext = {
  ticketsNotifications: 0,
  ticketsNotificationsMessage: '',
  resetTicketsNotifications: () => {},
};

export const TicketsContext = createContext<ITicketsContext>(defaultValue);

/**
 * Make Tickets Polling state available to all components.
 */
export const TicketsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { portalAccountGUID, hasOrgService } = useAuthUser();

  const [ticketsNotificationsMessage, setTicketsNotificationsMessage] =
    useState(defaultValue.ticketsNotificationsMessage);
  const [ticketsNotifications, setTicketsNotifications] = useState(
    defaultValue.ticketsNotifications
  );

  const ticketsUserNotifiedRef = useRef(
    sessionStorage.getItem('ticketsUserNotified') || JSON.stringify([])
  );

  const pollingInterval = 60000;

  const { data } = useGetTicketInventoryQuery(
    {
      portalAccountGUID,
      filters: {
        ticketCondition: TicketCondition.Open,
        ticketStatus: TicketStatus.NeedsAttention,
      },
    },
    {
      enabled: hasOrgService(OrgServiceType.Tickets),
      refetchInterval: pollingInterval,
    }
  );

  const ticketList =
    data?.user.organization?.services?.tickets?.inventory?.tickets;

  useEffect(() => {
    const ticketIds = ticketList?.map((ticket) => ticket.ticketId) || [];
    const noOfTicketsToNotify = ticketIds.length || 0;

    ticketsUserNotifiedRef.current = JSON.stringify(ticketIds);

    setTicketsNotifications(() => noOfTicketsToNotify);

    // Update session storage item containing the ticket IDs of tickets user has been notified about.
    sessionStorage.setItem(
      'ticketsUserNotified',
      ticketsUserNotifiedRef.current
    );
  }, [ticketList]);

  useEffect(() => {
    if (ticketsNotifications > 0) {
      // Setting the content to be displayed in toast
      const ticketsMessageContent = `${ticketsNotifications} ticket${
        ticketsNotifications > 1 ? 's' : ''
      } need${ticketsNotifications > 1 ? '' : 's'} your response.`;

      setTicketsNotificationsMessage(() => ticketsMessageContent);
    }
  }, [ticketsNotifications]);

  const resetTicketsNotifications = () => {
    setTicketsNotifications(() => 0);
  };

  return (
    <TicketsContext.Provider
      value={{
        ticketsNotifications,
        ticketsNotificationsMessage,
        resetTicketsNotifications,
      }}
    >
      {children}
    </TicketsContext.Provider>
  );
};
