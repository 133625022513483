import { IAuthSessionContext } from '../types/authSession';

// Default data for init of context
const authContextDefault: IAuthSessionContext = {
  isAuthenticating: true,
  isAuthenticated: false,
  isAuthError: false,
  authToken: null,
  idToken: null,
  isUserIdleLogout: false,
  idTokenObj: null,
  legacyToken: null,
  showIdlePrompt: false,
  signOut: () => Promise.resolve(),
  aimSessionTransfer: () => {},
  getAnonymousToken: () => Promise.resolve(''),
  resetIdlePrompt: () => {},
  authenticateViaCreds: (username: string, password: string) =>
    Promise.resolve(),
  authenticateWithCode: (authCode: string) => Promise.resolve(),
  authenticateViaEsso: () => {},
};

export default authContextDefault;
