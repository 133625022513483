/**
 * Analytics SDR for Ticket Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Tickets';
const OPEN_TICKETS = 'Open';
const CLOSED_TICKETS = 'Closed';
const CREATE_TICKET = 'Create Ticket';
const CREATE_TICKET_FORM = 'Create Ticket: Form';
const CREATE_TICKET_SUMMARY = 'Create Ticket: Summary';
const CREATE_TICKET_SUCCESS = 'Create Ticket: Success';
const CREATE_TICKET_FAILURE = 'Create Ticket: Failure';
const CREATE_TICKET_MODAL = 'Create Ticket: Modal';
const VIEW_DETAILS = 'View Details';
const DOWNLOAD_CSV = 'Download CSV';
const TICKET_TYPE = 'Ticket Type';
const CUSTOMER_NAME = 'Customer Name';

export const ticketAnalytics: IAnalyticSdr = {
  ticketInventory: {
    siteSection,
    siteSubSection: 'Tickets',
    buttonClicks: {
      openTicketsTab: {
        itemClicked: OPEN_TICKETS,
      },
      closedTicketsTab: {
        itemClicked: CLOSED_TICKETS,
      },
      createTicketBtn: {
        itemClicked: CREATE_TICKET,
      },
      viewDetailsOpenBtn: {
        itemClicked: VIEW_DETAILS,
        pageRegion: OPEN_TICKETS,
      },
      viewDetailsClosedBtn: {
        itemClicked: VIEW_DETAILS,
        pageRegion: CLOSED_TICKETS,
      },
      downloadCsvOpenBtn: {
        itemClicked: DOWNLOAD_CSV,
        pageRegion: OPEN_TICKETS,
      },
      downloadCsvClosedBtn: {
        itemClicked: DOWNLOAD_CSV,
        pageRegion: CLOSED_TICKETS,
      },
    },
    menuSelections: {
      statusOpenMenu: {
        itemClicked: 'Status',
        pageRegion: OPEN_TICKETS,
      },
      ticketTypeOpenMenu: {
        itemClicked: TICKET_TYPE,
        pageRegion: OPEN_TICKETS,
      },
      ticketTypeClosedMenu: {
        itemClicked: TICKET_TYPE,
        pageRegion: CLOSED_TICKETS,
      },
      customerOpenMenu: {
        itemClicked: CUSTOMER_NAME,
        pageRegion: OPEN_TICKETS,
      },
      customerClosedMenu: {
        itemClicked: CUSTOMER_NAME,
        pageRegion: CLOSED_TICKETS,
      },
    },
  },
  ticketDetails: {
    siteSection,
    siteSubSection: 'Tickets Details',
    buttonClicks: {
      submitBtn: {
        itemClicked: 'Submit',
        pageRegion: 'Request to Close Dialog',
      },
      printBtn: {
        itemClicked: 'Print',
      },
      requestToCloseBtn: {
        itemClicked: 'Request to Close Ticket',
      },
      attachFileBtn: {
        itemClicked: 'Attach File',
      },
      sendNoteBtn: {
        itemClicked: 'Send Note',
      },
    },
  },
  createTicket: {
    siteSection,
    siteSubSection: CREATE_TICKET,
    buttonClicks: {
      closeBtnSuccess: {
        itemClicked: 'Close',
        pageRegion: CREATE_TICKET_SUCCESS,
      },
      closeBtnForm: {
        itemClicked: 'Close',
        pageRegion: CREATE_TICKET_FORM,
      },
      viewDetailsBtn: {
        itemClicked: 'View Details',
        pageRegion: CREATE_TICKET_SUCCESS,
      },
      submitBtn: {
        itemClicked: 'Submit',
        pageRegion: CREATE_TICKET_SUMMARY,
      },
      editBtn: {
        itemClicked: 'Edit',
        pageRegion: CREATE_TICKET_SUMMARY,
      },
      cancelBtnForm: {
        itemClicked: 'Cancel',
        pageRegion: CREATE_TICKET_FORM,
      },
      cancelBtnModal: {
        itemClicked: 'Cancel',
        pageRegion: CREATE_TICKET_MODAL,
      },
      leavePageBtnModal: {
        itemClicked: 'Leave Page',
        pageRegion: CREATE_TICKET_MODAL,
      },
      xBtnModal: {
        itemClicked: 'x',
        pageRegion: CREATE_TICKET_MODAL,
      },
      cancelBtnSummary: {
        itemClicked: 'Cancel',
        pageRegion: CREATE_TICKET_SUMMARY,
      },
      backBtnForm: {
        itemClicked: 'Back',
        pageRegion: CREATE_TICKET_FORM,
      },
      backBtnSummary: {
        itemClicked: 'Back',
        pageRegion: CREATE_TICKET_SUMMARY,
      },
      closeBtnSummary: {
        itemClicked: 'Close',
        pageRegion: CREATE_TICKET_FAILURE,
      },
      tryAgainBtnSummary: {
        itemClicked: 'Try Again',
        pageRegion: CREATE_TICKET_FAILURE,
      },
      continueBtn: {
        itemClicked: 'Continue',
        pageRegion: CREATE_TICKET_FORM,
      },
    },
  },
};
