export enum OrgServiceType {
  Billing = 'Billing',
  BusinessInternet = 'BusinessInternet',
  BusinessVoice = 'BusinessVoice',
  CloudSecurity = 'CloudSecurity',
  SecureAccess = 'SecureAccess',
  EnterpriseNetworkEdge = 'EnterpriseNetworkEdge',
  Fiber = 'Fiber',
  Locations = 'Locations',
  ManagedSecurity = 'ManagedSecurity',
  ManageLocationNames = 'ManageLocationNames',
  ManageNetworkEdge = 'ManageNetworkEdge',
  ManagedRouterService = 'ManagedRouterService',
  Network = 'Network',
  Reporting = 'Reporting',
  SecureInternet = 'SecureInternet',
  Storage = 'Storage',
  Tickets = 'Tickets',
  UnifiedComms = 'UnifiedComms',
  Video = 'Video', // TODO Remove Video later
  TV = 'TV',
  VirtualSecurity = 'VirtualSecurity',
  VoiceTrunking = 'VoiceTrunking',
  Wavelength = 'Wavelength',
  Wireless = 'Wireless',
  Registration = 'Registration',
}
