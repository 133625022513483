/**
 * TDCS is a service that allows us to store app config settings
 * that we want to ability to change at runtime.
 * If your values aren't expected to change, use App Config instead.
 *
 * Note: No sensitive information here, this file is public!
 * For more info, see https://chalk.charter.com/display/ERSPM/FE+Configs.
 */

import { restFetcher } from '../../services/restFetcher';
import { AppEnv, IAppConfig } from '../appConfig/appConfig.types';
import { ITdcsConfig, ITdcsResponse, ITdcsResponseError } from './tdcs.types';

/**
 * Fetch TDCS config from TDCS API
 * @param appConfig App Config
 * @returns TDCS Response
 */
export const fetchTdcsConfig = async (
  appConfig: IAppConfig
): Promise<ITdcsResponse> => {
  const { host } = appConfig.tdcs;
  const queryParams = `apiKey=${appConfig.tdcs.apiKey}`;
  const endpoint = `${host}/tdcs/public/inform?${queryParams}`;

  return restFetcher({
    endpoint,
    method: 'POST',
    requestBody: JSON.stringify(appConfig.tdcs.config),
    additionalHeaders: {
      'Content-Type': 'application/json',
    },
    onResponse: (responseBody, hasError) => {
      let code = '';
      let message = '';
      if (hasError) {
        const resError = responseBody as unknown as ITdcsResponseError;
        code = resError.statusCode.toString();
        message = resError.message;
      }

      return {
        code,
        message,
      };
    },
  });
};

/**
 * Get Backup TDCS file when the fetch TDCS endpoint errors out.
 * @param env Current running env set in App Config
 * @returns TDCS Config backup file
 */
export const backupTdcsConfig = async (
  env: AppEnv | string
): Promise<ITdcsConfig> => {
  const tdcsConfig: ITdcsResponse = await import(`./configs/${env}.json`);
  return tdcsConfig.config;
};
