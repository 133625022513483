import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FetchAnonymousTokenDocument } from '../../hooks/useRequest.generated';
import {
  generateChallenge,
  generateCodeVerifier,
} from '../utils/authSessionUtils';
import {
  IPiNxtAuthCodeAPIResponse,
  IPiNxtTokenAPIResponse,
} from '../types/pinxt';
import { restFetcher } from '../../services/restFetcher';
import { useAppConfig } from '../../hooks/useAppConfig';
import { graphqlFetcher } from '../../services/graphqlFetcher';
import {
  IFetchAnonymousTokenMutation,
  IFetchAnonymousTokenMutationVariables,
} from '../../types/types.generated';

/**
 * Manages all the API calls directly to PiNXt for Authentication.
 * Don't use directly on Page Level Components, use useAuthSession hook instead.
 */
export const useAuthService = () => {
  const {
    api: { auth: authHost, authAnonymous: authAnonymousHost },
    auth: { targetBg, piClientId, gqlServicesClientId },
  } = useAppConfig();

  const codeVerifier = useMemo(generateCodeVerifier, []);

  /**
   * PiNXt Auth Step 1 of 2: Requesting auth code
   * @param username
   * @param password
   * @param codeVerifier
   * @param baseAuthUrl
   */
  const fetchAuthCode = useCallback(
    async (
      username: string,
      password: string
    ): Promise<IPiNxtAuthCodeAPIResponse> => {
      const codeChallenge = await generateChallenge(codeVerifier);
      const auth = window.btoa(`${username}:${password}`);
      const endpoint = `${authHost}/enterprise/portals/password/auth`;

      const qsParams = [
        `client_id=${piClientId}`,
        `response_type=code`,
        `state=state`,
        `code_challenge_method=S512`,
        `code_challenge=${codeChallenge}`,
      ];

      return restFetcher({
        endpoint,
        method: 'POST',
        requestBody: qsParams.join('&'),
        targetBg,
        additionalHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${auth}`, // requires Basic
        },
        onResponse: (responseBody) => ({
          code: responseBody.resultCode,
          message: responseBody.resultMessage,
        }),
      });
    },
    [authHost, codeVerifier, piClientId, targetBg]
  );

  /**
   * PiNXt Auth Step 2 of 2: Requesting auth tokens (authToken, IdToken, etc.)
   * @param authCode
   * @param codeVerifier
   * @param baseAuthUrl
   */
  const fetchTokens = useCallback(
    async (authCode: string): Promise<IPiNxtTokenAPIResponse> => {
      const endpoint = `${authHost}/token`;

      const qsParams = [
        `client_id=${piClientId}`,
        `grant_type=authorization_code`,
        `code=${authCode}`,
        `code_verifier=${codeVerifier}`,
      ];

      return restFetcher({
        endpoint,
        method: 'POST',
        requestBody: qsParams.join('&'),
        targetBg,
        additionalHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        onResponse: (responseBody) => ({
          code: responseBody.resultCode,
          message: responseBody.resultMessage,
        }),
      });
    },
    [authHost, codeVerifier, piClientId, targetBg]
  );

  /**
   * Grabs auth token for new session
   * @param refreshToken
   * @param baseAuthUrl
   */
  const fetchTokensOnRefresh = useCallback(
    async (refreshToken: string): Promise<IPiNxtTokenAPIResponse> => {
      const endpoint = `${authHost}/token`;

      const qsParams = [
        `client_id=${piClientId}`,
        `grant_type=refresh_token`,
        `refresh_token=${refreshToken}`,
      ];

      return restFetcher({
        endpoint,
        method: 'POST',
        requestBody: qsParams.join('&'),
        targetBg,
        additionalHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        onResponse: (responseBody) => ({
          code: responseBody.resultCode,
          message: responseBody.resultMessage,
        }),
      });
    },
    [authHost, piClientId, targetBg]
  );

  /**
   * Logs out of current session
   * @param accessToken
   * @param baseAuthUrl
   */
  const fetchLogout = useCallback(
    async (accessToken: string): Promise<IPiNxtTokenAPIResponse> =>
      restFetcher({
        endpoint: `${authHost}/logout/local`,
        method: 'POST',
        accessToken,
        targetBg,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        onResponse: (responseBody) => ({
          code: responseBody.resultCode,
          message: responseBody.resultMessage,
        }),
      }),
    [authHost, targetBg]
  );

  /**
   * Fetch anonymous tokens for password recovery and verify new user flows.
   */
  const fetchAnonymousToken = useCallback(async (): Promise<string> => {
    const variables: IFetchAnonymousTokenMutationVariables = {
      clientName: gqlServicesClientId,
    };

    const res = await graphqlFetcher<IFetchAnonymousTokenMutation>({
      endpoint: authAnonymousHost,
      accessToken: '',
      targetBg,
      query: FetchAnonymousTokenDocument,
      variables,
      additionalHeaders: {
        requestId: uuidv4(),
      },
    });

    return res.fetchAnonymousToken?.data.access_token || '';
  }, [gqlServicesClientId, targetBg, authAnonymousHost]);

  return {
    fetchAuthCode,
    fetchTokens,
    fetchTokensOnRefresh,
    fetchLogout,
    fetchAnonymousToken,
  };
};
