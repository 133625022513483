import { ITdcsConfig, ITdcsResponse } from './tdcs.types';

/**
 * Create TDCS Config Object
 * @param res TDCS API response
 * @returns TDCS Config Object
 */
export const createTdcsConfig = (res: ITdcsResponse): ITdcsConfig => ({
  ...res.config,
  analytics: {
    adobeEnabled: (res.config.analytics.adobeEnabled as any) === 'true',
  },
});
