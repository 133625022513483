/**
 * Initializes TDCS Context that gives all components access to the
 * TDCS object from the useTdcs hook.
 *
 * @see https://chalk.charter.com/display/ERSPM/FE+Configs.
 */

import React, { createContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { KiteProgressIndicator } from '@kite/react-kite';
import { backupTdcsConfig, fetchTdcsConfig } from '../config/tdcs/tdcs.service';
import {
  ITdcsConfig,
  ITdcsResponse,
  ITdcsResponseError,
} from '../config/tdcs/tdcs.types';
import { useAppConfig } from '../hooks/useAppConfig';
import { createTdcsConfig } from '../config/tdcs/tdcsConfig';
import tdcsDefault from './utils/tdcsDefault';
import QuantumService from './services/quantumService';
import { getErrorMessage, getErrorName } from '../utils/errorUtils';

interface ITdcsConfigContext {
  tdcsConfig: ITdcsConfig;
}

const defaultValue: ITdcsConfigContext = {
  tdcsConfig: tdcsDefault,
};

export const TdcsConfigContext =
  createContext<ITdcsConfigContext>(defaultValue);

export const TdcsConfigContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tdcsConfig, setTdcsConfig] = useState<ITdcsConfig>();

  const appConfig = useAppConfig();
  const { tdcs, app, isMock } = appConfig;

  /**
   * Allows devs to inject their own local version of TDCS config when developing locally.
   * For more info, see https://chalk.charter.com/display/ERSPM/FE+Configs.
   */
  if (!tdcsConfig && !isMock && tdcs.localConfig) {
    backupTdcsConfig(app.env).then(setTdcsConfig).catch(console.error);
  }

  if (!tdcsConfig && !tdcs.enabled) {
    backupTdcsConfig(app.env)
      .then(setTdcsConfig)
      .catch((err) => {
        QuantumService.jsError({
          appErrorType: 'application',
          msgFeatureName: 'tdcs',
          appErrorMessage: `Failed to get default TDCS file: ${getErrorMessage(
            err
          )}`,
          appErrorCode: getErrorName(err) || 'Unexpected',
        });
        console.error(err);
      });
  }

  const onError = (): void => {
    // If for some reason TDCS is down, use our static, backup TDCS file in code.
    console.info(
      'TDCS endpoint failed, attempt to continue using a backup version.'
    );

    backupTdcsConfig(app.env)
      .then(setTdcsConfig)
      .catch(() => {});
  };

  useQuery<ITdcsResponse, ITdcsResponseError>(
    ['tdcsConfig'],
    () => fetchTdcsConfig(appConfig),
    {
      retry: 0,
      onSuccess: (data: ITdcsResponse): void => {
        setTdcsConfig(createTdcsConfig(data));
      },
      onError,
      enabled: !tdcsConfig && tdcs.enabled,
    }
  );

  const contextValue = useMemo(
    () => ({ tdcsConfig: tdcsConfig! }),
    [tdcsConfig]
  );

  return (
    <TdcsConfigContext.Provider value={contextValue}>
      {tdcsConfig && children}
      {!tdcsConfig && (
        <div className="display-loader">
          <KiteProgressIndicator id="tdcs-loader" />
        </div>
      )}
    </TdcsConfigContext.Provider>
  );
};
