import { useContext } from 'react';
import { AuthSessionContext } from '../contexts/authSessionContext';

/**
 * Interact with the Auth Session Context
 */
export const useAuthSession = () => {
  const {
    isAuthenticating,
    isAuthenticated,
    isAuthError,
    authToken,
    idToken,
    idTokenObj,
    legacyToken,
    isUserIdleLogout,
    showIdlePrompt,
    resetIdlePrompt,
    signOut,
    aimSessionTransfer,
    getAnonymousToken,
    authenticateViaCreds,
    authenticateWithCode,
    authenticateViaEsso,
  } = useContext(AuthSessionContext);
  return {
    isAuthenticating,
    isAuthenticated,
    isAuthError,
    authToken,
    idToken,
    idTokenObj,
    legacyToken,
    isUserIdleLogout,
    showIdlePrompt,
    resetIdlePrompt,
    signOut,
    aimSessionTransfer,
    getAnonymousToken,
    authenticateViaCreds,
    authenticateWithCode,
    authenticateViaEsso,
  };
};
