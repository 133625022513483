import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { secureInternetAnalytics } from './types/secureInternetAnalytics';
import { secureInternetRoutePerm } from '../../components/Routes/routePermissions';
import { FeatureToggleName } from '../../config/featureToggles/featureToggles.types';

const SecureInternetInventory = lazy(
  () =>
    import(
      /* webpackChunkName: "secure-internet-inventory" */ './features/SecureInternetInventory/SecureInternetInventory'
    )
);

const SecureInternetDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "secure-internet-details" */ './features/SecureInternetDetails/SecureInternetDetails'
    )
);

const SecureInternetCreateReport = lazy(
  () =>
    import(
      /* webpackChunkName: "secure-internet-create-report" */ './features/SecureInternetCreateReport/SecureInternetCreateReport'
    )
);

const SecureInternetEquipmentCreateReport = lazy(
  () =>
    import(
      /* webpackChunkName: "secure-internet-equipment-create-report" */ './features/SecureInternetCreateEquipmentReport/SecureInternetEquipmentCreateReport'
    )
);

const ReportConfirm = lazy(
  () =>
    import(
      /* webpackChunkName: "secure-internet-report-confirm" */ '../../components/ReportConfirm'
    )
);

const { orgServices } = secureInternetRoutePerm;
const {
  secureInternetInventory,
  secureInternetDetails,
  secureInternetCreateReport,
  secureInternetCreateReportSuccess,
  secureInternetEquipmentCreateReport,
  secureInternetEquipmentCreateReportSuccess,
} = secureInternetAnalytics;

export const secureInternetRoutes = (
  <>
    <Route path="services/secure-internet/*">
      <Route
        path="inventory"
        element={
          <PageRoute
            featureToggle={FeatureToggleName.SecureInternet}
            orgServices={orgServices}
            pageInfo={secureInternetInventory}
          >
            <SecureInternetInventory />
          </PageRoute>
        }
      />

      <Route
        path="details/:circuitId"
        element={
          <PageRoute
            featureToggle={FeatureToggleName.SecureInternetDetails}
            orgServices={orgServices}
            pageInfo={secureInternetDetails}
          >
            <SecureInternetDetails />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>

    <Route path="reports/build/*">
      <Route
        path="secure-internet/:circuitId"
        element={
          <PageRoute
            featureToggle={FeatureToggleName.SecureInternet}
            orgServices={orgServices}
            pageInfo={secureInternetCreateReport}
          >
            <SecureInternetCreateReport />
          </PageRoute>
        }
      />
      <Route
        path="secure-internet/:circuitId/success"
        element={
          <PageRoute
            featureToggle={FeatureToggleName.SecureInternet}
            orgServices={orgServices}
            pageInfo={secureInternetCreateReportSuccess}
          >
            <ReportConfirm analytics={secureInternetCreateReportSuccess} />
          </PageRoute>
        }
      />
      <Route
        path="equipment/secure-internet/:circuitId"
        element={
          <PageRoute
            featureToggle={FeatureToggleName.SecureInternet}
            orgServices={orgServices}
            pageInfo={secureInternetEquipmentCreateReport}
          >
            <SecureInternetEquipmentCreateReport />
          </PageRoute>
        }
      />
      <Route
        path="equipment/secure-internet/:circuitId/success"
        element={
          <PageRoute
            featureToggle={FeatureToggleName.SecureInternet}
            orgServices={orgServices}
            pageInfo={secureInternetEquipmentCreateReportSuccess}
          >
            <ReportConfirm
              analytics={secureInternetEquipmentCreateReportSuccess}
            />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </>
);
