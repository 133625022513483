/**
 * Analytics SDR for Secure Internet Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Secure Internet';
const detailsSubSection = 'Secure Internet Details';

export const secureInternetAnalytics: IAnalyticSdr = {
  secureInternetInventory: {
    siteSection,
    siteSubSection: 'Secure Internet Inventory',
    buttonClicks: {
      secureInternetPortalLink: {
        itemClicked: 'Secure Internet Portal',
      },
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      viewDetailsBtn: {
        itemClicked: 'View Details',
      },
    },
  },
  secureInternetDetails: {
    siteSection,
    siteSubSection: detailsSubSection,
    buttonClicks: {
      secureInternetPortalLink: {
        itemClicked: 'Secure Internet Portal',
      },
      utilizationTab: {
        itemClicked: 'Utilization',
      },
      equipmentTab: {
        itemClicked: 'Equipment Status',
      },
      equipmentTabNoMetricsSupportLink: {
        itemClicked: 'Support Page',
      },
      createReport: {
        itemClicked: 'Create Report',
      },
      createEquipmentReport: {
        itemClicked: 'Create Report',
        pageRegion: 'Equipment Status',
      },
      createTicket: {
        itemClicked: 'Create Ticket',
      },
      backBtn: {
        itemClicked: 'Back',
      },
    },
  },
  secureInternetCreateReport: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Secure Internet Report',
  },
  secureInternetCreateReportSuccess: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Secure Internet Report - Success',
  },
  secureInternetEquipmentCreateReport: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Secure Internet Equipment Report',
  },
  secureInternetEquipmentCreateReportSuccess: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Secure Internet Equipment Report - Success',
  },
};
