import { AppEnv } from '../../appConfig/appConfig.types';
import { FeatureToggleName, IFeatureToggles } from '../featureToggles.types';
import { featureTogglesDefault } from './default';

/**
 * Specific hard-coded Engprod overrides to ./default.ts.
 *
 * For more info, see https://chalk.charter.com/display/ERSPM/FE+Configs
 */
export const featureTogglesEngprod: IFeatureToggles = {
  environment: AppEnv.EngProd,
  toggles: [
    ...featureTogglesDefault.toggles,
    {
      name: FeatureToggleName.ArchitectureDisableReCaptcha,
      isEnabled: true,
    },
  ],
};
