/**
 * Analytics SDR for Settings Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr, IAnalyticSdrPage } from '../../../types/analyticsSdr';

const siteSection = 'Settings';

export const settingsAnalytics: IAnalyticSdr = {
  settingsAccount: {
    siteSection,
    siteSubSection: 'Account',
  },

  settingsProfile: {
    siteSection,
    siteSubSection: 'Profile',
    menuSelections: {
      billingEmailMenu: {
        itemClicked: 'Billing Emails',
      },
    },
    buttonClicks: {
      editPasswordBtn: {
        itemClicked: 'Edit Password',
      },
      saveBtn: {
        itemClicked: 'Save',
        pageRegion: 'Password Form',
      },
      cancelBtn: {
        itemClicked: 'Cancel',
        pageRegion: 'Password Form',
      },
    },
  },

  settingsUsers: {
    siteSection,
    siteSubSection: 'Users',
    buttonClicks: {
      inviteNewUserBtn: {
        itemClicked: 'Invite New User',
      },
      editUserBtn: {
        itemClicked: 'Edit User',
      },
      deleteUserBtn: {
        itemClicked: 'Delete User',
      },
      sendRegistrationEmailBtn: {
        itemClicked: 'Send Registration Email',
      },
      resendEmailBtn: {
        itemClicked: 'Email Sent. Click to Resend',
      },
      permissionsLink: {
        itemClicked: '', // This is dynamic
      },
    },
  },

  settingsUsersPermissionsModal: {
    siteSection,
    siteSubSection: 'Users',
    siteSubSubSection: '',
    buttonClicks: {
      permissionsCloseBtn: {
        itemClicked: 'Close',
        pageRegion: '', // Dynamic
      },
      permissionsXbtn: {
        itemClicked: 'x',
        pageRegion: '', // Dynamic
      },
    },
  },

  settingsUsersDeleteUserModal: {
    siteSection,
    siteSubSection: 'Users',
    siteSubSubSection: 'Modal - Are you sure you want to delete this user?',
    buttonClicks: {
      cancelDeleteUserModalBtn: {
        itemClicked: 'Cancel',
      },
      deleteUserModalBtn: {
        itemClicked: 'Delete',
      },
      xDeleteUserModalBtn: {
        itemClicked: 'x',
      },
    },
  },

  settingsUsersSendRegModal: {
    siteSection,
    siteSubSection: 'Users',
    siteSubSubSection: 'Modal - Send Registration Email to User',
    buttonClicks: {
      cancelSendRegBtn: {
        itemClicked: 'Cancel',
      },
      sendEmailBtn: {
        itemClicked: 'Send Email',
      },
      xSentEmailBtn: {
        itemClicked: 'x',
      },
    },
  },

  settingsUsersSendRegSuccessModal: {
    siteSection,
    siteSubSection: 'Users',
    siteSubSubSection: 'Modal - User Invite Successful',
    buttonClicks: {
      closeSendRegSuccessBtn: {
        itemClicked: 'close',
      },
      xSendRegSuccessBtn: {
        itemClicked: 'x',
      },
    },
  },

  settingsUsersSendRegErrorModal: {
    siteSection,
    siteSubSection: 'Users',
    siteSubSubSection: 'Modal - User Invite Unsuccessful',
    buttonClicks: {
      closeSendRegErrorBtn: {
        itemClicked: 'close',
      },
      xSendRegErrorBtn: {
        itemClicked: 'x',
      },
      tryAgainErrorBtn: {
        itemClicked: 'Try Again',
      },
    },
  },

  settingsUserDetails: {
    siteSection,
    siteSubSection: 'Users',
    siteSubSubSection: 'User Details',
  },

  settingsUserInvite: {
    siteSection,
    siteSubSection: 'Users',
    siteSubSubSection: 'Invite User',
  },

  settingsEventLogs: {
    siteSection,
    siteSubSection: 'Event Logs',
    buttonClicks: {
      viewAndFilterBtn: {
        itemClicked: 'View & Filter',
      },
      viewResultBtn: {
        itemClicked: 'View Results',
      },
    },
    menuSelections: {
      eventTypesMenu: {
        itemClicked: 'Event Types',
      },
    },
  },

  settingsLocationNames: {
    siteSection,
    siteSubSection: 'Location Names',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      editLocationBtn: {
        itemClicked: 'Edit Location Name',
      },
      saveLocationBtn: {
        itemClicked: 'Save',
      },
    },
  },

  settingsLogout: {
    siteSection: '', // Dynamically set to current page,
    buttonClicks: {
      signOutBtn: {
        pageRegion: 'Settings Menu',
        itemClicked: 'Sign Out',
      },
    },
  },

  settingsCompany: {
    siteSection: '', // Dynamically set to current page,
    buttonClicks: {
      viewAccountBtn: {
        pageRegion: 'Settings Menu',
        itemClicked: 'View Account',
      },
      viewUsersBtn: {
        pageRegion: 'Settings Menu',
        itemClicked: 'View Users',
      },
      viewEventLogsBtn: {
        pageRegion: 'Settings Menu',
        itemClicked: 'View Event logs',
      },
      manageLocationNamesBtn: {
        pageRegion: 'Settings Menu',
        itemClicked: 'Manage Location Names',
      },
    },
  },

  settingsUser: {
    siteSection: '', // Dynamically set to current page,
    buttonClicks: {
      viewProfileBtn: {
        pageRegion: 'Settings Menu',
        itemClicked: 'View Profile',
      },
    },
  },
};

const ucRingCentralPermissionsModal = 'UC RingCentral Permissions Modal';
export const settingsUserUcRingCentralPermissionsModal = {
  buttonClicks: {
    ringCentralConfirmBtn: {
      itemClicked: 'Confirm',
      pageRegion: ucRingCentralPermissionsModal,
    },
    ringCentralCancelBtn: {
      itemClicked: 'Cancel',
      pageRegion: ucRingCentralPermissionsModal,
    },
    ringCentralXBtn: {
      itemClicked: 'x',
      pageRegion: ucRingCentralPermissionsModal,
    },
  },
};

const cancelFormModal = 'Cancel Form Modal';
export const cancelModalAnalytics = {
  buttonClicks: {
    leavePageBtn: {
      itemClicked: 'Leave Page',
      pageRegion: cancelFormModal,
    },
    xBtn: {
      itemClicked: 'x',
      pageRegion: cancelFormModal,
    },
    cancelBtn: {
      itemClicked: 'Cancel',
      pageRegion: cancelFormModal,
    },
  },
};
