/**
 * Analytics SDR for Fiber Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Fiber Internet';
const detailsSubSection = 'Fiber Internet Details';

export const fiberAnalytics: IAnalyticSdr = {
  fiberInventory: {
    siteSection,
    siteSubSection: 'Fiber Internet Inventory',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
    },
  },
  fiberDetails: {
    siteSection,
    siteSubSection: detailsSubSection,
    buttonClicks: {
      utilizationTab: {
        itemClicked: 'Utilization',
      },
      equipmentTab: {
        itemClicked: 'Equipment Status',
      },
      equipmentTabNoMetricsSupportLink: {
        itemClicked: 'support page',
      },
      createReport: {
        itemClicked: 'Create Report',
      },
      createEquipmentReport: {
        itemClicked: 'Create Report',
        pageRegion: 'Equipment Status',
      },
      createTicket: {
        itemClicked: 'Create Ticket',
      },
      wirelessInternetLink: {
        itemClicked: 'Wireless Internet',
      },
      wirelessModalCloseBtn: {
        itemClicked: 'Close',
        pageRegion: 'Wireless Internet Modal',
      },
      wirelessModalVisitBtn: {
        itemClicked: 'View More Wireless Internet Details',
        pageRegion: 'Wireless Internet Modal',
      },
      wirelessModalXBtn: {
        itemClicked: 'x',
        pageRegion: 'Wireless Internet Modal',
      },
    },
  },
  fiberCreateReport: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Fiber Report',
  },
  fiberCreateReportSuccess: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Fiber Report - Success',
  },
  fiberEquipmentCreateReport: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Fiber Equipment Report',
  },
  fiberEquipmentCreateReportSuccess: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Create Fiber Equipment Report - Success',
  },
  fiberWirelessBackUpModal: {
    siteSection,
    siteSubSection: detailsSubSection,
    siteSubSubSection: 'Wireless Connection Modal',
  },
};
