import {
  getAppEnv,
  isDemo,
  isLocalhost,
  isMock,
  mockEndpoints,
} from '../config/appConfig/appConfig.utils';

export interface IMockConfig {
  /** Whether or not the app environment is running as Mock. */
  readonly isMock: boolean;

  /** Whether or not the app environment is running as Demo. */
  readonly isDemo: boolean;

  /** Whether or not the app is running locally */
  readonly isLocal: boolean;

  readonly api: {
    readonly piHost: string;
    readonly authAnonymousHost: string;
    readonly directHost: string;
    readonly directLegacyHost: string;
    readonly cxdoHost: string;
    readonly directSSOHost: string;
    readonly wsVoiceCallGuardUpdatesHost: string;
    readonly wsBusinessInternetRestartUpdatesHost: string;
  };
}

export const mockConfig: IMockConfig = {
  isMock: isMock(getAppEnv()),
  isDemo: isDemo(getAppEnv()),
  isLocal: isLocalhost(),
  api: mockEndpoints(),
};
