/**
 * Analytics SDR for Video Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */
import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'TV';
export const tvAnalytics: IAnalyticSdr = {
  tvInventory: {
    siteSection,
    siteSubSection: 'TV Inventory',
    menuSelections: {
      viewMenu: {
        itemClicked: 'Views',
      },
      servicesMenu: {
        itemClicked: 'Services type',
      },
    },
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      viewFilterBtn: {
        itemClicked: 'View & Filter',
      },
    },
  },
  tvDashboard: {
    siteSection,
    siteSubSection: 'Dashboard',
    // TODO: update siteSubSubSection, & buttonClicks
  },
};
