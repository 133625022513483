/**
 * Analytics SDR for Registration Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import {
  IRegistrationAnalyticsSdr,
  IRegisterSuccessModalAnalytics,
} from '../types/registrationAnalytics';
import tdcsDefault from '../../../contexts/utils/tdcsDefault';

const registerSection = 'Register';
const resendEmailEventName = 'Resend Email';

const registerSuccessModal: IRegisterSuccessModalAnalytics = {
  buttonClicks: {
    closeBtn: {
      pageRegion: 'Registration Success Modal',
      itemClicked: 'Close',
    },
  },
};

export const registrationAnalytics: IRegistrationAnalyticsSdr = {
  registerStep1: {
    siteSection: registerSection,
    siteSubSection: 'Register - Step 1',
    buttonClicks: {
      agreeContinue: {
        itemClicked: 'Agree & Continue',
      },
      termsLink: {
        itemClicked: 'Terms and Conditions',
      },
      supportPageLnk: {
        itemClicked: 'Support Page',
      },
    },
  },

  registerStep1AlertRegistered: {
    siteSection: registerSection,
    siteSubSection: 'Register - Step 1 - Alert Registered',
    buttonClicks: {
      registerNewEmailBtn: {
        itemClicked: 'Register with New Email Address',
      },
      signInBtn: {
        itemClicked: 'Sign In',
      },
      sendPwdResetBtn: {
        itemClicked: 'Forgot Password',
      },
    },
  },

  registerStep1AlertPending: {
    siteSection: registerSection,
    siteSubSection: 'Register - Step 1 - Alert Pending',
    buttonClicks: {
      resendEmailBtn: {
        itemClicked: resendEmailEventName,
      },
      newEmailRegisterBtn: {
        itemClicked: 'Register with New Email Address',
      },
    },
  },

  registerStep2: {
    siteSection: registerSection,
    siteSubSection: 'Register - Step 2',
    buttonClicks: {
      accountNumAndBanLink: {
        itemClicked: 'account number and security code',
      },
      verificationMethodsLink: {
        itemClicked: 'verification methods',
      },
      registerButton: {
        itemClicked: 'Register',
      },
    },
    menuSelections: {
      verificationMethodMenu: {
        itemClicked: 'Verification Method',
      },
    },
  },

  registerVerificationMethodsInfo: {
    siteSection: registerSection,
    siteSubSection: 'Register - Verfication Methods',
    buttonClicks: {
      tryAgainBtn: {
        itemClicked: 'Try Again',
      },
      missingInfoBtn: {
        itemClicked: `I don't have this information`,
      },
    },
  },

  registerStep2AlertNoMatch: {
    siteSection: registerSection,
    siteSubSection: 'Register - Step 2 - Alert No Match',
    buttonClicks: {
      tryAgainBtn: {
        itemClicked: 'Try Again',
      },
      supportNumber: {
        itemClicked: tdcsDefault.supportNumbers.selfReg,
      },
    },
  },

  registerStep2UnableToComplete: {
    siteSection: registerSection,
    siteSubSection: '',
    buttonClicks: {
      backToSignIn: {
        itemClicked: 'Back to Sign In',
      },
      supportNumber: {
        itemClicked: tdcsDefault.supportNumbers.selfReg,
      },
    },
  },

  registerUserValidation: {
    siteSection: registerSection,
    siteSubSection: 'Register - User Validation',
    buttonClicks: {
      continueBtn: {
        itemClicked: 'Continue',
      },
    },
  },

  registerPendingSuccess: {
    siteSection: registerSection,
    siteSubSection: 'Register - Pending Success',
    buttonClicks: {
      backToSignIn: {
        itemClicked: 'Back to Sign In',
      },
      resendEmail: {
        itemClicked: resendEmailEventName,
      },
      senetLink: {
        itemClicked: 'SpectrumEnterprise.net',
      },
    },
  },

  registerSuccess: {
    siteSection: registerSection,
    siteSubSection: 'Register - Success',
    buttonClicks: {
      senetLnk: {
        itemClicked: 'SpectrumEnterprise.net',
      },
      okBtn: {
        itemClicked: 'Ok',
      },
    },
  },

  registerError: {
    siteSection: registerSection,
    siteSubSection: 'Register - Error',
    buttonClicks: {
      contactSupportBtn: {
        itemClicked: 'Contact Support',
      },
    },
  },

  registerAccountSecurityInfo: {
    siteSection: registerSection,
    siteSubSection: 'Register - Account Number and Security Code',
    buttonClicks: {
      tryAgainBtn: {
        itemClicked: 'Try Again',
      },
      missingInfoBtn: {
        itemClicked: `I don't have my account number and security code`,
      },
    },
  },

  registerLinkExpired: {
    siteSection: registerSection,
    siteSubSection: 'Register - Link Expired',
    buttonClicks: {
      resendEmail: {
        itemClicked: resendEmailEventName,
      },
    },
  },

  registerUserCreds: {
    siteSection: registerSection,
    siteSubSection: 'Register - User Credentials',
    buttonClicks: {
      saveSignInBtn: {
        itemClicked: 'Save & Sign In',
      },
      cancelBtn: {
        itemClicked: 'Cancel',
      },
    },
  },

  registerSessionExpired: {
    siteSection: registerSection,
    siteSubSection: 'Register - Session Expired',
    buttonClicks: {
      restartRegistrationBtn: {
        itemClicked: 'Restart Registration',
      },
    },
  },

  registerContactSupport: {
    siteSection: registerSection,
    siteSubSection: 'Register - Contact Support',
    buttonClicks: {
      tryAgainBtn: {
        itemClicked: 'Try Again',
      },
      supportNumber: {
        itemClicked: tdcsDefault.supportNumbers.selfReg,
      },
    },
  },
  registerSuccessModal,
};
