import { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import * as storage from '../services/authSessionStorage';
import { useAppConfig } from '../../hooks/useAppConfig';
import { Maybe } from '../../types/types.generated';
import {
  getLegacyToken,
  getIdTokenObj,
  hasTokenExpired,
} from '../utils/authSessionUtils';
import { useAuthService } from './useAuthService';
import QuantumService from '../services/quantumService';
import { IAnalyticLoginFailedEvent } from '../types/analytics';
import { IPiNxtAPIResponse } from '../types/pinxt';
import { IAimViewAsClientParams } from '../types/authUser';
import { parseAimViewOrgAsClientQuery } from '../utils/authUserUtils';
import { IAuthSessionSignOutProps } from '../types/authSession';

export interface ITokenState {
  token: Maybe<string>;
  tokenExp: Maybe<string>;
  refreshToken: Maybe<string>;
  idToken: Maybe<string>;
}

/**
 * Manages the users Auth Access token with PiNxt.
 * Don't use directly from within page components, instead use useAuthSession hook
 */
export const useAccessToken = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tokenState, setTokenState] = useState<ITokenState>({
    token: storage.getAccessToken(),
    refreshToken: storage.getRefreshToken(),
    idToken: storage.getIdToken(),
    tokenExp: storage.getAccessTokenExp(),
  });

  const {
    isMock,
    isProd,
    isTrueProd,
    auth: { loginUrl, piEsso },
    api: { auth: authHost },
  } = useAppConfig();

  const { token, tokenExp, refreshToken, idToken } = tokenState;

  const legacyToken = useMemo(() => getLegacyToken(idToken), [idToken]);
  const idTokenObj = useMemo(() => getIdTokenObj(idToken), [idToken]);

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tokenRefreshing, setTokenRefreshing] = useState(false);

  const { fetchTokensOnRefresh, fetchTokens, fetchAuthCode, fetchLogout } =
    useAuthService();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /**
   * Sets new auth session
   * @param accessTkn set the user's authenticated JWT token
   * @param refreshTkn set the user's JWT token to be used onpage refresh
   * @param idTkn set the user's (unencrypted) JWT token used for SOME api requests still
   */
  const setSession = (accessTkn: string, refreshTkn: string, idTkn: string) => {
    // Save state to local storage
    storage.setAccessToken(accessTkn);
    storage.setRefreshToken(refreshTkn);

    // ENTDEL-10991 Secure internal JWT (remove from Production environments)
    if (!isProd && !isTrueProd) {
      storage.setIdToken(idTkn);
    }

    // update context state
    setTokenState({
      token: storage.getAccessToken(),
      tokenExp: storage.getAccessTokenExp(),
      refreshToken: storage.getRefreshToken(),
      idToken: storage.getIdToken(),
    });

    setIsAuthenticated(true);
    setTokenRefreshing(false);
  };

  /**
   * Clear the entire auth session from local storage and reset state
   */
  const clearSession = useCallback(() => {
    storage.clearSessionStorageAndAuthTokens(isMock);

    setIsAuthenticated(false);
    setIsAuthenticating(false);
    setTokenRefreshing(false);

    // clear cache from Query data
    queryClient.removeQueries();
  }, [isMock, queryClient]);

  /**
   * Refreshes the current auth session by requesting new Auth Tokens
   */
  const refreshSession = useCallback(async (): Promise<void> => {
    setTokenRefreshing(true);

    // If no tokens, reset and void auth session.
    if (!refreshToken) {
      console.error('Unable to refresh tokens, no refresh token');
      clearSession();
      window.location.href = `${loginUrl}?login-error=true`;
      return;
    }

    try {
      const response = await fetchTokensOnRefresh(refreshToken);

      if (response.resultCode !== '200') {
        console.error('Unable to refresh auth tokens, logging out.');
        QuantumService.refreshAuth({
          eventInfo: {
            appErrorCode: response.resultCode,
          },
        });
        clearSession();
        window.location.href = `${loginUrl}?login-error=true`;
        return;
      }

      setSession(
        response.access_token,
        response.refresh_token!,
        response.id_token!
      );

      QuantumService.refreshAuth(
        {
          eventInfo: {},
        },
        response.id_token!,
        idTokenObj
      );
    } catch (err) {
      console.error('Unable to refresh auth tokens, logging out.');
      QuantumService.refreshAuth({
        eventInfo: {
          appErrorCode: 'unknown', // TODO: Add FE code on Verbatim Integration
        },
      });
      clearSession();
      window.location.href = `${loginUrl}?login-error=true`;
    }
  }, [clearSession, fetchTokensOnRefresh, idTokenObj, loginUrl, refreshToken]);

  /**
   * Starts a auth session with a PiNXt supplied Auth Token
   * @param authCode PiNXt supplied Auth Token
   */
  const authenticateWithCode = useCallback(
    async (authCode: string): Promise<void> => {
      setIsAuthenticated(false);
      setIsAuthenticating(true);
      setIsAuthError(false);

      try {
        const res = await fetchTokens(authCode);

        if (!res || res.resultCode !== '200') {
          return await Promise.reject(new Error('fetchTokens'));
        }

        setSession(res.access_token, res.refresh_token!, res.id_token!);

        setIsAuthenticating(false);

        return await Promise.resolve();
      } catch (error) {
        setIsAuthenticating(false);
        setIsAuthError(true);
        return Promise.reject(error);
      }
    },
    [fetchTokens]
  );

  /**
   * Starts a auth session by kicking off the PiNxt authentication handshake.
   * @param username User's username
   * @param password User's password
   */
  const authenticateViaCreds = useCallback(
    async (username: string, password: string): Promise<void> => {
      setIsAuthenticated(false);
      setIsAuthenticating(true);
      setIsAuthError(false);

      QuantumService.loginStart({
        eventInfo: { loginType: 'manualAuth', username },
      });

      try {
        const res = await fetchAuthCode(username, password);

        if (!res || res.resultCode !== '200') {
          await Promise.reject(new Error('fetchAuthCode'));
        }

        return await authenticateWithCode(res.authCode);
      } catch (error: unknown) {
        setIsAuthenticating(false);
        setIsAuthError(true);

        const piNxtResError = error as IPiNxtAPIResponse;
        const quantumLoginFailed: IAnalyticLoginFailedEvent = {
          eventInfo: {
            loginType: 'manualAuth',
            appErrorCode: piNxtResError.resultCode,
            appErrorMessage: piNxtResError.resultMessage,
          },
          userInfo: null,
        };
        QuantumService.loginStop(quantumLoginFailed);

        return Promise.reject(error);
      }
    },
    [authenticateWithCode, fetchAuthCode]
  );

  /** Starts an auth session by using Charter's ESSO Flow  */
  const authenticateViaEsso = useCallback(() => {
    const { clientId, redirectUri } = piEsso;
    const qsParams = [
      `client_id=${clientId}`,
      `state=state`,
      `redirect_uri=${redirectUri}`,
    ];
    return window.location.assign(`${authHost}/login?${qsParams.join('&')}`);
  }, [authHost, piEsso]);

  /**
   * Process a redirect from OpsCenter to ViewAsClient
   */
  const processViewAsClient = useCallback(
    async (aimParams: IAimViewAsClientParams) => {
      const {
        code: piNxtTransferCode,
        viewingAsPAG,
        aimHostOverride,
      } = aimParams;

      // The analyticsContext will handle the trackLoginStop when user info is loaded
      QuantumService.loginStart({
        eventInfo: { loginType: 'ssoAuth', username: '' },
      });

      try {
        await authenticateWithCode(piNxtTransferCode!);
        const params = [];

        if (viewingAsPAG) {
          params.push(`viewingAsPAG=${viewingAsPAG}`);
        }

        if (aimHostOverride) {
          params.push(`aimHostOverride=${aimHostOverride}`);
        }
        const paramString = params.join(`&`);
        // navigate through to the home page
        navigate(`/?${paramString}`);
      } catch {
        QuantumService.loginStop({
          eventInfo: {
            loginType: 'ssoAuth',
            appErrorCode: 'unknown',
            appErrorMessage: 'unknown',
          },
          userInfo: null,
        });
        clearSession();
        window.location.href = `${loginUrl}?login-error=true`;
      }
    },
    [authenticateWithCode, clearSession, loginUrl, navigate]
  );

  /**
   * Sign the user out and clear auth session
   * @param showError whether or not to show the Error Message banner on logout
   */
  const signOut = useCallback(
    async (options?: IAuthSessionSignOutProps) => {
      let loginError = options?.showError;
      try {
        await fetchLogout(token!);
      } catch (err) {
        console.error(err);
        loginError = true;
      } finally {
        QuantumService.logout();
        clearSession();
        window.location.href = `${loginUrl}${
          options?.redirectRoute ||
          (loginError ? '?login-error=true' : '?signed-out=true')
        }`;
      }
    },
    [clearSession, fetchLogout, loginUrl, token]
  );

  /**
   * Check to see if the Access Token is expired, if so try to refresh it.
   */
  const refreshTokenIfExpired = useCallback(() => {
    if (token && hasTokenExpired(tokenExp!) && !tokenRefreshing) {
      return refreshSession();
    }

    return Promise.resolve();
  }, [refreshSession, token, tokenExp, tokenRefreshing]);

  /**
   * Run once on Component Load
   */
  useEffect(() => {
    // check for a internal redirect PiNxt code
    const aimParams = parseAimViewOrgAsClientQuery();

    // Handle ESSO PINxt post redirect.
    if (aimParams.code) {
      processViewAsClient(aimParams).then(
        () => {
          setIsLoading(false);
        },
        () => {}
      );
      return;
    }

    if (token) {
      // Next check to be sure the token hasn't expired
      refreshTokenIfExpired().then(
        () => {
          // Token is valid, auth session ready to go
          setIsAuthenticated(true);
          setIsLoading(false);
        },
        () => {}
      );
      return;
    }

    // No token, user isn't authenticated
    setIsAuthenticated(false);
    setIsLoading(false);

    // Disable lint check as I want this to run once on component load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    accessToken: token,
    accessTokenExp: tokenExp,
    refreshToken,
    idToken,
    idTokenObj,
    legacyToken,

    isAuthenticating,
    isAuthenticated,
    isAuthError,

    clearAuthSession: clearSession,
    signOut,
    authenticateWithCode,
    authenticateViaCreds,
    authenticateViaEsso,
    refreshTokenIfExpired,
  };
};
