import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { UserCapability } from '../../types/userCapability';
import { locationNamesRoutePerm } from '../../components/Routes/routePermissions';
import { settingsAnalytics } from './types/settingsAnalytics';

const Account = lazy(
  () => import(/* webpackChunkName: "account" */ './Account')
);

const EventLogs = lazy(
  () => import(/* webpackChunkName: "event-logs" */ './EventLogs')
);

const LocationNames = lazy(
  () => import(/* webpackChunkName: "location-names" */ './LocationNames')
);

const Profile = lazy(
  () => import(/* webpackChunkName: "profile" */ './Profile')
);

const UsersList = lazy(
  () => import(/* webpackChunkName: "users-list" */ './UsersList')
);

const UserEdit = lazy(
  () => import(/* webpackChunkName: "user-edit" */ './UserEdit')
);

const UserInvite = lazy(
  () => import(/* webpackChunkName: "user-invite" */ './UserInvite')
);

const {
  settingsAccount,
  settingsLocationNames,
  settingsProfile,
  settingsUsers,
  settingsUserDetails,
  settingsUserInvite,
  settingsEventLogs,
} = settingsAnalytics;

export const settingsRoutes = (
  <>
    <Route path="settings/*">
      <Route
        path="account"
        element={
          <PageRoute pageInfo={settingsAccount}>
            <Account />
          </PageRoute>
        }
      />
      <Route
        path="location-names"
        element={
          <PageRoute
            orgServices={locationNamesRoutePerm.orgServices}
            pageInfo={settingsLocationNames}
          >
            <LocationNames />
          </PageRoute>
        }
      />
      <Route
        path="profile"
        element={
          <PageRoute pageInfo={settingsProfile}>
            <Profile />
          </PageRoute>
        }
      />
      <Route
        path="users"
        element={
          <PageRoute
            userCapabilities={[
              UserCapability.ViewUsers,
              UserCapability.EndUserAllAdmin,
              UserCapability.SuperAdmin,
            ]}
            pageInfo={{
              ...settingsUsers,
              title: 'Account Users',
            }}
          >
            <UsersList />
          </PageRoute>
        }
      />
      <Route
        path="users/edit/:userId"
        element={
          <PageRoute pageInfo={settingsUserDetails}>
            <UserEdit />
          </PageRoute>
        }
      />
      <Route
        path="users/invite"
        element={
          <PageRoute pageInfo={settingsUserInvite}>
            <UserInvite />
          </PageRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>

    <Route
      path="event-logs"
      element={
        <PageRoute pageInfo={settingsEventLogs}>
          <EventLogs />
        </PageRoute>
      }
    />
  </>
);
