/**
 * Initializes Feature Toggles Context that gives all components access to the
 * Feature Toggles object from the useFeatureToggles hook.
 *
 * @see https://chalk.charter.com/display/ERSPM/FE+Configs.
 */

import React, { createContext, useCallback, useMemo } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import {
  getLocalFeatureTogglesByEnv,
  isFeatureToggleEnabled,
} from '../config/featureToggles/featureToggles.utils';
import { useTdcsConfig } from '../hooks/useTdcsConfig';
import {
  FeatureToggleName,
  IFeatureToggles,
  IFlatFeatureToggles,
} from '../config/featureToggles/featureToggles.types';
import { useAppConfig } from '../hooks/useAppConfig';
import QuantumService from './services/quantumService';
import { getErrorMessage, getErrorName } from '../utils/errorUtils';

interface IFeatureTogglesContext {
  envToggles: IFeatureToggles;
  isFeatureToggledOn: (toggleName: FeatureToggleName) => boolean;
  getMultiToggleSettings: (
    toggleNames: FeatureToggleName[]
  ) => IFlatFeatureToggles;
}

const defaultValue: IFeatureTogglesContext = {
  envToggles: {} as any,
  isFeatureToggledOn: () => false,
  getMultiToggleSettings: () => {
    return {} as IFlatFeatureToggles;
  },
};

export const FeatureTogglesContext =
  createContext<IFeatureTogglesContext>(defaultValue);

export const FeatureTogglesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { app, isProd, dataDog } = useAppConfig();
  // TODO: Discuss if we want to update TDCS for additional toggle control
  // const { featureToggles } = useTdcsConfig();

  let { envToggles, isFeatureToggledOn, getMultiToggleSettings } = defaultValue;

  try {
    const { environment, toggles } = getLocalFeatureTogglesByEnv(app.env);

    envToggles = {
      environment,
      toggles,
    };
  } catch (error) {
    QuantumService.jsError({
      appErrorType: 'application',
      msgFeatureName: 'feature-toggles',
      appErrorCode: getErrorName(error) || 'unexpected',
      appErrorMessage: getErrorMessage(error),
    });
  }

  const togglesMap: Map<FeatureToggleName, boolean> = useMemo(() => {
    return new Map(
      envToggles.toggles.map((toggle) => [toggle.name, toggle.isEnabled])
    );
  }, [envToggles]);

  // TODO: Consume GetUserFeatureToggles to pull data from BE to override local toggles.

  if (!isProd) {
    // List out all the available toggles in Session Storage, so we can change them on the fly (only available to lowers)
    togglesMap.forEach((isEnabled, toggleName) => {
      if (!sessionStorage.getItem(`TOGGLE_${toggleName}`)) {
        sessionStorage.setItem(`TOGGLE_${toggleName}`, `${isEnabled}`);
      }
    });
  }

  isFeatureToggledOn = useCallback(
    (toggleName: FeatureToggleName) => {
      const isToggledOn = isFeatureToggleEnabled(
        toggleName,
        togglesMap,
        isProd
      );

      if (dataDog.enabled) {
        datadogRum.addFeatureFlagEvaluation(toggleName, isToggledOn);
      }

      return isToggledOn;
    },
    [togglesMap, isProd, dataDog.enabled]
  );

  getMultiToggleSettings = useCallback(
    (toggleNames: FeatureToggleName[]): IFlatFeatureToggles => {
      const toggleResults = {} as IFlatFeatureToggles;
      toggleNames.forEach((toggleName) => {
        toggleResults[`${toggleName}`] = isFeatureToggledOn(toggleName);
      });
      return toggleResults;
    },
    [isFeatureToggledOn]
  );

  return (
    <FeatureTogglesContext.Provider
      value={useMemo(
        () => ({
          envToggles,
          isFeatureToggledOn,
          getMultiToggleSettings,
        }),
        [envToggles, isFeatureToggledOn, getMultiToggleSettings]
      )}
    >
      {children}
    </FeatureTogglesContext.Provider>
  );
};
