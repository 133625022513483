import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { manageSecurityRoutePerm } from '../../components/Routes/routePermissions';
import { mssAnalytics } from './types/mssAnalytics';

const ManagedSecurityInventory = lazy(
  () =>
    import(
      /* webpackChunkName: "managed-security-inventory" */ './ManagedSecurityInventory'
    )
);

const { orgServices } = manageSecurityRoutePerm;
const { mssInventory } = mssAnalytics;

export const managedSecurityRoutes = (
  <Route
    path="services/managed-security/inventory"
    element={
      <PageRoute orgServices={orgServices} pageInfo={mssInventory}>
        <ManagedSecurityInventory />
      </PageRoute>
    }
  />
);
