import { IRoutePageInfo } from '../../types/common';

export const getPageName = (pageInfo: IRoutePageInfo): string => {
  const { title, siteSection, siteSubSection, siteSubSubSection } = pageInfo;

  // if no title provided, see if we can get it from the site sections starting with the last
  if (!title) {
    return siteSubSubSection ?? siteSubSection ?? siteSection ?? '';
  }

  return title;
};
