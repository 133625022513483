import { useCallback, useEffect, useState, useMemo } from 'react';
import {
  useAimAllCapabilitiesForUserQuery,
  useAimOrganizationByIdQuery,
  useGetOrgServicesQuery,
} from '../../hooks/useRequest.generated';
import {
  IAimCapabilitiesForUser,
  IAimOrganization,
  IAimSelf,
  IOrganization,
  Maybe,
} from '../../types/types.generated';
import { IAuthUser } from '../types/authUser';
import { buildAuthUser } from '../utils/authUserUtils';

interface IAuthUserServiceProps {
  loadUserOnSuccess: () => void;
  loadUserOnFailure: () => void;
}

/**
 * Hook to get the populate the current Authenticated user information based on various sources.
 * Don't use directly but instead use the useAuthUser hook with the AuthUser Context.
 */
export const useAuthUserFetcher = ({
  loadUserOnSuccess,
  loadUserOnFailure,
}: IAuthUserServiceProps) => {
  const [authUser, setAuthUser] = useState<Maybe<IAuthUser>>(null);

  const [loadUser, setLoadUser] = useState(false);
  const [isInternalUser, setIsInternalUser] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [selfData, setSelfData] = useState<Maybe<IAimSelf>>(null);

  const {
    isSuccess: capabilitiesIsSuccess,
    isError: capabilitiesIsError,
    data: capabilitiesRes,
  } = useAimAllCapabilitiesForUserQuery({}, { enabled: loadUser });

  const {
    isSuccess: orgIsSuccess,
    isError: orgIsError,
    data: orgRes,
  } = useAimOrganizationByIdQuery(
    {
      organizationId: selectedOrgId,
    },
    { enabled: loadUser && isInternalUser }
  );

  const {
    isSuccess: orgServicesIsSuccess,
    isError: orgServicesIsError,
    data: orgServicesRes,
  } = useGetOrgServicesQuery(
    {
      portalAccountGUID: selectedOrgId,
    },
    {
      enabled: loadUser,
    }
  );

  const orgServices: Maybe<IOrganization> = useMemo(
    () => orgServicesRes?.user?.organization || null,
    [orgServicesRes]
  );

  const capabilities: Maybe<IAimCapabilitiesForUser> =
    capabilitiesRes?.allCapabilitiesForUser || null;

  const organization: Maybe<IAimOrganization> =
    orgRes?.organizationById || null;

  useEffect(() => {
    if (selfData) {
      setIsInternalUser(selfData.isInternalUser || false);
    }
  }, [selfData]);

  const isError = capabilitiesIsError || orgIsError || orgServicesIsError;

  const allSourcesFinished =
    selfData &&
    capabilitiesIsSuccess &&
    capabilities &&
    orgServicesIsSuccess &&
    orgServices &&
    ((isInternalUser && orgIsSuccess && organization) || !isInternalUser) &&
    selectedOrgId;

  useEffect(() => {
    if (allSourcesFinished) {
      const user = buildAuthUser(
        selfData,
        capabilities,
        organization,
        orgServices,
        selectedOrgId
      );
      setAuthUser(user);
      loadUserOnSuccess();
      return;
    }

    if (isError) {
      loadUserOnFailure();
    }
  }, [
    allSourcesFinished,
    capabilities,
    orgServices,
    organization,
    selfData,
    selectedOrgId,
    isError,
    loadUserOnSuccess,
    loadUserOnFailure,
  ]);

  /**
   * Once the auth session is authenticated via PI, populate the current auth user bu calling various sources.
   * @param viewingAsPAG the org id they're view as (if internal user)
   */
  const loadAuthUser = useCallback(
    (data: Maybe<IAimSelf>, selectedOrg: string): void => {
      setSelfData(data);
      setSelectedOrgId(selectedOrg);
      setLoadUser(true);
    },
    []
  );

  /**
   * Clear the auth user on session sign-out so it can start all over again.
   */
  const clearAuthUser = useCallback((): void => {
    setLoadUser(false);
  }, []);

  return {
    authUser,
    loadAuthUser,
    clearAuthUser,
  };
};
