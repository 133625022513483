// Packages
import React from 'react';

// Hooks
import { useAnalytics } from '../../hooks/useAnalytics';
import { useAuthSession } from '../../hooks/useAuthSession';

// Components
import ModalWrapper from '../ModalWrapper';

// Utils
import { currentPageTitle } from '../../contexts/utils/analyticsUtils';
import { loginAnalytics } from '../../domains/Login/utils/loginAnalytics';

// Styles
import './AutoLogout.scss';

/** Show Auto Logout modal if user has been idle for an extended period of time. */
const AutoLogout = () => {
  // Hooks
  const { trackButtonEvent } = useAnalytics();
  const { stillHereBtn, signOutBtn } =
    loginAnalytics.autoLogoutModal.buttonClicks;

  const { showIdlePrompt, resetIdlePrompt, signOut } = useAuthSession();

  const handleStillHere = (): void => {
    trackButtonEvent(stillHereBtn, { siteSection: currentPageTitle() });
    resetIdlePrompt();
  };

  const handleSignOut = (): void => {
    trackButtonEvent(signOutBtn, { siteSection: currentPageTitle() });
    signOut().catch(() => {});
  };

  return (
    <ModalWrapper
      title="Still There?"
      canShow={showIdlePrompt}
      onHide={handleStillHere}
      ctaCopy="Sign Out"
      ctaAction={handleSignOut}
      secondaryCtaCopy="Still Here"
      secondaryCtaAction={handleStillHere}
    >
      <p>To keep your account safe, we&apos;ll sign you out in two minutes.</p>
    </ModalWrapper>
  );
};

export default AutoLogout;
