import { KiteProgressIndicator } from '@kite/react-kite';
import React from 'react';
import ModalGenericError from '../components/ModalGenericError';
import { noDataPresent, noSearchResults } from './copyText';

export const showGenericError = (
  <>
    <ModalGenericError />
    <p>{noDataPresent}</p>
  </>
);

export interface IRenderPageContentProps {
  /**  whether or not the use request as successful.  */
  isSuccess?: boolean;

  /**  whether or not the use request has an error */
  isError?: boolean;

  /**  when data has returned from the API, whether or not they have data. (optional) */
  hasData?: boolean;

  /** when filtering the data, whether or not they have any left. (optional) */
  hasFilteredData?: boolean;

  /** Whether or not to return loading progress indicator. (optional) */
  isLoading?: boolean;

  /** when successful, the function will be called for the JSX data to be rendered. */
  onSuccessContent?: () => React.ReactNode;

  /** When successful, but not data is present, provides JSX content to be rendered. (optional)
   * By default just renders the generic "no data" message
   * */
  onNoDataContent?: () => React.ReactNode;

  /** On Loading, supply custom loader content. (optional)
   * By default just renders the generic Kite Progress Indicator
   * */
  onLoadingContent?: () => React.ReactNode;

  /** What custom content to display on error. (optional)
   * By default shows the general modal error
   * */
  onErrorContent?: () => React.ReactNode;

  /** What custom content to display when search did't produce any results. (optional)
   * By default shows the general message of no Search Results
   * */
  onNoSearchResultsContent?: () => React.ReactNode;

  /**
   * What content to show (if any) pre api request
   */
  onInitialContent?: () => React.ReactNode;
}

/**
 * Handles the common renderings of API Requests
 * @deprecated This has known performance issues, use the RenderApiContent component version instead.
 */
export function renderApiContent({
  isSuccess,
  isError,
  hasData,
  hasFilteredData,
  isLoading,
  onSuccessContent,
  onNoDataContent,
  onLoadingContent,
  onErrorContent,
  onNoSearchResultsContent,
  onInitialContent,
}: IRenderPageContentProps): React.ReactNode {
  if (isError !== undefined && isError) {
    return onErrorContent ? onErrorContent() : showGenericError;
  }

  if (isLoading !== undefined && isLoading) {
    return onLoadingContent ? (
      onLoadingContent()
    ) : (
      <KiteProgressIndicator id={new Date().valueOf().toString()} />
    );
  }

  if (isSuccess !== undefined && isSuccess) {
    if (hasData !== undefined && !hasData) {
      return onNoDataContent ? onNoDataContent() : <p>{noDataPresent}</p>;
    }
    if (hasFilteredData !== undefined && !hasFilteredData) {
      return onNoSearchResultsContent ? (
        onNoSearchResultsContent()
      ) : (
        <p id="search-results-no-results-message">{noSearchResults}</p>
      );
    }

    return onSuccessContent ? onSuccessContent() : <i />;
  }

  return onInitialContent ? onInitialContent() : <i />;
}
