// chalk page ref https://chalk.charter.com/display/SPECENT/FE+SSO+integration+with+getSsoUrl+endpoint

// These SSO Service IDs are given to us by the AIM team
// owners of the getSsoUrl GQL service itself.
enum SsoServiceId {
  MNE_Meraki = 'b7f3a168-0b90-4811-a965-40a508d0754b',
  MNE_Site_Manager = 'eaak1nf2-e2fa-490d-9197-8cd94a962213',
  SASE_CS_Meraki = '98e1e4f3-dea8-4bd0-a0cd-e2a4a70664d9',
  SASE_Duo = 'eac60fa9-a815-4a87-a1a0-bbb97b321f83',
  ENE_FortiCloud = '',
  // NOTE:
  // The AIM system only handles SSO for Secure Internet Meraki,
  // but not Fortinet (aka Forticloud). Thus, there is no AIM service ID
  // here for Securet Internet Fortinet, and we do not call
  // AIM getSsoUrl when Secure Internet devices are managed by
  // Fortinet.
  SecureInternet_Meraki = '41c05b22-855d-4bdb-8f3a-f610f78f19d3',
  UC_RingCentral = '9cd693bf-beec-4d61-80cf-14d55d5141f3',
}

enum SsoUrlLabel {
  Cloud_Security = 'Cloud Security',
  Secure_Access = 'Secure Access',
  Manage_Devices_and_Metrics = 'Manage Devices and Metrics',
  Manage_Conference_WiFi_Passwords = 'Manage Conference WiFi Passwords',
  SecureInternet = 'View Security Features',
  RingCentral = 'RingCentral',
}

enum SsoUrlName {
  MNE_Portal = 'Managed Network Edge Portal',
  DUO_Admin_Portal = 'Duo Admin Portal',
  ENE_Portal = 'Enterprise Network Edge Portal',
  Site_and_Conference_Manager_Portal = 'Site and Conference Manager Portal',
  SecureInternet_Portal = 'Secure Internet Portal',
  RingCentral_Portal = 'RingCentral Portal',
}

export enum SsoFallBackUrl {
  MNE_Meraki = 'https://account.meraki.com/login/dashboard_login?go=%2F',
  MNE_Site_Manager = 'https://spectrumenterprise.wifiuseradmin.com',
  ENE_FortiCloud = 'https://login.forticloud.com/samlsplash?',
  SASE_CS_Meraki = 'https://account.meraki.com/login/dashboard_login?go=%2F',
  SASE_Duo = 'https://admin.duosecurity.com/login',
  SecureInternet_Meraki = 'https://account.meraki.com/login/dashboard_login?go=%2F',
  UC_RingCentral = 'https://login.spectrumenterprise.ringcentral.com/sw.html',
}

export interface SSOLink {
  id: SsoServiceId;
  label: SsoUrlLabel;
  name: SsoUrlName;
  fallBackUrl: SsoFallBackUrl;
}

interface SSOLinks {
  MNE: {
    Meraki: SSOLink;
    SiteManager: SSOLink;
  };
  ENE: {
    ForiCloud: SSOLink;
  };
  CloudSecurity: {
    Meraki: SSOLink;
  };
  SecureAccess: {
    DUO: SSOLink;
  };
  SecureInternet: {
    Meraki: SSOLink;
  };
  UC: {
    RingCentral: SSOLink;
  };
}

export const SSOLinksObject: SSOLinks = {
  MNE: {
    Meraki: {
      id: SsoServiceId.MNE_Meraki,
      label: SsoUrlLabel.Manage_Devices_and_Metrics,
      name: SsoUrlName.MNE_Portal,
      fallBackUrl: SsoFallBackUrl.MNE_Meraki,
    },
    SiteManager: {
      id: SsoServiceId.MNE_Site_Manager,
      label: SsoUrlLabel.Manage_Conference_WiFi_Passwords,
      name: SsoUrlName.Site_and_Conference_Manager_Portal,
      fallBackUrl: SsoFallBackUrl.MNE_Site_Manager,
    },
  },
  ENE: {
    ForiCloud: {
      id: SsoServiceId.ENE_FortiCloud,
      label: SsoUrlLabel.Manage_Devices_and_Metrics,
      name: SsoUrlName.ENE_Portal,
      fallBackUrl: SsoFallBackUrl.ENE_FortiCloud,
    },
  },
  CloudSecurity: {
    Meraki: {
      id: SsoServiceId.SASE_CS_Meraki,
      label: SsoUrlLabel.Cloud_Security,
      name: SsoUrlName.MNE_Portal,
      fallBackUrl: SsoFallBackUrl.SASE_CS_Meraki,
    },
  },
  SecureAccess: {
    DUO: {
      id: SsoServiceId.SASE_Duo,
      label: SsoUrlLabel.Secure_Access,
      name: SsoUrlName.DUO_Admin_Portal,
      fallBackUrl: SsoFallBackUrl.SASE_Duo,
    },
  },
  SecureInternet: {
    Meraki: {
      id: SsoServiceId.SecureInternet_Meraki,
      label: SsoUrlLabel.SecureInternet,
      name: SsoUrlName.SecureInternet_Portal,
      fallBackUrl: SsoFallBackUrl.SecureInternet_Meraki,
    },
  },
  UC: {
    RingCentral: {
      id: SsoServiceId.UC_RingCentral,
      label: SsoUrlLabel.RingCentral,
      name: SsoUrlName.RingCentral_Portal,
      fallBackUrl: SsoFallBackUrl.UC_RingCentral,
    },
  },
};
