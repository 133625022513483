// Packages
import React from 'react';
import { createPortal } from 'react-dom';

// Components
import { KiteDialog, KiteFormDialog } from '@kite/react-kite';

// Utils

// Styles
import './ModalWrapper.scss';

/** Wrapper for KiteDialogs that opens it in a Portal component */

export interface IModalWrapperProps {
  /**  Children to render in the modal */
  children: any;
  /** Boolean for is modal is open */
  canShow: boolean;
  /** Closed modal */
  onHide: () => void;
  /** Primary Button Label */
  ctaCopy: string;
  /** Primary Button Action */
  ctaAction: () => void;

  /** Optional KiteDialog Porperties */
  title?: string;
  secondaryCtaCopy?: string;
  secondaryCtaAction?: () => void | null;
  className?: string;
  /** String to append to component's "id" attribute, after "rk_dialog_" */
  icon?: string;
  /** Whether this dialog contains form inputs */
  isFormDialog?: boolean;

  returnFocusOnDeactivate?: boolean;
  reverseButtonOrder?: boolean;
  allowClickAway?: boolean;
}

const ModalWrapper = ({
  children,
  canShow,
  onHide,
  title,
  ctaAction,
  ctaCopy,
  secondaryCtaAction,
  secondaryCtaCopy,
  className,
  icon,
  isFormDialog = false,
  returnFocusOnDeactivate = true,
  reverseButtonOrder,
  allowClickAway,
}: IModalWrapperProps) => {
  let portalRoot = document.getElementById('portal-root');

  if (!portalRoot) {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', 'portal-root');
    portalRoot = rootContainer;
  }

  const dialog = isFormDialog ? (
    <KiteFormDialog
      className={className || ''}
      open={canShow}
      title={title || ''}
      onClose={onHide}
      allowClickAway={allowClickAway}
      submitBtnLabel={ctaCopy}
      onSubmit={ctaAction}
      secondaryBtnLabel={secondaryCtaCopy}
      onSecondaryBtnClick={secondaryCtaAction}
      icon={icon}
      returnFocusOnDeactivate={returnFocusOnDeactivate}
      reverseButtonOrder={reverseButtonOrder}
    >
      {children}
    </KiteFormDialog>
  ) : (
    <KiteDialog
      className={className || ''}
      open={canShow}
      title={title || ''}
      onClose={onHide}
      id={className}
      allowClickAway={allowClickAway}
      primaryBtnLabel={ctaCopy}
      onPrimaryBtnClick={ctaAction}
      secondaryBtnLabel={secondaryCtaCopy}
      onSecondaryBtnClick={secondaryCtaAction}
      icon={icon}
      returnFocusOnDeactivate={returnFocusOnDeactivate}
      reverseButtonOrder={reverseButtonOrder}
    >
      {children}
    </KiteDialog>
  );
  return createPortal(dialog, portalRoot) as React.ReactNode;
};

export default ModalWrapper;
