/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import isArray from 'lodash/isArray';
import prettyBytes from 'pretty-bytes';
import dayjs from 'dayjs';
import { ILocationAddress, Maybe } from '../types/types.generated';

export const capitalize = (word: string) => {
  if (word) {
    const firstChar = word.charAt(0).toUpperCase();
    return firstChar + word.slice(1).toLocaleLowerCase();
  }
  return '';
};

export const capitalizeEachWord = (words: string | undefined) => {
  if (words) {
    const output = words.split(' ').map((word) => capitalize(word));
    return output.join(' ');
  }
  return '';
};

export const parseInitials = (name: string) => {
  const [firstName, lastName] = (name || '').split(' ');
  if (!lastName) {
    return firstName[0];
  }
  return firstName[0] + lastName[0];
};

export const isFriendlyName = (nameString: string): boolean => {
  const isWhitespaceString = (str: string): boolean =>
    !str.replace(/\s/g, '').length;
  const isEmptyOrInvalidString: boolean =
    nameString === '--' || isWhitespaceString(nameString!);
  return !!nameString && !isEmptyOrInvalidString;
};

/**
 * Removes all non-numeric chars and leading zeros
 * Example: " 091 Main st apt 459-20? **etc." to "9145920"
 * Example: Used for Self-Registration - BAN Validation Updates (ENTDEL-8960)
 */
export const removeAllNonNumericCharsAndLeadingZeros = (
  ban: string
): string => {
  let cleanBan = removeNonNumericChars(ban);
  cleanBan = removeLeadingZeros(cleanBan);
  return cleanBan;
};

/**
 * Removes and any spaces in a str
 * Example: " this contains       spaces" to "this contains spaces"
 */
export const removeExtraWhiteSpace = (str: string): string => {
  return str.replace(/\s+/g, ' ').trim();
};

/**
 * Removes all white space in a str
 * Example: " this contains       spaces " to "thiscontainsspaces"
 */
export const removeAllWhiteSpace = (str: string): string => {
  return str.replace(/\s/g, '');
};

export const getPercentage = (value: number, total: number) => {
  return (value / total) * 100;
};

export const getPercentageDecimal = (value: number, total: number) => {
  return value / total;
};

export const formatGeneralDate = (date: string | number, format: string) =>
  dayjs(date).format(format);

/**
 * Removes and any special characters in a str
 * Example: "&this+contains_special?*chars" to "thiscontainsspecialchars"
 */
export const removeSpecialChars = (str: string): string => {
  return str.replace(/[^a-zA-Z0-9]/g, '');
};

/**
 * Removes all characters that are not numbers
 * Example: " 091 Main st apt 459-20? **etc." to "09145920"
 */
export const removeNonNumericChars = (str: string): string => {
  return str.replace(/[^0-9]/g, '');
};

/**
 * Removes leading zeros
 * Example: "0009876300" to "9876300"
 */
export const removeLeadingZeros = (str: string): string => {
  return str.replace(/^0+/, '');
};

/**
 * Formats a Kilobytes into Human readable format.
 */
export const formatBytes = (Kilobytes: number): string => {
  return prettyBytes(Kilobytes);
};

/**
 * Converts a file size in KiB (kibibyte) to kB (kilobyte)
 * This is used for on the File.size.prototype https://developer.mozilla.org/en-US/docs/Web/API/Blob/size
 *    1mb conversion to bytes with File.prototype.size comes to 1048576,
 *    so it is slightly off in the way Apple shows the size and
 *    with how File.prototype.size and how prettyBytes converts bytes into human friendly format.
 *    Question posted here:
 *    https://github.com/sindresorhus/pretty-bytes/issues/78#issuecomment-1355759680
 * */
export const convertKibibyteToKilobyte = (kibibyte: number): number => {
  return roundSingleMBValue(Math.round(kibibyte * 0.9765625));
};

/**
 * Rounds Up or down Kilobytes to the Nearest Millionth if over 1mb and under 2mb
 * This is used if the max size upload should be 1mb (used on: Ticketing Upload Modal)
 */
export const roundSingleMBValue = (kilobytes: number) => {
  const min = 1000000; // 1 times of max size
  const max = 2000000; // 2 times of max size
  if (kilobytes > min && kilobytes < max) {
    const quotient = kilobytes / min;
    return Math.round(quotient) * min;
  }
  return kilobytes;
};

/**
 * Formats a number in bits depending on size.
 */
export const formatBitsPerSecondLabel = (
  bits: number,
  decimals = 2
): string => {
  if (bits == 0) {
    return 'Bps';
  }

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bps',
    'Kbps',
    'Mbps',
    'Gbps',
    'Tbps',
    'Pbps',
    'Ebps',
    'Zbps',
    'Ybps',
  ];

  const i = Math.floor(Math.log(bits) / Math.log(k));

  return `${sizes[i]}`;
};

export const formatBitsLabel = (bits: number, decimals = 2): string => {
  if (bits == 0) {
    return 'Bits';
  }

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bits', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

  const i = Math.floor(Math.log(bits) / Math.log(k));

  return `${sizes[i]}`;
};

/**
 * Formats a number in bytes depending on size.
 */
export const formatBytesLabel = (bits: number, decimals = 2): string => {
  if (bits == 0) {
    return 'Bytes';
  }

  const bytes = bits / 8;
  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${sizes[i]}`;
};

/**
 * Round off bits
 */
export const roundOffBits = (bits: number, decimals = 2): string => {
  const dm = decimals < 0 ? 0 : decimals;
  const k = 1000;

  let formattedBits = (Math.round(bits * 100) / 100).toFixed(dm);

  if (bits !== 0) {
    const i = Math.floor(Math.log(bits) / Math.log(k));
    formattedBits = (bits / k ** i).toFixed(dm);

    return (+formattedBits * k ** i).toString();
  }

  return formattedBits;
};

/**
 * Formats a number in bits depending on size.
 */
export const formatBitsAsFloatString = (bits: number, decimals = 2): string => {
  const dm = decimals < 0 ? 0 : decimals;
  const k = 1000;

  let formattedBits = (Math.round(bits * 100) / 100).toFixed(dm);

  if (bits != 0) {
    const i = Math.floor(Math.log(bits) / Math.log(k));
    formattedBits = (bits / k ** i).toFixed(dm);
  }

  return formattedBits;
};

/**
 * Convert bits to bytes depending on size
 */
export const formatBitsToByteString = (bits: number, decimals = 2): string => {
  if (bits <= 0) {
    return '0.00';
  }

  const bytes = bits / 8;

  const dm = decimals < 0 ? 0 : decimals;
  const k = 1000;

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / k ** i).toFixed(dm);
};

export const formatBitsAsMegaBitsOnly = (
  bits: number,
  decimals = 2
): number => {
  if (bits === 0) {
    return 0;
  }

  const megabits = 1000000;
  const dm = decimals < 0 ? 0 : decimals;

  return parseFloat((bits / megabits).toFixed(dm));
};

export const formatBitsAsGigaBitsOnly = (
  bits: number,
  decimals = 2
): number => {
  if (bits === 0) {
    return 0;
  }

  const megabits = 1000000000;
  const dm = decimals < 0 ? 0 : decimals;

  return parseFloat((bits / megabits).toFixed(dm));
};

export const formatBitsByUnitScale = (
  bits: number,
  unitScale: number,
  decimals = 2
): number => {
  if (bits === 0) {
    return 0;
  }

  const dm = decimals < 0 ? 0 : decimals;

  return parseFloat((bits / unitScale).toFixed(dm));
};

/**
 * Formats a phone number like "(999) 999-9999" which should be the
 * standard across the site.
 */
export const formatPhoneNumber = (value: string): string => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) {
    return value;
  }

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) {
    return phoneNumber;
  }

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

/**
 * Clean up the phone number to remove non-digit values ex: "1234567890"
 * standard across the site.
 */
export const cleanUpPhoneNumber = (value: string): string => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) {
    return value;
  }

  // clean the input for any non-digit values.
  return value.replace(/[^\d]/g, '');
};

/**
 * Format address into a single line string
 * @returns `11921 N Mo Pac Expy, Austin, TX, 78759`
 */
export const formatAddress = (
  address: ILocationAddress | null,
  onlyCityStateZip?: boolean,
  joinStateAndZip?: boolean,
  joinFriendlyName?: boolean
) => {
  if (!address) {
    return '';
  }
  const { friendlyName, state, zip } = address;
  let { street, city } = address;

  street = capitalizeEachWord(street);
  city = capitalizeEachWord(city);

  if (onlyCityStateZip) {
    street = '';
  }

  if (joinStateAndZip) {
    const stateAndZip = [state, zip].filter(Boolean).join(' ');
    if (joinFriendlyName && friendlyName) {
      return [friendlyName, street, city, stateAndZip]
        .filter(Boolean)
        .join(', ');
    }
    return [street, city, stateAndZip].filter(Boolean).join(', ');
  }

  return [street, city, state, zip].filter(Boolean).join(', ');
};

/**
 * Get a date range starting with the provided ending date.
 * @param daysIntoPast the number of days in the past for calc the start date
 * @returns The start and end times will be in ISO 8601 string time.
 */
export const getPastDateRange = (
  endDate: number,
  daysIntoPast: number
): {
  endTime: string;
  startTime: string;
} => {
  const endTime = dayjs(endDate).toISOString();
  const startTime = dayjs(endTime)
    .subtract(daysIntoPast, 'day')
    .startOf('day')
    .toISOString();

  return {
    endTime,
    startTime,
  };
};

export const formatSecsAsTime = (seconds: number): string => {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
};

/**
 * Formats start and end date into a range
 * ie: 4/23/2020 - 5/23/2020
 */
export const formatDateRange = (
  startDate: string,
  endDate: string,
  format: string
): string => {
  return `${dayjs(startDate).format(format)} - ${dayjs(endDate).format(
    format
  )}`;
};

/**
 * Flatten object properties
 * @param obj the object to flatten
 */
const flattenObj = (obj: any) => {
  // The object which contains the
  // final result
  const result = {};

  // loop through the object "ob"
  for (const i in obj) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof obj[i] === 'object' && !Array.isArray(obj[i])) {
      const temp = flattenObj(obj[i]);
      for (const j in temp) {
        // Store temp in result
        (result as any)[`${i}.${j}`] = (temp as any)[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      (result as any)[i] = obj[i];
    }
  }
  return result;
};

export const toArray = <T>(item: T | T[] | undefined): T[] => {
  if (item === undefined) {
    return [];
  }

  if (isArray(item)) {
    return item;
  }

  return [item];
};

export const isValidIsoDate = (date: string): boolean => {
  const pattern =
    /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
  return pattern.test(date);
};

/**
 * Sort a list by obj field
 * @param items list of items to sort
 * @param sortField field to sort by
 */
export const sortBy = <T>(items: T[], sortField: string, desc = false): T[] => {
  const sortedItems = items.sort((a, b) => {
    const flatA = flattenObj(a) as any;
    const flatB = flattenObj(b) as any;

    let aSortValue;
    let bSortValue;

    if (sortField === 'event') {
      aSortValue = flatA.messageLabel
        ? flatA.messageDetails
          ? String(flatA.messageLabel) +
            ': '.concat(String(flatA.messageDetails))?.toLowerCase()
          : String(flatA.messageLabel)?.toLowerCase()
        : '';

      bSortValue = flatB.messageLabel
        ? flatA.messageDetails
          ? String(flatB.messageLabel) +
            ': '.concat(String(flatB.messageDetails))?.toLowerCase()
          : String(flatB.messageLabel)?.toLowerCase()
        : '';
    } else {
      aSortValue = flatA[sortField]
        ? String(flatA[sortField])?.toLowerCase()
        : '';
      bSortValue = flatB[sortField]
        ? String(flatB[sortField])?.toLowerCase()
        : '';
    }
    if (sortField === 'street') {
      return aSortValue.localeCompare(bSortValue, 'en', { numeric: true });
    }
    if (aSortValue === bSortValue) {
      return 0;
    }

    if (aSortValue === undefined || aSortValue === null) {
      return 1;
    }

    if (bSortValue === undefined || bSortValue === null) {
      return -1;
    }

    // special case for sorting bandwidth ex. '100 Mbps'
    if (sortField === 'bandwidth') {
      const a = aSortValue.split(' ');
      const b = bSortValue.split(' ');
      // sort by Mbps/Gbps first
      if (a[1] > b[1]) {
        return 1;
      }
      if (a[1] < b[1]) {
        return -1;
      }
      // sort by bandwidth # second
      if (parseInt(a[0]) < parseInt(b[0])) {
        return 1;
      }
      return -1;
    }

    if (isValidIsoDate(aSortValue)) {
      if (dayjs(aSortValue).valueOf() > dayjs(bSortValue).valueOf()) {
        return -1;
      }
    } else if (aSortValue > bSortValue) {
      return -1;
    }

    return 1;
  });

  return desc ? sortedItems.reverse() : sortedItems;
};

/**
 * separate words that begin with a capital letter.
 * For example, 'JohnDoe' to 'John Doe'.
 * @param val string to separate
 */
export const separateWords = (val: string | null | undefined): string =>
  val ? val.split(/(?=[A-Z])/).join(' ') : '';

/**
 * Formats Location Friendly Name according to these rules.
 * - Use "dashes" for location names that come back 'null' from the server.
 *    This usually indicates that the user never modified the name.
 *
 * - Use Empty string ('') for location names that come back as an empty string "" from the server.
 *    This usually indicates that the user at one point modified it then removed the value thus leaving an empty string.
 *
 */
export const formatFriendlyName = (
  friendlyName: Maybe<string> | undefined
): string => {
  if (friendlyName === null || friendlyName === undefined) {
    return '--';
  }

  return friendlyName || '';
};

/**
 * General Regex for alphanumeric
 */
export const alphanumericRegex = /^[A-Za-z\d\-\s#]+$/;

/**
 * General RegEx for email pattern
 */
export const emailNoApostrophes = /^[^']+$/;
export const emailNoApostrophesMessage =
  "Apostrophes (') are not allowed in email.";
export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailPatternMessage = 'Please enter a valid email address.';

export const isValidEmail = (email: string): boolean => {
  return emailPattern.test(email.replace(/\s/g, '').toLowerCase());
};

/**
 * Mask for phone numbers
 */
export const maskedPhoneNumber = (phone: string): string => {
  if (!phone) {
    return '';
  }

  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  phone = phone.replace(/-/g, '');
  return phone;
};

export const toBase64 = (value: string): string => window.btoa(value);
export const fromBase64 = (value: string): string => window.atob(value);

// todo: revisit this bug and create a story for global phone formatting
//  and validation. We also should have clear error messaging stating that
//  the phone number cannot start with 0 or 1. This phoneRegex will check to make
//  sure the first digit is not a 0 or 1 in this format (xxx) xxx-xxxx
// Parity fix with AIM bug (https://jira.charter.com/browse/ENTDEL-8649)
export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const phoneErrorMessage = 'Please enter a 10-digit phone number.';
export const phoneRequiredMessage = 'Please enter a phone number';
// This new regex matches AIM's on ticket ENTDEL-8649 to check the phone number cannot start with 0 or 1.
export const phoneValidateRegex =
  /^\(?([2-9]{1})\)?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const phoneValidationMessage = 'Please enter a valid phone number.';

/**
 * General phone validation
 */
export const validatePhoneNumber = (phone: string): boolean => {
  const match = phoneRegex;
  return !!(!!phone && phone.match(match));
};

/**
 * General days remaining in momth
 */
export const getDaysRemainingInMonth = () => {
  const endOfMonth = dayjs().endOf('month');
  return endOfMonth.diff(dayjs(), 'days');
};

/**
 * Remove dashes from the phone number
 */
export const cleanPhoneNumber = (phone: string): string => {
  return phone.replace(/-/g, '');
};

export const areaCodeBlackList: string[] = [
  '264',
  '268',
  '242',
  '246',
  '441',
  '284',
  '345',
  '767',
  '809',
  '829',
  '849',
  '473',
  '658',
  '876',
  '664',
  '869',
  '758',
  '784',
  '721',
  '868',
  '649',
  '900',
  '976',
];

/**
 * Check if area code is blacklisted
 * @param phone pass masked phone number
 */
export const isBlackListedAreaCode = (phone: string): boolean => {
  const areaCode = phone.slice(0, 3);
  return areaCodeBlackList.includes(areaCode);
};

/**
 * Get error message displayed for blacklisted numbers
 * @param phone pass masked phone number
 */
export const phoneBlacklistMsg = (phone: string): string => {
  const areaCode = phone.slice(0, 3);
  const rerouteMsg = `We cannot reroute calls to area code ${areaCode}.`;
  const caribbeanMsg = 'Please use a non-Caribbean number.';
  return areaCode !== '900' && areaCode !== '976' ? caribbeanMsg : rerouteMsg;
};

export const formatNumberWithCommas = (number: number): string => {
  return number.toLocaleString('en-US');
};

export const getClientInfo = () => {
  const { navigator } = window;
  const windowUserAgent = window.navigator.userAgent;

  // NOTE: You can use these valid user agents to debug this code if it has issues.
  // const testCases = [
  //   'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko',
  //   'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36 OPR/88.0.4412.40',
  //   'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.246',
  //   'Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:15.0) Gecko/20100101 Firefox/15.0.1',
  //   'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_2) AppleWebKit/601.3.9 (KHTML, like Gecko) Version/9.0.2 Safari/601.3.9',
  //   'Mozilla/5.0 (iPhone14,3; U; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) Version/10.0 Mobile/19A346 Safari/602.1',
  //   'Mozilla/5.0 (Linux; Android 10; SM-G996U Build/QP1A.190711.020; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Mobile Safari/537.36',
  //   'Mozilla/5.0 (Linux; Android 9; SM-G973U Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.100 Mobile Safari/537.36',
  //   'Mozilla/5.0 (Windows Phone 10.0; Android 6.0.1; Microsoft; RM-1152) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/52.0.2743.116 Mobile Safari/537.36 Edge/15.15254',
  //   'Mozilla/5.0(iPad; U; CPU iPhone OS 3_2 like Mac OS X; en-us) AppleWebKit/531.21.10 (KHTML, like Gecko) Version/4.0.4 Mobile/7B314 Safari/531.21.10',
  // ];

  const getPlatformInfoFromUserAgent = (userAgent: string) => {
    const desktopPlatformMatches =
      userAgent.match(/\b\w*(macintosh|windows|linux)/i) || [];
    const mobilePlatformMathces =
      userAgent.match(/\b\w*(iphone|ipad|windows phone|android)/i) || [];
    const isMobile = mobilePlatformMathces.length !== 0;

    const platformName =
      (isMobile ? mobilePlatformMathces[0] : desktopPlatformMatches[0]) ||
      'unrecognized';

    return {
      name: platformName,
      isMobile: isMobile || null,
    };
  };

  const getBrowserInfo = (userAgent: string) => {
    let tem;
    let browserString = '';
    const browserMatches =
      userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

    let browserInfo = browserMatches[2]
      ? [browserMatches[1], browserMatches[2]]
      : [navigator.appName, navigator.appVersion, '-?'];

    if (/\b\w*(trident)/i.test(browserMatches[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      browserInfo = ['IE', tem[1]] || [];
      browserString = `IE ${tem[1] || ''}`;
    }

    if (browserMatches[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      browserInfo = [browserMatches[1], browserMatches[2]];
      if (tem != null) {
        browserString = tem[1].replace('OPR', 'Opera');
        browserInfo = [browserString, tem[2]] || [];
      }
    }

    return {
      name: browserInfo[0] || 'unrecognized',
      version: browserInfo[1] || null,
    };
  };

  const platformInfo = getPlatformInfoFromUserAgent(windowUserAgent);

  const browserInfo = getBrowserInfo(windowUserAgent);

  return {
    userAgent: windowUserAgent,
    os: platformInfo.name,
    isMobile: platformInfo.isMobile,
    browser: browserInfo.name,
    browserVersion: browserInfo.version,
  };
};

/**
 * To check input string is PID or not.
 */
export const isPID = (input: string) => {
  return /^P\d{7}$/i.test(input);
};

export const showLocationNameColumnOnInventoryPage = (
  locations: ILocationAddress[]
): boolean => {
  // When the superset of all locations has all friendlyName values only set to
  // to either '' (which means the user unset a previous friendlyName) or '--'
  // (which means the user never set a friendlyName at all and BE defaults this
  // unset field to null, and the BE returns "friendlyName": null), then HIDE
  // the "Location Name" column. Else, if there is at least one valid string
  // encountered for friendlyName, then we must SHOW the "Location Name" column.
  const filteredLocs = locations.filter((item) => {
    return (
      item.friendlyName?.trim().length === 0 ||
      item.friendlyName === '--' || // Consider the FE reformatting the BE null to '--'
      item.friendlyName === null // Consider the BE null
    );
  });

  return !(filteredLocs?.length === locations?.length);
};
