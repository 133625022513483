import dayjs from 'dayjs';
import QuantumService from '../services/quantumService';
import { getErrorMessage, getErrorName } from '../../utils/errorUtils';
import { Maybe } from '../../types/types.generated';
import { IPiNxtIdToken } from '../types/pinxt';

export const bufferToString = (buffer: Uint8Array): string => {
  const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const state = [];
  for (let i = 0; i < buffer.byteLength; i += 1) {
    const index = buffer[i] % CHARSET.length;
    state.push(CHARSET[index]);
  }
  return state.join('');
};

export const base64URLEncode = (str: string): string => {
  return window
    .btoa(str)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

/**
 * Generates a code verifier required by PiNxt
 */
export const generateCodeVerifier = (): string => {
  const buffer = new Uint8Array(32);
  window.crypto?.getRandomValues(buffer);
  return base64URLEncode(bufferToString(buffer));
};

export const generateChallenge = async (
  codeVerifier: string
): Promise<string> => {
  // encode as UTF-8
  const encodedStr = new TextEncoder().encode(codeVerifier);

  // hash the message
  const hashBuffer = await window.crypto.subtle.digest('SHA-512', encodedStr);

  const bufferString: string = String.fromCharCode.apply(
    null,
    new Uint8Array(hashBuffer) as unknown as number[]
  );

  return base64URLEncode(bufferString);
};

export const getIdTokenObj = (idToken: Maybe<string>): Maybe<IPiNxtIdToken> => {
  if (!idToken) {
    return null;
  }

  return JSON.parse(window.atob(idToken.split('.')[1]));
};

/**
 * Extract Legacy JWT Token from idToken.
 */
export const getLegacyToken = (idToken: Maybe<string>): Maybe<string> => {
  if (!idToken) {
    return null;
  }

  try {
    const idTokenObj = getIdTokenObj(idToken);
    if (!idTokenObj) {
      return null;
    }

    return idTokenObj['legacy-entpor-token'];
  } catch (error) {
    QuantumService.jsError({
      appErrorType: 'application',
      msgFeatureName: 'auth',
      appErrorMessage: `Failed to extract Legacy JWT Token from idToken: ${getErrorMessage(
        error
      )}`,
      appErrorCode: getErrorName(error) || 'Unexpected',
    });
    return null;
  }
};

export const hasTokenExpired = (tokenExp: string): boolean => {
  return dayjs(parseInt(tokenExp, 10)).isBefore(dayjs());
};
