import { useContext } from 'react';
import { TdcsConfigContext } from '../contexts/tdcsConfigContext';

/**
 * Hook to get the TDCS config
 */
export const useTdcsConfig = () => {
  const { tdcsConfig } = useContext(TdcsConfigContext);
  return tdcsConfig;
};
