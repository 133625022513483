import React, { useEffect } from 'react';
import { FeatureToggleName } from '../../config/featureToggles/featureToggles.types';
import { useAnalytics } from '../../hooks/useAnalytics';
import { IRoutePageInfo } from '../../types/common';
import { OrganizationFeature } from '../../types/organizationFeature';
import { OrgServiceType } from '../../types/orgServiceType';
import { UserCapability } from '../../types/userCapability';
import { getPageName } from './PageRouteUtils';
import ProtectedRoute from './ProtectedRoute';
import { useAppConfig } from '../../hooks/useAppConfig';

interface IPageRouteProps {
  userCapabilities?: UserCapability | UserCapability[];
  orgServices?: OrgServiceType | OrgServiceType[];
  orgFeatures?: OrganizationFeature | OrganizationFeature[];

  /** Any feature toggles related to this route */
  featureToggle?: FeatureToggleName;

  /**
   * Any custom prerequisites that need to be checked before showing this route
   * If the prerequisites are not met, return what to show the user.
   */
  prerequisites?: () => React.ReactElement | null;

  /** Analytics info for this route  */
  pageInfo?: IRoutePageInfo;

  /** Whether this route requires user authentication to access */
  isProtected?: boolean;

  /** The Components this route renders */
  children: React.ReactElement;
}

/**
 * Use page route wrapper to capture Page View Analytics and set the correct page Title
 */
const PageRoute = ({
  userCapabilities,
  orgServices,
  orgFeatures,
  featureToggle,
  pageInfo,
  isProtected = true,
  prerequisites,
  children,
}: IPageRouteProps) => {
  const {
    app: { displayName },
  } = useAppConfig();

  const { trackPageView } = useAnalytics();

  let content = children;
  let meetsPrerequisites = true;

  if (prerequisites) {
    const resultsJsx = prerequisites();
    if (resultsJsx) {
      meetsPrerequisites = false;
      content = resultsJsx;
    }
  }

  if (pageInfo) {
    const pageName = getPageName(pageInfo);
    if (pageName) {
      document.title = pageName ? `${pageName} | ${displayName}` : displayName;
    }
  } else {
    document.title = displayName;
  }

  useEffect(
    () => {
      // Don't count as page view hit if it doesn't meet Prerequisites
      if (!pageInfo || !meetsPrerequisites) {
        return;
      }

      let { siteSection } = pageInfo;
      const { siteSubSection, siteSubSubSection, skipPageView } = pageInfo;

      const pageName = getPageName(pageInfo);
      siteSection = siteSection ?? pageName;

      // pageViews for protected routes will be handled in ProtectedRoute when AuthUser is populated
      if (!skipPageView && !isProtected && siteSection) {
        trackPageView(siteSection, siteSubSection, siteSubSubSection);
      }
    },
    // omit trackPageView as deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageInfo, isProtected]
  );

  // If protected route, wrap the returned content in ProtectedRoute component
  if (meetsPrerequisites && isProtected) {
    content = (
      <ProtectedRoute
        userCapabilities={userCapabilities}
        orgServices={orgServices}
        orgFeatures={orgFeatures}
        featureToggle={featureToggle}
        pageInfo={pageInfo}
      >
        {children}
      </ProtectedRoute>
    );
  }

  return content;
};

export default PageRoute;
