// Packages
import React, { useState, useEffect, useRef, useMemo } from 'react';
import classNames from 'classnames';

// Hooks
import { KiteButton, KiteIcon, KiteTooltip } from '@kite/react-kite';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import { useAuthUser } from '../../hooks/useAuthUser';

// Types
import { INavItem } from '../../types/common';

// Components
import NavItem from '../NavItem/NavItem';
import UserInfo from '../UserInfo/UserInfo';

// Styles
import './SideNav.scss';

export interface ISideNavProps {
  /** List of NavItems, each with a label, relative url path and icon name for KiteIcon, with optional property for a bottom border */
  navItems: INavItem[];
  /** Allows styling for SideNav on mobile view to always be expanded */
  fill?: boolean | undefined;
  /** Determines wether the navigation drawer is open or closed for mobile view */
  setDrawerIsOpen?: (boolean: boolean) => void;
  /** Sets wether items within the `SideNav` can be tabbed to based on the drawer being open/closed in mobile view */
  tabIndex?: number;
  drawerIsOpen?: boolean;
}

/**
 * A dynamic and accessible side navigation menu with hover labels and expand/collapse functionality.
 */

const SideNav = ({
  navItems,
  fill,
  setDrawerIsOpen,
  drawerIsOpen,
  tabIndex,
}: ISideNavProps) => {
  const screenWidth = useScreenWidth();
  const [isOpen, setIsOpen] = useState(!!(screenWidth >= 1280 || fill));

  const [navWidth, setNavWidth] = useState(isOpen ? '280px' : '65px');
  const { authUser } = useAuthUser();

  useEffect(() => {
    if (screenWidth < 1280 && screenWidth > 960) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    // eslint-disable-next-line
  }, [screenWidth]);

  useEffect(() => {
    if (isOpen) {
      setNavWidth('280px');
    } else {
      setNavWidth('65px');
    }
  }, [isOpen]);

  const navItemsByGroup = useMemo(
    () =>
      navItems.reduce((acc: { [key: string]: INavItem[] }, navItem) => {
        const { group } = navItem;

        if (!acc[group]) {
          acc[group] = [navItem];
        } else {
          acc[group].push(navItem);
        }
        return acc;
      }, {}),
    [navItems]
  );

  const renderNavItems = useMemo(
    () =>
      Object.entries(navItemsByGroup).map(([group, items], index) => {
        return items.map((ni, i) => {
          let topBorder = false;
          if (group !== 'locations') {
            if (!i && index) {
              topBorder = true;
            }
          }
          const { label, path, icon, notifications } = ni;
          return (
            <NavItem
              key={path}
              label={label}
              path={path}
              icon={icon}
              topBorder={topBorder}
              isOpen={isOpen}
              tabIndex={tabIndex}
              notificationBadgeData={notifications}
            />
          );
        });
      }),
    [navItemsByGroup, isOpen, tabIndex]
  );

  return (
    <div
      className="side-nav-placeholder"
      style={{ width: fill ? '100%' : navWidth, height: fill ? '100%' : '' }}
    >
      <div
        className={classNames({
          'side-nav-container': true,
          'side-nav-container-internal': authUser?.isInternalUser,
        })}
        style={{ width: isOpen ? '280px' : navWidth }}
      >
        <nav
          className={classNames({
            'side-nav': true,
            'side-nav--closed': !isOpen,
          })}
          style={{
            width: fill && !isOpen ? '100%' : navWidth,
            height: fill ? '100%' : '',
          }}
        >
          <ul>
            {screenWidth <= 960 && (
              <li className="side-nav__user-info">
                {drawerIsOpen && (
                  <UserInfo
                    isSideNav
                    setDrawerIsOpen={drawerIsOpen && setDrawerIsOpen}
                  />
                )}
              </li>
            )}
            {renderNavItems}
          </ul>
          {screenWidth > 960 && (
            <div className="side-nav__btn-container">
              <KiteButton
                className="side-nav__btn"
                variant="borderless"
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
                aria-label="Left Navigation"
                size="sm"
              >
                <KiteTooltip
                  direction="right"
                  id="toggle-menu"
                  text={isOpen ? 'Collapse' : 'Expand'}
                  type="icon"
                >
                  <KiteIcon
                    icon={isOpen ? 'ki-chevron-left' : 'ki-chevron-right'}
                  />
                </KiteTooltip>
              </KiteButton>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};

export default SideNav;
