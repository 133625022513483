/**
 * Analytics SDR for Wireless Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Wireless Internet';

export const wirelessAnalytics: IAnalyticSdr = {
  wirelessAccounts: {
    siteSection,
    siteSubSection: 'Wireless Internet Accounts',
  },
  wirelessAccountDetails: {
    siteSection,
    siteSubSection: 'Wireless Internet Account Details',
    buttonClicks: {
      planTabClick: {
        /**
         * Set dynamically in the code. Could be any of the following:
         * 1 GB Data Pool, 5 GB Data Pool, 10 GB Data Pool, 20 GB Data Pool, Unlimited Plan or Backup Plan
         */
        itemClicked: '',
      },
      downloadCsvBtn: {
        pageRegion: '', // Set dynamically in the code based on the tab
        itemClicked: 'Download CSV',
      },
      backBtn: {
        itemClicked: 'Back',
      },
    },
  },
  wirelessDeviceDetails: {
    siteSection,
    siteSubSection: 'Wireless Internet Device Details',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      backBtn: {
        itemClicked: 'Back',
      },
      fiberLinkBtn: {
        itemClicked: 'Fiber Internet',
      },
      fiberModalCloseBtn: {
        itemClicked: 'Close',
        pageRegion: 'Fiber Internet Modal',
      },
      fiberModalVisitBtn: {
        itemClicked: 'View More Fiber Internet Details',
        pageRegion: 'Fiber Internet Modal',
      },
      fiberModalXBtn: {
        itemClicked: 'x',
        pageRegion: 'Fiber Internet Modal',
      },
    },
  },
};
