/**
 * Initializes App Config Context that gives all components access to the
 * App Config object from the useAppConfig hook.
 *
 * Also responsible for initializing any upfront services needed and pulling in env-vars
 * created by CI Pipeline.
 *
 * @see https://chalk.charter.com/display/ERSPM/FE+Configs.
 */

import React, { createContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { KiteProgressIndicator } from '@kite/react-kite';
import { datadogRum } from '@datadog/browser-rum';
import { createAppConfig } from '../config/appConfig/appConfig';
import { IAppConfig } from '../config/appConfig/appConfig.types';
import { fetchEnvConfig } from '../config/envConfig/envConfig';
import { IEnvConfig } from '../config/envConfig/envConfig.types';
import QuantumService from './services/quantumService';
import MockService from './services/mockService';
import appConfigDefault from './utils/appConfigDefault';

interface IAppConfigContext {
  appConfig: IAppConfig;
}

export const AppConfigContext = createContext<IAppConfigContext>({
  appConfig: appConfigDefault,
});

export const AppConfigContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [appConfig, setAppConfig] = useState<IAppConfig>();

  const onSuccess = (data: IEnvConfig): void => {
    const config = createAppConfig(data);
    if (!config.isProd) {
      console.debug('App Config', config);
    }

    const { app, dataDog } = config;

    if (dataDog.enabled) {
      const { site, service, env, rum } = config.dataDog;
      datadogRum.init({
        applicationId: rum.applicationId,
        clientToken: rum.clientToken,
        site,
        service,
        env,
        version: app.versionName,
        sessionSampleRate: data.dd_session_sample_rate || 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
        enableExperimentalFeatures: ['feature_flags'],
      });
      datadogRum.startSessionReplayRecording();
    }

    // Init all the upfront service integrations
    MockService.init(config)
      .then(() => {
        return QuantumService.init(config)
          .finally(() => {
            setAppConfig(config);
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  useQuery<IEnvConfig, Error>(['envConfig'], fetchEnvConfig, {
    onSuccess,
  });

  const contextValue = useMemo(() => ({ appConfig: appConfig! }), [appConfig]);
  return (
    <AppConfigContext.Provider value={contextValue}>
      {appConfig && children}
      {!appConfig && (
        <div className="display-loader">
          <KiteProgressIndicator id="app-config-loader" />
        </div>
      )}
    </AppConfigContext.Provider>
  );
};
