// Packages
import React, { useState, useRef } from 'react';
import classNames from 'classnames';

// Hooks
import { KiteIcon, KiteButton } from '@kite/react-kite';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useAppConfig } from '../../hooks/useAppConfig';
import { useAuthSession } from '../../hooks/useAuthSession';
import { useFeatureToggles } from '../../hooks/useFeatureToggles';

// Components

// Types
import { FeatureToggleName } from '../../config/featureToggles/featureToggles.types';

// Utils
import { UserCapability } from '../../types/userCapability';

// Styles
import './InternalUserInfo.scss';

/** A button containing information about the internal user that also displays a dropdown menu with links when clicked */

const InternalUserInfo = () => {
  const { authUser, portalAccountGUID, hasCapability } = useAuthUser();
  const { aimSessionTransfer } = useAuthSession();
  const InternalUserInfoRef = useRef(null);
  const [displayUserOptions, setDisplayUserOptions] = useState(false);
  const name = `${authUser?.firstName} ${authUser?.lastName}`;
  const username = authUser?.pid;

  const { isFeatureToggledOn } = useFeatureToggles();
  const showUpdatedOrgPageLayout = isFeatureToggledOn(
    FeatureToggleName.MiscOrganizationPageLayoutUpdate
  );

  const {
    api: { cxdo },
  } = useAppConfig();

  useOutsideClick(InternalUserInfoRef, () => {
    if (displayUserOptions) {
      setDisplayUserOptions(false);
    }
  });

  return (
    <div ref={InternalUserInfoRef}>
      <KiteButton
        className="internal-user-info"
        size="shrinkwrap"
        variant="borderless"
        onClick={() => setDisplayUserOptions(!displayUserOptions)}
        aria-expanded={displayUserOptions}
      >
        <span className="internal-user-info__menu">
          <KiteIcon icon="ki-person-f" className="person" />
          <KiteIcon
            icon={`ki-chevron-${
              displayUserOptions ? 'up-circle-f' : 'down-circle-f'
            }`}
          />
        </span>
      </KiteButton>
      <div
        className={classNames({
          'internal-user-info__options': true,
          'internal-user-info__options--open': displayUserOptions,
        })}
      >
        <div>
          <p className="internal-user-info__options-name">{name}</p>
          <p className="internal-user-info__options-username">{username}</p>
        </div>
        <a onClick={aimSessionTransfer} href={`${cxdo}/profile`}>
          View Profile
        </a>
        {!showUpdatedOrgPageLayout && (
          <>
            <a
              onClick={aimSessionTransfer}
              href={`${cxdo}/organizations/summary/${portalAccountGUID}`}
            >
              View Organization Summary
            </a>
            {(hasCapability(UserCapability.EditOrganization) ||
              hasCapability(UserCapability.SuperAdmin)) && (
              <a
                onClick={aimSessionTransfer}
                href={`${cxdo}/organizations/edit/${portalAccountGUID}`}
              >
                Edit Organization
              </a>
            )}
          </>
        )}
        {showUpdatedOrgPageLayout && (
          <a
            onClick={aimSessionTransfer}
            href={`${cxdo}/organizations/view/${portalAccountGUID}`}
          >
            View Organization Profile
          </a>
        )}
      </div>
    </div>
  );
};

export default InternalUserInfo;
