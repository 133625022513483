/**
 * Analytics SDR for MSS Domain.
 * All information should be entered and referenced form here into the components.
 * This information is also referenced for the SDR browser in Storybook.
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Managed Security';

export const mssAnalytics: IAnalyticSdr = {
  mssInventory: {
    siteSection,
    siteSubSection: 'Managed Security Inventory',
    buttonClicks: {
      portalLink: {
        itemClicked: 'Managed Security Portal', // was 'Managed Security Portal Link'
      },
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
    },
  },
};
