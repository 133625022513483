/* eslint-disable consistent-return */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

import { IStorageFile, IStoragePastFile } from '../../../types/types.generated';

// Commonly used Regex for
// Lowercase and uppercase letters A-Z, numerals 0-9, underscores, pounds, hyphens, and spaces
export const fileNamePattern = /^[0-9a-zA-Z_\-# ]+$/;

// File name pattern with extensions
export const fileNamePatternWithExt =
  /^[0-9a-zA-Z_\-# ]+\.(csv|CSV|doc|DOC|docx|DOCX|jpg|JPG|mov|MOV|mp3|MP3|pdf|PDF|png|PNG|ppt|PPT|pptx|PPTX|txt|TXT|xls|XLS|xlsx|XLSX|gif|GIF|msg|MSG|xml|XML|zip|ZIP)$/;

export enum StorageTabs {
  CURRENT_FILES_TAB = 'Current Files',
  ARCHIVED_FILES_TAB = 'Archived Files',
}

export const canViewFile = (file: IStorageFile | IStoragePastFile) => {
  const cantViewMimetypes = [
    'xls',
    'doc',
    'ppt',
    'mov',
    'pptx',
    'xlsx',
    'docx',
    'csv',
  ];
  if (file) {
    return !cantViewMimetypes.includes(file?.mimetype.toLowerCase());
  }
};

// Object and message defining the file types allowed to Upload in Storage.
export const storageAcceptedFiles = {
  'audio/*': ['.mp3'],
  'video/*': ['.mov'],
  'image/*': ['.png', '.jpg'],
  'application/pdf': ['.pdf'],
  'application/xlsx': ['.xls', '.xlsx'],
  'application/doc': ['.doc', '.docx'],
  'application/txt': ['.txt', '.csv'],
  'application/ppt': ['.ppt', '.pptx'],
};
export const storageAllowedFormatsMessage =
  'Your file can be up to 100 MB. Supported files are .csv, .doc, .docx, .jpg, .mov, .mp3, .pdf, .png, .ppt, .pptx, .txt, .xls, .xlsx.';
