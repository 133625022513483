import { FeatureToggleName, IFeatureToggles } from './featureToggles.types';
import { featureTogglesDefault } from './envs/default';
import { featureTogglesDemo } from './envs/demo';
import { featureTogglesDev } from './envs/dev';
import { featureTogglesEngprod } from './envs/engprod';
import { featureTogglesMock } from './envs/mock';
import { featureTogglesQA } from './envs/qa';
import { featureTogglesUat } from './envs/uat';
import { AppEnv } from '../appConfig/appConfig.types';

/**
 * Create Feature Toggle Config Object
 * Get hard-coded feature flags based on the current environment.
 * @param appEnv current app environment
 *
 * @see https://chalk.charter.com/display/ERSPM/FE+Configs.
 */
export const getLocalFeatureTogglesByEnv = (
  appEnv: AppEnv
): IFeatureToggles => {
  switch (appEnv) {
    case AppEnv.Mock:
      return featureTogglesMock;

    case AppEnv.Dev:
      return featureTogglesDev;

    case AppEnv.QA:
      return featureTogglesQA;

    case AppEnv.Uat:
      return featureTogglesUat;

    case AppEnv.EngProd:
      return featureTogglesEngprod;

    case AppEnv.Demo:
    case AppEnv.DemoStage:
      return featureTogglesDemo;

    default:
      return featureTogglesDefault;
  }
};

/**
 * Check whether a feature toggle is enabled
 *
 * @param toggleNameToCheck a set of feature toggles to check
 * @param availableTogglesMap the set of all the feature toggles available for this env
 * @param isProd Whether we are in Prod or not
 */
export const isFeatureToggleEnabled = (
  toggleNameToCheck: FeatureToggleName,
  availableTogglesMap: Map<FeatureToggleName, boolean>,
  isProd: boolean
): boolean => {
  // Before checking Feature Toggles Map, return sessionStorage value if it has been updated manually.
  // DEMO is considered a Prod Env, so DEMO will not be able to use SessionStorage for updating values on the fly.
  if (!isProd) {
    return sessionStorage.getItem(`TOGGLE_${toggleNameToCheck}`) === 'true';
  }

  // If no Feature Toggles available, return false
  if (!availableTogglesMap || availableTogglesMap.size === 0) {
    return false;
  }

  // If Toggle Name is not found, default to false.
  return availableTogglesMap.get(toggleNameToCheck) ?? false;
};
