import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import PageRoute from '../../components/Routes/PageRoute';
import { pageStateAnalytics } from './types/pageStatesAnalytics';

const PageError = lazy(
  () => import(/* webpackChunkName: "page-error" */ './PageError')
);

const PageNotFound = lazy(
  () => import(/* webpackChunkName: "page-not-found" */ './PageNotFound')
);

const { pageError, pageNotFound } = pageStateAnalytics;

export const pageStatesRoutes = (
  <>
    <Route
      path="/500"
      element={
        <PageRoute isProtected={false} pageInfo={pageError}>
          <PageError />
        </PageRoute>
      }
    />
    <Route
      path="/404"
      element={
        <PageRoute isProtected={false} pageInfo={pageNotFound}>
          <PageNotFound />
        </PageRoute>
      }
    />
  </>
);
