/**
 * Analytics SDR for MRS Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Manage Router Service';

export const mrsAnalytics: IAnalyticSdr = {
  mrsInventory: {
    siteSection,
    siteSubSection: 'Manage Router Service Inventory',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      viewDetailsBtn: {
        itemClicked: 'View Details',
      },
    },
    menuSelections: {
      viewMenu: {
        itemClicked: 'Views',
      },
    },
  },
  mrsDetails: {
    siteSection,
    siteSubSection: 'Manage Router Service Details',
    buttonClicks: {
      totalTrafficTab: {
        pageRegion: 'Performance Metrics',
        itemClicked: 'Total Traffic',
      },
      trafficByPortTab: {
        pageRegion: 'Performance Metrics',
        itemClicked: 'Traffic by Port',
      },
      circuitBtn: {
        itemClicked: 'MRS Circuit',
      },
      backBtn: {
        itemClicked: 'Back',
      },
    },
    menuSelections: {
      deviceTab: {
        itemClicked: 'Device Tab',
      },
    },
  },
};
