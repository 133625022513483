/**
 * Extract the page title from the browser tab.
 * @returns page title
 */
export const currentPageTitle = (): string =>
  document.title.split('|')[0].trim();

/**
 * List of soft error codes that shouldn't be logged as failed in the API metrics.
 * In the future this will be managed in https://verbatim.spectrumtoolbox.com/
 */
export const softErrorCodes: string[] = [
  // SelfRegistrationValidateUserProfileByBan
  'SE.IMP.321', // This account is associated with more than one organization.
  'SE.IMP.320', // The information entered doesn’t match our records.
  'SE.IMP.544', // The information entered is not Active and Commercial.
  'SE.IMP.545', // Ban and Security do not match.
  'SE.IMP.154', // Invalid step for registration step.
  'SE.IMP.159', // Registration Session has expired.

  // SelfRegistrationValidateUserProfileByCircuitId
  'SE.IMP.149', // No Registration Session Found
  'SE.IMP.154', // Invalid step for registration step
  'SE.IMP.159', // Registration Session has expired.
  'SE.IMP.547', // Rate limit hit for Shelob endpoint
  'SE.IMP.546', // There was a problem communicating with Shelob system
  'SE.IMP.235', // Unable to validate Circuit ID

  // SelfRegistrationUserProfileInformation
  'SE.IMP.326', // Existing Registered User Profile Found.
  'SE.IMP.327', // Charter Employees cannot self-register.
  'SE.IMP.543', // Existing Pending User Profile Found.

  // SelfRegistrationCreateUser
  'SE.IMP.233', // Unable to locate a single AIM organization for users validated authorization
  'SE.IMP.253', // Unable to locate an organization in AIM
  'SE.IMP.254', // Query key doesn't match with cxId or sfId

  // SendRegistrationEmail
  'SE.IMP.146', // User is already Active.
  'SE.IMP.148', // Invalid user organization count.
  'SE.IMP.147', // Sending email failed
  'SE.IMP.160', // Email not sent

  // ValidateUserActivationInformation
  'SE.IMP.150', // Registration Session is missing data
  'SE.IMP.159', // Registration Session has expired.
  'SE.IMP.096', // User not found
  'SE.IMP.149', // No Registration Session Found

  // ValidateActivationToken
  'SE.IMP.161', // Max attempts for registration session reached
  'SE.IMP.149', // No Registration Session Found

  // ForgotPasswordVerifyUsername
  'SE.IMP.167', // Current user status Pending. User status must be Active
  'SE.IMP.161', // Max attempts for registration session reached. , id : 9c3925fa-d731-4de4-baac-510ecce54821

  // ChangePasswordUpdateUserPassword (AIM)
  'SE.IMP.174', // Matches one of your last 5 passwords used.
  'SE.IMP.179', // Password cannot match username.

  // Get SSO URL from AIM - TODO: These should not be errors but success responses
  'SE.IMP.193', // Organization does not have the required service.
  'SE.IMP.194', // User does not have capability for SSO.

  'SE.ManagedNetworkEdge.004', // Account/Service accounts not found for provided portalAccountGUID
  'SE.CUSTOMER.005', // Account doesn't exist
  'SE.ENTPOR.008', // Service Account Not Found for the requested PAG.
  'SE.FIBER.002', // circuitId doesn't have any fiber internet services
  'SE.WIRELESS.004', // Account does not exist.
  'SE.WIRELESS.007', // No data found.
  'SE.MRS.003', // No locations found for locationId: <loc-id>

  '3104', // PINXT - Invalid Login Credentials
  '1030', // PINXT - Invalid Auth Code
  '2076', // PINXT - Access token expired
];
