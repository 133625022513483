export enum AppEnv {
  Mock = 'mock',
  Dev = 'dev',
  QA = 'qa',
  Uat = 'uat',
  EngProd = 'engprod',
  Prod = 'prod',
  DemoStage = 'demo-stage',
  Demo = 'demo',
}

export enum PiTargetBg {
  Default = 'default',
  Blue = 'blue',
  Green = 'green',
}

// https://prism.spectruminternal.com/SpectrumEnterpriseNet@Web/quantum-resources/event-cases/SpectrumEnterpriseNet_startSession_generic/portals
export interface IAppConfigQuantumConfig {
  readonly domain: string;
  readonly appName: string;
  readonly appVersion: string;
  readonly packageVersion: string;
  readonly requirementsVersion: string;
  readonly appEnvName: string;
  readonly startSession: string;
}

export interface IAppConfigApiHosts {
  /** PiNxt Gateway host to access APIs in Backend */
  readonly piHost: string;

  /** EntServices hosted auth endpoint used for fetching a anonymous token */
  readonly authAnonymousHost: string;

  /** Direct API Backend host thats behind PiNxt Gateway   */
  readonly directHost: string;

  readonly directLegacyHost: string;
  readonly cxdoHost: string;
  readonly directSSOHost: string;
  readonly wsVoiceCallGuardUpdatesHost: string;
  readonly wsBusinessInternetRestartUpdatesHost: string;
}

export interface IAppConfig {
  /** Whether or not the app environment is a prod based environment.  */
  readonly isProd: boolean;

  /** Whether or not the app environment is the true, public facing, production environment. */
  readonly isTrueProd: boolean;

  /** Whether or not the app environment is a mock based environment. */
  readonly isMock: boolean;

  /** Whether or not the app environment is running as Demo. */
  readonly isDemo: boolean;

  /** Whether or not the app is running locally */
  readonly isLocal: boolean;

  readonly app: {
    /** Short App name or abbreviation  */
    readonly shortName: string;

    /** Full app name in proper Title Case.  Used in page titles, etc. */
    readonly displayName: string;

    /** The environment the application is running as */
    readonly env: AppEnv;

    /** The current application version  */
    readonly version: string;

    /** The app version name according to the branch / ci_commit_ref_slug */
    readonly versionName: string;
  };

  readonly api: {
    readonly useDirectHost: boolean;
    readonly useAimDirectHost: boolean;

    /** PiNxt Auth endpoint used for all OAuth handshaking calls */
    readonly auth: string;

    /** EntServices hosted auth endpoint used for fetching a anonymous token */
    readonly authAnonymous: string;

    /** The Federated GraphQL path for anonymous calls that require a PiNxt anonymous token   */
    readonly anonymous: string;

    /** The Federated Graphql path for authenticated calls that require a PiNxt auth token  */
    readonly graphql: string;

    /**
     * The full endpoint for any left over REST based requests (<2%).
     * Used for those features dealing with file uploads.
     * */
    readonly restEndpoint: string;

    /** The full url to OpsCenter's UI */
    readonly cxdo: string;

    /** SSO Host url used for all SSO operations */
    readonly sso: string;

    /** Websocket Host url for Voice Call Guard Updates Subscriptions Messaging Server */
    readonly wsVoiceCallGuardUpdatesHost: string;

    /** Websocket Host url for Business Internet Device and Equipment Restart Updates Subscriptions Messaging Server */
    readonly wsBusinessInternetRestartUpdatesHost: string;

    readonly video: string;
  };

  readonly mapbox: {
    readonly devToken: string;
    readonly prodToken: string;
    readonly streetStyle: string;
    readonly heatmapStyle: string;
  };

  readonly amcharts: {
    readonly license: string;
  };

  readonly analytics: {
    readonly quantum: {
      readonly enabled: boolean;
      readonly devEndpoint: string;
      readonly prodEndpoint: string;

      readonly config: IAppConfigQuantumConfig;
    };

    readonly adobe: {
      readonly enabled: boolean;
      readonly devEndpoint: string;
      readonly prodEndpoint: string;
    };
  };

  readonly auth: {
    readonly targetBg: PiTargetBg;
    readonly loginUrl: string;
    readonly piClientId: string;
    readonly gqlServicesClientId: string;
    readonly piEsso: {
      clientId: string;
      redirectUri: string;
    };
    readonly autoLogout: {
      /** The number of mins the user must remain idle until the warning prompt */
      readonly idleWarningMins: number;

      /** The number of mins AFTER the warning prompt shown before logging them out */
      readonly postIdleWarnMins: number;
    };
  };

  readonly dataDog: {
    readonly enabled: boolean;
    readonly site: string;
    readonly service: string;
    readonly env: string;
    readonly rum: {
      readonly applicationId: string;
      readonly clientToken: string;
    };
  };

  readonly tdcs: {
    readonly enabled: boolean;
    readonly apiKey: string;
    readonly host: string;
    readonly config: {
      readonly appVersion: string;
      readonly clientType: string;
      readonly deviceId: string;
      readonly osVersion: string;
      readonly deviceType: string;
    };
    readonly localConfig: string; // for local development only
  };

  // Access Google API library
  readonly google: {
    readonly siteKey: string;
  };
}
