// Packages
import React from 'react';
import { EntHeader } from '@enterprise/EntHeader';

import UserInfo from '../UserInfo/UserInfo';
import InternalUserBanner from '../InternalUserBanner/InternalUserBanner';

// Utils
import { IAuthUser } from '../../contexts/types/authUser';

// Styles
import './Header.scss';

export interface IHeaderProps {
  user: IAuthUser;
  drawerIsOpen: boolean;
  setDrawerIsOpen?: (drawerIsOpen: boolean) => void;
}

/**
 * Header that is rendered on every page through the Layout component.
 */

const Header = ({ user, drawerIsOpen, setDrawerIsOpen }: IHeaderProps) => {
  return (
    <header className="header">
      {user?.isInternalUser && <InternalUserBanner user={user} />}
      <EntHeader
        className="header__auth-header"
        variant="sticky"
        mobileMenuOpen={drawerIsOpen}
        onMobileMenuClick={setDrawerIsOpen}
      >
        <UserInfo />
      </EntHeader>
    </header>
  );
};

export default Header;
