/* eslint-disable no-empty-function */
/* eslint-disable react/jsx-no-constructed-context-values */
// FIXME: Remove the above disabled ESLinting issues and fix them in this file.

// Packages
import React, { createContext, useCallback, useState } from 'react';

// ===============================================================================
// This context provider was created for Mock, until we find a way to mock
// web sockets.  Instead of setting and getting mock data off the session storage
// (bc when I first implemented it like this using session storage alone, I would often
// trounce the queue array as items were being added and removed off the queue array,
// bc of the unsynchronized nature of using the session storage .getItem and .setItem methods.
// So, by using the React useState, I am allowed to synchronize the queue array, and synchronize the
// addition and removal of items to and from the queue array.
// ===============================================================================
export interface IBusinessInternetEquipmentRestartMockQueueState {
  restartEquipmentQueue: string[];
  isChangeDueToAddition: boolean;
  equipmentIdentifierJustAdded: string | null;
}

export interface IBusinessInternetEquipmentRestartMockQueueingContext {
  mockRestartEquipmentQueueChanged: IBusinessInternetEquipmentRestartMockQueueState;
  mockAddItemToRestartEquipmentQueue: (equipmentIdentifier: string) => void;
  mockRemoveItemFromRestartEquipmentQueue: (
    equipmentIdentifier: string
  ) => void;
  mockResetRestartEquipmentQueue: () => void;
}

const defaultContextValue: IBusinessInternetEquipmentRestartMockQueueingContext =
  {
    mockRestartEquipmentQueueChanged: {
      restartEquipmentQueue: [],
      isChangeDueToAddition: false,
      equipmentIdentifierJustAdded: null,
    },
    mockAddItemToRestartEquipmentQueue: (equipmentIdentifier: string) => {},
    mockRemoveItemFromRestartEquipmentQueue: (
      equipmentIdentifier: string
    ) => {},
    mockResetRestartEquipmentQueue: () => {},
  };

export const BusinessInternetEquipmentRestartMockQueueingContext =
  createContext<IBusinessInternetEquipmentRestartMockQueueingContext>(
    defaultContextValue
  );

export const BusinessInternetEquipmentRestartMockQueueingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [
    mockRestartEquipmentQueueChanged,
    setMockRestartEquipmentQueueChanged,
  ] = useState<IBusinessInternetEquipmentRestartMockQueueState>({
    restartEquipmentQueue: [],
    isChangeDueToAddition: false,
    equipmentIdentifierJustAdded: null,
  });

  const mockAddItemToRestartEquipmentQueue = useCallback(
    (equipmentIdentifier: string) => {
      // Concatenate the new equipment id to the end of
      // the array.  FIFO. First in, first out queue.
      setMockRestartEquipmentQueueChanged((prevState) => {
        const newState: IBusinessInternetEquipmentRestartMockQueueState = {
          restartEquipmentQueue: [
            ...prevState.restartEquipmentQueue,
            equipmentIdentifier,
          ],
          isChangeDueToAddition: true,
          equipmentIdentifierJustAdded: equipmentIdentifier,
        };
        return newState;
      });
    },
    []
  );

  const mockRemoveItemFromRestartEquipmentQueue = useCallback(
    (equipmentIdentifier: string) => {
      setMockRestartEquipmentQueueChanged((prevState) => {
        const newState: IBusinessInternetEquipmentRestartMockQueueState = {
          restartEquipmentQueue: [
            ...prevState.restartEquipmentQueue.filter(
              (item) => item !== equipmentIdentifier
            ),
          ],
          isChangeDueToAddition: false,
          equipmentIdentifierJustAdded: null,
        };
        return newState;
      });
    },
    []
  );

  const mockResetRestartEquipmentQueue = useCallback(() => {
    setMockRestartEquipmentQueueChanged({
      restartEquipmentQueue: [],
      isChangeDueToAddition: false,
      equipmentIdentifierJustAdded: null,
    });
  }, []);

  return (
    <BusinessInternetEquipmentRestartMockQueueingContext.Provider
      value={{
        mockRestartEquipmentQueueChanged,
        mockAddItemToRestartEquipmentQueue,
        mockRemoveItemFromRestartEquipmentQueue,
        mockResetRestartEquipmentQueue,
      }}
    >
      {children}
    </BusinessInternetEquipmentRestartMockQueueingContext.Provider>
  );
};
