/**
 * Analytics SDR for Locations Domain.
 * This information should match the values in the SDR Excel file referenced here:
 * https://chalk.charter.com/display/ERSPM/FE+Analytics
 */

import { IAnalyticSdr } from '../../../types/analyticsSdr';

const siteSection = 'Locations';

export const locationAnalytics: IAnalyticSdr = {
  location: {
    siteSection,
    siteSubSection: 'Locations',
    buttonClicks: {
      downloadCsvBtn: {
        itemClicked: 'Download CSV',
      },
      viewSummaryLink: {
        itemClicked: 'View Summary',
      },
      locationNameLink: {
        itemClicked: 'Location Name',
      },
      networkLink: {
        itemClicked: 'Network',
      },
      wavelengthLink: {
        itemClicked: 'Wavelength',
      },
      eneLink: {
        itemClicked: 'Enterprise Network Edge',
      },
      mneLink: {
        itemClicked: 'Managed Network Edge',
      },
      mrsLink: {
        itemClicked: 'Managed Router Service',
      },
      fiberInternetLink: {
        itemClicked: 'Fiber Internet',
      },
      businessInternetLink: {
        itemClicked: 'Business Internet',
      },
      businessVoiceLink: {
        itemClicked: 'Business Voice',
      },
      wirelessLink: {
        itemClicked: 'Wireless Internet',
      },
      voiceTrunkingLink: {
        itemClicked: 'Voice Trunking',
      },
      unifiedCommLink: {
        itemClicked: 'Unified Communications',
      },
      videoLink: {
        itemClicked: 'Video',
      },
      cloudSecurityLink: {
        itemClicked: 'Cloud Security',
      },
      managedSecurityLink: {
        itemClicked: 'Managed Security',
      },
      secureAccessLink: {
        itemClicked: 'Secure Access',
      },
      secureInternetLink: {
        itemClicked: 'Secure Internet',
      },
      openTicketsLink: {
        itemClicked: 'Open Tickets',
      },
      activeReportsLink: {
        itemClicked: 'Active Reports',
      },
    },
    menuSelections: {
      serviceDetailsMenu: {
        itemClicked: 'Service Details',
      },
      viewMenu: {
        itemClicked: 'Views',
      },
    },
  },
  locationSummary: {
    siteSection,
    siteSubSection: 'Location Summary',
    buttonClicks: {
      networkViewDetailsLink: {
        itemClicked: 'View Details',
        pageRegion: 'Network',
      },
      netShowPeakUtil: {
        pageRegion: 'Network',
        itemClicked: 'Show Peak Utilization',
      },
      netHidePeakUtil: {
        pageRegion: 'Network',
        itemClicked: 'Hide Peak Utilization',
      },
      eneEdgePortalLink: {
        pageRegion: 'Enterprise Network Edge',
        itemClicked: 'Enterprise Network Edge Portal', // was 'Enterprise Network Edge Portal Link'
      },
      fiberViewDetailsLink: {
        itemClicked: 'View Details',
        pageRegion: 'Fiber Internet',
      },
      fiberShowPeakUtil: {
        pageRegion: 'Fiber Internet',
        itemClicked: 'Show Peak Utilization',
      },
      fiberHidePeakUtil: {
        pageRegion: 'Fiber Internet',
        itemClicked: 'Hide Peak Utilization',
      },
      secureInternetViewDetailsLink: {
        itemClicked: 'View Details',
        pageRegion: 'Secure Internet',
      },
      secureInternetShowPeakUtil: {
        pageRegion: 'Secure Internet',
        itemClicked: 'Show Peak Utilization',
      },
      secureInternetHidePeakUtil: {
        pageRegion: 'Secure Internet',
        itemClicked: 'Hide Peak Utilization',
      },
      mssPortalLink: {
        pageRegion: 'Managed Security',
        itemClicked: 'Managed Security Portal', // was 'Managed Security Portal Link'
      },
      edgePortalLink: {
        pageRegion: 'Managed Network Edge',
        itemClicked: 'Managed Network Edge Portal',
      },
      managerPortalLink: {
        pageRegion: 'Managed Network Edge',
        itemClicked: 'Site and Conference Manager Portal',
      },
      closePortalDialogBtn: {
        pageRegion: 'Unified Comm Portal Modal',
        itemClicked: 'Close',
      },
      continuePortalDialogBtn: {
        pageRegion: 'Unified Comm Portal Modal',
        itemClicked: 'Continue',
      },
      backBtn: {
        itemClicked: 'Back',
      },
      cloudSecurityPortalLink: {
        pageRegion: 'Cloud Security',
        itemClicked: 'Managed Network Edge Portal',
      },
      secureAccessPortalLink: {
        pageRegion: 'Secure Access',
        itemClicked: 'Duo Admin Portal',
      },
      createReport: {
        itemClicked: 'Create Report',
      },
      createTicket: {
        itemClicked: 'Create Ticket',
      },
      unifiedCommViewDetailsLink: {
        itemClicked: 'View Details',
        pageRegion: 'Unified Communications',
      },
      voiceViewDetailsLink: {
        itemClicked: 'View Details',
        pageRegion: 'Voice Trunking',
      },
      wirelessViewDetailsLink: {
        itemClicked: 'View Details',
        pageRegion: 'Wireless Internet',
      },
      wirelessViewAccountDetailsLink: {
        itemClicked: 'View Account Details',
        pageRegion: 'Wireless Internet',
      },
      businessInternetViewDetailsLink: {
        pageRegion: 'Business Internet',
        itemClicked: 'View Details',
      },
    },
  },
};
