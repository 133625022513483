// Packages
import { KiteCard } from '@kite/react-kite';
import React from 'react';

// Hooks

// Components
import TrafficByPortNotFoundSvg from 'static/images/traffic-by-port.svg?react';
// Utils

// Styles
import './NoDataMessage.scss';

export interface INoDataMessageProps {
  /** A custom class name to be passed to the top element in the component */
  className?: string;
  title?: string;
  message?: string;
}

/** Update this description, which will appear in the Storybook documentation */

const NoDataMessage = ({ className, title, message }: INoDataMessageProps) => {
  return (
    <KiteCard className="no-data-message">
      <TrafficByPortNotFoundSvg />
      <p>
        <strong>{title}</strong>
        <br />
        {message}
      </p>
    </KiteCard>
  );
};

export default NoDataMessage;
