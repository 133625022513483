import { useContext } from 'react';
import { AnalyticsContext } from '../contexts/analyticsContext';

export const useAnalytics = () => {
  const { pageView, buttonEvent, menuSelectionEvent, jsError, jsLog } =
    useContext(AnalyticsContext);

  return {
    /** Track the interaction of a Button click */
    trackButtonEvent: buttonEvent,

    /** Track a page view */
    trackPageView: pageView,

    /** Track the interaction of a Drop Down Menu click */
    trackMenuSelectionEvent: menuSelectionEvent,

    /** Track a "hard" Javascript error that show in Prism Dashboard */
    trackJsError: jsError,

    /** Track "soft" Javascript errors or general logging events of all levels. */
    trackJsLog: jsLog,
  };
};
